import { React, useContext, useEffect, Fragment, useState } from 'react';
import { Nav, Navbar, Row, Col, Image, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { AiTwotonePushpin } from 'react-icons/ai';
import { BsPinFill } from 'react-icons/bs';
import { FaExternalLinkAlt, FaRegQuestionCircle } from 'react-icons/fa';

import { capitalize } from 'helpers/utils';
import { navbarBreakPoint, topNavbarBreakpoint } from 'config';
import { useBreakpoints } from 'hooks/useBreakpoints';
import classNames from 'classnames';
import AppContext, { AuthWizardContext, StoreContext } from 'context/Context';
import Flex from 'components/common/Flex';
import Logo from 'components/common/Logo';
import NavbarVerticalMenu from './NavbarVerticalMenu';
import ToggleButton from './ToggleButton';
import NavbarTopDropDownMenus from 'components/navbar/top/NavbarTopDropDownMenus';
import bgNavbar from 'assets/img/generic/bg-navbar.png';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import CrmLogo from 'assets/img/logo/acodax-crm-logo-light.svg';

const NavbarVertical = () => {
  const {
    config: {
      navbarPosition,
      navbarStyle,
      isNavbarVerticalCollapsed,
      showBurgerMenu,
      pinSidebar
    }
  } = useContext(AppContext);
  const { user } = useContext(AuthWizardContext);
  const Translate = useAxisproTranslate();
  const { breakpoints } = useBreakpoints();
  const { setConfig } = useContext(AppContext);
  const { store } = useContext(StoreContext);

  const menuLinksFromLocalStorage = localStorage.getItem('ACODAX_MENU_LINKS');
  let parsedMenuLinksFromLocalStorage = menuLinksFromLocalStorage
    ? JSON.parse(menuLinksFromLocalStorage)
    : [];
  let menuLinks = Array.isArray(parsedMenuLinksFromLocalStorage)
    ? parsedMenuLinksFromLocalStorage
    : parsedMenuLinksFromLocalStorage
    ? Object.values(parsedMenuLinksFromLocalStorage)
    : [];

  const generateDynamicKeys = children => {
    const dynamicKeys = {};
    for (let i = 1; i <= (children?.length || 0); i++) {
      dynamicKeys[`subName${i}`] = children[i - 1].to;
    }
    return dynamicKeys;
  };

  const generateNestedChildren = child => {
    return (child.children || []).map(nestedChild => ({
      name: Translate(nestedChild.name),
      to: nestedChild.to,
      icon: '',
      tag: nestedChild?.tag
    }));
  };
  const getSubdomain = () => {
    const host = window.location.hostname;
    const parts = host.split('.');
    return parts[0];
  };
  const handleOpenCrmLink = subdomain => {
    // Replace the URL below with the one you want to open
    window.open(`https://${subdomain}.crm.acodax.com/`, '_blank');
  };
  const generateChildren = route => {
    return (route.children || []).map(child => ({
      name: Translate(child.name),
      to: child.to,
      icon: '',
      children: generateNestedChildren(child),
      tag: child?.tag,
      ...generateDynamicKeys(child.children)
    }));
  };
  const [routes] = useState([
    {
      label: 'Dashboard',
      labelDisable: true,
      children: [
        {
          name: Translate('Dashboard'),
          to: 'dashboard',
          icon: 'chart-pie'
        }
      ]
    },
    ...(menuLinks?.map(route => ({
      label: Translate(route.name),
      tag: route?.tag,
      labelDisable: true,
      children: [
        {
          name: Translate(route.name),
          to: route.to,
          icon: route.icon,
          children:
            route.display_type !== 'tile' ? generateChildren(route) : '',
          tag: route?.tag,
          ...generateDynamicKeys(route.children)
        }
      ]
    })) || [])
    // ...(user?.menu_links?.map(route => ({
    //   label: Translate(route.name),
    //   tag: route?.tag,
    //   labelDisable: true,
    //   children: [
    //     {
    //       name: Translate(route.name),
    //       to: route.to,
    //       icon: route.icon,
    //       children:
    //         route.display_type !== 'tile' ? generateChildren(route) : '',
    //       tag: route?.tag,
    //       ...generateDynamicKeys(route.children)
    //     }
    //   ]
    // })) || [])
  ]);

  const HTMLClassList = document.getElementsByTagName('html')[0].classList;

  // useEffect(() => {
  //   if (!pinSidebar) {
  //     if (isNavbarVerticalCollapsed) {
  //       HTMLClassList.add('navbar-vertical-collapsed');
  //     } else {
  //       HTMLClassList.remove('navbar-vertical-collapsed');
  //     }
  //     return () => {
  //       HTMLClassList.remove('navbar-vertical-collapsed-hover');
  //     };
  //   }
  // }, [isNavbarVerticalCollapsed, HTMLClassList]);

  //Control mouseEnter event
  // let time = null;
  const handleMouseEnter = () => {
    // if (isNavbarVerticalCollapsed) {
    //   time = setTimeout(() => {
    //     setConfig('isNavbarVerticalCollapsed', true);
    //     HTMLClassList.add('navbar-vertical-collapsed-hover');
    //   }, 1000);
    // }
  };
  const handleMouseLeave = () => {
    // setConfig('isNavbarVerticalCollapsed', false);
    // HTMLClassList.remove('navbar-vertical-collapsed-hover');
    // clearTimeout(time);
  };

  const show = false;

  const handlePinSideBar = () => {
    setConfig('pinSidebar', !pinSidebar);
  };

  useEffect(() => {
    const navbarElement = document.querySelector('.navbar-vertical');
    const handleClick = () => {
      if (!isNavbarVerticalCollapsed)
        setConfig('isNavbarVerticalCollapsed', true);
    };

    if (navbarElement && navbarElement?.offsetWidth === 50) {
      handleClick();
    }
  }, [isNavbarVerticalCollapsed, setConfig]);

  const NavbarLabel = ({ label }) => (
    <Nav.Item as="li">
      <Row className="mt-3 mb-2 navbar-vertical-label-wrapper">
        <Col xs="auto" className="navbar-vertical-label navbar-vertical-label">
          {label}
        </Col>
        <Col className="ps-0">
          <hr className="mb-0 navbar-vertical-divider"></hr>
        </Col>
      </Row>
    </Nav.Item>
  );

  return (
    <Navbar
      expand={navbarBreakPoint}
      className={classNames('navbar-vertical', {
        [`navbar-${navbarStyle}`]: navbarStyle !== 'transparent'
      })}
      variant="light"
      style={{ backgroundColor: '#213E56' }}
    >
      <Flex alignItems="center" className="mb-2">
        {isNavbarVerticalCollapsed && !pinSidebar ? (
          <>
            <ToggleButton />
            <Logo
              at="navbar-vertical"
              isNavbarVerticalCollapsed={isNavbarVerticalCollapsed}
              width={35}
              pinSidebar={pinSidebar}
            />
          </>
        ) : (
          <>
            <Logo
              at="navbar-vertical"
              isNavbarVerticalCollapsed={isNavbarVerticalCollapsed}
              width={35}
              pinSidebar={pinSidebar}
            />
            <ToggleButton />
          </>
        )}
      </Flex>
      <Navbar.Collapse
        in={showBurgerMenu}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          backgroundImage:
            navbarStyle === 'vibrant'
              ? `linear-gradient(-45deg, rgba(0, 160, 255, 0.86), #0048a2),url(${bgNavbar})`
              : 'none'
        }}
      >
        <div
          className="navbar-vertical-content scrollbar d-flex align-items-center justify-content-between"
          style={{
            backgroundColor: '#213E56'
          }}
        >
          <div style={{ overflow: 'scroll' }}>
            <Nav className="flex-column" as="ul">
              {routes &&
                routes.length > 0 &&
                routes.map((route, index) => (
                  <Fragment key={index}>
                    {!route.labelDisable && (
                      <NavbarLabel label={capitalize(route.label)} />
                    )}
                    <NavbarVerticalMenu routes={route.children} />
                  </Fragment>
                ))}
            </Nav>

            <>
              {navbarPosition === 'combo' && (
                <div className={`d-${topNavbarBreakpoint}-none`}>
                  <div className="navbar-vertical-divider">
                    <hr className="navbar-vertical-hr my-2" />
                  </div>
                  <Nav navbar>
                    <NavbarTopDropDownMenus />
                  </Nav>
                </div>
              )}
            </>
          </div>
          <div>
            {isNavbarVerticalCollapsed && !pinSidebar ? (
              ''
            ) : (
              <div
                className={`d-flex align-items-center justify-content-between mb-0 text-white  ${
                  store?.globalConfiguration?.show_nav_bar_crm_link && 'mb-5'
                }`}
              >
                <div className="d-flex align-items-center justify-content-center gap-1">
                  {store?.latestVersion?.version && (
                    <>
                      <p className="m-0 ms-2">
                        Version {store?.latestVersion?.version}
                      </p>
                      <FaRegQuestionCircle
                        size={12}
                        title="Press Ctrl + F5 to get updated with latest version!"
                      />
                    </>
                  )}
                </div>
                <Button
                  variant="transparent"
                  style={{ boxShadow: 'none' }}
                  className="p-0 me-1"
                  title={!pinSidebar ? 'Pin Sidebar' : 'UnPin Sidebar'}
                  onClick={handlePinSideBar}
                >
                  {pinSidebar ? (
                    <BsPinFill size={23} className="text-white" />
                  ) : (
                    <AiTwotonePushpin size={25} className="text-white" />
                  )}
                </Button>
              </div>
            )}
            {store?.globalConfiguration?.show_nav_bar_crm_link && (
              <div
                className="d-flex align-items-center justify-content-between mb-0 text-white p-2 mb-0 nav-bar-crm-link"
                onClick={() => handleOpenCrmLink(getSubdomain())}
              >
                <img src={CrmLogo} /> <FaExternalLinkAlt size={12} />
              </div>
            )}
          </div>
        </div>
      </Navbar.Collapse>

      {HTMLClassList.contains('navbar-vertical-collapsed') ||
      isNavbarVerticalCollapsed ||
      !show ? (
        ''
      ) : (
        <div
          style={{
            width: '100%'
          }}
          className={
            breakpoints.down('xl')
              ? 'd-flex align-items-center justify-content-center'
              : 'd-flex align-items-center justify-content-center mt-5'
          }
        >
          {user &&
            user.branch &&
            user.branch.company_logo_url &&
            user.branch.company_logo_url !== '' &&
            user.branch.company_logo_url !==
              'https://axiserp.s3.ap-south-1.amazonaws.com/default/axisproerp-dummy.png' && (
              <Image
                src={user.branch.company_logo_url}
                fluid
                style={{
                  height: '5rem',
                  width: '40%',
                  display: breakpoints.down('xl') ? 'none' : ''
                }}
              />
            )}
        </div>
      )}
    </Navbar>
  );
};

NavbarVertical.propTypes = {
  label: PropTypes.string
};

export default NavbarVertical;
