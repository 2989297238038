import { React, useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Col, Form, Modal, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { MdOutlinePayments } from 'react-icons/md';

import { showToast } from '../Toast/toast';
import { CancelButton, SaveButton } from '../Buttons/CommonButton';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import ErrorAlert from '../Error/ErrorAlert';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';

function AddPaymentTerms({ show, onHide, setFormData, data }) {
  const Translate = useAxisproTranslate();
  const [paymentTermData, setPaymentTermData] = useState({
    terms_indicator: '',
    terms: '',
    days_before_due: '',
    day_in_following_month: ''
  });
  const [onSave, setOnSave] = useState(false);
  const [formError, setFormError] = useState({});

  useEffect(() => {
    setPaymentTermData(prevPaymentTermData => ({
      ...prevPaymentTermData,
      terms: data
    }));
  }, [data]);

  const handleFieldChange = e => {
    setPaymentTermData({
      ...paymentTermData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    setFormError({});
    setOnSave(true);

    axios({
      method: 'post',
      url: 'settings/payment-terms',
      data: paymentTermData
    })
      .then(response => {
        if (response.data.success === true) {
          setPaymentTermData({
            terms_indicator: '',
            terms: '',
            days_before_due: '',
            day_in_following_month: ''
          });
          onHide();
          showToast(response.data.message, 'success');
          setFormData(prev => ({
            ...prev,
            payment_term_id: response.data.data.id,
            payment_term_id_ref: {
              label: response.data.data.terms,
              value: response.data.data.id
            }
          }));
        }
        setOnSave(false);
      })
      .catch(error => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          console.log(error);
        }
        setOnSave(false);
      });
  };

  const handleCancel = () => {
    onHide();
    setFormError({});
  };
  const handleClearForm = () => {
    setPaymentTermData({
      terms_indicator: '',
      terms: '',
      days: '',
      days_before_due: '',
      day_in_following_month: ''
    });
  };

  return (
    <Modal
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      className="modal-with-overlay"
      onHide={handleCancel}
    >
      <Card className="p-0">
        <Modal.Header
          className="d-flex flex-row align-items-center border-bottom"
          closeButton
        >
          <MdOutlinePayments size={25} className="text-dark me-1 m-0" />
          <h5 className="modal-form-heading">
            {Translate('Add ')}
            {Translate('Payment Terms')}
          </h5>
        </Modal.Header>
        <Form>
          <Card.Body className="pb-0">
            {!Object.keys(formError).length == 0 ? (
              <ErrorAlert setFormError={setFormError} formError={formError} />
            ) : (
              ''
            )}
            <Row>
              <Form.Group as={Col} md={12} sm={12} className="mb-3">
                <Row>
                  <Col xl={5} md={5} sm={12}>
                    <Form.Label className="require-data">
                      {Translate('Name')}
                    </Form.Label>
                  </Col>
                  <Col xl={7} md={7} sm={12}>
                    <Row className="d-flex flex-row">
                      <Col xl={9} md={9} sm={11} className="pe-sm-0 flex-2">
                        <Form.Control
                          type="text"
                          name="terms"
                          onChange={handleFieldChange}
                          value={paymentTermData.terms}
                          autoFocus
                        />
                      </Col>
                      <Col
                        xl={1}
                        md={1}
                        sm={1}
                        className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0"
                      >
                        {formError.terms ? (
                          <FormErrorPopover
                            id="terms"
                            totalErrorCount={Object.keys(formError).length}
                            errorMessage={formError.terms}
                          />
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group as={Col} md={12} sm={12} className="mb-3">
                <Row>
                  <Col xl={5} md={5} sm={12}>
                    <Form.Label className="require-data">
                      {Translate('Days')}
                    </Form.Label>
                  </Col>
                  <Col xl={7} md={7} sm={12}>
                    <Row className="d-flex flex-row">
                      <Col xl={9} md={9} sm={11} className="pe-sm-0 flex-2">
                        <Form.Control
                          type="number"
                          name="days"
                          onChange={handleFieldChange}
                          value={paymentTermData.days}
                          min={0}
                        />
                      </Col>
                      <Col
                        xl={1}
                        md={1}
                        sm={1}
                        className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0"
                      >
                        {formError.days ? (
                          <FormErrorPopover
                            id="days"
                            totalErrorCount={Object.keys(formError).length}
                            errorMessage={formError.days}
                          />
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Group>
            </Row>
          </Card.Body>
          <Card.Footer className="d-flex flex-row gap-2 justify-content-start border-top">
            <SaveButton
              handleSubmit={handleSubmit}
              onSave={onSave}
              createPermission={true}
              editPermission={true}
            />
            <CancelButton
              handleCancel={handleClearForm}
              onSave={onSave}
              clear={true}
            />
          </Card.Footer>
        </Form>
      </Card>
    </Modal>
  );
}

AddPaymentTerms.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  setFormData: PropTypes.func,
  data: PropTypes.any
};

export default AddPaymentTerms;
