import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { FaCirclePlus } from 'react-icons/fa6';
import { FaPlus } from 'react-icons/fa';
import { RxCross2 } from 'react-icons/rx';
import removeRefData from 'helpers/removeRefData';
import { showToast } from 'module/Common/Toast/toast';
import classNames from 'classnames';
import AddItemForm from './AddItemForm';
const ItemChooseModal = ({
  show,
  setShow,
  setFormData,
  editIndex,
  formData,
  setEditIndex
}) => {
  const Translate = useAxisproTranslate();
  const [itemFromData, setItemFromData] = useState({});
  const [shakeModal, setShakeModal] = useState(false);
  const handleHide = () => {
    setEditIndex(undefined);
    setItemFromData({});
    setShow(false);
  };
  const handleFieldChange = (e, action) => {
    if (action?.name === 'item' && action?.action === 'select-option') {
      setItemFromData(prev => ({
        ...prev,
        stock_id: e?.item?.stock_id,
        item_ref: e,
        item: e?.value,
        item_display_name: e?.item?.item_name,
        id: e?.item?.id,
        is_kit: '0',
        quantity: '1'
      }));
    } else {
      setItemFromData(prev => ({
        ...prev,
        [e?.target?.name]: e?.target?.value
      }));
    }
  };
  const handleEdit = index => {
    setFormData(prev => {
      const updatedDetails = [...prev.details];
      updatedDetails[index] = itemFromData;
      return {
        ...prev,
        details: updatedDetails
      };
    });
    setEditIndex(undefined);
    setItemFromData({});
    setShow(false);
  };
  const handleAddItem = async () => {
    const newItemFormData = removeRefData(itemFromData);
    if (newItemFormData.unit_price && newItemFormData.stock_id) {
      setFormData(prev => ({
        ...prev,
        details: [...prev.details, newItemFormData]
      }));
      setShow(false);
      setItemFromData({});
    } else {
      setShakeModal(true);
      setTimeout(() => {
        setShakeModal(false);
      }, 500);
      showToast(Translate('Please fill in all required fields'), 'error');
    }
  };
  useEffect(() => {
    if (editIndex === 0 || editIndex) {
      let EditData = formData?.details[editIndex];
      setItemFromData(() => ({
        item_ref: {
          label: EditData?.item_display_name,
          value: EditData?.id
        },
        item_display_name: EditData?.item_display_name,
        item: EditData?.id,
        id: EditData?.id,
        stock_id: EditData?.stock_id,
        unit_price: EditData?.unit_price,
        quantity: EditData?.quantity,
        description: EditData?.description
      }));
    }
  }, [editIndex, formData?.details]);
  return (
    <Modal
      show={show}
      onHide={handleHide}
      className={`modal-with-overlay ${classNames({
        'modal-shake': shakeModal
      })}`}
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold">
          {' '}
          <FaCirclePlus size={17} className=" me-1" color="green" />{' '}
          {Translate('ADD ITEMS')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AddItemForm
          formData={formData}
          itemFromData={itemFromData}
          handleFieldChange={handleFieldChange}
        />
      </Modal.Body>
      <Modal.Footer className="p-1 pe-0">
        <Button
          size="sm"
          variant="secondary"
          onClick={handleHide}
          className="fs--2"
        >
          <RxCross2 /> {Translate('CLOSE')}
        </Button>
        {editIndex === 0 || editIndex ? (
          <Button
            onClick={() => handleEdit(editIndex)}
            className="me-3 mt-1 fs--2"
            size="sm"
            variant="success"
          >
            <FaPlus className="me-1" /> {Translate('UPDATE ITEM')}
          </Button>
        ) : (
          <Button onClick={handleAddItem} className="me-3 mt-1 fs--2" size="sm">
            <FaPlus className="me-1" /> {Translate('ADD ITEM')}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};
ItemChooseModal.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  setFormData: PropTypes.func,
  setIsLoading: PropTypes.func,
  editIndex: PropTypes.string,
  formData: PropTypes.object,
  setEditIndex: PropTypes.func
};
export default ItemChooseModal;
