import { React, useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import LoadingScreenCover from 'components/common/loading-screen/LoadingScreenCover';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import SalesInvoiceTabView from 'module/Common/ViewPages/SalesInvoice/SalesInvoiceTabView';
import { Fragment } from 'react';
import AfterSavePaymentModal from 'components/after-save/AfterSavePaymentModal';

function SalesInvoiceInfo({
  salesInvoiceId,
  offCanvas,
  setShowVoucherDetailArea,
  voidTransactionReport,
  getData,
  interBranch
}) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const apiRequestAbortControllerRef = useRef(null);
  const [afterSavePaymentModal, setAfterSavePaymentModal] = useState({
    show: false,
    data: ''
  });

  const fetchData = useCallback(
    async (enableLoading = true) => {
      enableLoading && setLoading(true);
      apiRequestAbortControllerRef?.current?.abort();
      apiRequestAbortControllerRef.current = new AbortController();
      let url = voidTransactionReport
        ? 'settings/void-transaction-details'
        : `sales/sales-invoice/${salesInvoiceId}`;
      enableLoading && setLoading(true);
      try {
        const response = await axios.get(url, {
          params: {
            trans_type: voidTransactionReport ? 'SI' : '',
            trans_no: voidTransactionReport ? salesInvoiceId : ''
          },
          signal: apiRequestAbortControllerRef.current?.signal
        });
        if (response.data.success) {
          setLoading(false);
          setData(response.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [voidTransactionReport, salesInvoiceId]
  );

  useEffect(() => {
    fetchData();
  }, [salesInvoiceId, voidTransactionReport, fetchData]);

  const handlePaymentSuccess = paymentData => {
    if (paymentData) {
      setAfterSavePaymentModal({
        show: true,
        data: paymentData
      });
    }
  };

  const afterSave = {
    mainData: 'Payment Received Successfully!',
    printButton: {
      url: 'finance/customer_receipt/print',
      pageTitle: 'Customer Receipt Print View'
    },
    viewButton: {
      title: 'View Customer Receipt',
      url: '/finance/voucher-inquiry'
    }
  };

  return (
    <Fragment>
      {loading ? (
        <LoadingScreenCover>
          <LoadingScreen message="Looking for sales invoice info" />
        </LoadingScreenCover>
      ) : (
        <SalesInvoiceTabView
          salesInvoiceData={data}
          loading={loading}
          creditFormatted={
            data?.customer?.unused_credits_formatted
              ? data.customer.unused_credits_formatted
              : ''
          }
          credit={
            data?.customer?.unused_credits ? data.customer.unused_credits : ''
          }
          fetchData={fetchData}
          offCanvas={offCanvas}
          setShowVoucherDetailArea={setShowVoucherDetailArea}
          voidTransactionReport={voidTransactionReport}
          getData={getData}
          interBranch={interBranch}
          handlePaymentSuccess={handlePaymentSuccess}
        />
      )}
      <AfterSavePaymentModal
        show={afterSavePaymentModal?.show}
        onHide={() => {
          setAfterSavePaymentModal({
            show: false,
            data: ''
          });
        }}
        data={afterSavePaymentModal?.data}
        dataProcessing={loading}
        afterSave={afterSave}
      />
    </Fragment>
  );
}

SalesInvoiceInfo.propTypes = {
  salesInvoiceId: PropTypes.string,
  firstId: PropTypes.string,
  rendering: PropTypes.bool,
  offCanvas: PropTypes.bool,
  setShowVoucherDetailArea: PropTypes.func,
  voidTransactionReport: PropTypes.any,
  interBranch: PropTypes.bool,
  getData: PropTypes.func
};

export default SalesInvoiceInfo;

// import { React, useState, useEffect } from 'react';
// import axios from 'axios';
// import PropTypes from 'prop-types';

// import LoadingScreenCover from 'components/common/loading-screen/LoadingScreenCover';
// import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
// import SalesInvoiceTabView from 'module/Common/ViewPages/SalesInvoice/SalesInvoiceTabView';
// import { Fragment } from 'react';
// function SalesInvoiceInfo({
//   salesInvoiceId,
//   rendering,
//   firstId,
//   offCanvas,
//   setShowVoucherDetailArea,
//   voidTransactionReport,
//   getData,
//   interBranch
// }) {
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(false);

//   const fetchData = async isLoading => {
//     if (!rendering) {
//       if (firstId !== undefined) {
//         let url = voidTransactionReport
//           ? 'settings/void-transaction-details'
//           : `sales/sales-invoice/${firstId}`;
//         const fetchData = async () => {
//           try {
//             const response = await axios.get(url, {
//               params: {
//                 trans_type: voidTransactionReport ? 'SI' : '',
//                 trans_no: voidTransactionReport ? firstId : ''
//               }
//             });

//             if (response.data.success) {
//               setData(response.data.data);
//             }
//           } catch (error) {
//             console.log(error);
//           } finally {
//             setLoading(false);
//           }
//         };
//         fetchData();
//       }
//     } else {
//       if (salesInvoiceId !== undefined) {
//         if (isLoading) {
//           setLoading(true);
//         }
//         let url = voidTransactionReport
//           ? 'settings/void-transaction-details'
//           : `sales/sales-invoice/${salesInvoiceId}`;
//         await axios
//           .get(url, {
//             params: {
//               trans_type: voidTransactionReport ? 'SI' : '',
//               trans_no: voidTransactionReport ? salesInvoiceId : ''
//             }
//           })
//           .then(res => {
//             setLoading(false);
//             if (res.data.success) {
//               setData(res.data.data);
//             }
//           })
//           .catch(error => {
//             setLoading(false);
//             console.log(error);
//           });
//       }
//     }
//   };

//   useEffect(() => {
//     setLoading(true);
//     const timeoutId = setTimeout(() => {
//       fetchData(loading);
//     }, 200);
//     return () => {
//       clearTimeout(timeoutId);
//     };
//   }, [salesInvoiceId, firstId]);

//   return (
//     <Fragment>
//       {loading ? (
//         <LoadingScreenCover>
//           <LoadingScreen message="Looking for sales invoice info" />
//         </LoadingScreenCover>
//       ) : (
//         <SalesInvoiceTabView
//           salesInvoiceData={data}
//           loading={loading}
//           creditFormatted={
//             data && data.customer && data.customer.unused_credits_formatted
//               ? data.customer.unused_credits_formatted
//               : ''
//           }
//           credit={
//             data && data.customer && data.customer.unused_credits
//               ? data.customer.unused_credits
//               : ''
//           }
//           fetchData={fetchData}
//           offCanvas={offCanvas}
//           setShowVoucherDetailArea={setShowVoucherDetailArea}
//           voidTransactionReport={voidTransactionReport}
//           getData={getData}
//           interBranch={interBranch}
//         />
//       )}
//     </Fragment>
//   );
// }

// SalesInvoiceInfo.propTypes = {
//   salesInvoiceId: PropTypes.string,
//   firstId: PropTypes.string,
//   rendering: PropTypes.bool,
//   offCanvas: PropTypes.bool,
//   setShowVoucherDetailArea: PropTypes.func,
//   voidTransactionReport: PropTypes.any,
//   interBranch: PropTypes.bool,
//   getData: PropTypes.func
// };

// export default SalesInvoiceInfo;
