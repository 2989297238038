import useAxisproTranslate from 'hooks/useAxisproTranslate';
import React, { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { FaSyncAlt } from 'react-icons/fa';
import PropTypes from 'prop-types';
import axios from 'axios';
import { showToast } from 'module/Common/Toast/toast';
import IssueItemPopUp from '../../Common/IssueItem/IssueItemPopUp';

export default function WorkOrderIssueItemsButton({
  targetId,
  filters,
  disabled,
  successCallBack
}) {
  const Translate = useAxisproTranslate();
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(undefined);
  function handleModal(event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (disabled) return;
    if (show) {
      setShow(false);
      return;
    }
    setIsLoading(true);
    setData(undefined);
    axios
      .get('purchase/material-request/' + targetId, {
        params: filters || null
      })
      .then(response => {
        if (response?.data?.data) {
          setData(response?.data?.data);
          setShow(true);
          return;
        }
        showToast(
          Translate(
            'Something went wrong, please refresh the page and try again.'
          )
        );
      })
      .catch(error => {
        showToast(
          Translate(
            error?.response?.data?.message ??
              'Something went wrong, please refresh the page and try again.'
          )
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <>
      <Button
        size="sm"
        className="rounded px-2"
        onClick={handleModal}
        disabled={disabled}
      >
        <div className="d-flex flex-row align-items-center gap-1">
          {isLoading ? (
            <>
              <Spinner
                style={{
                  width: 10,
                  height: 10
                }}
              />
              <span className="small">{Translate('Loading')}</span>
            </>
          ) : (
            <>
              <FaSyncAlt size={10} />
              <span className="small">{Translate('Issue Items')}</span>
            </>
          )}
        </div>
      </Button>

      <IssueItemPopUp
        show={show}
        data={data}
        onHide={handleModal}
        successCallBack={successCallBack}
        isMaterialRequest
      />
    </>
  );
}

WorkOrderIssueItemsButton.propTypes = {
  targetId: PropTypes.string,
  disabled: PropTypes.bool,
  successCallBack: PropTypes.func,
  filters: PropTypes.object
};
