import axios from 'axios';
import SelectAccountDescriptions from 'components/form/SelectAccountDescriptions';
import { CustomerProfileUpdateFormKeys } from 'helpers/formKeys';
import generateFormDataHelper from 'helpers/generateFormDataHelper';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { showToast } from 'module/Common/Toast/toast';
import React, { useEffect, useState, useCallback } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import removeRefData from 'helpers/removeRefData';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';

const CommissionSettings = ({ customerData }) => {
  const Translate = useAxisproTranslate();
  const [formData, setFormData] = useState(CustomerProfileUpdateFormKeys);
  const [isUpdating, setIsUpdating] = useState(false);
  const [formError, setFormError] = useState({});

  const handleFieldChange = useCallback(
    (e, action) => {
      let newFormData = generateFormDataHelper(e, action, formData);

      if (!action && e?.target?.name === 'same_customer_account') {
        const isSameCustomerAccount =
          newFormData.same_customer_account === 'same';
        newFormData = {
          ...newFormData,
          same_customer_account: isSameCustomerAccount,
          commission_payable_to: isSameCustomerAccount
            ? ''
            : newFormData.commission_payable_to,
          commission_payable_to_ref: isSameCustomerAccount
            ? ''
            : newFormData.commission_payable_to_ref
        };
      }

      setFormData(prev => ({ ...prev, ...newFormData }));
    },
    [formData]
  );

  useEffect(() => {
    if (customerData) {
      setFormData(prev => ({
        ...prev,
        commission_enabled: customerData.commission_enabled,
        commission_expense_account: customerData.commission_expense_account,
        commission_expense_account_ref: customerData.commission_expense_account
          ? {
              value: customerData.commission_expense_account,
              label:
                customerData.commission_expense_account +
                ' - ' +
                customerData.commission_expense_account_name
            }
          : null,
        commission_payable_to: customerData.commission_payable_to,
        commission_payable_to_ref: customerData.commission_payable_to
          ? {
              value: customerData.commission_payable_to,
              label:
                customerData.commission_payable_to +
                ' - ' +
                customerData.commission_payable_to_account_name
            }
          : null,
        same_customer_account: customerData.commission_payable_to ? false : true
      }));
    }
  }, [customerData]);

  const stringifiedFormData = JSON.stringify(formData);

  const handleSubmit = useCallback(
    async e => {
      const parsedFormData = JSON.parse(stringifiedFormData);
      e.preventDefault();
      setFormError({});
      if (isUpdating) return;

      setIsUpdating(true);
      const payloadData = { ...removeRefData(parsedFormData) };

      try {
        const response = await axios.post(
          `sales/customer/${customerData.id}/update-commission-settings`,
          payloadData
        );

        const isSuccess = response?.data?.success;
        const message = isSuccess
          ? response?.data?.message
          : 'Something went wrong, please refresh the page and try again.';
        showToast(message, isSuccess ? 'success' : 'error');
      } catch (error) {
        const errorData = error?.response?.data;
        showToast(
          errorData?.message ||
            Translate(
              'Something went wrong, please refresh the page and try again.'
            ),
          'error'
        );
        errorData?.data?.errors && setFormError({ ...errorData.data.errors });
      } finally {
        setIsUpdating(false);
      }
    },
    [isUpdating, stringifiedFormData, customerData.id, Translate]
  );

  return (
    <Form className="mt-3 h-100" onSubmit={handleSubmit}>
      <Form.Group className="mb-3" as={Row} controlId="commission_expense">
        <Row>
          <Col lg={2} md={3} sm={12}>
            <Form.Label
              className={`${formData.commission_enabled ? 'require-data' : ''}`}
            >
              {Translate('Commission Expense')}
            </Form.Label>
          </Col>
          <Col
            lg={4}
            md={5}
            sm={12}
            className="d-flex flex-row align-items-center"
          >
            <div className="w-100">
              <SelectAccountDescriptions
                name="commission_expense_account"
                value={formData.commission_expense_account_ref}
                keyName="code"
                handleFieldChange={handleFieldChange}
                filter_keys={{ group_parent_codes: '3,5' }}
                url="finance/chart-of-accounts-by-parent"
              />
            </div>
            <FormErrorPopover
              id="sales_account_code"
              totalErrorCount={Object.keys(formError).length}
              errorMessage={formError.commission_expense_account}
            />
          </Col>
        </Row>
      </Form.Group>

      <Form.Group className="mb-3" as={Row} controlId="commission_payable">
        <Row>
          <Col lg={2} md={3} sm={12}>
            <Form.Label>{Translate('Commission Payable')}</Form.Label>
          </Col>
          <Col
            lg={4}
            md={5}
            sm={12}
            className="d-flex flex-row align-items-center"
          >
            <div className="w-100">
              <Form.Check
                inline
                label={Translate('Same Customer Account')}
                name="same_customer_account"
                type="radio"
                id="inline-radio-1"
                checked={formData.same_customer_account}
                value="same"
                onChange={handleFieldChange}
              />
              <Form.Check
                inline
                label={Translate('Change Account')}
                name="same_customer_account"
                type="radio"
                id="inline-radio-2"
                value="change"
                checked={!formData.same_customer_account}
                onChange={handleFieldChange}
              />
              {!formData.same_customer_account && (
                <SelectAccountDescriptions
                  name="commission_payable_to"
                  value={formData.commission_payable_to_ref}
                  keyName="code"
                  handleFieldChange={handleFieldChange}
                  filter_keys={{ group_parent_codes: '1,2' }}
                  url="finance/chart-of-accounts-by-parent"
                />
              )}
            </div>
            {!formData.same_customer_account && (
              <div className="mt-auto mb-1">
                <FormErrorPopover
                  id="sales_account_code"
                  totalErrorCount={Object.keys(formError).length}
                  errorMessage={formError.commission_payable_to}
                />
              </div>
            )}
          </Col>
        </Row>
      </Form.Group>

      <Form.Group as={Row} controlId="commission_enabled">
        <Col lg={2} md={3} sm={12}>
          <Form.Label>{Translate('Commission Enabled')}</Form.Label>
        </Col>
        <Col md={4} lg={4} sm={12}>
          <Form.Check
            type="switch"
            checked={formData.commission_enabled}
            name="commission_enabled"
            onChange={handleFieldChange}
          />
        </Col>
      </Form.Group>

      <Form.Group controlId="submit">
        <Button
          type="submit"
          size="sm"
          variant="primary"
          className="my-3 me-1"
          disabled={isUpdating}
        >
          {isUpdating ? Translate('Updating...') : Translate('Update')}
        </Button>
      </Form.Group>
    </Form>
  );
};

CommissionSettings.propTypes = {
  customerData: PropTypes.any
};

export default CommissionSettings;
