import AppDatePicker from 'components/app-date-picker/AppDatePicker';
import React from 'react';
import { Button } from 'react-bootstrap';
import { FaCalendar } from 'react-icons/fa';
import PropTypes from 'prop-types';
const DatePickerFilter = ({
  index,
  datePickersRefs,
  item,
  formData,
  handleFieldChange
}) => {
  return (
    <div className="d-flex flex-row">
      <Button
        variant="transparent"
        size="sm"
        onClick={() => {
          if (datePickersRefs.current[index]) {
            datePickersRefs.current[index].openCalendar();
          }
        }}
        className="px-2 align-self-stretch d-flex justify-content-center align-items-center customer-advance-search-button-style"
      >
        <FaCalendar color="#344050" size={10} />
      </Button>
      <AppDatePicker
        name={item.name}
        value={formData[item.name]}
        yearPlaceholder="yyyy"
        monthPlaceholder="mm"
        dayPlaceholder="dd"
        onChange={handleFieldChange}
        datePickersRefs={el => (datePickersRefs.current[index] = el)}
        showDefaultDate={item?.showDefaultTodayDate ?? false}
      />
    </div>
  );
};
DatePickerFilter.propTypes = {
  index: PropTypes.string,
  item: PropTypes.object,
  datePickersRefs: PropTypes.object,
  formData: PropTypes.object,
  handleFieldChange: PropTypes.func,
  setFormData: PropTypes.func
};
export default DatePickerFilter;
