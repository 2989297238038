import { React, useLayoutEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Badge, Button, Card, Form } from 'react-bootstrap';

import { FaRegEdit } from 'react-icons/fa';

import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import { showToast } from 'module/Common/Toast/toast';
import { bankTransferValidators } from 'module/Common/Validators/Finance/FinanceModuleValidators';
import { bankTransferFormKeys } from 'helpers/formKeys';
import { apiCall } from 'helpers/apiCalls';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import BackButton from 'components/common/BackButton';
import ErrorAlert from 'module/Common/Error/ErrorAlert';
import FileController from 'components/file-controller/FileController';
import TransactionDateInputField from 'module/Common/InputFields/Finance/TransactionDateInputField';
import PaymentFromInputField from 'module/Common/InputFields/Finance/PaymentFromInputField';
import PaymentToInputField from 'module/Common/InputFields/Finance/PaymentToInputField';
import AmountInputField from 'module/Common/InputFields/Finance/AmountInputField';
import BankChargeInputField from 'module/Common/InputFields/Finance/BankChargeInputField';
import CostCenterInputField from 'module/Common/InputFields/Finance/CostCenterInputField';
import NoteInputField from 'module/Common/InputFields/Finance/NoteInputField';
import setNewFormData from 'helpers/setNewFormData';
import SimpleBar from 'simplebar-react';
import generateFormDataHelper from 'helpers/generateFormDataHelper';
import removeRefData from 'helpers/removeRefData';

const BankTransferFormEditFrom = () => {
  let navigate = useNavigate();
  const Translate = useAxisproTranslate();
  const { voucherId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [onSave, setOnSave] = useState(false);
  const [formError, setFormError] = useState({});
  const [formData, setFormData] = useState(bankTransferFormKeys);
  const [editItemData, setEditItemData] = useState();
  const permission = {
    update: getAcodaxPermissionSlug('BT', 'update')
  };

  const handleFieldChange = (e, action) => {
    setFormData(prevFormData => {
      let newFormData = generateFormDataHelper(e, action, prevFormData);
      if (e?.target?.name?.length > 0) {
        if (
          e.target.name === 'amount' &&
          Boolean(parseFloat(newFormData?.amount) < 1)
        ) {
          showToast(Translate('Amount must be greater than zero.'), 'error');
          return prevFormData;
        }
        if (
          e.target.name === 'bank_charges' &&
          Boolean(parseFloat(newFormData?.bank_charges) < 1)
        ) {
          showToast(
            Translate('Bank charge cannot be less than zero!'),
            'error'
          );
          return prevFormData;
        }
      }
      if (action?.name.length > 0) {
        if (
          ['from_bank_account_id', 'to_bank_account_id'].includes(
            action.name
          ) &&
          (newFormData?.from_bank_account_id?.length > 0 ||
            newFormData?.to_bank_account_id?.length > 0) &&
          newFormData?.from_bank_account_id === newFormData?.to_bank_account_id
        ) {
          showToast(
            Translate('Payment from and payment to fields must be different.'),
            'error'
          );
          newFormData[action.name + '_ref'] = null;
        }
      }
      return newFormData;
    });
  };

  const handleFiles = files => {
    handleFieldChange({ target: { name: 'files', value: files } });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setFormError({});
    if (!bankTransferValidators(formData)) return;
    setOnSave(true);

    try {
      const response = await axios.postForm(
        `finance/bank-transfers/${voucherId}`,
        setNewFormData(removeRefData({ ...formData, _method: 'PUT' }))
      );

      const responseData = response?.data;
      if (responseData?.success !== true) {
        showToast(
          'Something went wrong, please refresh the page and try again.',
          'error'
        );
        return;
      }
      showToast(responseData?.message, 'success');
      navigate(`/finance/voucher-inquiry?entry=${voucherId}&layout=column`, {
        replace: true
      });
    } catch (error) {
      const errorData = error?.response?.data;
      showToast(
        errorData?.message ??
          error?.message ??
          'Something went wrong, please refresh the page and try again.',
        'error'
      );
      setFormError({ ...(errorData?.data?.errors ?? null) });
    } finally {
      setOnSave(false);
    }
  };

  useLayoutEffect(() => {
    const fetchDetails = async () => {
      setIsLoading(true);
      if (voucherId) {
        try {
          const itemDetails = await apiCall({
            url: `finance/vouchers/${voucherId}`
          });
          if (itemDetails?.id) {
            setEditItemData(itemDetails);
            setFormData(prev => ({
              ...prev,
              amount: itemDetails?.amount ?? '',
              bank_charges:
                itemDetails?.bank_charges > 0 ? itemDetails.bank_charges : '',
              bank_charges_type: itemDetails?.bank_charges_type ?? 'percent',
              cost_center: itemDetails?.cost_center ?? '',
              cost_center_ref: itemDetails?.cost_center
                ? {
                    label: itemDetails?.cost_center_name,
                    value: itemDetails.cost_center
                  }
                : null,
              from_bank_account_id: itemDetails?.from_bank_id ?? '',
              from_bank_account_id_ref: itemDetails?.from_bank_id
                ? {
                    label: itemDetails?.from_bank_account_name,
                    value: itemDetails.from_bank_id
                  }
                : null,
              memo: itemDetails?.memo ?? '',
              to_bank_account_id: itemDetails?.to_bank_id ?? '',
              to_bank_account_id_ref: itemDetails?.to_bank_id
                ? {
                    label: itemDetails?.to_bank_account_name,
                    value: itemDetails.to_bank_id
                  }
                : null,
              trans_date: itemDetails?.trans_date ?? ''
            }));
          }
        } catch (error) {
          let errorMessage =
            'Something went wrong, please refresh the page and try again.';
          if (error?.response?.data?.message) {
            errorMessage = error.response.data.message;
          }

          showToast(Translate(errorMessage), 'error');
        }
      }
      setIsLoading(false);
    };
    fetchDetails();
  }, [voucherId, Translate]);

  return !permission.update ? (
    <Navigate to="/errors/403" />
  ) : (
    <>
      <Card style={{ height: '92vh' }}>
        <Card.Header className="d-flex flex-row  align-items-center border-bottom">
          <h5
            className="fs-1 mb-0 d-flex align-items-center text-uppercase"
            style={{ fontSize: '18px' }}
          >
            <FaRegEdit size={25} className="text-success me-1" />
            {Translate('Edit Bank Transfer')}
          </h5>
          <Badge className="ms-2 mb-0 bg-warning">
            {editItemData?.reference}
          </Badge>
        </Card.Header>
        <Form onSubmit={handleSubmit} className="mt-2">
          {!isLoading ? (
            <>
              <SimpleBar
                style={{
                  height: '79vh',
                  overflowX: 'hidden',
                  overflowY: 'auto'
                }}
              >
                <Card.Body className="pt-3">
                  {Object.keys(formError).length > 0 && (
                    <ErrorAlert
                      formError={formError}
                      setFormError={setFormError}
                    />
                  )}
                  <TransactionDateInputField
                    formData={formData}
                    formError={formError}
                    handleFieldChange={handleFieldChange}
                    size={{ col1: 6, col2: 3, col3: 5 }}
                  />
                  <PaymentFromInputField
                    formData={formData}
                    formError={formError}
                    handleTableDataSelectFieldChange={handleFieldChange}
                    size={{ col1: 6, col2: 3, col3: 5 }}
                  />
                  <PaymentToInputField
                    formData={formData}
                    formError={formError}
                    handleTableDataSelectFieldChange={handleFieldChange}
                    size={{ col1: 6, col2: 3, col3: 5 }}
                  />
                  <AmountInputField
                    formData={formData}
                    formError={formError}
                    handleFieldChange={handleFieldChange}
                    size={{ col1: 6, col2: 3, col3: 5 }}
                  />
                  <BankChargeInputField
                    formData={formData}
                    formError={formError}
                    handleFieldChange={handleFieldChange}
                  />
                  <CostCenterInputField
                    formData={formData}
                    formError={formError}
                    handleCostCenter={handleFieldChange}
                    size={{ col1: 6, col2: 3, col3: 5 }}
                  />

                  <Form.Group className="my-5">
                    <FileController
                      limit={10}
                      limitFeature={true}
                      limitFileSizeInMB={5}
                      limitFileType={[
                        'jpg',
                        'png',
                        'jpeg',
                        'gif',
                        'pdf',
                        'excel',
                        'xlsx',
                        'docx',
                        'doc'
                      ]}
                      onChange={handleFiles}
                      className="my-3"
                      note="You can upload a maximum of 10 files, 5MB each"
                    />
                  </Form.Group>

                  <NoteInputField
                    formData={formData}
                    formError={formError}
                    handleFieldChange={handleFieldChange}
                    size={{ col1: 6, rows: 7 }}
                  />
                </Card.Body>
              </SimpleBar>
              <Card.Footer className="footer d-flex flex-row gap-1 border-top justify-content-start py-0">
                <Button
                  size="sm"
                  type="submit"
                  variant={'info'}
                  className="my-3 me-0"
                  disabled={onSave}
                >
                  {onSave ? Translate('Updating') + '...' : Translate('Update')}
                </Button>
                {!onSave && (
                  <BackButton variant={'danger'} size={'sm'}>
                    {Translate('Cancel')}
                  </BackButton>
                )}
              </Card.Footer>
            </>
          ) : (
            <SimpleBar
              style={{
                height: '79vh',
                overflowX: 'hidden',
                overflowY: 'auto'
              }}
            >
              <Card.Body
                style={{ height: '75vh' }}
                className="d-flex align-items-center justify-content-center"
              >
                <LoadingScreen message="Looking for details" />
              </Card.Body>
            </SimpleBar>
          )}
        </Form>
      </Card>
    </>
  );
};

export default BankTransferFormEditFrom;
