import React from 'react';
import PropTypes from 'prop-types';
import TreeNode from './TreeNode';
const TreeView = ({ data, handleSelect }) => {
  return (
    <div>
      {data.map(node => (
        <TreeNode key={node.id} node={node} handleSelect={handleSelect} />
      ))}
    </div>
  );
};
TreeView.propTypes = {
  data: PropTypes.any,
  handleSelect: PropTypes.func
};
export default TreeView;
