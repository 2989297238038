import {
  createSearchParams,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import { ConfirmationBox } from './ConfirmationBox';
import { useState, React, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

const ShortCutKeyController = ({ children }) => {
  const navigate = useNavigate();
  const [selectButton, setSelectButton] = useState(true);
  const [queryParams] = useSearchParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const [show, setShow] = useState(false);
  const [key, setKey] = useState('');
  const stringifiedAllQueryParams = JSON.stringify(allQueryParams);

  const onHide = useCallback(() => {
    setShow(false);
    setSelectButton(true);
    setKey('');
  }, [setShow, setSelectButton, setKey]);

  const handleSubmit = useCallback(() => {
    const parsedAllQueryParams = JSON.parse(stringifiedAllQueryParams);
    if (!key) return;

    onHide();
    switch (key) {
      case 'F1':
        navigate(
          `?${createSearchParams({
            ...parsedAllQueryParams,
            add_new_customer: true
          })}`
        );
        break;

      case 'F6':
        navigate('/inventory/items/add');
        break;

      case 'F3':
        navigate('/sales/customer-receipt');
        break;

      case 'F2':
        navigate(
          `?${createSearchParams({
            ...parsedAllQueryParams,
            add_new_supplier: true
          })}`
        );
        break;

      default:
        break;
    }

    setKey('');
  }, [key, onHide, navigate, stringifiedAllQueryParams]);

  const handleKeyDown = useCallback(
    event => {
      switch (true) {
        case event.ctrlKey && ['F1', 'F2', 'F3', 'F6'].includes(event.key): {
          setShow(true);
          setKey(event.key);
          break;
        }
        case event.key === 'Enter' && key: {
          if (selectButton) {
            handleSubmit();
          } else {
            onHide();
          }
          break;
        }
        case event.key === 'ArrowLeft': {
          setSelectButton(false);
          break;
        }
        case event.key === 'ArrowRight': {
          setSelectButton(true);
          break;
        }
        default:
          break;
      }
    },
    [key, selectButton, handleSubmit, onHide]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <>
      {children}
      <ConfirmationBox
        show={show}
        handleSubmit={handleSubmit}
        onHide={onHide}
        selectButton={selectButton}
      />
    </>
  );
};

ShortCutKeyController.propTypes = {
  children: PropTypes.any
};

export default ShortCutKeyController;
