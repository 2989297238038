import { React, useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';

import { salesEndPoints, systemEndPoints } from 'module/Common/Api/endpoints';
import LoadingScreenCover from 'components/common/loading-screen/LoadingScreenCover';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import SalesQuotationTabView from 'module/Common/ViewPages/SalesQuotation/SalesQuotationTabView';

function SalesQuotationInfo({
  salesQuotationId,
  rendering,
  firstId,
  offCanvas,
  setShowVoucherDetailArea,
  voidTransactionReport,
  onHide,
  getData
}) {
  const [queryParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const status = queryParams.get('status');

  const fetchData = async isLoading => {
    if (!rendering) {
      if (firstId !== undefined) {
        let url = voidTransactionReport
          ? systemEndPoints.void
          : `${salesEndPoints.sales_quotation}/${firstId}`;
        const fetchData = async () => {
          try {
            const response = await axios.get(url, {
              params: {
                trans_type: voidTransactionReport ? 'SQ' : '',
                trans_no: voidTransactionReport ? firstId : ''
              }
            });

            if (response.data.success) {
              setData(response.data.data);
            }
          } catch (error) {
            console.log(error);
          } finally {
            setLoading(false);
          }
        };
        fetchData();
      }
    } else {
      if (salesQuotationId !== undefined) {
        if (isLoading) {
          setLoading(true);
        }
        let url = voidTransactionReport
          ? systemEndPoints.void
          : `${salesEndPoints.sales_quotation}/${salesQuotationId}`;
        await axios
          .get(url, {
            params: {
              trans_type: voidTransactionReport ? 'SQ' : '',
              trans_no: voidTransactionReport ? salesQuotationId : ''
            }
          })
          .then(res => {
            if (res.data.success) {
              setData(res.data.data);
              setLoading(false);
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    const timeoutId = setTimeout(() => {
      fetchData();
    }, 200);
    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesQuotationId, firstId, status]);

  return (
    <>
      {loading && data ? (
        <LoadingScreenCover>
          <LoadingScreen message="Looking for details" />
        </LoadingScreenCover>
      ) : (
        <SalesQuotationTabView
          salesQuotationData={data}
          loading={loading}
          fetchData={fetchData}
          offCanvas={offCanvas}
          setShowVoucherDetailArea={setShowVoucherDetailArea}
          voidTransactionReport={voidTransactionReport}
          onHide={onHide}
          getData={getData}
        />
      )}
    </>
  );
}

SalesQuotationInfo.propTypes = {
  salesQuotationId: PropTypes.string,
  firstId: PropTypes.string,
  rendering: PropTypes.bool,
  offCanvas: PropTypes.bool,
  setShowVoucherDetailArea: PropTypes.func,
  voidTransactionReport: PropTypes.bool,
  onHide: PropTypes.func,
  getData: PropTypes.func
};

export default SalesQuotationInfo;
