import React, { useEffect, useState } from 'react';
import {
  createSearchParams,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  Modal,
  OverlayTrigger,
  Table,
  Tooltip
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import { FaFilter, FaTimes } from 'react-icons/fa';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import FilterItem from './FilterItem';
import createFilterArray from 'helpers/filterArrayBuilderHelper';
import { showToast } from 'module/Common/Toast/toast';
import { DateFormatVaildation } from 'module/Common/Functions/DateFormatVaildation';

const FilterButton = ({
  size,
  variant,
  clearButtonVariant,
  disable,
  className,
  showText,
  filters,
  url,
  search,
  customer,
  salesman,
  supplier,
  voucher,
  ...rest
}) => {
  const navigate = useNavigate();
  const Translate = useAxisproTranslate();
  const [queryParams] = useSearchParams();
  const [activeFilters, setActiveFilters] = useState([]);
  const [options, setOptions] = useState([]);
  const [show, setShow] = useState(false);
  const [changeEvent, setChangeEvent] = useState(false);
  let filtersQueryParam = queryParams.get('filters');
  let threeColumnLayout = queryParams.get('layout');

  const handleWindow = () => {
    setShow(!show);
  };

  const handleNewFilterItem = () => {
    setActiveFilters(prev => [
      ...prev,
      {
        column: '',
        type: '',
        operator: '',
        value: '',
        value_ref: null
      }
    ]);
  };

  const handleFilter = () => {
    let filtersArray = activeFilters.filter(
      item =>
        item.column &&
        item.column.length > 0 &&
        item.operator &&
        item.operator.length > 0 &&
        ((item.value && item.value.length > 0) ||
          (item.value_from &&
            item.value_from.length > 0 &&
            item.value_to &&
            item.value_to.length))
    );

    const date = filtersArray.find(entry => {
      return entry.column === 'date';
    });
    if (filtersArray.length < 1) {
      showToast(`Filters are empty, please check!`, 'error');
    } else if (date && date.value && !DateFormatVaildation(date.value)) {
      showToast(
        'You have selected an invalid date. Please select a valid date',
        'error'
      );
    } else {
      filtersArray = filtersArray.reduce((itemsArray, item) => {
        itemsArray.push(JSON.stringify(item));
        return itemsArray;
      }, []);
      navigate({
        pathname: '/' + url,
        search: `?${createSearchParams({
          ...(search ? search : null),
          filters: filtersArray.join(',,'),
          ...(threeColumnLayout === 'column' && { layout: 'column' })
        })}`
      });
      setShow(false);
    }
  };

  const handleClearFilters = () => {
    queryParams.delete('filters');
    search = search ?? {};
    if (typeof search === 'object') {
      Object.keys(search).map(key => queryParams.append(key, search[key]));
    }

    navigate(
      {
        pathname: '/' + url,
        search: `?${queryParams}`
      },
      { replace: true }
    );
    setShow(false);
  };

  useEffect(() => {
    if (show && filters !== null && typeof filters === 'object') {
      let objectKeys = Object.keys(filters);
      if (objectKeys.length > 0) {
        setOptions(objectKeys);
        let newActiverFiltersArray = [];
        if (filtersQueryParam) {
          newActiverFiltersArray = createFilterArray(
            filtersQueryParam,
            objectKeys
          );
        } else {
          let firstItem = filters[objectKeys[0]];
          newActiverFiltersArray.push({
            column: objectKeys[0],
            operator:
              firstItem?.operator ?? firstItem?.operators?.[0]?.value ?? '',
            type: firstItem.type ?? 'string',
            value: firstItem.value ?? '',
            value_ref: firstItem.value_ref ?? null
          });
        }

        setActiveFilters(newActiverFiltersArray);
      }
    }

    return () => {
      setActiveFilters([]);
    };
  }, [filters, show, filtersQueryParam]);

  useEffect(() => {
    if (customer) {
      const updatedArray = activeFilters.map(item => {
        if (item.column === 'id' && item.operator === 'LIKE') {
          return { ...item, column: 'name', operator: 'LIKE' };
        } else if (item.column === 'name' && item.operator === '=') {
          return { ...item, column: 'id', operator: '=' };
          // } else if (item.column === 'customer_id' && item.operator === 'LIKE') {
          //   return { ...item, column: 'customer', operator: 'LIKE' };
          // } else if (item.column === 'customer.name' && item.operator === '=') {
          //   return { ...item, column: 'customer_id', operator: '=' };
          // }
        }
        return item;
      });
      setActiveFilters(updatedArray);
    } else if (salesman) {
      const updatedArray = activeFilters.map(item => {
        if (item.column === 'id' && item.operator === 'LIKE') {
          return { ...item, column: 'salesman_name', operator: 'LIKE' };
        } else if (item.column === 'salesman_name' && item.operator === '=') {
          return { ...item, column: 'id', operator: '=' };
        }
        return item;
      });

      setActiveFilters(updatedArray);
    } else if (supplier) {
      const updatedArray = activeFilters.map(item => {
        if (item.column === 'id' && item.operator === 'LIKE') {
          return { ...item, column: 'name', operator: 'LIKE' };
        } else if (item.column === 'name' && item.operator === '=') {
          return { ...item, column: 'id', operator: '=' };
        }
        return item;
      });
      setActiveFilters(updatedArray);
    } else if (voucher) {
      const updatedArray = activeFilters.map(item => {
        if (item.column === 'supplier_id') {
          return {
            ...item,
            label: 'Supplier Name',
            column: 'person_id',
            operator: '=',
            personType: 'SUPPLIER'
          };
        } else if (item.column === 'customer_id') {
          return {
            ...item,
            label: 'Customer Name',
            column: 'person_id',
            operator: '=',
            personType: 'CUSTOMER'
          };
        }
        return item;
      });
      setActiveFilters(updatedArray);
    }
  }, [changeEvent, activeFilters, customer, salesman, supplier, voucher]);

  return (
    <>
      <ButtonGroup
        aria-label="filter-button-group"
        className={className}
        {...rest}
      >
        <Button
          size={size ?? 'sm'}
          variant={variant ?? 'primary'}
          className={`d-flex flex-row align-items-center`}
          disabled={disable}
          onClick={handleWindow}
        >
          <>
            <FaFilter className={showText ? 'me-1' : ''} size={10} />
            {showText ? (
              <span className="fs--1">{Translate('Filter')}</span>
            ) : null}
          </>
        </Button>
        {filtersQueryParam && (
          <OverlayTrigger
            overlay={
              <Tooltip id="clear-filter-toolptip" className="small">
                {Translate('Clear all filters')}
              </Tooltip>
            }
          >
            <Button
              size="sm"
              variant={clearButtonVariant ?? 'danger'}
              className={`d-flex flex-row align-items-center px-1 position-absolute rounded-circle`}
              style={{
                top: '-5px',
                right: '-2px',
                width: '17px',
                height: '17px',
                zIndex: '1'
              }}
              disabled={disable}
              onClick={handleClearFilters}
            >
              <>
                <FaTimes size={10} />
              </>
            </Button>
          </OverlayTrigger>
        )}
      </ButtonGroup>

      {show ? (
        <Modal
          show={show}
          onHide={handleWindow}
          size="lg"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title className="h5 mb-0 d-flex flex-row">
              <FaFilter className="me-1 text-warning" />
              <span>{Translate('FILTER OPTIONS')}</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0">
            <Table className="mb-0">
              <thead style={{ backgroundColor: 'rgb(213, 243, 255)' }}>
                <tr className="fs--1 text-dark">
                  <th className="text-center py-2">{Translate('OPTIONS')}</th>
                  <th className="text-center py-2">{Translate('OPERATOR')}</th>
                  <th className="text-center py-2">{Translate('VALUE')}</th>
                </tr>
              </thead>
              <tbody>
                {activeFilters.map((item, key) => (
                  <FilterItem
                    key={key}
                    index={key}
                    item={item}
                    options={options}
                    filters={filters}
                    setActiveFilters={setActiveFilters}
                    setChangeEvent={setChangeEvent}
                    changeEvent={changeEvent}
                  />
                ))}
              </tbody>
            </Table>
            {options &&
            Array.isArray(options) &&
            activeFilters.length < options.length ? (
              <Button
                className="w-100 border-top-0 border-end-0 border-start-0"
                variant="light"
                size="sm"
                onClick={handleNewFilterItem}
              >
                <small>**{Translate('Click here to add new Filter')}</small>
              </Button>
            ) : null}
            <div className="px-3 pt-2 d-flex justify-content-end">
              {activeFilters.length > 0 ? (
                <Button
                  variant="transparent"
                  size="sm"
                  style={{ boxShadow: 'none' }}
                  onClick={handleClearFilters}
                >
                  <span className="small text-primary">
                    {Translate('Clear all filters')}
                  </span>
                </Button>
              ) : null}
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <div className="d-flex justify-content-start w-100">
              <Button
                variant="outline-success"
                onClick={handleFilter}
                size="sm"
              >
                {Translate('Filter')}
              </Button>
              <Button
                variant="outline-danger"
                onClick={handleWindow}
                size="sm"
                className="ms-1"
              >
                {Translate('Close')}
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      ) : null}
    </>
  );
};

FilterButton.propTypes = {
  variant: PropTypes.string,
  clearButtonVariant: PropTypes.string,
  disable: PropTypes.bool,
  size: PropTypes.string,
  className: PropTypes.string,
  showText: PropTypes.bool,
  filters: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
  search: PropTypes.object,
  customer: PropTypes.bool,
  salesman: PropTypes.bool,
  supplier: PropTypes.bool,
  voucher: PropTypes.bool
};

export default FilterButton;
