import { React, useCallback, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { FcPlus } from 'react-icons/fc';
import FollowUpAddPopup from '../FollowUpAddPopup';
import {
  createSearchParams,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import { apiCall } from 'helpers/apiCalls';
import { FaEdit, FaEye, FaTrash } from 'react-icons/fa';
import { DeleteContext } from 'context/Context';
import { useContext } from 'react';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import FollowupComments from '../FollowupComments';

function FollowUp({ person, invoiceData }) {
  const Translate = useAxisproTranslate();
  const [followUps, setFollowUps] = useState([]);
  const { dispatch } = useContext(DeleteContext);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const show = queryParams.get('follow_up_add') ? true : false;
  const show_id = queryParams.get('show_id') ? true : false;
  const allQueryParams = Object.fromEntries([...queryParams]);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const data = await apiCall({
      url: 'sales/transaction-followups',
      params: {
        subject_id: invoiceData.id,
        subject_type: 'SI'
      }
    });
    if (data.data.length >= 1) {
      data.data.length = 5;
    }
    setFollowUps(data.data);
    setIsLoading(false);
  }, [invoiceData.id]);

  useEffect(() => {
    if (invoiceData.id) {
      fetchData();
    }
  }, [invoiceData.id, fetchData]);

  const handleDelete = data => {
    dispatch({
      type: 'CONFIG',
      payload: {
        target: data.id,
        url: '/sales/followups/' + data.id,
        title: Translate('Delete Follow Ups'),
        message: Translate('Are you sure you want to delete this follow up?'),
        onSuccess: () => {
          fetchData();
          dispatch({
            type: 'RESET'
          });
        }
      }
    });
  };
  return (
    <>
      <div className="text-end pt-3">
        <FcPlus
          size={25}
          className="cursor-pointer me-3"
          onClick={() => {
            navigate(
              `?${createSearchParams({
                ...allQueryParams,
                follow_up_add: true
              })}`
            );
          }}
        />
      </div>
      <FollowUpAddPopup
        show={show}
        onHide={() => {
          navigate(-1);
        }}
        person={person}
        invoiceData={invoiceData}
        fetchData={fetchData}
      />
      <FollowupComments show={show_id} invoiceData={invoiceData} />

      {isLoading ? (
        <LoadingScreen message={Translate('Looking for follow ups')} />
      ) : (
        <>
          {followUps.length == 0 ? (
            <h5 className="text-center text-secondary mt-5">
              {Translate('No Follow Ups')} &#128578;
            </h5>
          ) : (
            <Table
              striped
              bordered
              hover
              responsive
              className="mt-2 simple-table-animation"
            >
              <thead className="bg-dark text-light text-uppercase">
                <tr>
                  <th className="fs--2 p-2 text-center">#</th>
                  <th className="fs--2 p-2 text-center">
                    {Translate('Created')}
                  </th>
                  <th className="fs--2 p-2 text-center">
                    {Translate('Follow Up Date')}
                  </th>
                  <th className="fs--2 p-2 text-center">
                    {Translate('Next Follow Up Date')}
                  </th>
                  <th className="fs--2 p-2 text-center">
                    {Translate('Notes')}
                  </th>
                  <th className="fs--2 p-2 text-center"></th>
                </tr>
              </thead>
              {followUps.length > 0 &&
                followUps.map((item, key) => (
                  <tbody key={key} className="text-dark">
                    <tr>
                      <td className="p-2 text-center">{key + 1}</td>
                      <th
                        className="p-2 text-center text-primary hover-underline cursor-pointer"
                        onClick={() => {
                          navigate(
                            `?${createSearchParams({
                              ...allQueryParams,
                              show_id: item.id
                            })}`
                          );
                        }}
                      >
                        {item.created_user_full_name}
                      </th>
                      <th className="p-2 text-center">
                        {item.followup_date_formatted}
                      </th>
                      <th className="p-2 text-center">
                        {item.next_followup_date_formatted}
                      </th>
                      <th className="p-2 text-center">{item.memo}</th>
                      <th className="p-2 text-center">
                        <div className=" d-flex align-items-center justify-content-center mt-1">
                          <FaEdit
                            className="text-primary cursor-pointer"
                            size={12}
                            onClick={() => {
                              navigate(
                                `?${createSearchParams({
                                  ...allQueryParams,
                                  follow_up_add: true,
                                  edit_id: item.id
                                })}`
                              );
                            }}
                          />
                          <FaEye
                            className="text-primary ms-3 cursor-pointer"
                            size={14}
                            onClick={() => {
                              navigate(
                                `?${createSearchParams({
                                  ...allQueryParams,
                                  show_id: item.id
                                })}`
                              );
                            }}
                          />
                          <FaTrash
                            className="text-danger ms-3 cursor-pointer"
                            size={11}
                            onClick={() => {
                              handleDelete(item);
                            }}
                          />
                        </div>
                      </th>
                    </tr>
                  </tbody>
                ))}
            </Table>
          )}
        </>
      )}
    </>
  );
}

FollowUp.propTypes = {
  invoiceData: PropTypes.object,
  person: PropTypes.any
};

export default FollowUp;
