import { useCallback } from 'react';

const useAcodaxNumberRoundFormat = () => {
  const decimalPoints = 2;

  const acodaxNumberRoundFormat = useCallback(number => {
    if (number !== 0) {
      return number.toFixed(decimalPoints);
    } else {
      return number.toFixed(decimalPoints);
    }
  }, []);

  return acodaxNumberRoundFormat;
};

export default useAcodaxNumberRoundFormat;
