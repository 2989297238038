import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import MonthlyRevenue from './MonthlyRevenue';
import axios from 'axios';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import PropTypes from 'prop-types';

function SalesOrderProgress({ draggable }) {
  const Translate = useAxisproTranslate();
  const [isLoadingSalesOrder, setIsLoadingSalesOrder] = useState(false);
  const [salesOrderData, setSalesOrderData] = useState(undefined);
  const [salesOrderProgressPercentage, setSalesOrderProgressPercentage] =
    useState(undefined);

  useEffect(() => {
    const getSalesOrderData = () => {
      setIsLoadingSalesOrder(true);
      axios
        .get('/sales/get-sales-order-completion-progress-report')
        .then(response => {
          if (response?.data?.data) {
            let { data } = response.data;
            setSalesOrderData([
              {
                label: Translate('Order'),
                value: data?.ordered_quantity
              },
              {
                label: Translate('Delivered'),
                value: data?.delivered_quantity
              }
            ]);
            setSalesOrderProgressPercentage(data?.order_completion_percentage);
            setIsLoadingSalesOrder(false);
          }
        })
        .catch(error => console.log(error));
    };

    getSalesOrderData();
  }, [Translate]);

  return (
    <Card className={`g-3 h-100 ${draggable && 'dashboard_shake'}`}>
      <MonthlyRevenue
        draggable={draggable}
        isLoading={isLoadingSalesOrder}
        title={Translate('Sales Order Completion Progress')}
        dataArray={salesOrderData}
        progressPercentage={salesOrderProgressPercentage}
      />
    </Card>
  );
}
SalesOrderProgress.propTypes = {
  draggable: PropTypes.bool
};
export default SalesOrderProgress;
