import { React, useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { Alert, Container, Table } from 'react-bootstrap';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import { showToast } from 'module/Common/Toast/toast';
import { DeferredTransactionReportHead } from '../Common/data';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import ReportsContainer from 'components/report-components/reports-container/ReportsContainer';
import TableHeader from '../Common/TableHeader';
import MinimalReportDataFetching from 'module/Common/Loading/MinimalReportDataFetching';
import useReportApiCalls from '../Common/useReportApiCalls';
import ClickReference from 'module/Common/ClickableData/ClickReference';
import VoucherDetail from 'components/voucher-details/VoucherDetail';

function DeferredTransactionReport() {
  const Translate = useAxisproTranslate();
  const reportApiCall = useReportApiCalls();
  const [queryParams] = useSearchParams();
  const filterItems = queryParams.get('filters');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showVoucherDetailArea, setShowVoucherDetailArea] = useState(false);
  const [voucherId, setVoucherId] = useState('');
  const [nextLink, setNextLink] = useState(null);
  const pageNumber = queryParams.get('page');
  useEffect(() => {
    const fetchData = async (page = 1) => {
      setLoading(true);
      try {
        const data = await reportApiCall('finance/deferred-transaction', {
          page: page
        });
        if (data?.links?.next !== null) {
          setNextLink(data.links.next);
        } else {
          setNextLink(null);
        }
        setData(data.data);
      } catch (error) {
        console.error('Error fetching cheque data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData(pageNumber);
  }, [pageNumber, filterItems, reportApiCall]);

  const clickOnVoucher = voucher_id => {
    setVoucherId(voucher_id);
    setShowVoucherDetailArea(!showVoucherDetailArea);
  };

  const loadNextPage = useCallback(async () => {
    if (nextLink && nextLink !== null) {
      try {
        const res = await axios.get(nextLink);
        if (res.data.success) {
          setData(prevData => [...prevData, ...res.data.data.data]);
          setNextLink(res?.data?.data?.links?.next || null);
        }
      } catch (error) {
        showToast('Something went wrong, please contact admin', 'error');
      }
    }
  }, [nextLink, setData]);

  return (
    <>
      <ReportsContainer
        title={'Deferred Transaction Report'}
        url="finance/deferred-transaction-report/export"
        initialMessage={'Deferred Transaction export started'}
        successMessage={'Deferred Transaction export completed'}
        data={data}
        loading={loading}
        moduleName="deferred_transactions"
      >
        <Container fluid className="p-0 reports-container-wrapper">
          <Table striped hover bordered={data?.length > 0 ? true : false}>
            <TableHeader headers={DeferredTransactionReportHead} />
            {data ? (
              data.length > 0 ? (
                <>
                  {data.map((item, index) => (
                    <tbody key={index}>
                      <tr className="report-column-custom-style">
                        <td>
                          <span className="report-column-content-custom-style">
                            {index + 1}
                          </span>
                        </td>
                        <td>{item?.trans_date_formatted}</td>
                        <td style={{ minWidth: '13rem' }}>
                          <span className="report-column-content-custom-style">
                            {item.reference ? (
                              <ClickReference
                                permission={getAcodaxPermissionSlug(
                                  'SI',
                                  'show'
                                )}
                                id={item.sales_invoice_id}
                                trans_type={'SI'}
                                reference={item.reference}
                                clickOnFunction={clickOnVoucher}
                              />
                            ) : (
                              '-'
                            )}
                          </span>
                        </td>
                        <td style={{ minWidth: '11rem' }}>
                          <span className="report-column-content-custom-style">
                            {item.total_amount ?? '-'}
                          </span>
                        </td>

                        <td>
                          {' '}
                          <span className="report-column-content-custom-style">
                            {item.status ?? '-'}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                  {nextLink && nextLink !== null && nextLink !== '' && (
                    <MinimalReportDataFetching
                      row={3}
                      column={11}
                      nextLink={nextLink}
                      loadNextPage={loadNextPage}
                    />
                  )}
                </>
              ) : (
                <tr className="alert-styles">
                  <td colSpan={12}>
                    <Alert variant="warning">
                      {Translate('Currently there is no report generated.')}
                    </Alert>
                  </td>
                </tr>
              )
            ) : (
              ''
            )}
          </Table>
        </Container>
      </ReportsContainer>
      <VoucherDetail
        show={showVoucherDetailArea}
        onHide={() => setShowVoucherDetailArea(false)}
        setShowVoucherDetailArea={setShowVoucherDetailArea}
        type={'SI'}
        voucherId={voucherId}
        offCanvas={true}
      />
    </>
  );
}

export default DeferredTransactionReport;
