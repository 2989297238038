import React from 'react';

import { BiPurchaseTag } from 'react-icons/bi';
import { FaCoins, FaRegMoneyBillAlt } from 'react-icons/fa';
import {
  MdOutlineInventory2,
  MdOutlineInventory,
  MdSell
} from 'react-icons/md';
import { FcSettings } from 'react-icons/fc';
import { RiFolderSettingsFill } from 'react-icons/ri';
import { HiOutlineReceiptTax } from 'react-icons/hi';

import { FaBell } from 'react-icons/fa6';

export const SystemSettingsMenuArray = [
  {
    name: 'Company Settings',
    icon: <FcSettings className="me-2" size={15} />,
    route: '/system-settings/company-preference',
    permissionSlug: 'CSS'
  },
  {
    name: 'Finance Settings',
    icon: <FaRegMoneyBillAlt className="me-2" size={15} />,
    route: '/system-settings/finance',
    permissionSlug: 'CSS'
  },
  {
    name: 'Sales Settings',
    icon: <MdSell className="me-2" size={15} />,
    route: '/system-settings/sales-settings',
    permissionSlug: 'CSS'
  },
  {
    name: 'Purchase Settings',
    icon: <BiPurchaseTag className="me-2" size={15} />,
    route: '/system-settings/purchase-settings',
    permissionSlug: 'CSS'
  },
  {
    name: 'Inventory Settings',
    icon: <MdOutlineInventory2 className="me-2" size={15} />,
    route: '/system-settings/inventory-settings',
    permissionSlug: 'CSS'
  },
  {
    name: 'Asset Settings',
    icon: <MdOutlineInventory className="me-2" size={15} />,
    route: '/system-settings/asset-settings',
    permissionSlug: 'CSS'
  },
  {
    name: 'Tax Settings',
    icon: <HiOutlineReceiptTax className="me-2" size={15} />,
    route: '/system-settings/tax-settings',
    permissionSlug: 'CSS'
  },
  {
    name: 'System Settings',
    icon: <RiFolderSettingsFill className="me-2" size={15} />,
    route: '/system-settings/system-settings',
    permissionSlug: 'CSS'
  },
  {
    name: 'Opening Balance',
    icon: <FaCoins className="me-2" size={15} />,
    route: '/system-settings/opening-balance',
    permissionSlug: 'CSS'
  },
  {
    name: 'Notification Settings',
    icon: <FaBell className="me-2" size={15} />,
    route: '/system-settings/notification-settings',
    permissionSlug: 'CSS'
  }
  // {
  //   name: 'Print Settings',
  //   icon: <BiPrinter className="me-2" size={15} />,
  //   route: '/system-settings/print-settings',
  //   permissionSlug: 'CSS'
  // }
];
