import VoucherDetail from 'components/voucher-details/VoucherDetail';
import ClickReference from 'module/Common/ClickableData/ClickReference';
import MinimalReportDataFetching from 'module/Common/Loading/MinimalReportDataFetching';
import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Container, Table } from 'react-bootstrap';
import TableHeader from '../Common/TableHeader';
import { MaterialRequestDetailsHead } from '../Common/data';
import ReportsContainer from 'components/report-components/reports-container/ReportsContainer';
import axios from 'axios';
import { showToast } from 'module/Common/Toast/toast';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { useSearchParams } from 'react-router-dom';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import useReportApiCalls from '../Common/useReportApiCalls';
import ItemName from 'components/item-details/ItemName';
import useAcodaxNumberFormat from 'hooks/useAcodaxNumberFormat';
import { materialRequestDetailsReportSortData } from '../Common/sortData';

export default function MaterialRequestDetailsReport() {
  const reportApiCall = useReportApiCalls();
  const acodaxNumberFormat = useAcodaxNumberFormat();
  const Translate = useAxisproTranslate();
  const [queryParams] = useSearchParams();
  const filterItems = queryParams.get('filters');
  const sortBy = queryParams.get('sort_by');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalDetails, setModalDetails] = useState(undefined);
  const [nextLink, setNextLink] = useState(null);
  const pageNumber = queryParams.get('page');
  const permissions = {
    material_request_show: getAcodaxPermissionSlug('MR', 'show'),
    supplier_show: getAcodaxPermissionSlug('supplier', 'show')
  };

  useEffect(() => {
    const fetchData = async (page = 1) => {
      setLoading(true);
      try {
        const data = await reportApiCall(
          '/purchase/material-request-details-report',
          {
            page: page,
            ...(sortBy && sortBy !== 'SORT BY' && { sort: [sortBy] })
          }
        );
        if (data?.links?.next !== null) {
          setNextLink(data.links.next);
        } else {
          setNextLink(null);
        }

        setData(data.data);
      } catch (error) {
        console.error('Error fetching material request details report:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData(pageNumber);
  }, [pageNumber, filterItems, sortBy, reportApiCall]);

  function handleVoucherModal(targetId, type) {
    setModalDetails({ modal: 'voucher', targetId: targetId, type: type });
  }

  const loadNextPage = useCallback(async () => {
    if (nextLink && nextLink !== null) {
      try {
        const res = await axios.get(nextLink);
        if (res.data.success) {
          setData(prevData => [...prevData, ...res.data.data.data]);
          setNextLink(res?.data?.data?.links?.next || null);
        }
      } catch (error) {
        console.error(error);
        showToast('Something went wrong, please contact admin', 'error');
      }
    }
  }, [nextLink, setData, setNextLink]);

  return (
    <>
      <ReportsContainer
        title={'Material Request Details Report'}
        url="purchase/material-requests-details-report/export"
        initialMessage={'Material Request Details Export Started'}
        successMessage={'Material Request Details Export Completed'}
        data={data}
        loading={loading}
        moduleName="material_request_report"
        sort={true}
        sortValue={materialRequestDetailsReportSortData}
      >
        <Container fluid className="p-0 reports-container-wrapper">
          <Table bordered={data?.length > 0 ? true : false} striped hover>
            <TableHeader headers={MaterialRequestDetailsHead} />
            {data?.length > 0 ? (
              <>
                {data.map((item, index) => (
                  <tbody key={index}>
                    <tr className="report-column-custom-style">
                      <td>{index + 1}</td>
                      <td>
                        <span className="report-column-content-custom-style">
                          {item?.trans_date_formatted}
                        </span>
                      </td>
                      <td>
                        <ClickReference
                          permission={permissions?.material_request_show}
                          id={item?.material_request_id}
                          reference={item?.reference}
                          trans_type="MR"
                          clickOnFunction={handleVoucherModal}
                        />
                      </td>
                      <td className="name-column report-column-content-custom-style">
                        <ItemName
                          name={item.item_display_name}
                          id={item.item_id}
                        />
                      </td>
                      <td>
                        <span className="report-column-content-custom-style">
                          {item?.cost_center_name ?? '-'}
                        </span>
                      </td>
                      <td>
                        <span className="report-column-content-custom-style">
                          {item?.quantity ?? '-'}
                        </span>
                      </td>
                      <td>
                        <span className="report-column-content-custom-style">
                          {item?.quantity_issued ?? '-'}
                        </span>
                      </td>
                      <td>
                        <span className="report-column-content-custom-style">
                          {item?.quantity_send ?? '-'}
                        </span>
                      </td>
                      <td>
                        <span className="report-column-content-custom-style">
                          {item?.unit_discount
                            ? acodaxNumberFormat(item.unit_discount)
                            : '-'}
                        </span>
                      </td>
                      <td>
                        <span className="report-column-content-custom-style">
                          {item?.unit_discount
                            ? acodaxNumberFormat(item.unit_tax)
                            : '-'}
                        </span>
                      </td>
                      <td>
                        <span className="report-column-content-custom-style">
                          {item?.unit_discount
                            ? acodaxNumberFormat(item.unit_price)
                            : '-'}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                ))}
                {nextLink && nextLink !== null && nextLink !== '' && (
                  <MinimalReportDataFetching
                    row={4}
                    column={6}
                    nextLink={nextLink}
                    loadNextPage={loadNextPage}
                  />
                )}
              </>
            ) : (
              <tr className="alert-styles">
                <td colSpan={12}>
                  <Alert variant="warning">
                    {Translate('Currently there is no report generated.')}
                  </Alert>
                </td>
              </tr>
            )}
          </Table>
        </Container>
      </ReportsContainer>
      {modalDetails?.modal === 'voucher' ? (
        <VoucherDetail
          show
          onHide={() => setModalDetails(undefined)}
          setShowVoucherDetailArea={() => setModalDetails(undefined)}
          type={modalDetails?.type}
          voucherId={modalDetails?.targetId}
          offCanvas={true}
        />
      ) : null}
    </>
  );
}
