import axios from 'axios';
import React, { useEffect, useMemo, useReducer, useRef } from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import LoadingScreenCover from 'components/common/loading-screen/LoadingScreenCover';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import { Alert, Button, Card } from 'react-bootstrap';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { FiChevronsRight } from 'react-icons/fi';
import { FaSave, FaUserShield } from 'react-icons/fa';
import { showToast } from 'module/Common/Toast/toast';
import { MdRotateLeft } from 'react-icons/md';
import './_systemAdminPermissionTreeview.scss';
import permissionDataReducer, {
  initPermissionData
} from 'reducers/permissionDataReducer';
import PermissionsTableListView from './components/PermissionsTableListView';
import PermissionPermissionsTreeView from './components/permissions-tree-view/PermissionPermissionsTreeView';
export default function SystemAdminPermissionTreeview({
  module,
  targetId,
  targetName
}) {
  const Translate = useAxisproTranslate();
  const [permissionData, dispatchPermissionData] = useReducer(
    permissionDataReducer,
    initPermissionData
  );
  const [loading, setLoading] = useState(false);
  const [updatingPermissions, setUpdatingPermissions] = useState(false);
  const postApiRequestAbortControllerRef = useRef(null);
  const statusChangedItems = useMemo(() => {
    return Array.isArray(permissionData?.allPermissionsData)
      ? permissionData.allPermissionsData.filter(
          item =>
            Object.keys(item).includes('hasPermissionRef') &&
            (item.hasPermission !== item.hasPermissionRef ||
              item.hasPermission !== item.hasPermissionRef)
        )
      : [];
  }, [permissionData]);
  const statusChangedItemsCount = statusChangedItems.length;
  async function handlePermissions(event, type) {
    if (event) event.preventDefault();
    setUpdatingPermissions(true);
    if (type === 'reset') {
      dispatchPermissionData({
        type: 'RESET_DATA'
      });
      setUpdatingPermissions(false);
      return;
    }
    postApiRequestAbortControllerRef?.current?.abort();
    postApiRequestAbortControllerRef.current = new AbortController();
    let newPermissionIdResult = (
      Array.isArray(permissionData?.allPermissionsData)
        ? permissionData.allPermissionsData
        : []
    ).reduce((newResult, item) => {
      if (
        (item.hasPermission === true && item?.hasPermissionRef !== false) ||
        item?.hasPermissionRef === true
      ) {
        newResult.push(item.id);
      }
      return newResult;
    }, []);
    try {
      const apiRUL =
        module === 'user' ? 'user-permissions' : 'role-permissions';
      let payload = {};
      if (module === 'user') {
        payload.user_id = targetId;
      } else {
        payload.role_id = targetId;
        payload.sync = true;
      }
      payload.permission_slugs = newPermissionIdResult;
      const response = await axios.post(apiRUL, payload, {
        signal: postApiRequestAbortControllerRef?.current?.signal
      });
      if (response.data.success && response.data?.data) {
        showToast(
          Translate(
            response.data?.message ??
              `${
                module === 'user' ? 'User' : 'Role'
              } Permissions updated Successfully`
          ),
          'success'
        );
        return;
      }
      showToast(
        Translate(
          'Something went wrong, please refresh the page and try again.'
        ),
        'error'
      );
    } catch (error) {
      error?.name === 'CanceledError'
        ? console.info('Api request canceled')
        : showToast(
            Translate(
              error?.response?.data?.message ??
                'Something went wrong, please refresh the page and try again.'
            ),
            'error'
          );
    } finally {
      !postApiRequestAbortControllerRef?.current?.signal?.aborted &&
        setUpdatingPermissions(false);
    }
  }

  function handlePermissionsOnCheckModule(slectedModulesArray = []) {
    if (updatingPermissions) return;
    dispatchPermissionData({
      type: 'CHECK_MODULE',
      slectedModulesArray: slectedModulesArray
    });
  }

  function onSwitch(event, id, module = false, selectedModulesArray = []) {
    if (updatingPermissions) return;
    let checked = event.target.checked;
    dispatchPermissionData({
      type: 'SWITCH_PERMISSION',
      checked: checked,
      id: id,
      module: module,
      selectedModulesArray: selectedModulesArray
    });
  }

  useEffect(() => {
    let apiCall;
    let fetchApiCallAbortController;
    async function fetchData() {
      fetchApiCallAbortController = new AbortController();

      setLoading(prevLoading => {
        if (!prevLoading) return true;
        return prevLoading;
      });

      let apiURL =
        module === 'user'
          ? 'user-permissions?user_id=' + targetId
          : 'role-permissions?role_id=' + targetId;
      try {
        const response = await axios.get(apiURL, {
          signal: fetchApiCallAbortController?.signal
        });
        if (response.data.success && response.data?.data) {
          dispatchPermissionData({
            type: 'SET_DATA',
            data: response.data.data
          });
        }
      } catch (error) {
        error?.name === 'CanceledError'
          ? console.info('Api request canceled')
          : console.error(error);
      } finally {
        !fetchApiCallAbortController?.signal?.aborted && setLoading(false);
      }
    }

    if (targetId) {
      setLoading(true);
      apiCall = setTimeout(() => {
        fetchData();
      }, 1200);
    }

    return () => {
      fetchApiCallAbortController?.abort();
      dispatchPermissionData({
        type: 'SET_DEFAULT_DATA'
      });
      setLoading(false);
      clearTimeout(apiCall);
    };
  }, [targetId, module]);

  return (
    <Card className="flex-fill view-page-content-wrapper system-permission-view-card">
      <Card.Header className="d-flex m-0 align-items-center border-bottom">
        <div className="d-flex flex-row align-items-center me-2">
          <FaUserShield size={20} className="me-2 text-danger" />
          <div>
            <p
              className="mb-0 d-flex text-uppercase"
              style={{
                fontSize: '.95rem'
              }}
            >
              <span className="text-dark text-capitalize">
                {Translate(`${module} Permissions`)}
              </span>
              {targetName ? (
                <>
                  <FiChevronsRight color="red" className="mx-2" />
                  {targetName}
                </>
              ) : null}
              {permissionData?.activeModule?.name ? (
                <>
                  <FiChevronsRight color="red" className="mx-2" />
                  {permissionData?.activeModule.name}
                </>
              ) : null}
            </p>
          </div>
        </div>
      </Card.Header>
      <Card.Body className="d-flex flex-row p-0">
        {!loading && permissionData?.permissionsTreeData.length > 0 ? (
          <>
            <div
              className={`${
                statusChangedItemsCount > 0 ? 'with' : 'without'
              }-footer-content-height border-end`}
            >
              <PermissionPermissionsTreeView
                data={permissionData?.permissionsTreeData}
                handleShowPermissionsList={module =>
                  dispatchPermissionData({
                    type: 'SET_ACTIVE_MODULE_DATA',
                    module: module
                  })
                }
                onSelectItems={handlePermissionsOnCheckModule}
                defaultSelectedModules={permissionData?.defaultSelectedModules}
                defaultExpanded={[permissionData?.permissionsTreeData[0].id]}
              />
            </div>
            <div
              className={`flex-fill ${
                statusChangedItemsCount > 0 ? 'with' : 'without'
              }-footer-content-height`}
            >
              <PermissionsTableListView
                Translate={Translate}
                updatingPermissions={updatingPermissions}
                onSwitch={onSwitch}
                permissions={permissionData?.permissionsData}
                activeModule={permissionData?.activeModule?.id}
              />
            </div>
          </>
        ) : (
          <LoadingScreenCover className="mb-3 bg-transparent shadow-none">
            {loading ? (
              <LoadingScreen
                message={Translate(`Looking for ${module} permissions details`)}
              />
            ) : (
              <h5 className=" text-center text-secondary">
                {Translate(`There were no results found for this ${module}!`)}{' '}
                &#128578;
              </h5>
            )}
          </LoadingScreenCover>
        )}
      </Card.Body>

      <Card.Footer className="border-top">
        {updatingPermissions ? (
          <Alert variant="info" className="m-0 rounded-0">
            {Translate('Saving changes, please wait...')}
          </Alert>
        ) : (
          <>
            {' '}
            <p className="small mb-1">
              {statusChangedItemsCount} Change
              {statusChangedItemsCount > 1 ? 's' : ''} found
            </p>
            <div className="d-flex flex-row align-items-center">
              <Button
                size="sm"
                variant="success"
                className="d-flex flex-row align-items-center me-2 py-1"
                onClick={event => handlePermissions(event, 'enable')}
              >
                <FaSave />
                <span className="ms-1 small">{Translate('Save')}</span>
              </Button>
              <Button
                size="sm"
                variant="danger"
                className="d-flex flex-row align-items-center me-2 py-1"
                onClick={event => handlePermissions(event, 'reset')}
              >
                <MdRotateLeft />
                <span className="ms-1 small">{Translate('Reset')}</span>
              </Button>
            </div>
          </>
        )}
      </Card.Footer>
    </Card>
  );
}

SystemAdminPermissionTreeview.propTypes = {
  module: PropTypes.string,
  targetId: PropTypes.string,
  targetName: PropTypes.string
};
