import { React, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, Dropdown, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Search from 'components/search/Search';
import SettingsItem from 'components/settingsItem/SettingsItem';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import { SystemSettingsMenuArray } from 'module/SystemSettings/Components/Common/menuArray';

const SettingsDropdown = () => {
  let navigate = useNavigate();
  const { pathname } = useLocation();
  const Translate = useAxisproTranslate();
  const [isOpen, setIsOpen] = useState(false);
  const [menuItems, setMenuItems] = useState([]);

  const filterSettings = event => {
    let filteredMenuItems = event.target.value
      ? SystemSettingsMenuArray.filter(
          item => item.name.toLowerCase().indexOf(event.target.value) !== -1
        )
      : SystemSettingsMenuArray;
    setMenuItems(filteredMenuItems);
  };
  // Handler
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleNavigate = (link = null) => {
    if (link) {
      handleToggle();
      navigate({
        pathname: link,
        replace: true
      });
    }
  };

  useEffect(() => {
    setMenuItems(SystemSettingsMenuArray);
    window.addEventListener('scroll', () => {
      window.innerWidth < 1200 && setIsOpen(false);
    });
  }, []);

  return (
    <Dropdown navbar={true} as="li" show={isOpen} onToggle={handleToggle}>
      <Dropdown.Toggle
        bsPrefix="toggle"
        variant="transparent"
        style={{ boxShadow: 'none', border: 'none' }}
        className="px-0 p-0 settings-button-driver-element"
      >
        <FontAwesomeIcon
          icon="cog"
          transform="shrink-6"
          className="fs-4"
          title={Translate('Settings')}
        />
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="dropdown-menu-card dropdown-menu-end dropdown-caret-bg"
        style={{ width: '16rem' }}
      >
        <Card
          className="dropdown-menu-notification dropdown-menu-end shadow-none"
          style={{ minWidth: '15rem' }}
        >
          <div className="bg-light">
            <FalconCardHeader
              className="card-header py-2 ps-2 pe-3"
              title={
                <span style={{ fontSize: '1rem' }}>
                  {Translate('Settings')}
                </span>
              }
              titleTag="h3"
              light={false}
              endEl={
                <Button
                  size="sm"
                  variant="light"
                  className="p-0 shadow-none border-0"
                  style={{
                    backgroundColor: 'transparent !important'
                  }}
                  onClick={handleToggle}
                >
                  <FontAwesomeIcon icon="times" />
                </Button>
              }
            />
            <Search
              iconClassname={'py-0'}
              className="py-1 bg-white"
              onChange={filterSettings}
            />
          </div>

          <ListGroup variant="flush" className="fw-normal fs--1 scrollbar">
            {menuItems.map((item, index) =>
              getAcodaxPermissionSlug(item?.permissionSlug, 'show') ? (
                <ListGroup.Item
                  key={index}
                  className="border-0 text-decoration-none"
                  onClick={() => {
                    handleNavigate(item.route);
                  }}
                >
                  <SettingsItem item={item} active={pathname === item.route} />
                </ListGroup.Item>
              ) : (
                ''
              )
            )}
          </ListGroup>
        </Card>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SettingsDropdown;
