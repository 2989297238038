import { React, useContext, useEffect, useLayoutEffect, useState } from 'react';
import axios from 'axios';
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import { MdOutlinePayments } from 'react-icons/md';
import { BiError } from 'react-icons/bi';

import { showToast } from 'module/Common/Toast/toast';
import { AuthWizardContext } from 'context/Context';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import UnUsedCreditsTable from './UnUsedCreditsTable';
import AllocatingTableLoading from 'module/Finance/CustomerAllocation/AllocationScreen/AllocatingTable/AllocatingTableLoading';

function UnUsedCreditsPopup({
  show,
  onHide,
  transId,
  transType,
  reference,
  dueAmount,
  reLoad,
  person
}) {
  const Translate = useAxisproTranslate();
  const { user } = useContext(AuthWizardContext);
  const [advanceReceipt, setAdvanceReceipt] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [total, setTotal] = useState({
    totalAmount: 0,
    allocatedAmount: 0,
    dueAmount: 0
  });
  const [saving, setSaving] = useState(false);
  const [errorData, setErrorData] = useState(false);
  const baseCurrency = user?.branch?.base_currency ?? '';

  useEffect(() => {
    const fetchData = () => {
      setLoading(true);
      person?.personType === 'CUSTOMER'
        ? axios
            .get('finance/customer-advance-trans', {
              params: {
                customer_id: person.personId
              }
            })
            .then(res => {
              // let data =
              //   res?.data?.data?.allocs.filter(
              //     item =>
              //       !(parseFloat(item.alloc) > 0 && item.trans_type === 'CN')
              //   ) ?? [];
              setAdvanceReceipt(res?.data?.data?.allocs);
              setLoading(false);
            })
            .catch(error => console.log(error))
        : axios
            .get('finance/supplier-advance-trans', {
              params: {
                supplier_id: person?.personId
              }
            })
            .then(res => {
              // let data =
              //   res?.data?.data?.allocs.filter(
              //     item =>
              //       !(parseFloat(item.alloc) > 0 && item.trans_type === 'DN')
              //   ) ?? [];
              setAdvanceReceipt(res?.data?.data?.allocs);
              setLoading(false);
            })
            .catch(error => console.log(error));
    };

    if (show) {
      setErrorData(false);
      fetchData();
    }
  }, [show, person?.personType, person?.personId]);

  useLayoutEffect(() => {
    if (person && person.personType && person.personType === 'CUSTOMER') {
      setFormData(prevData => ({
        ...prevData,
        customer_id: person.personId,
        trans_id: transId,
        trans_type: transType,
        allocation: []
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        supplier_id: person.personId,
        trans_id: transId,
        trans_type: transType,
        allocation: []
      }));
    }
  }, [person, transId, transType]);

  const handleAdvanceReceiptAmount = (e, index) => {
    if (advanceReceipt[index]) {
      advanceReceipt[index].payment_amount = e.target.value;
      if (advanceReceipt[index].balance_amount < e.target.value) {
        setErrorData(true);
      } else {
        setErrorData(false);
      }
    }
    setAdvanceReceipt([...advanceReceipt]);
  };

  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      allocation: advanceReceipt
        .filter(payment => payment.payment_amount)
        .map(item => ({
          trans_type: item.trans_type,
          voucher_id: item.id,
          amount: item.payment_amount
        }))
    }));
  }, [advanceReceipt, formData.amount]);

  const handleSplitCreditAmount = () => {
    let newData = advanceReceipt.map(item => {
      if (dueAmount > 0) {
        let pendingAmount = parseFloat(item.balance_amount);
        item.this_alloc = dueAmount > pendingAmount ? pendingAmount : dueAmount;
        item.payment_amount =
          dueAmount > pendingAmount ? pendingAmount : dueAmount;
        dueAmount = dueAmount - pendingAmount;
      } else {
        item.this_alloc = 0;
      }
      return item;
    });
    setAdvanceReceipt([...newData]);
  };

  const allocatedAmount = index => {
    const remainingDue = parseFloat(dueAmount - total.allocatedAmount);
    if (remainingDue > 0) {
      const selectedPayment = advanceReceipt[index];
      const paymentAmount =
        parseFloat(selectedPayment.balance_amount) > remainingDue
          ? remainingDue
          : parseFloat(selectedPayment.balance_amount);
      selectedPayment.payment_amount = paymentAmount;
      selectedPayment.this_alloc = paymentAmount;
      total.allocatedAmount = total.allocatedAmount + paymentAmount;
      setAdvanceReceipt([...advanceReceipt]);
    }
  };

  const ClearAllocation = index => {
    const amount = advanceReceipt[index].payment_amount;
    advanceReceipt[index].payment_amount = '';
    advanceReceipt[index].this_alloc = '';
    total.allocatedAmount = total.allocatedAmount - amount;
    setAdvanceReceipt([...advanceReceipt]);
  };

  const handleClearAppliedAmount = () => {
    setErrorData(false);
    let newData = advanceReceipt.map(item => {
      item.payment_amount = 0;
      return item;
    });
    setAdvanceReceipt([...newData]);
  };

  useEffect(() => {
    let totalPaymentAmount = 0,
      totalDueAmount = 0,
      totalAllocatedAmount = 0;
    advanceReceipt.map(item => {
      let payment_amount = item.payment_amount;
      let amount = item.amount;
      let pending_amount = item.balance_amount;
      totalPaymentAmount = amount
        ? parseFloat(amount) + totalPaymentAmount
        : totalPaymentAmount;
      totalDueAmount = pending_amount
        ? parseFloat(pending_amount) + totalDueAmount
        : totalDueAmount;
      totalAllocatedAmount = payment_amount
        ? parseFloat(payment_amount) + totalAllocatedAmount
        : totalAllocatedAmount;
    });
    setTotal(prevData => ({
      ...prevData,
      dueAmount: totalDueAmount,
      allocatedAmount: totalAllocatedAmount,
      totalAmount: totalPaymentAmount
    }));
  }, [advanceReceipt, formData.allocation]);

  const handleSubmit = e => {
    e.preventDefault();
    setSaving(true);
    axios({
      method: 'post',
      url:
        person && person.personType && person.personType === 'CUSTOMER'
          ? 'finance/customer-allocation/allocate-to-advances'
          : 'finance/supplier-allocation/allocate-to-advances',
      data: formData
    })
      .then(response => {
        if (response.data.success === true) {
          showToast(response.data.message, 'success');
          onHide();
          reLoad();
        } else {
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            'error'
          );
        }
        setSaving(false);
      })
      .catch(error => {
        if (error.response.data && error.response.data.message) {
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && validation_error[0] && validation_error[0][0]
            ? showToast(validation_error[0][0], 'error')
            : showToast(error.response.data.message, 'error');
        } else {
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            'error'
          );
        }
        setSaving(false);
      });
  };

  // const handleAdvanceReceiptAmount = (e, index) => {
  //   if (pendingInvoices[index]) {
  //     pendingInvoices[index].payment_amount = e.target.value;
  //   }
  //   setPendingInvoices([...pendingInvoices]);
  // };

  return (
    <Modal
      show={show}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      className="modal-with-overlay"
    >
      <Form onSubmit={handleSubmit}>
        <Card>
          <Card.Header className="fs-1 p-3 text-dark d-flex align-items-center border-bottom">
            <MdOutlinePayments size={20} className="me-2" />
            {Translate('Apply Credits for')} {reference}
          </Card.Header>
          <Card.Body className="p-3">
            {dueAmount - total.allocatedAmount < 0 || errorData ? (
              <Alert variant="danger">
                {dueAmount - total.allocatedAmount < 0 && (
                  <>
                    <BiError />
                    <span>
                      {Translate(
                        'Total Allocating amount is greater than Total Allocatable amount'
                      )}
                    </span>
                  </>
                )}
                {errorData &&
                  !(dueAmount - total.allocatedAmount < 0) &&
                  Translate(
                    'Allocating amount must be less than Balance amount'
                  )}
              </Alert>
            ) : (
              ''
            )}
            <Row>
              <Col
                xs={12}
                className="d-flex flex-column align-items-center gap-2"
              >
                <div className="d-flex align-items-center">
                  <h5 className="fs--1 m-0 text-dark">
                    {Translate('Balance Due')}
                  </h5>
                  &nbsp;&nbsp;
                  <span>-</span>
                  &nbsp;&nbsp;
                  <h5 className="fs-1 fw-normal text-warning m-0">
                    {baseCurrency}
                    {(dueAmount - total.allocatedAmount).toLocaleString(
                      navigator.language,
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      }
                    )}
                  </h5>
                </div>
                <div className="d-flex gap-2">
                  <OverlayTrigger
                    placement="left"
                    overlay={
                      <Tooltip>
                        <span className="fs--2">
                          {Translate('Allocate due amount to advances')}
                        </span>
                      </Tooltip>
                    }
                  >
                    <Button
                      variant="info"
                      style={{ boxShadow: 'none' }}
                      size="sm"
                      onClick={handleSplitCreditAmount}
                    >
                      {Translate('Allocate')}
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip>
                        <span className="fs--2">
                          {Translate('Clear all applied amount')}
                        </span>
                      </Tooltip>
                    }
                  >
                    <Button
                      variant="warning"
                      style={{ boxShadow: 'none' }}
                      size="sm"
                      onClick={handleClearAppliedAmount}
                    >
                      {Translate('Clear')}
                    </Button>
                  </OverlayTrigger>
                </div>
              </Col>
            </Row>
            {!loading ? (
              <Row className="mt-3">
                <Col xs={12}>
                  <UnUsedCreditsTable
                    advanceReceipt={advanceReceipt}
                    handleAdvanceReceiptAmount={handleAdvanceReceiptAmount}
                    total={total}
                    errorData={errorData}
                    dueAmount={parseFloat(dueAmount - total.allocatedAmount)}
                    allocatedAmount={allocatedAmount}
                    ClearAllocation={ClearAllocation}
                  />
                </Col>
              </Row>
            ) : (
              <AllocatingTableLoading />
            )}
          </Card.Body>
          <Card.Footer className="d-flex gap-2 justify-content-end p-3 pt-1">
            <Button
              variant="success"
              style={{ boxShadow: 'none' }}
              size="sm"
              type="submit"
              disabled={
                dueAmount - total.allocatedAmount < 0 || errorData || saving
              }
            >
              {saving ? 'Allocating...' : 'Allocate'}
            </Button>
            <Button
              variant="danger"
              style={{ boxShadow: 'none' }}
              size="sm"
              onClick={onHide}
              disabled={saving}
            >
              {Translate('Cancel')}
            </Button>
          </Card.Footer>
        </Card>
      </Form>
    </Modal>
  );
}

UnUsedCreditsPopup.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  person: PropTypes.object,
  transType: PropTypes.string,
  transId: PropTypes.string,
  reference: PropTypes.string,
  dueAmount: PropTypes.number,
  reLoad: PropTypes.func
};

export default UnUsedCreditsPopup;
