function isValidDate(date, minYear) {
  return (
    date instanceof Date &&
    !isNaN(date.getTime()) &&
    (!minYear || date?.getFullYear() >= minYear)
  );
}

export default function checkDueDateIsValid(date, dueDate) {
  return new Promise((resolve, reject) => {
    const date1 = new Date(date);
    const date2 = new Date(dueDate);

    if (
      date === null ||
      dueDate === null ||
      !isValidDate(date1, 1000) ||
      !isValidDate(date2, 1000) ||
      date2 >= date1
    ) {
      resolve('The due date is valid');
      return;
    }

    reject({
      message:
        'The due date must be later than or equal to the transaction date'
    });
  });
}
