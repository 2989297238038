import { BroadcastChannelContext } from 'context/Context';
import React, { useContext, useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { AiOutlineReload } from 'react-icons/ai';

export const useBroadcastChannel = () => {
  return useContext(BroadcastChannelContext);
};

export const handleReload = () => {
  window.location.reload();
};

export default function BroadcastChannelContextProvider({ children }) {
  const [channel, setChannel] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isTabInactive, setIsTabInactive] = useState(false);

  useEffect(() => {
    if ('BroadcastChannel' in window) {
      // Initialize BroadcastChannel on application start
      const bc = new BroadcastChannel('ACCODAX-BROADCAST-CHANNEL');
      setChannel(bc);

      // Listen for visibility changes to check if the tab is inactive
      const handleVisibilityChange = () => {
        if (document.visibilityState === 'hidden') {
          setIsTabInactive(true); // Tab is now inactive
        } else {
          setIsTabInactive(false); // Tab is active
        }
      };

      // Set up the visibility change listener
      document.addEventListener('visibilitychange', handleVisibilityChange);

      // Set up message listener for BroadcastChannel
      bc.onmessage = event => {
        const actionName = event?.data?.action;
        if (actionName === 'LoggedIn' && isTabInactive) {
          setShowModal(true);
        } else if (actionName === 'LoggedOut') {
          handleReload();
        }
      };

      // Clean up the channel when the component is unmounted
      return () => {
        bc.close();
        document.removeEventListener(
          'visibilitychange',
          handleVisibilityChange
        );
        bc.onmessage = null; // Optionally clean up the message handler
      };
    } else {
      console.warn('BroadcastChannel API is not supported in this environment');
    }
  }, [isTabInactive]);

  return (
    <BroadcastChannelContext.Provider value={channel}>
      {children}
      <Modal show={showModal} centered>
        <Modal.Body className="fs--1 d-flex flex-column gap-2 align-items-center justify-content-center">
          <AiOutlineReload size={40} />
          You need to reload this tab to continue!
        </Modal.Body>
        <Button variant="danger" onClick={handleReload}>
          Reload
        </Button>
      </Modal>
    </BroadcastChannelContext.Provider>
  );
}

BroadcastChannelContextProvider.propTypes = {
  children: Proptypes.node.isRequired
};
