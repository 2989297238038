import { useEffect, useMemo, useRef, useState } from 'react';
import {
  createSearchParams,
  useNavigate,
  useSearchParams
} from 'react-router-dom';

export default function useKeyDownNavigatorHook({
  focusElementClassName,
  activeKeyName,
  activeItemRef,
  dataResult,
  lastPageNumber,
  params,
  to,
  includeFilters = true
}) {
  const [queryParams] = useSearchParams();
  const containerRef = useRef(null);
  const [windowIsActive, setWindowIsActive] = useState(true);
  const navigate = useNavigate();
  const pageNumber = queryParams.get('page');
  const filterQueryParam = queryParams.get('filters');
  const result = useMemo(() => {
    return Array.isArray(dataResult) ? dataResult : [];
  }, [dataResult]);
  let activeItem = result.find(item => item[activeKeyName] === activeItemRef);
  let indexOfActiveItem = result.indexOf(activeItem);
  const stringifiedActiveItem = JSON.stringify(activeItem);
  const stringifiedParams = JSON.stringify(params);
  const stringifiedResult = JSON.stringify(result);

  useEffect(() => {
    const parsedParams = JSON.parse(stringifiedParams);
    const parsedResult = JSON.parse(stringifiedResult);
    function handleKeyDown(event) {
      const navigateTo = (ItemDetails, pageNumber) => {
        if (focusElementClassName) {
          const element = document.querySelector('.' + focusElementClassName);
          if (element) element.focus();
        }
        let URLparams = {};
        if (Array.isArray(parsedParams) && typeof ItemDetails === 'object') {
          parsedParams.forEach(item => {
            URLparams[item?.paramKey] =
              item?.value ?? ItemDetails?.[item?.dataKey];
          });
        }
        navigate(
          `${to}?${createSearchParams({
            ...URLparams,
            ...(includeFilters && filterQueryParam
              ? { filters: filterQueryParam }
              : null),
            layout: 'column',
            ...(pageNumber ? { page: pageNumber } : null)
          })}`,
          {
            state: {
              apiCallDelay: 1100
            }
          }
        );
      };

      if (event.key === 'ArrowDown') {
        let nextItem = parsedResult?.[indexOfActiveItem + 1];
        if (nextItem) {
          navigateTo(nextItem, pageNumber);
        } else if (lastPageNumber > 1 && pageNumber < lastPageNumber) {
          navigateTo(undefined, parseInt(pageNumber ?? 1) + 1);
        }
      } else if (event.key === 'ArrowUp') {
        let prevItem = parsedResult?.[indexOfActiveItem - 1];
        if (prevItem) {
          navigateTo(prevItem, pageNumber);
        } else if (pageNumber > 1) {
          navigateTo(undefined, parseInt(pageNumber) - 1);
        }
      } else if (event.key === 'ArrowLeft' && pageNumber > 1) {
        navigateTo(undefined, parseInt(pageNumber) - 1);
      } else if (
        event.key === 'ArrowRight' &&
        lastPageNumber > 1 &&
        pageNumber < lastPageNumber
      ) {
        navigateTo(undefined, parseInt(pageNumber ?? 1) + 1);
      }
    }

    function handleClick(event) {
      if (containerRef.current) {
        if (!containerRef.current.contains(event.target)) {
          setWindowIsActive(false);
        } else if (!windowIsActive) {
          setWindowIsActive(true);
        }
      }
    }

    document.addEventListener('mousedown', handleClick);

    if (windowIsActive) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('mousedown', handleClick);
    };
  }, [
    stringifiedActiveItem,
    windowIsActive,
    stringifiedParams,
    pageNumber,
    lastPageNumber,
    focusElementClassName,
    navigate,
    to,
    includeFilters,
    filterQueryParam,
    stringifiedResult,
    indexOfActiveItem
  ]);

  // useEffect(() => {
  //   const element = document.querySelector('.' + focusElementClassName);
  //   if (element) element.focus();
  // }, [document.querySelector('.' + focusElementClassName)]);

  return {
    containerRef: containerRef
  };
}
