import { React, useCallback, useState } from 'react';
import axios from 'axios';
import { Badge, Card, Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { BiDotsVertical } from 'react-icons/bi';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import ReconcileLoading from 'module/Common/Loading/ReconcileLoading';
import { showToast } from 'module/Common/Toast/toast';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import GroupAvatarImage from 'assets/img/group-avatar.jpg';
import ChatWindowSmallScreen from '../chatWindow/smallScreen/ChatWindowSmallScreen';

const GroupTab = ({
  groupData,
  setGroupLinks,
  groupLinks,
  setgroupData,
  loading,
  fetchGroups,
  hideOffCanvas,
  readUnreadMessage
}) => {
  const Translate = useAxisproTranslate();
  const [showChatWindow, setShowChatWindow] = useState(false);
  const [item, setItem] = useState({});
  const [deleteClicked, setDeleteClicked] = useState(false);

  const loadNextPage = useCallback(
    async next_page_url => {
      if (next_page_url) {
        try {
          const res = await axios.get(next_page_url);
          setgroupData(prevGroupData => [
            ...prevGroupData,
            ...res.data.data.data
          ]);
          setGroupLinks(res.data.data);
        } catch (error) {
          console.error(error);
          showToast('Something went wrong please contact admin', 'error');
        }
      }
    },
    [setgroupData, setGroupLinks]
  );

  const DeleteGroup = item => {
    axios
      .delete(`/user/chat-groups/${item.id}`)
      .then(response => {
        if (response.data.success) {
          showToast(response.data.message, 'success');
          fetchGroups();
        } else {
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            'error'
          );
        }
      })
      .catch(() => {
        showToast(
          `Something went wrong, please refresh the page and try again.`,
          'error'
        );
      });
  };

  const loadChat = item => {
    if (item.un_read_message_count > 0) {
      readUnreadMessage('GROUP', item.id);
    }
    setItem(item);
    setShowChatWindow(true);
  };

  function handleCardClick(item) {
    if (!deleteClicked) {
      loadChat(item);
    }
  }

  function handleDeleteClick(item) {
    setDeleteClicked(true);
    DeleteGroup(item);
  }

  function handleDeleteOptionClick(event) {
    event.stopPropagation();
    setDeleteClicked(true);
  }

  const hideDetailOffCanvas = () => {
    hideOffCanvas();
    setShowChatWindow(false);
  };

  return (
    <>
      {!loading ? (
        <>
          {groupData && groupData.length > 0 ? (
            groupData.map((item, index) => (
              <Card
                className="chat-tab-custom-style"
                key={index}
                onClick={event => {
                  if (
                    !event.target.classList.contains('toggle') &&
                    !event.target.classList.contains('bi-dots-vertical')
                  ) {
                    handleCardClick(item);
                  }
                }}
              >
                <Card.Header className="d-flex align-items-center">
                  <div className="position-relative me-2">
                    <img
                      src={GroupAvatarImage}
                      width={50}
                      height={50}
                      className="rounded-circle"
                    />
                  </div>
                  <div
                    className="d-flex flex-column align-items-start me-2"
                    style={{ width: '78%', cursor: 'pointer' }}
                  >
                    <h3 className="m-0">{item.name}</h3>
                    {item.unread_message_count > 0 && (
                      <Badge bg="success">
                        {item.unread_message_count}
                        {Translate('unread')}
                      </Badge>
                    )}
                  </div>
                  <div className="d-flex flex-column align-items-start me-2 ms-auto">
                    <Dropdown>
                      <Dropdown.Toggle
                        bsPrefix="toggle"
                        variant="transparent"
                        style={{ boxShadow: 'none', border: 'none' }}
                        className="px-0 d-flex align-items-center justify-content-center toggle"
                      >
                        <BiDotsVertical
                          size={20}
                          transform="shrink-6"
                          className="me-1 bi-dots-vertical"
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu-card  dropdown-menu-end">
                        <div className="bg-white rounded-2 p-0">
                          <Dropdown.Item
                            className="d-flex gap-1 align-items-center p-0 ps-1 dropdown-item"
                            onClick={() => handleDeleteClick(item)}
                          >
                            <span onClick={handleDeleteOptionClick}>
                              {Translate('Delete Group')}
                            </span>
                          </Dropdown.Item>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Card.Header>
              </Card>
            ))
          ) : (
            <Card.Body
              className="d-flex justify-content-center align-items-center"
              style={{ height: '45rem' }}
            >
              <div className="d-flex flex-column justify-content-center align-items-center">
                <p>{Translate('No groups found,Let us add one')}</p>
              </div>
            </Card.Body>
          )}
          {groupLinks &&
            groupLinks.meta &&
            groupLinks.meta.current_page !== groupLinks.meta.last_page && (
              <ReconcileLoading
                loadNextPage={loadNextPage}
                links={groupLinks}
              />
            )}
        </>
      ) : (
        <Card.Body
          className="d-flex justify-content-center align-items-center"
          style={{ height: '45rem' }}
        >
          <LoadingScreen />
        </Card.Body>
      )}
      <ChatWindowSmallScreen
        show={showChatWindow}
        hideDetailOffCanvas={hideDetailOffCanvas}
        type={'GROUP'}
        item={item}
        setShowChatWindow={setShowChatWindow}
      />
    </>
  );
};

GroupTab.propTypes = {
  groupData: PropTypes.any,
  showChat: PropTypes.func,
  setGroupLinks: PropTypes.func,
  groupLinks: PropTypes.any,
  setgroupData: PropTypes.func,
  loading: PropTypes.bool,
  setShowGroupForm: PropTypes.func,
  fetchGroups: PropTypes.func,
  hideOffCanvas: PropTypes.func,
  readUnreadMessage: PropTypes.func
};

export default GroupTab;
