import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import MonthlyRevenue from './MonthlyRevenue';
import axios from 'axios';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import PropTypes from 'prop-types';

function PaymentReceivedProgress({ draggable }) {
  const Translate = useAxisproTranslate();

  const [isLoadingPaymentReceive, setIsLoadingPaymentReceive] = useState(false);
  const [paymentReceiveData, setPaymentReceiveData] = useState(undefined);
  const [salesInvoiceProgressPercentage, setSalesInvoiceProgressPercentage] =
    useState(undefined);

  useEffect(() => {
    const getPaymentReceivedData = () => {
      setIsLoadingPaymentReceive(true);
      axios
        .get('/sales/get-payment-received-progress-report')
        .then(response => {
          if (response?.data?.data) {
            let { data } = response.data;
            setPaymentReceiveData([
              {
                label: Translate('Total'),
                value: data?.total_amount
              },
              {
                label: Translate('Received'),
                value: data?.received_amount
              }
            ]);
            setSalesInvoiceProgressPercentage(data?.received_amount_percentage);
            setIsLoadingPaymentReceive(false);
          }
        })
        .catch(error => console.log(error));
    };

    getPaymentReceivedData();
  }, [Translate]);

  return (
    <Card className={`g-3 h-100 ${draggable && 'dashboard_shake'}`}>
      <MonthlyRevenue
        title={Translate('Payment Received Progress')}
        isLoading={isLoadingPaymentReceive}
        dataArray={paymentReceiveData}
        progressPercentage={salesInvoiceProgressPercentage}
      />
    </Card>
  );
}
PaymentReceivedProgress.propTypes = {
  draggable: PropTypes.bool
};
export default PaymentReceivedProgress;
