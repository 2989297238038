import { React, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { Dropdown, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { FaDownload, FaFileExcel, FaFilePdf } from 'react-icons/fa';
import { MdAttachEmail } from 'react-icons/md';

import {
  AuthWizardContext,
  ExportContext,
  StoreContext
} from 'context/Context';
import { showToast } from 'module/Common/Toast/toast';
import { showWarningToast } from 'module/Common/Toast/showWarningToast';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import EmailExport from './EmailExport';
import FormattedData from '../../Common/TableFilter/FormatedData';
function Export({
  className,
  url,
  initialMessage,
  successMessage,
  variant,
  formData,
  newExportMethod,
  interBranch,
  validations = [],
  ...rest
}) {
  const { user } = useContext(AuthWizardContext);
  const { store } = useContext(StoreContext);
  let branch_id = user?.branch_id ?? '';
  let enableBranchSwitching =
    store?.globalConfiguration?.show_multi_branch_for_users;
  const { exportArray, dispatch } = useContext(ExportContext);
  const Translate = useAxisproTranslate();
  const [isExporting, setIsExporting] = useState(false);
  const [sendMail, setSendMail] = useState(false);
  const [queryParams] = useSearchParams();
  const filterItems = queryParams.get('filters');

  const handleExport = (e, file_type) => {
    e.preventDefault();

    const hasError = validations?.some(validation => {
      if (validation.status) {
        showToast(validation?.message, 'error');
        return true;
      }
    });

    if (hasError) {
      return;
    }

    setIsExporting(true);
    showWarningToast(Translate(initialMessage));
    if (!newExportMethod) {
      axios
        .get(url, {
          params: {
            mode: file_type,
            ...FormattedData(formData),
            ...FormattedData(JSON.parse(filterItems)),
            ...(interBranch && {
              show_inter_branch_transactions: interBranch ? 1 : 0
            }),
            ...(!enableBranchSwitching && {
              branch_id: branch_id
            })
          }
        })
        .then(response => {
          if (response.status === 200 && response.data && response.data.data) {
            // Add to export context
            dispatch({
              type: 'ADD',
              payload: {
                url: url,
                file_type: file_type,
                initialMessage: Translate(initialMessage),
                successMessage: Translate(successMessage),
                ...response.data.data
              }
            });
          } else {
            showToast(
              `Something went wrong, please refresh the page and try again.`,
              'error'
            );
            setIsExporting(false);
          }
        })
        .catch(error => {
          console.error(error);
          if (error?.response?.data?.message) {
            showToast(error?.response?.data?.message, 'error');
          } else {
            showToast(
              `Something went wrong, please refresh the page and try again.`,
              'error'
            );
          }
          setIsExporting(false);
        });
    } else {
      axios
        .post(
          url,
          { data: JSON.parse(filterItems), mode: file_type },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )
        .then(response => {
          if (response.status === 200 && response.data && response.data.data) {
            // Add to export context
            dispatch({
              type: 'ADD',
              payload: {
                url: url,
                file_type: file_type,
                initialMessage: Translate(initialMessage),
                successMessage: Translate(successMessage),
                ...response.data.data
              }
            });
          } else {
            showToast(
              `Something went wrong, please refresh the page and try again.`,
              'error'
            );
            setIsExporting(false);
          }
        })
        .catch(error => {
          console.error(error);
          if (error?.response?.data?.message) {
            showToast(error?.response?.data?.message, 'error');
          } else {
            showToast(
              `Something went wrong, please refresh the page and try again.`,
              'error'
            );
          }
          setIsExporting(false);
        });
    }
  };

  useEffect(() => {
    let exportItem = url
      ? exportArray.filter(item => item.url === url && !item.status)
      : [];

    exportItem = exportItem.length > 0 ? exportItem[0] : null;
    setIsExporting(exportItem ? true : false);
  }, [exportArray, url]);

  const handleSendEmail = () => {
    setSendMail(true);
  };

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle
          style={{ boxShadow: 'none' }}
          id="dropdown-basic"
          disabled={isExporting}
          variant={variant ? variant : 'transaparent'}
          className={`d-flex flex-row align-items-center px-2 ${className}`}
          {...rest}
        >
          {isExporting ? (
            <>
              <Spinner
                animation="border"
                className="me-1"
                style={{ width: 12, height: 12 }}
              />
              <span className="fs--1">{Translate('Exporting')}</span>
            </>
          ) : (
            <>
              <FaDownload className="me-1" size={10} />
              <span className="fs--1">{Translate('Export')}</span>
            </>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item href="#" onClick={e => handleExport(e, 'pdf')}>
            <div className="d-flex flex-row align-items-center">
              <FaFilePdf
                className="me-1"
                size={11}
                style={{ color: '#f40f02' }}
              />
              <span className="fw-bolder mb-0 small">{Translate('PDF')}</span>
            </div>
          </Dropdown.Item>
          <Dropdown.Item href="#" onClick={e => handleExport(e, 'excel')}>
            <div className="d-flex flex-row align-items-center">
              <FaFileExcel
                className="me-1"
                size={11}
                style={{ color: '#1d6f42' }}
              />
              <span className="fw-bolder mb-0 small">{Translate('Excel')}</span>
            </div>
          </Dropdown.Item>
          <Dropdown.Item href="#" onClick={handleSendEmail}>
            <div className="d-flex flex-row align-items-center">
              <MdAttachEmail
                className="me-1"
                size={11}
                style={{ color: '#1BA3E9' }}
              />
              <span className="fw-bolder mb-0 small">
                {Translate('Send Mail')}
              </span>
            </div>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <EmailExport
        showEmailModal={sendMail}
        handleCancel={() => setSendMail(false)}
        url={`${url}-sendmail`}
        filteredParams={FormattedData(formData)}
        newExportMethod={newExportMethod}
      />
    </>
  );
}

Export.propTypes = {
  to: PropTypes.string,
  className: PropTypes.string,
  url: PropTypes.string.isRequired,
  initialMessage: PropTypes.string.isRequired,
  successMessage: PropTypes.string.isRequired,
  formData: PropTypes.object,
  variant: PropTypes.string,
  newExportMethod: PropTypes.any,
  interBranch: PropTypes.bool,
  validations: PropTypes.array
};

export default Export;
