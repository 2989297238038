import { React, useState } from 'react';
import {
  createSearchParams,
  useNavigate,
  useOutletContext,
  useSearchParams
} from 'react-router-dom';
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Container,
  Tab,
  Tabs
} from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import PropTypes from 'prop-types';

import { AiOutlineFileText } from 'react-icons/ai';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useBreakpoints } from 'hooks/useBreakpoints';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import {
  CloseButton,
  EditButton,
  MoreButton
} from 'module/Common/Buttons/ViewPageButtons';
import EstimationView from './EstimationView';
import VoidTransactionTab from '../Common/VoidTransactionTab';
import ViewPagePrintButton from '../Common/ViewPagePrintButton';
import AttachementsView from '../Common/AttachementsView';
import FileUpload from '../Common/FileUpload';
import SendEmail from '../Common/SendEmail';
import useAxisproPermission from 'hooks/useAxisproPermission';
import PDFView from 'components/PDFViewerButton/PDFView';
import CustomerDetails from 'components/customer-details/CustomerDetails';
import EstimationEntryHandleStaus from 'module/Production/Estimation/components/EstimationEntryHandleStaus';
import ShowReference from 'components/show-reference/ShowReference';
import SalesmanOffcanvasDetails from 'components/salesman-offcanvas-details/SalesmanOffcanvasDetails';
import EnquiryReferenceView from 'components/EnquiryReferenceView/EnquiryReferenceView';

function EstimationTabView({
  estimationEntryData,
  setEstimationEntryData,
  setEstimationEntries,
  loading,
  voidScreen,
  infoPage,
  fetchData,
  offCanvas,
  setShowVoucherDetailArea,
  onHide,
  voidTransactionReport,
  getData
}) {
  const axisProPermission = useAxisproPermission();
  const Translate = useAxisproTranslate();
  const [queryParams] = useSearchParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const navigate = useNavigate();
  let [permissions] = useOutletContext() ?? [];
  if (!permissions) {
    permissions = {
      sendMail: axisProPermission('send-mail-estimation'),
      print: axisProPermission('print-estimation'),
      create: axisProPermission('store-estimation'),
      edit: axisProPermission('update-estimation'),
      revise: axisProPermission('revise-estimation'),
      voidTransaction: axisProPermission('transaction-void')
    };
  }
  const { breakpoints } = useBreakpoints();
  const [key, setKey] = useState(voidScreen ? 'voidForm' : 'overview');
  const [showDetailsArea, setshowDetailsArea] = useState(undefined);
  const [upload, setUpload] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);

  const sendEmail = () => {
    setShowEmailModal(true);
  };

  const handleCancel = () => {
    setShowEmailModal(false);
  };

  return (
    <>
      <Card className="flex-fill view-page-content-wrapper">
        <Card.Header
          className={
            breakpoints.up('sm')
              ? 'd-flex m-0 align-items-center border-bottom'
              : 'border-bottom'
          }
        >
          <>
            <div className="d-flex m-0 align-items-center">
              <AiOutlineFileText size={28} className="me-2 text-dark" />
              <h5
                className="mb-0 d-flex text-uppercase view-page-header"
                style={{ fontSize: '25px' }}
              >
                {Translate('Estimation')}
              </h5>
            </div>

            <div className="ms-2 d-flex flex-row align-items-center gap-2">
              <EstimationEntryHandleStaus
                setEstimationEntryData={setEstimationEntryData}
                setEstimationEntries={setEstimationEntries}
                status={estimationEntryData?.status?.toLowerCase()}
                targetId={estimationEntryData?.id}
                readOnly={voidTransactionReport}
              />

              {voidTransactionReport && (
                <Badge bg="danger" className="fs--1">
                  {Translate('Voided')}
                </Badge>
              )}
            </div>

            <ButtonGroup className="ms-auto d-flex gap-2 flex-row align-items-center">
              {voidScreen || offCanvas ? (
                ''
              ) : (
                <MoreButton
                  sendEmail={sendEmail}
                  setUpload={setUpload}
                  emailPermission={permissions?.sendMail}
                  firstConversionValidation
                  firstConversionTo={
                    '/sales/sales-quotation/add?estimation_id=' +
                    estimationEntryData?.id
                  }
                  beforeFirstConversion={Translate(
                    'Convert to Sales Quotation'
                  )}
                  revise={{
                    permission: permissions?.revise,
                    path:
                      '/production/estimation/revise/' + estimationEntryData?.id
                  }}
                  voidTransactionData={
                    permissions?.voidTransaction
                      ? {
                          id: estimationEntryData?.id,
                          trans_type: 'ES',
                          callBack: () => {
                            const url = `/production/estimation`;
                            const allQueryParamsWithoutEntry = {
                              ...allQueryParams
                            };
                            delete allQueryParamsWithoutEntry.entry;
                            navigate(
                              `${url}?${createSearchParams({
                                ...allQueryParamsWithoutEntry
                              })}`
                            );
                            typeof getData === 'function' && getData(1);
                          }
                        }
                      : undefined
                  }
                />
              )}
              {!voidTransactionReport && !infoPage && permissions?.edit ? (
                <EditButton
                  to={`/production/estimation/edit/${estimationEntryData.id}`}
                />
              ) : null}

              {permissions?.print && !voidTransactionReport && (
                <ViewPagePrintButton
                  variant="danger"
                  className="text-white"
                  style={{ boxShadow: 'none' }}
                  url={`crm/estimation-print/${estimationEntryData.id}`}
                  pageTitle="Estimation print view"
                />
              )}
              {offCanvas || onHide ? (
                <CloseButton
                  setShowVoucherDetailArea={setShowVoucherDetailArea}
                  onHide={onHide}
                />
              ) : (
                ''
              )}
            </ButtonGroup>
          </>
        </Card.Header>
        <Card.Body className={loading && 'd-flex'}>
          <div
            className="d-flex flex-wrap gap-3 border p-3 mb-2"
            style={{
              backgroundColor: '#edf2f5'
            }}
          >
            <div className="me-auto">
              <div className="d-flex flex-wrap align-items-center gap-2">
                <h6 className="mb-2 fw-bold">{Translate('Customer Name')}:</h6>
                <div className="d-flex flex-row align-items-center gap-1 mb-2">
                  <h6 className="m-0 text-uppercase">
                    {estimationEntryData?.customer_name}
                  </h6>
                  <Button
                    className="p-0 m-0 text-primary outline-none"
                    variant="transparent"
                    onClick={() => setshowDetailsArea('CUSTOMER')}
                    size="sm"
                  >
                    <FontAwesomeIcon
                      className="p-0 m-0"
                      icon="external-link-alt"
                      size="xs"
                      cursor={'pointer'}
                    />
                  </Button>
                </div>
              </div>

              {estimationEntryData?.salesman_id?.length > 0 ? (
                <div className="d-flex flex-wrap align-items-center gap-2">
                  <h6 className="mb-2 fw-bold">{Translate('Salesman')}:</h6>
                  <div className="d-flex flex-row align-items-center gap-1 mb-2">
                    <h6 className="m-0 text-uppercase">
                      {estimationEntryData?.salesman_name}
                    </h6>
                    <Button
                      className="p-0 m-0 text-primary outline-none"
                      variant="transparent"
                      onClick={() => setshowDetailsArea('SALESMAN')}
                      size="sm"
                    >
                      <FontAwesomeIcon
                        className="p-0 m-0"
                        icon="external-link-alt"
                        size="xs"
                        cursor={'pointer'}
                      />
                    </Button>
                  </div>
                </div>
              ) : null}

              <div className="d-flex flex-wrap align-items-center gap-2">
                <h6 className="mb-2 fw-bold">{Translate('Estimate Date')}:</h6>
                <div className="d-flex flex-row align-items-center gap-1">
                  <h6 className="mb-2 text-uppercase">
                    {estimationEntryData?.date_formatted ?? '-'}
                  </h6>
                </div>
              </div>

              <div className="d-flex flex-wrap align-items-center gap-2">
                <h6 className="mb-2 fw-bold">{Translate('Valid Until')}:</h6>
                <div className="d-flex flex-row align-items-center gap-1">
                  <h6 className="mb-2 text-uppercase">
                    {estimationEntryData?.valid_until_formatted ?? '-'}
                  </h6>
                </div>
              </div>

              <div className="d-flex flex-wrap align-items-center gap-2">
                <h6 className="mb-2 fw-bold">{Translate('Estimator')}:</h6>
                <div className="d-flex flex-row align-items-center gap-1">
                  <h6 className="mb-2 text-uppercase">
                    {estimationEntryData?.estimator_name ?? '-'}
                  </h6>
                </div>
              </div>
            </div>

            <div>
              <div className="d-flex flex-wrap align-items-center gap-2">
                <h6 className="mb-2 fw-bold me-auto">
                  {Translate('Reference')}:
                </h6>
                <div className="mb-2 d-flex flex-row">
                  <ShowReference
                    data={estimationEntryData}
                    to={`/production/estimation?entry=${estimationEntryData.id}&layout=column`}
                    offCanvas
                    readOnly={voidTransactionReport}
                  />
                </div>
              </div>

              <div className="d-flex flex-wrap align-items-center gap-2">
                <h6 className="mb-2 fw-bold">
                  {Translate('Expected Start Date')}:
                </h6>
                <div className="d-flex flex-row align-items-center gap-1">
                  <h6 className="mb-2 text-uppercase">
                    {estimationEntryData?.expected_start_date_formatted ?? '-'}
                  </h6>
                </div>
              </div>

              <div className="d-flex flex-wrap align-items-center gap-2">
                <h6 className="mb-2 fw-bold">
                  {Translate('Expected Duration')}:
                </h6>
                <div className="d-flex flex-row align-items-center gap-1">
                  <h6 className="mb-2 text-uppercase">
                    {estimationEntryData?.expected_duration ? (
                      <>
                        {estimationEntryData.expected_duration}{' '}
                        <small className="fw-bold text-info">(in days)</small>
                      </>
                    ) : (
                      '-'
                    )}
                  </h6>
                </div>
              </div>

              {estimationEntryData?.enquiry_id ? (
                <div className="d-flex flex-wrap align-items-center gap-2">
                  <h6 className="mb-2 fw-bold me-auto">
                    {Translate('Enquiry Reference')}:
                  </h6>
                  <EnquiryReferenceView
                    targetId={estimationEntryData?.enquiry_id}
                    reference={estimationEntryData?.enquiry_reference}
                  />
                </div>
              ) : null}
            </div>
          </div>
          <Container className="p-0 view-page-tab-style-wrapper">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={k => setKey(k)}
              color="red"
            >
              {voidScreen ? (
                <Tab eventKey="voidForm" title={Translate('Voiding Form')}>
                  <SimpleBar className="simplebar-view-page-style">
                    <VoidTransactionTab
                      transType={estimationEntryData.trans_type}
                      transId={estimationEntryData.id}
                    />
                  </SimpleBar>
                </Tab>
              ) : null}
              <Tab eventKey="overview" title={Translate('Overview')}>
                <SimpleBar className="simplebar-view-page-style">
                  <EstimationView estimationEntryData={estimationEntryData} />
                </SimpleBar>
              </Tab>
              {estimationEntryData?.media?.length > 0 ? (
                <Tab eventKey="attachments" title={Translate('Attachments')}>
                  <SimpleBar className="simplebar-view-page-style">
                    <AttachementsView
                      data={estimationEntryData}
                      fetchData={fetchData}
                      setKey={setKey}
                    />
                  </SimpleBar>
                </Tab>
              ) : (
                ''
              )}

              {!voidTransactionReport && estimationEntryData?.id ? (
                <Tab eventKey="pdfView" title={Translate('PDF View')}>
                  <SimpleBar className="simplebar-view-page-style pdf-tab-view-simplebar-style">
                    <PDFView
                      viewType="tab"
                      activeTabKey={key}
                      url={`crm/estimation-print/${estimationEntryData.id}`}
                    />
                  </SimpleBar>
                </Tab>
              ) : null}
            </Tabs>
          </Container>
        </Card.Body>
        <Card.Footer className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-3">
            <h6 className="m-0 fw-bold">{Translate('Created By')}:&nbsp;</h6>
            <h6 className="m-0">
              {estimationEntryData?.created_user_full_name}
            </h6>
          </div>
          <div className="d-flex align-items-center gap-3">
            <h6 className="m-0 fw-bold">{Translate('Created At')}:&nbsp;</h6>
            <h6 className="m-0">{estimationEntryData?.created_at}</h6>
          </div>
        </Card.Footer>
      </Card>

      {showDetailsArea === 'CUSTOMER' ? (
        <CustomerDetails
          show={true}
          onHide={() => setshowDetailsArea(undefined)}
          value={{
            value: estimationEntryData?.customer_id
          }}
          showCustomerDetailsArea={true}
        />
      ) : showDetailsArea === 'SALESMAN' ? (
        <SalesmanOffcanvasDetails
          show
          onHide={() => setshowDetailsArea(undefined)}
          salesmanId={estimationEntryData?.salesman_id}
        />
      ) : null}

      <FileUpload
        show={upload}
        onHide={() => setUpload(false)}
        fetchData={fetchData}
        type={'ES'}
        id={estimationEntryData.id}
        setKey={setKey}
      />

      <SendEmail
        url={'crm/estimation-mail/' + estimationEntryData.id}
        showEmailModal={showEmailModal}
        handleCancel={handleCancel}
        data={estimationEntryData?.customer?.email}
      />
    </>
  );
}

EstimationTabView.propTypes = {
  estimationEntryData: PropTypes.any,
  setEstimationEntryData: PropTypes.func,
  setEstimationEntries: PropTypes.func,
  loading: PropTypes.bool,
  voidScreen: PropTypes.bool,
  infoPage: PropTypes.bool,
  fetchData: PropTypes.func,
  offCanvas: PropTypes.bool,
  setShowVoucherDetailArea: PropTypes.func,
  onHide: PropTypes.bool,
  voidTransactionReport: PropTypes.bool,
  getData: PropTypes.func
};

export default EstimationTabView;
