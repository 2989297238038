import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import createMarkup from 'helpers/createMarkup';
import Avatar from 'components/common/Avatar';

const Notification = ({
  avatar,
  time,
  className,
  read_at,
  data,
  flush = false,
  emoji,
  children
}) => (
  <Link
    className={classNames(
      'notification',
      { 'notification-unread': read_at === null, 'notification-flush': flush },
      className
    )}
    to="#!"
  >
    <div className="notification-avatar">
      {avatar ? (
        <Avatar {...avatar} className="me-3" />
      ) : (
        <div
          className="rounded-circle bg-dark me-2 d-flex justify-content-center align-items-center"
          style={{ width: 25, height: 25 }}
        >
          {data && data.body && (
            <span className="text-uppercase text-white small fw-bold">
              {data.body.charAt(0)}
            </span>
          )}
        </div>
      )}
    </div>

    <div className="notification-body">
      {children && (
        <p className="mb-1" dangerouslySetInnerHTML={createMarkup(children)} />
      )}
      {data && (
        <p className="mb-1" dangerouslySetInnerHTML={createMarkup(data.body)} />
      )}

      <span className="notification-time">
        {emoji && (
          <span className="me-2" role="img" aria-label="Emoji">
            {emoji}
          </span>
        )}
        {time}
      </span>
    </div>
  </Link>
);

Notification.propTypes = {
  avatar: PropTypes.shape(Avatar.propTypes),
  time: PropTypes.string.isRequired,
  className: PropTypes.string,
  read_at: PropTypes.any,
  data: PropTypes.object,
  flush: PropTypes.bool,
  emoji: PropTypes.string,
  children: PropTypes.node
};

export default Notification;
