import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import { apiCall } from 'helpers/apiCalls';
import ListLoading from 'module/Common/Loading/ListLoading';
import React, { useCallback } from 'react';
import { showToast } from 'module/Common/Toast/toast';
import { useState } from 'react';
import qs from 'qs';
import { useEffect } from 'react';
import { Button, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { BiCommentEdit, BiCommentX } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import CommentInputFeild from './CommentInputFeild';
import { FollowUpCommentsFormKeys } from 'helpers/formKeys';
import PropTypes from 'prop-types';
import avatarIcon from '../../../../../assets/img/response.png';
const CommonComments = ({ commentType, id }) => {
  const [formData, setFormData] = useState(FollowUpCommentsFormKeys);
  const [onComment, setOnComment] = useState(false);
  const [onEdit, setOnEdit] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const [commentsList, setCommentList] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const commentKeysCount = Object.keys(commentsList).length - 1;
  const countDisplay = commentKeysCount > 0 ? `${commentKeysCount}+` : '';
  const [formError, setFormError] = useState({});

  // const getData = async () => {
  //   setIsLoading(true);
  //   const data = await apiCall({
  //     url: 'sales/followup-comments',
  //     params: {
  //       filters: [
  //         { column: 'followup_id', type: 'string', operator: '=', value: id }
  //       ]
  //     },
  //     paramsSerializer: params => {
  //       return qs.stringify(params);
  //     }
  //   });
  //   setIsLoading(false);
  //   setCommentList(data.data);
  // };

  const getData = useCallback(async () => {
    setIsLoading(true);
    const data = await apiCall({
      url: 'sales/followup-comments',
      params: {
        filters: [
          { column: 'followup_id', type: 'string', operator: '=', value: id }
        ]
      },
      paramsSerializer: params => {
        return qs.stringify(params);
      }
    });
    setIsLoading(false);
    setCommentList(data.data);
  }, [id]);

  useEffect(() => {
    if (id) {
      setFormData(prev => ({
        ...prev,
        followup_id: id
      }));
      getData();
    }
  }, [id, getData]);

  const resetData = () => {
    getData();
    setFormData({
      ...formData,
      comment: ''
    });
    handleCommentDistroy();
  };

  const handleSubmit = e => {
    e.preventDefault();
    setOnComment(true);
    setFormError({});
    if (formData.comment.replace(/(<([^>]+)>)/gi, '')) {
      axios({
        method: `${onEdit ? 'put' : 'post'}`,
        url: `${
          onEdit
            ? 'sales/followup-comments/' + onEdit
            : 'sales/followup-comments'
        }`,
        data: formData
      }).then(response => {
        if (response.data.success === true) {
          setOnComment(false);
          resetData();
        } else {
          setOnComment(false);
          showToast(
            'Something went wrong, please refresh the page and try again.',
            'error'
          );
        }
      });
    } else {
      setFormError({ comment: 'Leave a comment.' });
    }
  };

  const handleFieldChange = (e, action) => {
    let newOldData = [];
    if (action) {
      formData.subject_type = commentType;
      formData.followup_id = id;
      setFormData({
        ...formData,
        [action.name]: action.value
      });
    } else {
      if (e.target.name === 'files') {
        newOldData.push(e.target.files[0]);
        setAttachments(oldArray => [...oldArray, ...newOldData]);
      }
    }
  };

  const handleEditComment = (e, comment) => {
    setOnEdit(comment.id);
    setFormData({
      ...formData,
      subject_type: commentType,
      subject_id: id,
      comment: comment.comment
    });
  };

  const handleDetachAttachment = name => {
    setAttachments(attachments.filter(attachment => attachment.name !== name));
  };

  const handleCommentDistroy = () => {
    setOnEdit(null);
    setFormData({
      ...formData,
      comment: ''
    });
  };

  const handleDelete = id => {
    axios({
      method: `Delete`,
      url: `sales/followup-comments/` + id
    }).then(response => {
      if (response.data.success === true) {
        getData();
      } else {
        showToast(
          'Something went wrong, please refresh the page and try again.',
          'error'
        );
      }
    });
  };

  return (
    <>
      <div className="my-3 position-relative text-center">
        <hr className="position-absolute top-50 border-300 w-100 my-0" />
        <span className="position-relative bg-white dark__bg-card-dark">
          <Button
            size="sm"
            variant="outline-secondary"
            className="rounded-pill border-300"
          >
            Conversation {countDisplay}
          </Button>
        </span>
      </div>
      <Row style={{ height: '30rem', overflow: 'hidden', overflowY: 'scroll' }}>
        {!onEdit && (
          <CommentInputFeild
            formError={formError}
            handleFieldChange={handleFieldChange}
            formData={formData}
            handleSubmit={handleSubmit}
            onComment={onComment}
            onEdit={onEdit}
            handleCommentDistroy={handleCommentDistroy}
          />
        )}
        {Object.keys(commentsList).length > 0 ? (
          <>
            {commentsList.map((comment, key) => (
              <div key={key} className="">
                <div className="d-flex justify-content-end">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id="button-tooltip-2">Edit Comment</Tooltip>
                    }
                  >
                    <Button
                      variant="transparent"
                      size="sm"
                      className="custom-transperent-common-button p-0"
                      onClick={e => {
                        handleEditComment(e, comment);
                      }}
                    >
                      <BiCommentEdit size={18} />
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id="button-tooltip-2">Delete Comment</Tooltip>
                    }
                  >
                    <Button
                      variant="transparent"
                      size="sm"
                      className="custom-transperent-common-button p-0 ms-1"
                      onClick={() => {
                        handleDelete(comment.id);
                      }}
                    >
                      <BiCommentX size={18} />
                    </Button>
                  </OverlayTrigger>
                </div>
                <div className="d-md-flex d-xl-inline-block d-xxl-flex align-items-center justify-content-between mb-x1 ms-2">
                  <Flex alignItems="center" className="gap-2">
                    <Link to="/support-desk/contact-details">
                      <Avatar src={avatarIcon} size="2xl" />
                    </Link>
                    <p className="mb-0">
                      <Link className="fw-semi-bold mb-0 text-800">
                        {comment.created_user_full_name}
                      </Link>
                    </p>
                  </Flex>
                  <p className="mb-0 fs--2 fs-sm--1 fw-semi-bold mt-2 mt-md-0 mt-xl-2 mt-xxl-0 ms-5">
                    {comment.created_at}

                    <FontAwesomeIcon
                      icon="star"
                      className="ms-2 text-warning"
                    />
                  </p>
                </div>
                <div
                  className={`mb-3  pb-3 ms-2  ${
                    key !== Object.keys(commentsList).length - 1 &&
                    'border-bottom'
                  } `}
                >
                  {onEdit === comment.id ? (
                    <div className="mt-2">
                      <CommentInputFeild
                        title="Reply"
                        bodyClassName="shadow-none"
                        handleFieldChange={handleFieldChange}
                        formData={formData}
                        handleSubmit={handleSubmit}
                        onComment={onComment}
                        attachments={attachments}
                        handleDetachAttachment={handleDetachAttachment}
                        handleCommentDistroy={handleCommentDistroy}
                        handleFiles={handleFieldChange}
                        formError={formError}
                        onEdit={onEdit}
                      />
                    </div>
                  ) : (
                    <>
                      <div
                        className="mt-2 ps-2"
                        dangerouslySetInnerHTML={{ __html: comment.comment }}
                      />
                    </>
                  )}
                </div>
              </div>
            ))}
          </>
        ) : isLoading ? (
          <ListLoading style={{ maxHeight: '55vh', overflow: 'hidden' }} />
        ) : (
          <div className="d-flex justify-content-center">
            <span>No Conversions Yet 😉</span>
          </div>
        )}
      </Row>
    </>
  );
};
CommonComments.propTypes = {
  commentType: PropTypes.object,
  id: PropTypes.any
};
export default CommonComments;
