import { React } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { BiLogoGmail } from 'react-icons/bi';
import {
  FaCentercode,
  FaProjectDiagram,
  FaPhoneAlt,
  FaWarehouse,
  FaCalendarAlt
} from 'react-icons/fa';
import {
  MdOutlineLocalShipping,
  MdOutlineMan3,
  MdPayment
} from 'react-icons/md';
import { BsCoin } from 'react-icons/bs';

import LineItemTable from '../Common/Tables/LineItemTable';
import PersonName from '../Common/ViewPageDetails/PersonName';
import Badges from '../Common/ViewPageDetails/Badges';
import Details from '../Common/ViewPageDetails/Details';
import QuillPreviewContainer from 'components/QuillPreviewContainer/QuillPreviewContainer';
import { IoDocumentTextSharp } from 'react-icons/io5';

function SalesInvoiceView({ salesInvoiceData }) {
  return (
    <Container className="mt-3 ps-3 pe-3 voucher-inquiry-view-wrapper">
      <Row>
        <Col lg={6} md={6} sm={12} className="p-0">
          {salesInvoiceData?.customer_name ? (
            <PersonName value={salesInvoiceData?.customer_name} />
          ) : null}
          {salesInvoiceData?.reference && (
            <Badges label={salesInvoiceData?.reference} bg={'danger'} />
          )}
          <div className="mt-2">
            {salesInvoiceData?.due_date_formatted && (
              <Details
                label={'Due Date'}
                value={salesInvoiceData?.due_date_formatted}
                className={'mb-2 fs--1'}
                icon={<FaCalendarAlt className="me-1 text-dark" size={13} />}
              />
            )}
            {salesInvoiceData?.customer_lpo_number && (
              <Details
                label={'Customer LPO Number'}
                value={salesInvoiceData?.customer_lpo_number}
                className={'mb-2 fs--1'}
                iconColor={'text-info'}
              />
            )}
            {salesInvoiceData?.customer_lpo_date_formatted && (
              <Details
                label={'Customer LPO Date'}
                value={salesInvoiceData?.customer_lpo_date_formatted}
                className={'mb-2 fs--1'}
                iconColor={'text-warning'}
              />
            )}
            {salesInvoiceData?.email && (
              <Details
                label={'Email Address'}
                value={salesInvoiceData?.email}
                icon={<BiLogoGmail className="me-1 text-danger" size={15} />}
                className={'mb-2 fs--1'}
              />
            )}
            {salesInvoiceData?.mobile && (
              <Details
                label={'Phone Number'}
                value={salesInvoiceData?.mobile}
                icon={<FaPhoneAlt className="me-1 text-dark" size={15} />}
                className={'mb-2 fs--1'}
              />
            )}
            {salesInvoiceData?.sales_type_name && (
              <Details
                label={'Sales Type'}
                value={salesInvoiceData?.sales_type_name}
                className={'mb-2 fs--1'}
                iconColor={'text-info'}
              />
            )}
            {salesInvoiceData?.cost_center_name && (
              <Details
                label={'Cost Center'}
                value={salesInvoiceData?.cost_center_name}
                className={'mb-2 fs--1'}
                icon={<FaCentercode className="me-1 text-warning" size={15} />}
              />
            )}
            {salesInvoiceData?.payment_term_name && (
              <Details
                label={'Payment Term'}
                value={salesInvoiceData?.payment_term_name}
                className={'mb-2 fs--1'}
                icon={<MdPayment className="me-1 text-success" size={15} />}
              />
            )}
            {salesInvoiceData?.warehouse_name && (
              <Details
                label={'Delivery Location'}
                value={salesInvoiceData?.warehouse_name}
                className={'mb-2 fs--1'}
                icon={<FaWarehouse className="me-1 text-dark" size={15} />}
              />
            )}
            {salesInvoiceData?.fa_stock_id && (
              <Details
                label={'Asset'}
                value={salesInvoiceData?.fa_stock_name}
                className={'mb-2 fs--1 text-capitalize'}
                icon={
                  <IoDocumentTextSharp
                    className="me-1  text-primary"
                    size={15}
                  />
                }
              />
            )}
            {salesInvoiceData?.customer_shipping_address && (
              <Details
                label={'Shipping Address'}
                value={[
                  salesInvoiceData?.customer_shipping_address?.address1,
                  salesInvoiceData?.customer_shipping_address?.city,
                  salesInvoiceData?.customer_shipping_address?.state,
                  salesInvoiceData?.customer_shipping_address?.zip_code,
                  salesInvoiceData?.customer_shipping_address?.country
                ]
                  .filter(Boolean)
                  .join(', ')}
                className={'mb-2 fs--1'}
                icon={
                  <MdOutlineLocalShipping
                    className="me-1 text-dark"
                    size={16}
                  />
                }
              />
            )}
            {salesInvoiceData?.project_name && (
              <Details
                label={'Project Name'}
                value={salesInvoiceData?.project_name}
                className={'mb-2 fs--1'}
                icon={<FaProjectDiagram className="me-1 text-dark" size={15} />}
              />
            )}
            {salesInvoiceData?.salesman_name && (
              <Details
                label={'Salesman Name'}
                value={salesInvoiceData?.salesman_name}
                className={'mb-2 fs--1'}
                icon={<MdOutlineMan3 className="me-1 text-dark" size={15} />}
              />
            )}
          </div>
        </Col>
        <Col lg={6} md={6} sm={12} className="p-0">
          <div className="mt-sm-2 mt-md-5">
            <Details
              label={
                salesInvoiceData?.tax_included &&
                salesInvoiceData?.tax_included === '1'
                  ? 'Amount (Inc.Tax)'
                  : 'Amount'
              }
              value={salesInvoiceData?.amount_formatted ?? '0.00'}
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />
            <Details
              label={'Paid Amount'}
              value={salesInvoiceData?.alloc_formatted ?? '0.00'}
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />
            <Details
              label={'Balance Amount'}
              value={
                salesInvoiceData?.balance_formatted
                  ? salesInvoiceData.balance_formatted
                  : '0.00'
              }
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />
            <Details
              label={'Total Tax'}
              value={salesInvoiceData?.total_tax_formatted ?? '0.00'}
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />
            <Details
              label={'Round Off Amount'}
              value={salesInvoiceData?.round_off_amount_formatted ?? '0.00'}
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />
            <Details
              label={'Total Discount'}
              value={
                salesInvoiceData?.total_discount_amount_formatted ?? '0.00'
              }
              className={'mb-2 fs--1'}
              icon={<BsCoin className="me-1 text-dark" size={15} />}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xl={12} md={12} sm={12} className="p-0 mt-2">
          <LineItemTable
            data={salesInvoiceData}
            batch
            exportInvoice={
              salesInvoiceData?.props &&
              JSON.parse(salesInvoiceData?.props)?.['invoice-type'] ===
                'export-invoice'
                ? true
                : false
            }
          />
        </Col>
      </Row>
      {salesInvoiceData?.memo && (
        <QuillPreviewContainer
          title="Notes :"
          value={salesInvoiceData.memo}
          dashedBorder
        />
      )}
    </Container>
  );
}

SalesInvoiceView.propTypes = {
  salesInvoiceData: PropTypes.any
};

export default SalesInvoiceView;
