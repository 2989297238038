import { React, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import LoadingScreenCover from 'components/common/loading-screen/LoadingScreenCover';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import SalesDeliveryTabView from 'module/Common/ViewPages/SalesDelivery/SalesDeliveryTabView';

function SalesDeliveryInfo({
  salesDeliveryId,
  firstId,
  offCanvas,
  setShowVoucherDetailArea,
  voidTransactionReport,
  getData,
  interBranch
}) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const id = salesDeliveryId ? salesDeliveryId : firstId;
    if (id === undefined) return;

    const url = voidTransactionReport
      ? 'settings/void-transaction-details'
      : `sales/sales-delivery/${id}`;

    try {
      const response = await axios.get(url, {
        params: {
          trans_type: voidTransactionReport ? 'SD' : '',
          trans_no: voidTransactionReport ? id : ''
        }
      });

      if (response.data.success) {
        setData(response.data.data);
      }
      setLoading(false);
    } catch (error) {
      console.log('error', error);
    }
  }, [firstId, voidTransactionReport, salesDeliveryId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      {loading && data ? (
        <LoadingScreenCover className="">
          <LoadingScreen message="Looking for sales delivery info" />
        </LoadingScreenCover>
      ) : (
        <SalesDeliveryTabView
          salesDeliveryData={data}
          loading={loading}
          fetchData={fetchData}
          offCanvas={offCanvas}
          setShowVoucherDetailArea={setShowVoucherDetailArea}
          voidTransactionReport={voidTransactionReport}
          getData={getData}
          interBranch={interBranch}
        />
      )}
    </>
  );
}

SalesDeliveryInfo.propTypes = {
  salesDeliveryId: PropTypes.string,
  firstId: PropTypes.string,
  offCanvas: PropTypes.bool,
  setShowVoucherDetailArea: PropTypes.func,
  voidTransactionReport: PropTypes.bool,
  interBranch: PropTypes.bool,
  getData: PropTypes.func
};

export default SalesDeliveryInfo;
