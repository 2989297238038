import { React, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Col, Collapse, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FaFilter, FaTimes } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { DateFormatVaildation } from 'module/Common/Functions/DateFormatVaildation';
import { showToast } from 'module/Common/Toast/toast';
import { _validateEmail } from '../Validators/InputValidators';
import { SelectedFilterFormData } from './FilterData';
import { AuthWizardContext, StoreContext } from 'context/Context';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import generateFormDataHelper from 'helpers/generateFormDataHelper';
import SelectBranch from 'components/form/SelectBranch';
import FilterComponentData from './Components/FilterComponentData';

const Filter = ({ modal, handleModal, moduleName, year, report }) => {
  const hideFilterObject =
    JSON.parse(localStorage.getItem('HIDE_ACODAX_ERP_FILTER_MODULE')) || [];

  const Translate = useAxisproTranslate();
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const { user } = useContext(AuthWizardContext);
  const { store } = useContext(StoreContext);
  const filterItems = queryParams.get('filters');
  const layout = queryParams.get('layout');
  const [formData, setFormData] = useState({});
  const [enableButton, setEnableButton] = useState(false);
  const filterForm = SelectedFilterFormData(moduleName);
  const allQueryParams = Object.fromEntries([...queryParams]);

  const show_multi_branch =
    store?.globalConfiguration?.show_multi_branch_for_admins ||
    store?.globalConfiguration?.show_multi_branch_for_users ||
    false;

  const runFilter = e => {
    e.preventDefault();
    refreshPage();

    if (formData?.email && !_validateEmail(formData.email.trim())) {
      return showToast('Enter a valid email ID to search!', 'error');
    }

    const dateFields = [
      { from: 'date_from', to: 'date_to' },
      { from: 'exp_date_from', to: 'exp_date_to' },
      { from: 'from_date', to: 'to_date' }
    ];

    for (const { from, to } of dateFields) {
      if (
        (formData[from] && !DateFormatVaildation(formData[from])) ||
        (formData[to] && !DateFormatVaildation(formData[to]))
      ) {
        return showToast(
          'You have selected an invalid date. Please select a valid date.',
          'error'
        );
      }

      if (formData[from] > formData[to]) {
        return showToast(
          'Start date cannot be greater than end date.',
          'error'
        );
      }
    }

    const queryParams = {
      ...allQueryParams,
      page: 1,
      ...(layout && { layout }),
      ...(modal && !layout && { info_filter: true }),
      filters: JSON.stringify(formData)
    };

    const queryString = new URLSearchParams(queryParams).toString();

    navigate({ pathname: '', search: queryString ? `?${queryString}` : '' });
    if (modal) handleModal();
  };

  const handleFieldChange = useCallback(
    (e, action) => {
      if (Array.isArray(e)) {
        setFormData(prev => ({
          ...prev,
          [action?.name]: e?.map(items => items?.value),
          [action?.name + '_ref']: e
        }));
      } else {
        setFormData(prev => generateFormDataHelper(e, action, prev));
      }
      if (action?.name === 'account_code') {
        setFormData(prev => ({
          ...prev,
          sub_ledger_code: '',
          sub_ledger_code_ref: ''
        }));
      }
    },
    [setFormData]
  );

  const clearFilter = () => {
    setFormData({});
    navigate({ pathname: '', search: '' });
  };

  const refreshPage = () => {
    setFormData(prev => ({
      ...prev,
      refresh: (prev?.refresh || 0) + 1
    }));
  };

  useEffect(() => {
    const initializeFormData = () => {
      if (filterItems && Object.keys(filterItems).length > 0) {
        setFormData(JSON.parse(filterItems));
      } else if (show_multi_branch) {
        setFormData(prev => ({
          ...prev,
          branch_id: user.branch?.id,
          branch_id_ref: {
            label: user?.branch?.branch_name,
            value: user.branch?.id
          }
        }));
      } else {
        setFormData({});
      }
    };

    initializeFormData();
  }, [filterItems, show_multi_branch, user]);

  useEffect(() => {
    if (filterItems) {
      const parsedFilterItems = JSON.parse(filterItems);
      const keysLength = Object.keys(parsedFilterItems).length;
      const hasSearchKey =
        Object.prototype.hasOwnProperty.call(parsedFilterItems, 'search') ||
        Object.prototype.hasOwnProperty.call(parsedFilterItems, 'refresh');
      const hasSearchRefreshKey =
        Object.prototype.hasOwnProperty.call(parsedFilterItems, 'search') &&
        Object.prototype.hasOwnProperty.call(parsedFilterItems, 'refresh');
      if (
        (hasSearchKey && keysLength === 1) ||
        (hasSearchRefreshKey && keysLength === 2)
      ) {
        setEnableButton(true);
      } else {
        setEnableButton(false);
      }
    }
  }, [filterItems]);

  return (
    <Collapse in={!hideFilterObject.includes(moduleName)}>
      <Form
        className="border-bottom filter-bg-color"
        onSubmit={e => e.preventDefault()}
      >
        <Row className="pb-0 p-0 pt-2 ps-2 pe-2">
          {show_multi_branch && (
            <Col md={modal ? 4 : 2}>
              <Form.Group className="mb-3">
                <Form.Label>{Translate('Branch')}</Form.Label>
                <SelectBranch
                  name={'branch_id'}
                  value={formData?.branch_id_ref}
                  handleFieldChange={handleFieldChange}
                  withAllOption={true}
                  navbar={true}
                />
              </Form.Group>
            </Col>
          )}
          {Object.keys(filterForm)?.map((items, index) => (
            <FilterComponentData
              filterData={filterForm[items]}
              feildName={items}
              key={index}
              modal={modal}
              setFormData={setFormData}
              formData={formData}
              filterForm={filterForm}
              handleFieldChange={handleFieldChange}
              year={year}
            />
          ))}
          {!modal ? (
            <Col
              className={`d-flex align-items-center button-group ${
                filterForm?.searchEnd ? 'justify-content-end pt-1' : ''
              }   mt-0 mb-2 `}
              style={{
                paddingTop: !filterForm?.searchEnd ? '1.5rem' : undefined
              }}
            >
              <Button
                type="button"
                variant="info"
                size="sm"
                className=" ms-0 me-1 border-0 filter-apply-button"
                onClick={runFilter}
                style={{
                  width: report ? '85px' : '70px',
                  backgroundColor: report ? '#27BCFD' : '#F5803E'
                }}
              >
                <FaFilter className=" text-light " />{' '}
                {Translate(report ? 'Generate' : 'Apply')}
              </Button>
              {filterItems ? (
                enableButton ? null : (
                  <Button
                    variant="danger"
                    className="me-1 filter-clear-button"
                    size="sm"
                    onClick={clearFilter}
                  >
                    <MdClose className="bi-dots-vertical clear-filter-button" />{' '}
                    {Translate('Clear')}
                  </Button>
                )
              ) : null}
            </Col>
          ) : (
            <Form.Group className="d-flex flex-row  pb-2">
              <Button
                type="button"
                variant="info"
                size="sm"
                className="ms-auto "
                onClick={runFilter}
              >
                <FaFilter /> {Translate(report ? 'Generate' : 'Apply')}
              </Button>
              <Button
                variant="danger"
                className="ms-1"
                size="sm"
                onClick={handleModal}
              >
                <FaTimes /> {Translate('Cancel')}
              </Button>
            </Form.Group>
          )}
        </Row>
      </Form>
    </Collapse>
  );
};
Filter.propTypes = {
  filterOption: PropTypes.any,
  modal: PropTypes.any,
  data: PropTypes.array,
  setData: PropTypes.any,
  handleModal: PropTypes.func,
  moduleName: PropTypes.string,
  year: PropTypes.array,
  report: PropTypes.bool
};

export default Filter;
