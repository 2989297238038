import { React, useEffect, useState } from 'react';
import axios from 'axios';
import { Alert, Badge, Spinner, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { showToast } from 'module/Common/Toast/toast';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import VoucherDetail from 'components/voucher-details/VoucherDetail';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import { FaFileCirclePlus } from 'react-icons/fa6';
import ConfirmWarning from 'module/Common/Warnings/ConfirmWarning';
import PrintButton from 'components/print-button/PrintButton';

function DeferredTransactionTable({
  id,
  tabApiCallCompleted,
  setTabApiCallCompleted,
  activeTabKey
}) {
  const Translate = useAxisproTranslate();
  const [voucherId, setVoucherId] = useState();
  const [transType, setTransType] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [showVoucherDetailArea, setShowVoucherDetailArea] = useState(false);
  const [showConfirmationBox, setShowConfirmationBox] = useState(false);
  const [deferredIds, setDeferredIds] = useState([]);

  const clickOnVoucher = (voucher_id, trans_type) => {
    setVoucherId(voucher_id);
    setTransType(trans_type);
    setShowVoucherDetailArea(true);
  };
  const handleVoucherDetailsArea = () => {
    setShowVoucherDetailArea(!showVoucherDetailArea);
  };
  const handleChangeStatus = ids => {
    setDeferredIds(ids);
    setShowConfirmationBox(true);
  };
  const handleCancel = () => {
    setShowConfirmationBox(false);
    setDeferredIds([]);
  };
  const handleSubmit = () => {
    setShowConfirmationBox(false);
    setIsSaving(true);
    axios({
      method: 'post',
      url: `finance/post-deferred-transaction`,
      data: { deferred_transaction_ids: deferredIds }
    })
      .then(response => {
        setIsSaving(false);
        if (response.data.success) {
          const updatedData = [...(data?.deferred_transactions ?? [])];
          updatedData
            .filter(item => deferredIds.includes(item.id[0]))
            .map(item => {
              item.status = 'completed';
              item.voucher_id = response?.data?.data?.id;
              item.voucher_reference = response?.data?.data?.reference;
              return item;
            });
          setData({ deferred_transactions: updatedData });
          setDeferredIds([]);
          showToast(response.data.message, 'success');
        } else {
          showToast(
            Translate(
              `Something went wrong, please refresh the page and try again.`
            ),
            'error'
          );
        }
      })
      .catch(error => {
        if (error.response.data && error.response.data.message) {
          showToast(Translate(error.response.data.message), 'error');
        } else {
          showToast(
            Translate(
              `Something went wrong, please refresh the page and try again.`
            ),
            'error'
          );
        }
      });
  };
  useEffect(() => {
    const fetchAllocationData = () => {
      setLoading(true);
      axios
        .get(`/finance/deferred-transaction`, {
          params: { sales_invoice_id: id }
        })
        .then(res => {
          if (res?.data?.success) {
            setData({
              deferred_transactions: res?.data?.data?.data
            });
          } else {
            showToast('Something went wrong! Please contact admin', 'error');
          }
        })
        .catch(error => {
          if (error) {
            showToast('Something went wrong! Please contact admin', 'error');
          }
        })
        .finally(() => {
          setLoading(false);
          setTabApiCallCompleted(prev => ({
            ...prev,
            deferred_transactions: true
          }));
        });
    };
    if (id) {
      if (activeTabKey !== 'deferred_transactions' || tabApiCallCompleted) {
        return;
      }
      fetchAllocationData();
    }
  }, [activeTabKey, tabApiCallCompleted, id, setTabApiCallCompleted]);

  return (
    <>
      <Table
        striped
        bordered
        hover
        responsive
        className="mt-2 simple-table-animation"
      >
        {!loading ? (
          <>
            <thead className="bg-dark text-light text-uppercase">
              <tr>
                <th className="fs--2 p-2 text-center">#</th>
                <th className="fs--2 p-2 text-center">{Translate('Date')}</th>
                <th className="fs--2 p-2 text-center">
                  {Translate('Voucher Reference')}
                </th>
                <th className="fs--2 p-2 text-center">{Translate('Amount')}</th>
                <th className="fs--2 p-2 text-center">{Translate('Status')}</th>
                <th className="fs--2 p-2 text-center">{Translate('')}</th>
              </tr>
            </thead>
            {data?.deferred_transactions?.length > 0 ? (
              data?.deferred_transactions.map((item, key) => (
                <tbody key={key} className="text-dark">
                  <tr>
                    <td className="p-2 text-center">{key + 1}</td>
                    <th className="p-2 text-center">
                      {item.trans_date_formatted}
                    </th>
                    <th className="p-2 text-center">
                      {item?.voucher_id ? (
                        getAcodaxPermissionSlug('JV', 'show') ? (
                          <a
                            href="#"
                            onClick={() =>
                              clickOnVoucher(item?.voucher_id, 'JV')
                            }
                          >
                            {item?.voucher_reference}
                          </a>
                        ) : (
                          item?.voucher_reference
                        )
                      ) : (
                        ' - '
                      )}
                    </th>
                    <th className="p-2 text-center text-uppercase">
                      {item?.total_amount}
                    </th>
                    <th className="p-2 text-center text-uppercase">
                      {item?.status === 'pending' ? (
                        <Badge bg="warning">{item?.status}</Badge>
                      ) : item?.status === 'completed' ? (
                        <Badge bg="success">{item?.status}</Badge>
                      ) : (
                        <Badge bg="danger">{item?.status}</Badge>
                      )}
                    </th>
                    <th className="p-2 text-center">
                      {deferredIds[0] &&
                      deferredIds[0] === item?.id[0] &&
                      isSaving ? (
                        <Spinner size="sm" className="" />
                      ) : item?.status === 'completed' ? (
                        <PrintButton
                          variant="transparent"
                          className=" m-0 p-0 ps-1 pe-1 text-black"
                          style={{ boxShadow: 'none' }}
                          url={`finance/journal-entry/print/${item?.voucher_id}`}
                          pageTitle={'Journal Voucher Print View'}
                        />
                      ) : (
                        <FaFileCirclePlus
                          className="cursor-pointer"
                          color="#2c7be5"
                          size={15}
                          title="Add Journal Voucher"
                          onClick={() => handleChangeStatus(item.id)}
                        />
                      )}
                    </th>
                  </tr>
                </tbody>
              ))
            ) : (
              <tbody>
                <tr>
                  <td colSpan={8} className="p-2">
                    <Alert className="text-center m-0" variant="danger">
                      {Translate('Nothing to show!')}
                    </Alert>
                  </td>
                </tr>
              </tbody>
            )}
          </>
        ) : (
          <div
            style={{ height: '50vh' }}
            className="d-flex align-items-center justiyf-content-center w-100"
          >
            <LoadingScreen
              message={Translate('Getting deferred transactions details!')}
            />
          </div>
        )}
      </Table>
      <VoucherDetail
        show={showVoucherDetailArea}
        onHide={handleVoucherDetailsArea}
        setShowVoucherDetailArea={setShowVoucherDetailArea}
        type={transType}
        voucherId={voucherId}
        infoPage={true}
        offCanvas={true}
      />
      <ConfirmWarning
        show={showConfirmationBox && deferredIds?.length > 0}
        onHide={handleCancel}
        handleSubmit={handleSubmit}
        modalTitle={'Are you sure?'}
        content={'Are you sure you want to post this deferred transaction '}
        buttonTitle="Yes"
      />
    </>
  );
}

DeferredTransactionTable.propTypes = {
  allocationData: PropTypes.object,
  id: PropTypes.any,
  t_type: PropTypes.any,
  activeTabKey: PropTypes.string,
  tabApiCallCompleted: PropTypes.bool,
  setTabApiCallCompleted: PropTypes.func
};

export default DeferredTransactionTable;
