import { React, useEffect, useRef, useState } from 'react';
import { Card, Placeholder } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ActivityFetching = ({ links, loadNextPage, info }) => {
  const [nextPage, setNextPage] = useState();

  const componentRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0
    };
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        loadNextPage(nextPage);
      }
    }, options);
    observer.observe(componentRef.current);
    return () => {
      observer.disconnect();
    };
  }, [nextPage, loadNextPage]);

  useEffect(() => {
    if (links.next_page_url) {
      setNextPage(links.next_page_url);
    }
  }, [links]);

  return (
    <>
      <ul
        ref={componentRef}
        className="customer-info-tab-content activity-tree"
      >
        <li className="d-flex flex-row">
          <div className="icon-cover d-flex justify-content-center align-items-center shadow border">
            <FontAwesomeIcon icon="comment-dots" size="sm" color="#344050" />
          </div>
          <Card.Body>
            <Placeholder as="p" animation="glow">
              <Placeholder
                className={info ? 'w-50' : 'w-100'}
                size="md"
                bg="secondary"
                style={{ opacity: '0.01' }}
              />
            </Placeholder>
            <Placeholder as="p" animation="glow">
              <Placeholder
                className={info ? 'w-50' : 'w-100'}
                size="md"
                bg="secondary"
                style={{ opacity: '0.01' }}
              />
            </Placeholder>
            {!info ? (
              <Placeholder as="p" animation="glow">
                <Placeholder
                  className={info ? 'w-50' : 'w-100'}
                  size="md"
                  bg="secondary"
                  style={{ opacity: '0.01' }}
                />
              </Placeholder>
            ) : (
              ''
            )}
          </Card.Body>
        </li>
        <li className="d-flex flex-row">
          <div className="icon-cover d-flex justify-content-center align-items-center shadow border">
            <FontAwesomeIcon icon="comment-dots" size="sm" color="#344050" />
          </div>
          <Card.Body>
            <Placeholder as="p" animation="glow">
              <Placeholder
                className={info ? 'w-50' : 'w-100'}
                size="md"
                bg="secondary"
                style={{ opacity: '0.01' }}
              />
            </Placeholder>
            <Placeholder as="p" animation="glow">
              <Placeholder
                className={info ? 'w-50' : 'w-100'}
                size="md"
                bg="secondary"
                style={{ opacity: '0.01' }}
              />
            </Placeholder>
            {!info ? (
              <Placeholder as="p" animation="glow">
                <Placeholder
                  className={info ? 'w-50' : 'w-100'}
                  size="md"
                  bg="secondary"
                  style={{ opacity: '0.01' }}
                />
              </Placeholder>
            ) : (
              ''
            )}
          </Card.Body>
        </li>
      </ul>
    </>
  );
};

ActivityFetching.propTypes = {
  links: PropTypes.any,
  loadNextPage: PropTypes.func,
  info: PropTypes.bool
};

export default ActivityFetching;
