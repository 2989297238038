import { TranslationsContext } from 'context/Context';
import { useCallback, useContext } from 'react';

const useAxisproTranslate = () => {
  const { translations } = useContext(TranslationsContext);

  const Translate = useCallback(
    word => {
      return translations?.[word?.toLowerCase()] || word;
    },
    [translations]
  );
  return Translate;
};
export default useAxisproTranslate;
