import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import FormLayer from 'module/Common/Layer/FormLayer';
import axios from 'axios';
import { DeleteContext } from 'context/Context';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import ProposalPrintLayoutsItem from './ProposalPrintLayoutsItem';
import { showToast } from 'module/Common/Toast/toast';
import DataFetching from 'module/Report/LedgerReport/DataFetching';
import generateProposalTableHelper from 'helpers/generateProposalTableHelper';

export default function ProposalPrintLayouts({
  setEditorValue,
  setActiveLayout,
  setPages,
  setEditorPadding,
  setFileName,
  onHide,
  data
}) {
  const { dispatch: configConfirm } = useContext(DeleteContext);
  const Translate = useAxisproTranslate();
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [layoutItems, setLayoutItems] = useState(undefined);
  const nextPage = (layoutItems?.meta?.current_page || 0) + 1;
  const lastPage = layoutItems?.meta?.last_page || 0;

  const handleView = (event, layout) => {
    event?.preventDefault();
    if (typeof setEditorValue !== 'function') return;

    if (layout?.id) setActiveLayout(layout);

    if (typeof setPages === 'function' && layout?.configs?.pages)
      setPages(layout.configs.pages);

    if (typeof setEditorPadding === 'function' && layout?.configs?.padding)
      setEditorPadding(layout.configs.padding);

    if (typeof setFileName === 'function')
      setFileName(layout?.name || 'Proposal' + Date.now());

    const clickedElementHTML = layout?.body || event.currentTarget.innerHTML;
    const DIV = document.createElement('div');
    DIV.innerHTML = clickedElementHTML;
    const setInnerText = (element, text) => {
      element?.forEach(parent => {
        if (parent.children) {
          parent.children[0].innerText = text;
        } else {
          parent.innerText = text;
        }
      });
    };

    // set text data
    const dataArray = [
      {
        class: 'ql-direction-dmContentUserName',
        data: data?.customer_name || '-'
      },
      {
        class: 'ql-direction-dmContentUserMail',
        data: data?.email || '-'
      },
      {
        class: 'ql-direction-dmContentUserPhone',
        data: data?.mobile || '-'
      }
    ];

    dataArray.forEach(data => {
      const Elements = DIV.querySelectorAll('.' + data?.class);

      if (Elements) {
        setInnerText(Elements, data?.data);
      }
    });

    //set table data
    const tableElements = DIV.querySelectorAll('table');
    if (tableElements) {
      const newTable = generateProposalTableHelper({
        type: 'sq',
        data: data,
        resultType: 'HTML'
      });

      tableElements.forEach(table => {
        const dynamicTableDataElement = table.querySelector(
          '.ql-direction-dmContentTable'
        );
        if (dynamicTableDataElement) {
          DIV.replaceChild(newTable, table);
        }
      });
    }

    setEditorValue(DIV?.innerHTML);
    if (typeof onHide === 'function') onHide();
  };

  const handleTrash = (event, layout) => {
    event?.preventDefault();

    configConfirm({
      type: 'CONFIG',
      payload: {
        show: true,
        title: Translate('Delete selected layout'),
        message: Translate(
          'Are you sure you want to delete the selected layout item?'
        ),
        submitButtonVariant: 'danger',
        buttonText: Translate('Delete'),
        cancelButtonVariant: 'secondary',
        onSubmit: async event => {
          event?.preventDefault();

          try {
            configConfirm({
              type: 'RESET'
            });

            setIsDeleting(true);
            const apiResponse = await axios.delete('proposals/' + layout?.id);

            if (apiResponse?.data?.success) {
              showToast(apiResponse?.data?.message, 'success');
              setLayoutItems(prev => ({
                ...prev,
                data: prev?.data?.filter(item => item?.id !== layout?.id)
              }));
            }
          } catch (error) {
            showToast(
              error?.response?.data?.message ||
                error?.message ||
                'Something went wrong, please refresh the page and try again.',
              'error'
            );
          } finally {
            setIsDeleting(false);
          }
        }
      }
    });
  };

  const getResult = useCallback(
    async (page = 1) => {
      try {
        setIsLoading(true);
        const apiResult = await axios.get('proposals', {
          params: { page: page }
        });
        const result = apiResult?.data?.data;
        if (!result) return;
        setLayoutItems(prev =>
          page > 1
            ? {
                ...result,
                data: [...(prev?.data || []), ...(result?.data || [])]
              }
            : result
        );
      } catch (error) {
        console.log('error', error);
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, setLayoutItems]
  );

  useEffect(() => {
    getResult();

    return () => {
      setLayoutItems(undefined);
    };
  }, [getResult]);

  return isLoading && nextPage <= 1 ? (
    <FormLayer />
  ) : layoutItems?.meta?.total > 0 ? (
    <>
      {isDeleting ? <FormLayer /> : null}

      <Row xl={5} lg={4} md={3} sm={2} xs={1} className="g-4">
        {layoutItems?.data?.map(layout => (
          <ProposalPrintLayoutsItem
            key={layout?.id}
            as={Col}
            layout={layout}
            handleView={handleView}
            handleTrash={handleTrash}
          />
        ))}
      </Row>

      {!isLoading ? (
        layoutItems.meta?.current_page >= lastPage ? (
          <p className="mt-5 text-center">
            {Translate('You have seen it all')} &#x1F642;.
          </p>
        ) : (
          <DataFetching
            nextLink={nextPage}
            loadNextPage={() => getResult(nextPage)}
          />
        )
      ) : null}
    </>
  ) : (
    <Alert variant="danger">{Translate('No result found!')}</Alert>
  );
}

ProposalPrintLayouts.propTypes = {
  setEditorValue: PropTypes.func,
  setActiveLayout: PropTypes.func,
  setFileName: PropTypes.func,
  onHide: PropTypes.func,
  data: PropTypes.object,
  setPages: PropTypes.func,
  setEditorPadding: PropTypes.func
};
