import { React, useEffect, useState } from 'react';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import PropTypes from 'prop-types';
import { BsHandbagFill } from 'react-icons/bs';
import { getAcodaxPermissionSlug } from '../Validators/permissions/PermissionSlug';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import ItemAdvanceSearch from 'components/item-advance-search/ItemAdvanceSearch';
import DropdownAddButton from '../Buttons/DropdownAddButton';
import AddItem from '../Forms/AddItem';

function SearchAndSelectItem({
  handleFieldChange,
  name,
  error,
  value,
  advanceSearch,
  advanceSearchType,
  keyName,
  stockItemList,
  type,
  disableAddForm,
  inCludeFixedAsset,
  assetType,
  onKeyDown
}) {
  const Translate = useAxisproTranslate();
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [addItem, setAddItem] = useState(false);
  const [loading, setLoading] = useState(false);
  const permission = {
    create_item: getAcodaxPermissionSlug('IT', 'create')
  };

  const setItems = async name => {
    setLoading(true);
    try {
      let response = await axios.get('inventory/items-auto', {
        params: {
          item_name: name,
          exclude_kits: stockItemList ? 1 : type ? 1 : 0,
          product_type: type ? 'govt_service' : '',
          include_fixed_asset: inCludeFixedAsset ? 1 : 0,
          fixed_asset_type: assetType ? assetType : ''
        }
      });
      let data = response && response.data.data ? response.data.data : [];
      return data.map(item => ({
        label: item.item_name,
        value: item.stock_id,
        id: item.id,
        type: item.type,
        unit_name: item.unit_name,
        price: item.sale_rate
      }));
    } finally {
      setLoading(false);
    }
  };

  const getOptionLabel = option => {
    return (
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ color: 'rgb(52,69,85)' }}
      >
        <span>{option.label}</span>
        {option.type == 'kit' ? (
          <BsHandbagFill
            style={{ fill: 'red' }}
            size={13}
            className="text-dark"
          />
        ) : (
          ''
        )}
      </div>
    );
  };

  const handleFocus = async () => {
    if (defaultOptions.length === 0) {
      const defaultOptionsData = await setItems('');
      setDefaultOptions(defaultOptionsData);
    }
  };

  const { MenuList } = components;

  const CustomMenuList = props => (
    <MenuList {...props}>
      {loading ? (
        <div className="w-100 d-flex align-items-center justify-content-center p-3">
          Loading...
        </div>
      ) : (
        <div>
          {permission?.create_item && !disableAddForm && (
            <DropdownAddButton
              title={Translate('Add Item')}
              show={() => setAddItem(true)}
            />
          )}
          {props.children}
        </div>
      )}
    </MenuList>
  );

  useEffect(() => {
    if (value?.value && defaultOptions.length > 0) {
      if (value?.value) {
        let dataExist = defaultOptions.filter(
          obj => obj['value'] === value?.value
        );
        if (dataExist?.length > 0) {
          return;
        } else {
          let newoptionSet = [value, ...defaultOptions];
          setDefaultOptions(newoptionSet);
        }
      }
    }
  }, [value, defaultOptions]);

  return (
    <>
      <div className="d-flex flrex-row align-items-center">
        <AsyncSelect
          cacheOptions
          loadOptions={setItems}
          defaultOptions={defaultOptions}
          onFocus={handleFocus}
          isClearable
          isLoading={loading}
          getOptionLabel={getOptionLabel}
          isOptionSelected={item => item.value === value?.value}
          name={name?.label ? name?.label : 'stock'}
          value={value}
          placeholder=""
          onKeyDown={onKeyDown}
          onChange={handleFieldChange}
          classNamePrefix="app-react-select"
          className={`w-100  ${
            error ? ' form-control ps-0 py-0 is-invalid' : ''
          }`}
          components={{ MenuList: CustomMenuList }}
          styles={{
            menuPortal: provided => ({ ...provided, zIndex: 9999 }),
            menu: provided => ({
              ...provided,
              zIndex: 9999,
              fontFamily: 'sans-serif',
              fontSize: '12px',
              borderRadius: '0px',
              textTransform: 'capitalize'
            }),
            control: (base, state) => ({
              ...base,
              boxShadow: state.isFocused ? 0 : 0,
              borderRadius: '0px',
              border: '1px solid #b5b5b5',
              height: '10px'
            })
          }}
          menuPortalTarget={document.body}
        />
        {advanceSearch && (
          <ItemAdvanceSearch
            setItem={handleFieldChange}
            name={name ?? 'item'}
            keyName={keyName}
            advanceSearchType={advanceSearchType}
          />
        )}
      </div>
      <AddItem
        show={addItem}
        onHide={() => setAddItem(false)}
        setFormData={handleFieldChange}
        key={new Date()}
      />
    </>
  );
}

SearchAndSelectItem.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.any,
  error: PropTypes.any,
  value: PropTypes.any,
  advanceSearch: PropTypes.bool,
  advanceSearchType: PropTypes.string,
  keyName: PropTypes.string,
  stockItemList: PropTypes.bool,
  type: PropTypes.bool,
  children: PropTypes.any,
  disableAddForm: PropTypes.bool,
  inCludeFixedAsset: PropTypes.bool,
  assetType: PropTypes.string,
  onKeyDown: PropTypes.func
};

export default SearchAndSelectItem;
