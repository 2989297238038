import { AuthWizardContext, StoreContext } from 'context/Context';
import { useState, useEffect, useContext, useCallback } from 'react';
import { Layout_1 } from './DashboardLayout';
import axios from 'axios';
import { showToast } from 'module/Common/Toast/toast';

const useBootstrapBreakpoints = () => {
  const [preLayout, setPreLayout] = useState([]);
  const [count, setCount] = useState(1);
  const { addIitemToStore } = useContext(StoreContext);
  const [block, setBlock] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const { user } = useContext(AuthWizardContext);
  const [layout, setLayout] = useState(Layout_1.xl);
  const [size, setSize] = useState('xl');
  const [updatedLayout, setUpdatedLayout] = useState();
  const [deletedLayout, setDeletedLayout] = useState({});
  const [selectLayout, setSelectedLayout] = useState({});
  const updateBreakpoint = useCallback(() => {
    const { innerWidth } = window;
    const updatedCustomerLayout = user?.custom_layout
      ? JSON.parse(user?.custom_layout)
      : null;
    if (
      updatedCustomerLayout !== null &&
      Object.keys(updatedCustomerLayout?.layout).length > 0
    ) {
      if (updatedCustomerLayout?.deleted_layout) {
        setDeletedLayout(updatedCustomerLayout?.deleted_layout);
      }
      const userLayout = updatedCustomerLayout?.layout;
      setSelectedLayout(userLayout);
      if (innerWidth >= 1300) {
        setSize('xl');
        setLayout(userLayout.xl);
      } else if (innerWidth < 1300 && innerWidth >= 770) {
        setSize('md');
        setLayout(userLayout.md);
      } else if (innerWidth < 770) {
        setSize('sm');
        setLayout(userLayout.sm);
      } else {
        setSize('xl');
        setLayout(userLayout.xl);
      }
    } else {
      setSelectedLayout(Layout_1);
      if (innerWidth >= 1300) {
        setSize('xl');
        setLayout(Layout_1.xl);
      } else if (innerWidth < 1300 && innerWidth >= 770) {
        setSize('md');
        setLayout(Layout_1.md);
      } else if (innerWidth < 770) {
        setSize('sm');
        setLayout(Layout_1.sm);
      } else {
        setSize('xl');
        setLayout(Layout_1.xl);
      }
    }
  }, [user?.custom_layout]);
  const handleRemoveItem = itemToRemove => {
    layout.map(item => {
      if (item.i === itemToRemove) {
        setDeletedLayout(prev => ({
          ...prev,
          [size]: [...(prev[size] || []), item]
        }));
        return false;
      } else {
        return true;
      }
    });
    const newLayout = layout.map(item => {
      if (item.i === itemToRemove) {
        return false;
      } else {
        return item;
      }
    });
    setLayout(newLayout);
  };
  const onDragStart = () => {
    setIsDragging(true);
  };
  const onDragStop = () => {
    setIsDragging(false);
  };
  const onLayoutChange = newLayout => {
    setLayout(newLayout);
    setUpdatedLayout(newLayout);
    if (!block) {
      setPreLayout(prev => [...prev, { ...newLayout }]);
    } else {
      setBlock(false);
    }
  };

  const handleUndo = () => {
    if (count > 0 && preLayout.length - count >= 0) {
      setBlock(true);
      const newLayout = preLayout[preLayout.length - count];
      if (newLayout) {
        const dataArray = Object.values(newLayout);
        setLayout(dataArray);
        setCount(prev => prev + 1);
      }
    }
  };

  const handleRedo = () => {
    if (preLayout.length - count <= 0) {
      setCount(preLayout.length);
    }
    if (
      count > 1 &&
      preLayout.length - count >= 0 &&
      preLayout.length - count < preLayout.length
    ) {
      setBlock(true);
      const newLayout = preLayout[preLayout.length - count + 1];
      if (newLayout) {
        const dataArray = Object.values(newLayout);
        setLayout(dataArray);
        setCount(prev => prev - 1);
      }
    }
  };
  const CloseDragOption = () => {
    setLayout(selectLayout[size]);
    setUpdatedLayout(selectLayout[size]);
    setPreLayout([]);
    setDeletedLayout(JSON.parse(user?.custom_layout)?.deleted_layout ?? {});
    setCount(1);
    addIitemToStore('dashboard_draggable', false);
  };
  const updateLayoutData = updatedLayout => {
    const data = updatedLayout.map(Layout => {
      return {
        i: Layout.i,
        x: Layout.x,
        y: Layout.y,
        w: Layout.w,
        h: Layout.h,
        minW: Layout.minW,
        maxW: Layout.maxW,
        minH: Layout.minH,
        maxH: Layout.maxH
      };
    });
    return data;
  };
  const handleSubmit = async () => {
    selectLayout[size] = updateLayoutData(updatedLayout);
    let data = selectLayout;
    try {
      const response = await axios.post(
        `user/update-custom-layout?user_id=${user.id}`,
        {
          custom_layout: { layout: data, deleted_layout: deletedLayout ?? {} }
        }
      );
      if (response.data.success === true) {
        showToast('Successfully Updated', 'success');
        window.location.reload();
        CloseDragOption();
      }
    } catch (error) {
      showToast('something went wrong');
    }
  };
  useEffect(() => {
    updateBreakpoint();
    window.addEventListener('resize', updateBreakpoint);
    return () => {
      window.removeEventListener('resize', updateBreakpoint);
    };
  }, [updateBreakpoint]);

  useEffect(() => {
    if (user.language == 'AR') {
      const gridLayoutContainer = document.querySelector('.react-grid-layout');
      gridLayoutContainer.setAttribute('dir', 'ltr');
    }
  }, [user.language]);

  return {
    setLayout,
    CloseDragOption,
    layout,
    handleRemoveItem,
    onDragStart,
    onLayoutChange,
    isDragging,
    handleRedo,
    handleUndo,
    onDragStop,
    preLayout,
    count,
    handleSubmit,
    setSelectedLayout,
    size,
    setSize,
    selectLayout,
    deletedLayout,
    setDeletedLayout
  };
};

export default useBootstrapBreakpoints;
