import { React, useContext, useEffect, useState } from 'react';
import { Badge, Button, Container } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { FaEdit } from 'react-icons/fa';
import { BsFillTrashFill } from 'react-icons/bs';

import { DeleteContext } from 'context/Context';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { apiCall } from 'helpers/apiCalls';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';

function CreditStatusList({ setEditId, editId }) {
  const Translate = useAxisproTranslate();
  const { config, dispatch } = useContext(DeleteContext);
  const [isLoading, setIsLoading] = useState(false);
  const [CreditStatus, setCreditStatus] = useState({});

  const handleDelete = data => {
    dispatch({
      type: 'CONFIG',
      payload: {
        target: data.id,
        url: '/sales/credit-status/' + data.id,
        title: 'Delete Credit Status',
        message: 'Are you sure you want to delete this Credit Status?'
      }
    });
  };

  const columns = [
    {
      accessor: 'description',
      Header: Translate('Status'),
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { description } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{description}</h5>;
      }
    },
    {
      accessor: 'disallow_invoices',
      Header: Translate('Allow Invoice'),
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { disallow_invoices } = rowData.row.original;
        return (
          <h5 className="mb-0 fs--1">
            {parseInt(disallow_invoices) ? (
              <Badge bg="success">{Translate('Yes')}</Badge>
            ) : (
              <Badge bg="danger">{Translate('No')}</Badge>
            )}
          </h5>
        );
      }
    },
    {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: 'text-end'
      },
      Cell: rowData => {
        const { id } = rowData.row.original;
        return (
          <>
            <Button
              variant="transparent"
              className="text-info"
              style={{ boxShadow: 'none' }}
              onClick={() => setEditId(id)}
            >
              <FaEdit />
            </Button>
            <Button
              variant="transparent"
              className="me-1 text-danger"
              style={{ boxShadow: 'none' }}
              onClick={() => handleDelete(rowData.row.original)}
            >
              <BsFillTrashFill />
            </Button>
          </>
        );
      }
    }
  ];

  useEffect(() => {
    const getData = async (page = 1) => {
      setIsLoading(true);
      const data = await apiCall({
        url: 'sales/credit-status',
        params: {
          page: page
        }
      });
      setCreditStatus(data);
      setIsLoading(false);
    };
    getData();
  }, [editId]);

  const stringifiedCreditStatus = JSON.stringify(CreditStatus);

  useEffect(() => {
    const creditStatus = JSON.parse(stringifiedCreditStatus);
    if (config.last_deleted_item) {
      const dataWithoutDeletedItem = creditStatus.data.filter(
        center => center.id !== config.last_deleted_item
      );
      setCreditStatus(prevData => ({
        ...prevData,
        data: dataWithoutDeletedItem
      }));
      dispatch({
        type: 'RESET'
      });
    }

    return () => {
      dispatch({
        type: 'RESET'
      });
    };
  }, [stringifiedCreditStatus, config.last_deleted_item, dispatch]);

  return !isLoading &&
    CreditStatus &&
    CreditStatus.data &&
    CreditStatus.data.length > 0 ? (
    <AdvanceTableWrapper columns={columns} data={CreditStatus.data}>
      <AdvanceTable
        table
        headerClassName="bg-200 text-900 text-nowrap align-middle"
        rowClassName="align-middle white-space-nowrap"
        tableProps={{
          size: 'sm',
          striped: true,
          className: 'fs--1 mb-0 overflow-hidden'
        }}
      />
    </AdvanceTableWrapper>
  ) : CreditStatus.data && CreditStatus.data.length <= 0 && !isLoading ? (
    <Container
      style={{ height: '10vh' }}
      className="d-flex align-items-center justify-content-center"
    >
      {Translate('No data available')}
    </Container>
  ) : (
    <Container
      style={{ height: '10vh' }}
      className="d-flex align-items-center justify-content-center"
    >
      <LoadingScreen />
    </Container>
  );
}

CreditStatusList.propTypes = {
  setEditId: PropTypes.func,
  editId: PropTypes.any
};

export default CreditStatusList;
