import { React, useCallback, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Badge, Card, Col, Dropdown, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FcNoIdea } from 'react-icons/fc';

import Flex from 'components/common/Flex';
import { getColor } from 'helpers/utils';
import * as echarts from 'echarts/core';
import { PieChart } from 'echarts/charts';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { AuthWizardContext } from 'context/Context';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import {
  GridComponent,
  TooltipComponent,
  TitleComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import BasicECharts from 'components/common/BasicEChart';
import Loader from '../Common/Loader';
import { grossProfit } from '../Common/LoaderData';
import FalconCardHeader from 'components/common/FalconCardHeader';
import CardDropdown from 'components/common/CardDropdown';
import { FaCalendar } from 'react-icons/fa';
import CalendarRangePickerModal from 'components/CalendarRangePickerModal/CalendarRangePickerModal';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer
]);

const getOptions = (data, radius, baseCurrency) => ({
  color: [getColor(data[0].color), getColor(data[1].color)],
  tooltip: {
    padding: [7, 10],
    transitionDuration: 0,
    backgroundColor: getColor('100'),
    borderColor: getColor('300'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    formatter: params =>
      `<strong>${params.data.name}:</strong> ${params.data.valueFormatted}`
  },
  series: [
    {
      name: `${baseCurrency} ` + data[0].valueFormatted,
      type: 'pie',
      radius,
      avoidLabelOverlap: false,
      emphasis: {
        scale: false
      },
      itemStyle: {
        borderWidth: 2,
        borderColor: getColor('card-bg')
      },
      label: {
        show: true,
        position: 'center',
        formatter: '{a}',
        fontSize: 13,
        color: getColor('dark')
      },
      data
    }
  ]
});

const GrossProfitItem = ({ item, index }) => {
  const { breakpoints } = useBreakpoints();
  const { name, color, valueFormatted } = item;

  return (
    <Flex
      alignItems="center"
      justifyContent="between"
      className={`text-dark fs--2 mb-1 ${
        index == 0 && 'mt-3'
      } gross-profit-style`}
    >
      <p className="mb-0">
        <FontAwesomeIcon
          icon="circle"
          className={`me-1 text-${color || 'text-info'}`}
        />
        {name}
      </p>
      <div
        style={{
          marginRight: breakpoints.down('sm') ? 'none' : '25px'
        }}
        className={`text-${color || 'text-info'}`}
      >
        {valueFormatted}
      </div>
    </Flex>
  );
};

function GrossProfitShare({ radius, data, setData, draggable }) {
  const Translate = useAxisproTranslate();
  const [isLoading, setIsLoading] = useState(false);
  const [pieData, setPieData] = useState([
    {
      color: 'success',
      id: 1,
      name: 'Gross Profit',
      value: ''
    },
    {
      color: 'danger',
      id: 2,
      name: 'Total Purchase',
      value: ''
    }
  ]);
  const { user } = useContext(AuthWizardContext);
  const baseCurrency = user?.branch?.base_currency ?? '';
  const [showDateRangePicker, setshowDateRangePicker] = useState(false);
  const [dateRange, setDateRange] = useState(null);

  function handleSetDateRange(event) {
    setDateRange(event?.target?.value ?? null);
  }

  const fetchData = useCallback(
    (reset = false) => {
      setIsLoading(true);
      setshowDateRangePicker(false);

      if (reset) {
        setDateRange(null);
      }

      const params =
        !reset && Array.isArray(dateRange) && dateRange.length > 0
          ? {
              from_date: dateRange[0],
              to_date: dateRange[1]
            }
          : null;

      axios
        .get('sales/get-gross-profit-summary', { params })
        .then(res => {
          if (res.data.success) {
            const formatValue = value =>
              Math.abs(value) >= 10000 ? Math.floor(value / 1000) + 'K' : value;

            const profitAmount = formatValue(res.data.data.gross_profit_amount);
            const salesAmount = formatValue(res.data.data.total_sales_amount);
            const purchaseAmount = formatValue(
              res.data.data.total_purchase_amount
            );

            setData([
              {
                color: 'info',
                id: 2,
                name: Translate('Total Sales'),
                value: res.data.data.total_sales_amount,
                valueFormatted: salesAmount
              },
              {
                color: 'success',
                id: 1,
                name: Translate('Gross Profit'),
                value: res.data.data.gross_profit_amount,
                valueFormatted: profitAmount
              },
              {
                color: 'danger',
                id: 3,
                name: Translate('Total Purchase'),
                value: res.data.data.total_purchase_amount,
                valueFormatted: purchaseAmount
              }
            ]);

            setPieData([
              {
                color: 'success',
                id: 1,
                name: Translate('Gross Profit'),
                value: res.data.data.gross_profit_amount,
                valueFormatted: profitAmount
              },
              {
                color: 'danger',
                id: 2,
                name: Translate('Total Purchase'),
                value: res.data.data.total_purchase_amount,
                valueFormatted: purchaseAmount
              }
            ]);

            setIsLoading(false);
          }
        })
        .catch(error => {
          console.error('Error fetching gross profit summary:', error);
        });
    },
    [dateRange, Translate, setData]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const total = data.reduce((acc, val) => val.value + acc, 0);
  return (
    <>
      {showDateRangePicker ? (
        <CalendarRangePickerModal
          show={showDateRangePicker}
          value={dateRange}
          onChange={handleSetDateRange}
          onBack={() => setshowDateRangePicker(false)}
          onReset={() => fetchData(true)}
          onApply={() => fetchData(false)}
        />
      ) : null}
      <Card
        className={`h-100 ${draggable && 'dashboard_shake'} position-relative`}
      >
        <FalconCardHeader
          title={Translate('Gross Profit Summary')}
          light={false}
          titleTag="h6"
          className="pb-0  pt-2"
          endEl={
            <CardDropdown>
              <div className="py-2">
                <Dropdown.Item onClick={() => setshowDateRangePicker(true)}>
                  {Translate('Change Date')}
                </Dropdown.Item>
              </div>
            </CardDropdown>
          }
        />

        {!isLoading ? (
          data.length > 0 ? (
            <Card.Body className="pb-1 pt-1 gros-profit-summary">
              <Row className="justify-content-between g-0">
                <Col xs={5} sm={6} xxl className="pe-2 w-50">
                  {data.map((item, index) => (
                    <GrossProfitItem
                      item={item}
                      index={index}
                      key={index}
                      total={total}
                    />
                  ))}
                </Col>
                <Col xs="auto">
                  <div className="ps-0 ">
                    <BasicECharts
                      echarts={echarts}
                      options={getOptions(pieData, radius, baseCurrency)}
                      style={{ width: '6rem', height: '6rem' }}
                    />
                  </div>
                </Col>
              </Row>
            </Card.Body>
          ) : (
            <Card.Body>
              <Row className="d-flex flex-column justify-content-center align-items-center h-100">
                <FcNoIdea size={30} />
                {Translate('No Data Available')}
              </Row>
            </Card.Body>
          )
        ) : (
          <Loader loader={grossProfit} className={'ps-4'} />
        )}
        {Array.isArray(dateRange) && dateRange.length > 1 ? (
          <div className="d-flex flex-row dashboard-date-range-div align-items-center">
            <FaCalendar size={10} />{' '}
            <Badge bg="light" className="ms-1 text-dark">
              {dateRange[0]} - {dateRange[1]}
            </Badge>
          </div>
        ) : null}
      </Card>
    </>
  );
}

GrossProfitItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    valueFormatted: PropTypes.any
  }),
  index: PropTypes.number.isRequired,
  total: PropTypes.any.isRequired
};

GrossProfitShare.propTypes = {
  data: PropTypes.array.isRequired,
  radius: PropTypes.array.isRequired,
  setData: PropTypes.func.isRequired,
  draggable: PropTypes.bool
};

export default GrossProfitShare;
