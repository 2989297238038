import { React, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Card, Col, Form, Modal, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { BsBox } from 'react-icons/bs';

import { apiCall } from 'helpers/apiCalls';
import { showToast } from '../Toast/toast';
import { CancelButton, SaveButton } from '../Buttons/CommonButton';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import ErrorAlert from '../Error/ErrorAlert';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';
import StockIdInputField from '../InputFields/InventoryInputFields/StockIdInputField';
import BarCodeInptField from '../InputFields/InventoryInputFields/BarCodeInptField';
import ItemNameInputField from '../InputFields/InventoryInputFields/ItemNameInputField';
import CategoryInputField from '../InputFields/InventoryInputFields/CategoryInputField';
import UnitInputField from '../InputFields/InventoryInputFields/UnitInputField';
import TaxGroupInputField from '../InputFields/InventoryInputFields/TaxGroupInputField';
import AccountInputField from '../InputFields/InventoryInputFields/AccountInputField';
import PriceInputField from '../InputFields/InventoryInputFields/PriceInputField';
import ConfirmWarning from '../Warnings/ConfirmWarning';
import removeRefData from 'helpers/removeRefData';
import { AuthWizardContext } from 'context/Context';
import { FaArrowRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

function AddItem({ show, onHide, setFormData }) {
  const navigate = useNavigate();
  const { user } = useContext(AuthWizardContext);
  const systemSettings = user?.branch?.system_settings;
  const financeSettings = user?.branch?.finance_settings;
  const Translate = useAxisproTranslate();
  const [itemData, setItemData] = useState({
    product_type: 'goods',
    stock_id: '',
    barcode: '',
    item_name: '',
    category_id: '',
    category_id_ref: '',
    unit_id: '',
    unit_id_ref: '',
    tax_group_id: '',
    tax_group_id_ref: '',
    inventory_account_code: '',
    inventory_account_code_ref: '',
    purchase_account_code: '',
    purchase_account_code_ref: '',
    purchase_rate: '',
    sales_account_code: '',
    sales_account_code_ref: '',
    sales_rate: '',
    ...(systemSettings?.default_unit_id
      ? {
          unit_id: systemSettings?.default_unit_id,
          unit_id_ref: {
            label: systemSettings?.default_unit_name,
            value: systemSettings?.default_unit_id
          }
        }
      : {
          unit_id_ref: '',
          unit_id: ''
        })
  });
  const [onSave, setOnSave] = useState(false);
  const [formError, setFormError] = useState({});
  const [showWarning, setShowWarning] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(true);
  const handleShow = () => setShowWarning(!showWarning);

  const handleFieldChange = (e, action) => {
    let newFormData;
    let value;
    if (action) {
      if (action.action == 'select-option') {
        value = e.code ? e.code : e.value;
      }
      if (action.name == 'category_id') {
        if (action.action === 'select-option') {
          newFormData = {
            ...itemData,
            [action.name]: value,
            [action.name + '_ref']: e
          };
        } else {
          newFormData = {
            ...itemData,
            [action.name]: value,
            [action.name + '_ref']: e,
            unit_id: '',
            unit_id_ref: '',
            tax_group_id: '',
            tax_group_id_ref: '',
            inventory_account_code: '',
            inventory_account_code_ref: '',
            purchase_account_code: '',
            purchase_account_code_ref: '',
            sales_account_code: '',
            sales_account_code_ref: ''
          };
        }
      } else {
        newFormData = {
          ...itemData,
          [action.name]: value,
          [action.name + '_ref']: e
        };
      }
    } else {
      let value = null;
      if (e.target.type === 'checkbox') {
        e.target.checked ? (value = 1) : (value = 0);
      }
      if (e.target.name === 'purchase_rate') {
        setIsConfirmed(true);
        if (parseFloat(e.target.value) > parseFloat(itemData.sale_rate)) {
          setIsConfirmed(false);
        }
        value = e.target.value;
      }
      if (e.target.name === 'sale_rate') {
        setIsConfirmed(true);
        if (parseFloat(e.target.value) < parseFloat(itemData.purchase_rate)) {
          setIsConfirmed(false);
        }
        value = e.target.value;
      }
      value = e.target.value;
      newFormData = {
        ...itemData,
        [e.target.name]: value
      };
    }
    setItemData(newFormData);
  };

  const handleSubmit = () => {
    setFormError({});
    setShowWarning(false);
    setOnSave(true);
    let dataWithoutRefKeys = removeRefData(itemData, 'object');
    if (['goods', 'service'].includes(dataWithoutRefKeys?.product_type)) {
      delete dataWithoutRefKeys.wip_account_code;
    }

    if (
      ['service', 'manufactured'].includes(dataWithoutRefKeys?.product_type)
    ) {
      if (
        Object.keys(dataWithoutRefKeys).includes(
          'inventory_adjustment_account_code'
        )
      ) {
        delete dataWithoutRefKeys.inventory_adjustment_account_code;
      }
    }

    if (['goods'].includes(dataWithoutRefKeys?.product_type)) {
      delete dataWithoutRefKeys.wip_crediting_account_code;
      dataWithoutRefKeys.inventory_adjustment_account_code =
        financeSettings?.inventory_adjustments_account || '';
    }
    if (['service'].includes(dataWithoutRefKeys?.product_type)) {
      delete dataWithoutRefKeys.purchase_account_code;
    }

    axios({
      method: 'post',
      url: `inventory/items`,
      data: dataWithoutRefKeys
    })
      .then(response => {
        if (response.data.success === true) {
          showToast(response.data.message, 'success');
          setItemData({
            product_type: 'goods',
            stock_id: '',
            barcode: '',
            item_name: '',
            category_id: '',
            category_id_ref: '',
            unit_id: '',
            unit_id_ref: '',
            tax_group_id: '',
            tax_group_id_ref: '',
            inventory_account_code: '',
            inventory_account_code_ref: '',
            purchase_account_code: '',
            purchase_account_code_ref: '',
            purchase_rate: '',
            sales_account_code: '',
            sales_account_code_ref: '',
            sale_rate: ''
          });
          setOnSave(false);
          onHide();
          let event = {
            label: response?.data?.data?.item_name,
            value: response?.data?.data?.stock_id,
            id: response?.data?.data?.id
          };
          let action = {
            action: 'select-option'
          };
          setFormData(event, action);
        }
      })
      .catch(error => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          showToast(
            Translate(
              'Something went wrong, please refresh the page and try again.'
            ),
            'error'
          );
        }
        setOnSave(false);
      });
  };

  const handleCancel = () => {
    onHide();
    setFormError({});
  };

  const clickOnSave = () => {
    if (!isConfirmed) {
      setShowWarning(true);
    } else {
      handleSubmit();
    }
  };

  useEffect(() => {
    async function initFunc() {
      if (show) {
        let details = await apiCall({
          url: '/inventory/generate-stock-id'
        });
        setItemData(prevItemData => ({
          ...prevItemData,
          stock_id: details.stock_id
        }));
      }
    }

    initFunc();
  }, [show]);

  const getCategoryDetails = id => {
    axios({
      method: 'get',
      url: `settings/categories/${id}`
    })
      .then(response => {
        if (response.data.success === true) {
          setItemData(prev => ({
            ...prev,
            unit_id: response?.data?.data?.unit_id,
            unit_id_ref: response?.data?.data?.unit_id
              ? {
                  label: response?.data?.data?.unit?.name,
                  value: response?.data?.data?.unit_id
                }
              : '',
            tax_group_id: response?.data?.data?.tax_group_id,
            tax_group_id_ref: response?.data?.data?.tax_group_id
              ? {
                  label: response?.data?.data?.tax_group_name,
                  value: response?.data?.data?.tax_group_id
                }
              : '',
            inventory_account_code:
              response?.data?.data?.inventory_account_code,
            inventory_account_code_ref: response?.data?.data
              ?.inventory_account_code
              ? {
                  label: response?.data?.data?.inventory_account_name,
                  code: response?.data?.data?.inventory_account_code
                }
              : '',
            purchase_account_code: response?.data?.data?.purchase_account_code,
            purchase_account_code_ref: response?.data?.data
              ?.purchase_account_code
              ? {
                  label: response?.data?.data?.purchase_account_name,
                  code: response?.data?.data?.purchase_account_code
                }
              : '',
            sales_account_code: response?.data?.data?.sales_account_code,
            sales_account_code_ref: response?.data?.data?.sales_account_code
              ? {
                  label: response?.data?.data?.sales_account_name,
                  code: response?.data?.data?.sales_account_code
                }
              : '',
            wip_account_code: response?.data?.data?.wip_account_code,
            wip_account_code_ref: response?.data?.data?.wip_account_code
              ? {
                  label: response?.data?.data?.wip_account_name,
                  code: response?.data?.data?.wip_account_code
                }
              : '',
            wip_crediting_account_code:
              response?.data?.data?.wip_crediting_account_code,
            wip_crediting_account_code_ref: response?.data?.data
              ?.wip_crediting_account_code
              ? {
                  label: response?.data?.data?.wip_crediting_account_name,
                  code: response?.data?.data?.wip_crediting_account_code
                }
              : ''
          }));
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (itemData?.category_id) {
      getCategoryDetails(itemData?.category_id);
    }
  }, [itemData?.category_id]);

  useEffect(() => {
    if (itemData?.product_type === 'goods') {
      setItemData(prev => ({
        ...prev,
        track_inventory: 1
      }));
    } else if (itemData?.product_type === 'service') {
      setItemData(prev => ({
        ...prev,
        track_inventory: 0
      }));
    }
  }, [itemData?.product_type]);

  return (
    <>
      <Modal
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        className="modal-with-overlay"
        onHide={handleCancel}
      >
        <Card className="p-0">
          <Modal.Header
            className="d-flex flex-row align-items-center border-bottom"
            closeButton
          >
            <BsBox size={25} className="text-dark me-1 m-0" />
            <h5 className="modal-form-heading">{Translate('Add Item')}</h5>
          </Modal.Header>
          <Form>
            <Card.Body className="pb-0">
              {!Object.keys(formError).length == 0 ? (
                <ErrorAlert setFormError={setFormError} formError={formError} />
              ) : (
                ''
              )}
              <Row>
                <Form.Group as={Col} md={12} sm={12} className="mb-3">
                  <Row>
                    <Col xl={4} md={4} sm={12}>
                      <Form.Label className="require-data">
                        {Translate('Type')}
                      </Form.Label>
                    </Col>
                    <Col xl={8} md={8} sm={12}>
                      <Row className="d-flex flex-row">
                        <Col xl={11} md={11} sm={11} className="pe-sm-0 flex-2">
                          <Form.Check
                            inline
                            label={Translate('Goods')}
                            name="product_type"
                            type="radio"
                            id="inline-radio-1"
                            checked={
                              itemData.product_type === 'goods' ? true : false
                            }
                            value="goods"
                            onChange={handleFieldChange}
                          />
                          <Form.Check
                            inline
                            label={Translate('Service')}
                            name="product_type"
                            type="radio"
                            id="inline-radio-2"
                            value="service"
                            checked={
                              itemData.product_type === 'service' ? true : false
                            }
                            onChange={handleFieldChange}
                          />
                          <Form.Check
                            inline
                            label={Translate('Manufactured')}
                            name="product_type"
                            type="radio"
                            id="inline-radio-3"
                            value="manufactured"
                            checked={
                              itemData?.product_type === 'manufactured'
                                ? true
                                : false
                            }
                            onChange={handleFieldChange}
                          />
                        </Col>
                        <Col
                          xl={1}
                          md={1}
                          sm={1}
                          className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0"
                        >
                          {formError.product_type ? (
                            <FormErrorPopover
                              id="product_type"
                              totalErrorCount={Object.keys(formError).length}
                              errorMessage={formError.product_type}
                            />
                          ) : (
                            ''
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form.Group>
              </Row>
              <Row>
                <Col md={12} sm={12}>
                  <StockIdInputField
                    formData={itemData}
                    formError={formError}
                    handleFieldChange={handleFieldChange}
                    required
                  />
                  <BarCodeInptField
                    formData={itemData}
                    formError={formError}
                    handleFieldChange={handleFieldChange}
                    disable={false}
                  />
                  <ItemNameInputField
                    label={Translate('Name')}
                    formData={itemData}
                    formError={formError}
                    handleFieldChange={handleFieldChange}
                    required
                  />
                  <PriceInputField
                    label={Translate('Purchase Price')}
                    name={'purchase_rate'}
                    value={itemData.purchase_rate}
                    error={formError.purchase_rate}
                    formError={formError}
                    handleFieldChange={handleFieldChange}
                  />
                  <PriceInputField
                    label={Translate('Sales Price')}
                    name={'sale_rate'}
                    value={itemData?.sale_rate}
                    error={formError?.sale_rate}
                    formError={formError}
                    handleFieldChange={handleFieldChange}
                  />
                  <CategoryInputField
                    formData={itemData}
                    setFormData={setItemData}
                    formError={formError}
                    handleFieldChange={handleFieldChange}
                    required
                  />
                  <UnitInputField
                    formData={itemData}
                    formError={formError}
                    handleFieldChange={handleFieldChange}
                    required
                  />

                  <div style={{ display: 'none' }}>
                    <TaxGroupInputField
                      formData={itemData}
                      formError={formError}
                      handleFieldChange={handleFieldChange}
                      required
                    />
                    <AccountInputField
                      label={Translate('Inventory/Expense Account')}
                      name={'inventory_account_code'}
                      value={itemData.inventory_account_code_ref}
                      error={formError.inventory_account_code}
                      formError={formError}
                      handleFieldChange={handleFieldChange}
                      required
                    />
                    <AccountInputField
                      label={Translate('Purchase/Cost Account')}
                      name={'purchase_account_code'}
                      value={itemData.purchase_account_code_ref}
                      error={formError.purchase_account_code}
                      formError={formError}
                      handleFieldChange={handleFieldChange}
                      required
                    />
                    <AccountInputField
                      label={Translate('Sales Account')}
                      name={'sales_account_code'}
                      value={itemData.sales_account_code_ref}
                      error={formError.sales_account_code}
                      formError={formError}
                      handleFieldChange={handleFieldChange}
                      required
                    />
                    <AccountInputField
                      label={Translate('WIP Account')}
                      name={'wip_account_code'}
                      value={itemData.wip_account_code_ref}
                      error={formError.wip_account_code}
                      formError={formError}
                      handleFieldChange={handleFieldChange}
                      required
                    />
                    <AccountInputField
                      label={Translate('WIP Crediting Account')}
                      name={'wip_crediting_account_code'}
                      value={itemData.wip_crediting_account_code_ref}
                      error={formError.wip_crediting_account_code}
                      formError={formError}
                      handleFieldChange={handleFieldChange}
                      required
                    />
                  </div>
                  <div
                    className="text-primary cursor-pointer d-flex align-items-center mb-3"
                    onClick={() => navigate('/inventory/items/add')}
                  >
                    {Translate('Go to the Advanced Item Form')}
                    <FaArrowRight className="ms-1" />
                  </div>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer className="d-flex flex-row gap-2 justify-content-start border-top">
              <SaveButton
                handleSubmit={clickOnSave}
                onSave={onSave}
                createPermission={true}
                editPermission={true}
              />
              <CancelButton handleCancel={handleCancel} onSave={onSave} />
            </Card.Footer>
          </Form>
        </Card>
      </Modal>
      <ConfirmWarning
        show={showWarning}
        onHide={handleShow}
        handleSubmit={handleSubmit}
        content={Translate(
          'Purchase Price is greater than Sales Price are you sure you want to continue?'
        )}
        setIsConfirmed={setIsConfirmed}
      />
    </>
  );
}

AddItem.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  setFormData: PropTypes.func
};

export default AddItem;
