import { React, useContext, useEffect, useRef, useState } from 'react';
import { Alert, Button, Form, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import axios from 'axios';
import { showToast } from '../Toast/toast';
import { AuthWizardContext } from 'context/Context';
import { FaArrowRight } from 'react-icons/fa';
import { MdPhoneIphone } from 'react-icons/md';

function VerifyOtpForm({ show, username, hiddenNumber, setIsotpVerifyForm }) {
  const { dispatch } = useContext(AuthWizardContext);
  const [formData, setFormData] = useState({});
  const [formError, setFormError] = useState({});
  const [onVerify, setOnVerify] = useState(false);
  const inputRef = useRef(null);

  const handleChange = e => {
    let name = e.target.name;
    let value = e.target.value;

    let newData = {
      ...formData,
      [name]: value
    };

    setFormData(newData);
  };

  const verifyOtp = () => {
    setFormError({});
    setOnVerify(true);
    axios
      .get('/verify-otp-code', {
        params: formData
      })
      .then(response => {
        if (response.data.success) {
          if (response.data.data) {
            const userData = {
              ...response.data.data,
              lockScreen: false
            };

            showToast(`Successfully logged in as ${userData.name}`, 'success');
            localStorage.setItem('AXIS_PRO_USER', JSON.stringify(userData));
            dispatch({ type: 'UPDATE', payload: { ...userData } });

            setOnVerify(false);
          }
        }
      })
      .catch(error => {
        if (error.response.data) {
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
          setOnVerify(false);
        } else {
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            'error'
          );
          setOnVerify(false);
        }
      });
  };

  useEffect(() => {
    setFormData(prevData => ({ ...prevData, username: username }));
  }, [username]);

  useEffect(() => {
    if (show) {
      inputRef.current.focus();
    }
  }, [show]);

  return (
    <>
      <div className="d-flex flex-column text-center align-items-center text-center">
        {Object.keys(formError).length > 0 ? (
          <Form.Group className="mb-3">
            <Alert variant="danger">{formError.otp_code}</Alert>
          </Form.Group>
        ) : null}
        <label style={{ fontSize: '16px' }} className={'text-dark'}>
          Check your phone
        </label>
        <MdPhoneIphone className="text-center" size={40} />
        <span className="mt-1">Enter the 4 digit code we just sent to</span>
        <span className="mt-1">{hiddenNumber}</span>
        <a href="#" onClick={() => setIsotpVerifyForm(false)}>
          Use another phone?
        </a>
        <Form.Control
          type="text"
          name="otp_code"
          className="text-center mt-2"
          required
          onChange={handleChange}
          autoFocus={true}
          ref={inputRef}
        />
      </div>

      <Form.Group className="d-flex flex-row mt-5">
        <Button
          variant="success"
          type="button"
          onClick={verifyOtp}
          disabled={onVerify}
          className="rounded w-100 py-2 d-flex flex-row justify-content-center align-items-center"
        >
          {onVerify ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                variant="white"
                className="me-1"
                style={{ width: '12px', height: '12px' }}
              />
              <span className="fs--1">Authenticating...</span>
            </>
          ) : (
            <>
              <span>Login</span>
              <FaArrowRight className="ms-2" size={15} />
            </>
          )}
        </Button>
      </Form.Group>
    </>
  );
}

VerifyOtpForm.propTypes = {
  show: PropTypes.bool,
  username: PropTypes.any,
  hiddenNumber: PropTypes.any,
  setIsotpVerifyForm: PropTypes.any
};

export default VerifyOtpForm;
