import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorMessage: '' };
  }

  static getDerivedStateFromError(error) {
    // Update state to display fallback UI
    return { hasError: true, errorMessage: error.message };
  }

  componentDidCatch(error, info) {
    // Log error details to an external service (e.g., Sentry)
    console.error('Error caught in ErrorBoundary:', error, info);
  }

  render() {
    if (this.state.hasError) {
      return <div>Error: {this.state.errorMessage}</div>;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired
};

export default ErrorBoundary;

// import React, { Component } from 'react';
// import PropTypes from 'prop-types';

// class ErrorBoundary extends Component {
//   constructor(props) {
//     super(props);
//     this.state = { hasError: false, errorMessage: '' };
//   }

//   static getDerivedStateFromError(error) {
//     return { hasError: true, errorMessage: error.message };
//   }

//   componentDidCatch(error, info) {
//     console.error('Error caught in ErrorBoundary:', error, info);

//     if (this.isChunkLoadError(error) && !this.hasRecentlyReloaded()) {
//       this.markReloadTime();
//       window.location.reload();
//     }
//   }

//   isChunkLoadError(error) {
//     return (
//       error.message &&
//       (error.message.includes('Loading chunk') ||
//         error.message.includes('chunk failed')) &&
//       error.name === 'ChunkLoadError'
//     );
//   }

//   hasRecentlyReloaded() {
//     const lastReloadTime = sessionStorage.getItem('lastReloadTime');
//     if (!lastReloadTime) return false;

//     const elapsedTime = Date.now() - parseInt(lastReloadTime, 10);
//     const RELOAD_TIMEOUT = 1 * 60 * 1000;
//     return elapsedTime < RELOAD_TIMEOUT;
//   }

//   markReloadTime() {
//     sessionStorage.setItem('lastReloadTime', Date.now().toString());
//   }

//   render() {
//     if (this.state.hasError) {
//       return <div>An unexpected error occurred. Please try again later.</div>;
//     }

//     return this.props.children;
//   }
// }

// ErrorBoundary.propTypes = {
//   children: PropTypes.node.isRequired
// };

// export default ErrorBoundary;
