import { Fragment, React, useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { FaWhatsapp } from 'react-icons/fa';

import { AuthWizardContext, StoreContext } from 'context/Context';
import {
  checkSessionActive,
  checkSessionActiveOrNot,
  generateUniqueId
} from './functions';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import MessagePopup from './MessagePopup';

function WhatsAppBotButton({ pdfUrl, pdfName, whatsAppNumberToPopulate }) {
  const { user } = useContext(AuthWizardContext);
  const { store, addIitemToStore } = useContext(StoreContext);
  let sessionActiveOrNot = store?.whatsAppSession ?? false;
  let userId = user?.id;
  const domain = window.location.host;
  let whatsAppSessionId = generateUniqueId(userId, domain);
  const Translate = useAxisproTranslate();
  const [openForm, setOpenForm] = useState(false);
  const [authenticated, setAuthenticated] = useState(sessionActiveOrNot);
  const [qrCode, setQrCode] = useState();
  const [restart, setRestart] = useState(false);
  const [modalClosingNotification, setModalClosingNotification] =
    useState(false);

  const handleOpenForm = () => {
    if (sessionActiveOrNot) {
      checkSessionActive(
        whatsAppSessionId,
        addIitemToStore,
        setAuthenticated,
        setQrCode,
        setModalClosingNotification,
        sessionActiveOrNot
      );
      setOpenForm(true);
    } else {
      checkSessionActiveOrNot(
        whatsAppSessionId,
        setAuthenticated,
        setQrCode,
        setModalClosingNotification,
        sessionActiveOrNot,
        addIitemToStore
      );
      setOpenForm(true);
    }
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setAuthenticated(sessionActiveOrNot);
    setQrCode();
  };

  const restartSession = () => {
    setQrCode();
    setModalClosingNotification(false);
    setRestart(true);
  };

  useEffect(() => {
    if (restart) {
      checkSessionActiveOrNot(
        whatsAppSessionId,
        setAuthenticated,
        setQrCode,
        setModalClosingNotification,
        sessionActiveOrNot,
        addIitemToStore
      );
      setRestart(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restart]);

  return (
    <Fragment>
      <Button
        size="sm"
        variant={'success'}
        title={Translate('Whatsapp Bot')}
        onClick={handleOpenForm}
        style={{ padding: '3px 10px' }}
      >
        <FaWhatsapp size={18} />
      </Button>
      <MessagePopup
        show={openForm}
        onHide={handleCloseForm}
        qrCode={qrCode}
        authenticated={authenticated}
        modalClosingNotification={modalClosingNotification}
        pdfUrl={pdfUrl}
        pdfName={pdfName}
        whatsAppSessionId={whatsAppSessionId}
        whatsAppNumberToPopulate={whatsAppNumberToPopulate}
        restartSession={restartSession}
      />
    </Fragment>
  );
}

WhatsAppBotButton.propTypes = {
  pdfUrl: PropTypes.string,
  pdfName: PropTypes.string,
  whatsAppNumberToPopulate: PropTypes.string
};

export default WhatsAppBotButton;
