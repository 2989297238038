import { React, useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import {
  Badge,
  ButtonGroup,
  Card,
  Col,
  Form,
  Modal,
  Row
} from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import PropTypes from 'prop-types';

import { BiDollar } from 'react-icons/bi';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { showToast } from '../Toast/toast';
import { CloseButton } from '../Buttons/ViewPageButtons';
import UnpaidSalesInvoicesLoading from '../Loading/UnpaidSalesInvoicesLoading';

function UnpaidTransactionsPopUp({
  show,
  onHide,
  setReferenceOrBarcode,
  setIsSelectedReference
}) {
  const Translate = useAxisproTranslate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [links, setLinks] = useState([]);

  const loadNextPage = useCallback(
    async next_page_url => {
      if (!next_page_url) return;
      setLoading(true);
      try {
        const res = await axios.get(next_page_url);
        setData(prevData => [...prevData, ...res.data.data.data]);
        setLinks(res.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [setData, setLinks, setLoading]
  );

  const fetchData = (page = 1) => {
    setLoading(true);
    axios
      .get('/sales/sales-invoice-reports', {
        params: {
          is_unpaid: true,
          page: page
        }
      })
      .then(res => {
        setData(res.data.data.data);
        setLinks(res);
        setLoading(false);
      })
      .catch(() => {
        showToast(
          `Something went wrong, please refresh the page and try again.`,
          'error'
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    if (show) {
      fetchData();
    }
  }, [show]);

  const handleChoose = item => {
    setReferenceOrBarcode(item.reference);
    setIsSelectedReference(true);
    onHide();
  };

  return (
    <>
      <Modal
        show={show}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        className="modal-with-overlay"
      >
        <Card>
          <Card.Header
            className={
              'border-bottom text-uppercase fs-1 text-dark d-flex align-items-center gap-1'
            }
          >
            <>
              <BiDollar />
              {Translate('Unpaid Transactions')}
              <ButtonGroup className="ms-auto d-flex gap-2 flex-row align-items-center dropdown-menu-style">
                <CloseButton onHide={onHide} />
              </ButtonGroup>
            </>
          </Card.Header>
          {data && data.length > 0 ? (
            <SimpleBar className="simplebar-style-with-pagination">
              <Card.Body className="attachments-styles d-flex gap-4 flex-wrap">
                {data.map((data, key) => (
                  <Card
                    key={key}
                    className={'attachments-card mt-2'}
                    onClick={() => handleChoose(data)}
                    style={{
                      height: '12rem',
                      width: '15rem',
                      boxShadow: '5px 8px 10px 8px rgba(0, 0, 0, 0.2)'
                    }}
                  >
                    <Card.Header>
                      <Row
                        className="d-flex justify-content-center m-0 p-0"
                        style={{ fontWeight: 'bold' }}
                      >
                        {data.reference}
                      </Row>
                      <Row className="d-flex justify-content-center m-0 p-0">
                        {data.customer_code} -
                        {data.customer_name.length > 9
                          ? data.customer_name.substring(0, 9) + '...'
                          : data.customer_name}
                      </Row>
                      <Row className="d-flex justify-content-center m-0 p-0">
                        {data.trans_date_formatted}
                      </Row>
                      <hr className="m-0 mt-1 p-0" />
                      <Row>
                        <Col xl={6} md={6} sm={12} className="pe-0 m-0 mt-1">
                          <Form.Label>{Translate('Amount')}</Form.Label>
                        </Col>
                        <Col
                          xl={6}
                          md={6}
                          sm={12}
                          className="ps-0 m-0 mt-1 text-end"
                        >
                          <Badge bg="success" style={{ fontSize: '12px' }}>
                            {data.amount_formatted}
                          </Badge>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl={6} md={6} sm={12} className="pe-0">
                          <Form.Label>{Translate('Paid')}</Form.Label>
                        </Col>
                        <Col xl={6} md={6} sm={12} className="ps-0 text-end">
                          <Badge bg="warning" style={{ fontSize: '12px' }}>
                            {data.alloc_formatted}
                          </Badge>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl={6} md={6} sm={12} className="pe-0">
                          <Form.Label>{Translate('Balance')}</Form.Label>
                        </Col>
                        <Col xl={6} md={6} sm={12} className="ps-0 text-end">
                          <Badge bg="danger" style={{ fontSize: '12px' }}>
                            {data.balance_amount_formatted}
                          </Badge>
                        </Col>
                      </Row>
                    </Card.Header>
                    <div
                      className="attachments-hover-styles"
                      style={{ cursor: 'pointer' }}
                    >
                      <div className="d-flex text-white btn btn-danger">
                        {Translate('Choose')}
                      </div>
                    </div>
                  </Card>
                ))}
                {links &&
                  links.meta &&
                  links.meta.current_page !== links.meta.last_page && (
                    <UnpaidSalesInvoicesLoading
                      loading={loading}
                      loadNextPage={loadNextPage}
                      links={links}
                    />
                  )}
              </Card.Body>
            </SimpleBar>
          ) : (
            <Card.Body
              className="d-flex align-items-center justify-content-center"
              style={{ height: '45rem' }}
            >
              <p>{Translate('No unpaid invoices')}</p>
            </Card.Body>
          )}
        </Card>
      </Modal>
    </>
  );
}

UnpaidTransactionsPopUp.propTypes = {
  onHide: PropTypes.func,
  show: PropTypes.bool,
  setReferenceOrBarcode: PropTypes.func,
  setIsSelectedReference: PropTypes.func
};

export default UnpaidTransactionsPopUp;
