import { React, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import LoadingScreenCover from 'components/common/loading-screen/LoadingScreenCover';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import PurchaseInvoiceTabView from 'module/Common/ViewPages/PurchaseInvoice/PurchaseInvoiceTabView';
import { useLocation } from 'react-router-dom';
import AfterSavePaymentModal from 'components/after-save/AfterSavePaymentModal';
function PurchaseInvoiceInfo({
  purchaseInvoiceId,
  offCanvas,
  setShowVoucherDetailArea,
  voidTransactionReport,
  getData,
  setEntriesData,
  interBranch
}) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const apiCallDelayTime = location?.state?.apiCallDelay ?? 0;
  const [afterSavePaymentModal, setAfterSavePaymentModal] = useState({
    show: false,
    data: ''
  });

  const fetchData = useCallback(
    async config => {
      let url = voidTransactionReport
        ? 'settings/void-transaction-details'
        : `purchase/purchase-invoice/${purchaseInvoiceId}`;
      await axios
        .get(url, {
          params: {
            trans_type: voidTransactionReport ? 'PI' : '',
            trans_no: voidTransactionReport ? purchaseInvoiceId : ''
          }
        })
        .then(res => {
          if (res.data.success) {
            const invoiceDetails = res?.data?.data;
            if (invoiceDetails?.id === purchaseInvoiceId) {
              if (
                config?.updateListItemData &&
                typeof setEntriesData === 'function'
              ) {
                setEntriesData(prev => ({
                  ...prev,
                  data: prev?.data?.map(item =>
                    item?.id === invoiceDetails?.id ? invoiceDetails : item
                  )
                }));
              }
              setData(invoiceDetails);
            }

            setLoading(false);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    [voidTransactionReport, purchaseInvoiceId, setEntriesData]
  );

  useEffect(() => {
    setLoading(true);
    const apiCallTime = setTimeout(() => {
      fetchData();
    }, apiCallDelayTime);
    return () => clearTimeout(apiCallTime);
  }, [purchaseInvoiceId, apiCallDelayTime, fetchData]);

  const handlePaymentSuccess = paymentData => {
    if (paymentData) {
      setAfterSavePaymentModal({
        show: true,
        data: paymentData
      });
    }
  };

  const afterSave = {
    mainData: 'Payment Completed!',
    printButton: {
      url: '/purchase/payment/print',
      pageTitle: 'Supplier Payment print view'
    },
    viewButton: {
      title: 'View Supplier Payment',
      url: '/finance/voucher-inquiry'
    }
  };

  return (
    <>
      {loading && data ? (
        <LoadingScreenCover>
          <LoadingScreen message="Looking for purchase invoice info" />
        </LoadingScreenCover>
      ) : (
        <PurchaseInvoiceTabView
          purchaseInvoiceData={data}
          loading={loading}
          creditFormatted={
            data && data.supplier && data.supplier.unused_credits_formatted
              ? data.supplier.unused_credits_formatted
              : ''
          }
          credit={
            data && data.supplier && data.supplier.unused_credits
              ? data.supplier.unused_credits
              : ''
          }
          fetchData={fetchData}
          setShowVoucherDetailArea={setShowVoucherDetailArea}
          offCanvas={offCanvas}
          voidTransactionReport={voidTransactionReport}
          getData={getData}
          isFixedAsset={Boolean(Number(data?.is_fixed_asset_invoice))}
          interBranch={interBranch}
          handlePaymentSuccess={handlePaymentSuccess}
        />
      )}
      <AfterSavePaymentModal
        show={afterSavePaymentModal?.show}
        onHide={() => {
          setAfterSavePaymentModal({
            show: false,
            data: ''
          });
        }}
        data={afterSavePaymentModal?.data}
        dataProcessing={loading}
        afterSave={afterSave}
      />
    </>
  );
}

PurchaseInvoiceInfo.propTypes = {
  purchaseInvoiceId: PropTypes.string,
  setShowVoucherDetailArea: PropTypes.func,
  offCanvas: PropTypes.bool,
  voidTransactionReport: PropTypes.bool,
  getData: PropTypes.func,
  setEntriesData: PropTypes.func,
  interBranch: PropTypes.bool
};

export default PurchaseInvoiceInfo;
