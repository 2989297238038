import { React, useContext, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

import AppContext, { AuthWizardContext } from 'context/Context';
import NavbarTop from 'components/navbar/top/NavbarTop';
import NavbarVertical from 'components/navbar/vertical/NavbarVertical';
import classNames from 'classnames';
import LockScreenForm from 'components/authentication/LockScreenForm';
import DeleteWarning from 'module/Common/Warnings/DeleteWarning';

const MainLayout = () => {
  const { hash, pathname } = useLocation();
  const isKanban = pathname.includes('kanban');

  const {
    config: { isFluid, navbarPosition }
  } = useContext(AppContext);

  const { user } = useContext(AuthWizardContext);

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, [hash]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <div className={isFluid ? 'container-fluid' : 'container'}>
        {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
          <NavbarVertical />
        )}
        <div
          className={classNames('content d-flex flex-column pb-0', {
            'pb-0': isKanban
          })}
        >
          <NavbarTop />
          {/*------ Main Routes ------*/}
          <Outlet />
          {/* {!isKanban && <Footer />} */}
        </div>
      </div>

      <Modal
        show={user && user.lockScreen}
        backdrop="static"
        keyboard={false}
        centered
        className="modal-with-overlay"
      >
        <Modal.Header>
          <Modal.Title>Session Out!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <small>Enter your password to login again.</small>
          <LockScreenForm className="mt-0" />
        </Modal.Body>
      </Modal>

      {/* delete window */}
      <DeleteWarning />
    </>
  );
};

export default MainLayout;
