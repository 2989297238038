import useAxisproTranslate from 'hooks/useAxisproTranslate';
import React, { useRef } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import {
  createSearchParams,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import LoadingScreenCover from 'components/common/loading-screen/LoadingScreenCover';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import Search from 'components/search/Search';
import UserPermissionUserLineItem from './components/UserPermissionUserLineItem';
import axios from 'axios';
import SystemAdminPermissionTreeview from 'components/system-admin-permission-treeview/SystemAdminPermissionTreeview';
import PermissionLineItemLoading from 'components/permission-line-item-loading/PermissionLineItemLoading';

export default function UserPermissions() {
  const Translate = useAxisproTranslate();
  const navigateTo = useNavigate();
  const [queryParams] = useSearchParams();
  const pageNumber = queryParams.get('page');
  const userId = queryParams.get('user_id');
  const userName = queryParams.get('user_name');
  const [isLoading, setIsLoading] = useState(true);
  const [isSearching, setIsSearching] = useState(true);
  const [users, setUsers] = useState([]);
  const [searchKey, setSearchKey] = useState('');
  const apiRequestAbortControllerRef = useRef(null);

  useEffect(() => {
    const getData = async (page = 1, name) => {
      apiRequestAbortControllerRef?.current?.abort();
      apiRequestAbortControllerRef.current = new AbortController();
      try {
        const response = await axios.get('user/users-autocomplete', {
          params: {
            page: page,
            ...(name ? { name: name } : null)
          },
          signal: apiRequestAbortControllerRef.current?.signal
        });

        const usersList = response?.data?.data;
        const firstUserData = usersList?.[0];
        if (firstUserData?.id) {
          setUsers(usersList);
          navigateTo(
            `/system-admin/users-permissions?${createSearchParams({
              user_id: firstUserData.id,
              user_name: `${firstUserData?.first_name} ${firstUserData?.last_name}`
            })}`
          );
        }
      } catch (error) {
        if (error?.name !== 'CanceledError') {
          console.error('Error fetching user autocomplete data:', error);
        } else {
          console.error('API request canceled');
        }
      } finally {
        setIsSearching(false);
        setIsLoading(false);
      }
    };

    setIsSearching(true);
    const apiCall = setTimeout(
      () => {
        getData(pageNumber, searchKey);
      },
      searchKey ? 1200 : 0
    );

    return () => {
      clearTimeout(apiCall);
      apiRequestAbortControllerRef?.current?.abort();
    };
  }, [navigateTo, pageNumber, searchKey]);

  return !isLoading ? (
    <Row className="flex-fill mb-3">
      <Col sm={12} md={3} className="d-flex pe-md-2 ps-3">
        <Card className="p-0 flex-fill mb-0">
          <Card.Header
            className="p-3 d-flex"
            style={{ backgroundColor: 'whitesmoke' }}
          >
            <span className="fs--1 fw-bold text-dark text-uppercase">
              {Translate('Users')}
            </span>
          </Card.Header>
          <>
            <Search
              placeholder="Search users by name | email"
              onChange={event => setSearchKey(event.target.value)}
            />
            <SimpleBar className="simplebar-style-list-page-without-pagination">
              {isSearching ? (
                <PermissionLineItemLoading rows={100} />
              ) : users?.length > 0 ? (
                users.map((item, index) => (
                  <UserPermissionUserLineItem
                    key={index}
                    item={item}
                    active={item.id === userId ? true : false}
                  />
                ))
              ) : (
                <h5 className="text-center text-secondary mt-3">
                  {Translate('User not found!')} &#128578;
                </h5>
              )}
            </SimpleBar>
          </>
        </Card>
      </Col>
      <Col sm={12} md={9} className="d-md-flex flex-column ps-sm-3 ps-md-0">
        <SystemAdminPermissionTreeview
          module="user"
          targetId={userId}
          targetName={userName}
        />
      </Col>
    </Row>
  ) : (
    <LoadingScreenCover className="mb-3">
      {isLoading ? (
        <LoadingScreen message={Translate('Looking for users')} />
      ) : (
        <h5 className="text-center text-secondary">
          {Translate('Users not found!')} &#128578;
        </h5>
      )}
    </LoadingScreenCover>
  );
}
