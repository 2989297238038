import React, { useCallback, useEffect, useState } from 'react';
import './ActivityLog.scss';
import axios from 'axios';
import PropTypes from 'prop-types';
import ActivityLogItem from './ActivityLogItem';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import WorkOrderTabDataInfiniteScrollHandler from 'module/Common/ViewPages/WorkOrder/components/WorkOrderTabDataInfiniteScrollHandler';
import { Alert, Spinner } from 'react-bootstrap';
import useTabViewContentReloadHandler from 'hooks/useTabViewContentReloadHandler';

export default function ActivityLog({
  subjectID,
  tabViewReloadHandlerID,
  activeTabKey,
  tabApiCallCompleted,
  setTabApiCallCompleted
}) {
  const Translate = useAxisproTranslate();
  const { isTabIncludes, removeTab } = useTabViewContentReloadHandler();
  const loadTabData = tabViewReloadHandlerID
    ? isTabIncludes(tabViewReloadHandlerID)
    : true;

  const [activityLogs, setActivityLogs] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(undefined);

  const fetchData = useCallback(
    async (page = 1) => {
      try {
        setIsLoading(true);
        const apiResponse = await axios.get('activity-log', {
          params: { page: page, subject_id: subjectID }
        });

        if (apiResponse?.data?.data) {
          setActivityLogs(prev =>
            page > 1 && prev?.data && Array.isArray(apiResponse.data.data?.data)
              ? {
                  ...(apiResponse.data.data ?? null),
                  data: [...prev.data, ...(apiResponse.data.data.data ?? null)]
                }
              : apiResponse.data.data
          );
        }
      } catch (error) {
        setErrorMessage(
          error?.response?.data?.message ??
            'Something went wrong, please refresh the page and try again.'
        );
        console.error(error);
        throw new Error('Error found');
      } finally {
        setIsLoading(false);
        typeof setTabApiCallCompleted === 'function' &&
          setTabApiCallCompleted(prev => ({
            ...prev,
            activityLog: true
          }));
      }
    },
    [
      subjectID,
      setActivityLogs,
      setIsLoading,
      setErrorMessage,
      setTabApiCallCompleted
    ]
  );

  useEffect(() => {
    if (
      !subjectID ||
      (activeTabKey && activeTabKey !== 'activityLog') ||
      (tabApiCallCompleted && !loadTabData) ||
      !loadTabData
    )
      return;
    if (loadTabData) removeTab([tabViewReloadHandlerID]);
    setActivityLogs(undefined);
    fetchData(1);
  }, [
    subjectID,
    tabViewReloadHandlerID,
    loadTabData,
    activeTabKey,
    tabApiCallCompleted,
    fetchData,
    removeTab
  ]);

  return (
    <div className="app-activity-log-container p-3 w-100">
      <div className="py-2 app-activity-log-title-area">
        <p className="text-dark fw-bold mb-0">{Translate('Activity Log')}</p>
        <small className="text-muted">
          {Translate('Below shows users activity log related to this subject')}
        </small>
      </div>

      {errorMessage ? (
        <Alert variant="danger">{Translate(errorMessage)}</Alert>
      ) : isLoading && isNaN(parseFloat(activityLogs?.to)) ? (
        <Alert className="d-flex flex-row align-items-center gap-2">
          <span>{Translate('Looking for activity log')}</span>
          <Spinner
            style={{
              width: 15,
              height: 15
            }}
          />
        </Alert>
      ) : (
        <div className="app-activity-log-items-cover">
          {Array.isArray(activityLogs?.data) &&
          activityLogs.data?.length > 0 ? (
            activityLogs.data.map((activity, index) => (
              <ActivityLogItem key={index} activity={activity} />
            ))
          ) : (
            <Alert className="m-0 border-0 text-center rounded-0">
              {Translate('No Results found!')}
            </Alert>
          )}
        </div>
      )}

      <WorkOrderTabDataInfiniteScrollHandler
        isLoading={isLoading}
        current_page={activityLogs?.current_page}
        last_page={activityLogs?.last_page}
        nextLink={activityLogs?.current_page + 1}
        loadNextPage={() => fetchData(activityLogs?.current_page + 1)}
      />
    </div>
  );
}

ActivityLog.propTypes = {
  subjectID: PropTypes.string,
  tabViewReloadHandlerID: PropTypes.string,
  activeTabKey: PropTypes.string,
  tabApiCallCompleted: PropTypes.bool,
  setTabApiCallCompleted: PropTypes.func
};
