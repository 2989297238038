import {
  OverlayTrigger,
  Tooltip,
  Button,
  Modal,
  Form,
  Table,
  Badge
} from 'react-bootstrap';
import React, { useCallback, useEffect, useState } from 'react';
import { FaFilter } from 'react-icons/fa';
import SelectCustomerGroup from 'components/form/SelectCustomerGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import PropTypes from 'prop-types';
import FormattedData from 'module/Common/TableFilter/FormatedData';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import AppPagination from 'components/common/app-pagination/AppPagination';
import {
  createSearchParams,
  useLocation,
  useSearchParams
} from 'react-router-dom';

const CustomerAdvancedSearch = ({ handleCustomer }) => {
  const Translate = useAxisproTranslate();
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [formData, setFormData] = useState({});
  const [queryParams, setSearchParams] = useSearchParams();
  const customer_page = queryParams.get('customer_page');
  const allQueryParams = Object.fromEntries([...queryParams]);
  const location = useLocation();

  const handleOpenModal = () => {
    setShow(true);
    queryParams.delete('customer_page');
    setSearchParams(queryParams);
  };

  const handleCloseModal = () => {
    setShow(false);
    queryParams.delete('customer_page');
    setSearchParams(queryParams);
  };

  const searchCustomerData = useCallback(async () => {
    try {
      const { data } = await axios.get('sales/customers', {
        params: {
          ...FormattedData({ ...formData, page: customer_page || 1 })
        }
      });
      setCustomerData(data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }, [formData, customer_page]);

  const handleCustomerSelect = items => {
    handleCustomer(
      {
        label: items.customer_id + '-' + items.name,
        value: items.id
      },
      {
        action: 'select-option',
        option: undefined,
        name: 'customer_id'
      }
    );
    handleCloseModal();
  };
  const handleFieldChange = (e, action) => {
    queryParams.delete('customer_page');
    setSearchParams(queryParams);
    if (action) {
      setFormData(prev => ({
        ...prev,
        [action.name]: e?.value
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [e.target.name]: e.target.value
      }));
    }
  };
  useEffect(() => {
    let timeoutId;
    if (show) {
      setIsLoading(true);
      timeoutId = setTimeout(() => {
        searchCustomerData();
      }, 400);
    }
    return () => {
      setCustomerData(undefined);
      clearTimeout(timeoutId);
    };
  }, [show, formData, customer_page, searchCustomerData]);

  return (
    <>
      <OverlayTrigger
        overlay={<Tooltip>{Translate('Advance search')}</Tooltip>}
      >
        <Button
          onClick={handleOpenModal}
          variant="transparent"
          size="sm"
          className="px-2 align-self-stretch d-flex justify-content-center align-items-center customer-advance-search-button-style"
        >
          <FaFilter color="#344050" size={10} />
        </Button>
      </OverlayTrigger>
      <Modal
        show={show}
        onHide={handleCloseModal}
        backdrop="static"
        size="lg"
        scrollable
        className="customer-advanced-filter-modal"
      >
        <Modal.Header closeButton className="m-0">
          <Modal.Title>
            {' '}
            <FaFilter size={12} className="me-2 text-warning" />
            {Translate('ADVANCE CUSTOMER FILTER')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-end py-3 pt-0">
            <div className="filter-input-width w-100">
              <div className="text-dark">{Translate('Customer Group')}</div>
              <SelectCustomerGroup
                name="customer_group_id"
                handleFieldChange={handleFieldChange}
                value={formData.customer_group_id_ref}
              />
            </div>
            <div className="ms-3 w-100">
              <div className="text-dark">
                {Translate('Search Customer Name')}
              </div>
              <div className="d-flex align-items-center filter-input-width w-100">
                <Form.Control
                  type="text"
                  name={'search'}
                  placeholder=""
                  className="ps-4"
                  value={formData?.search}
                  onChange={handleFieldChange}
                />
                <FontAwesomeIcon
                  icon="search"
                  className="position-absolute text-400 search-box-icon ms-2"
                />
              </div>
            </div>
          </div>
          <div style={{ maxHeight: '400px' }} className="main-table-content">
            <Table bordered hover>
              <thead className="text-dark text-uppercase bg-dark position-sticky top-0">
                <tr>
                  <th
                    style={{ fontSize: '11px' }}
                    className="text-center text-light p-2"
                  >
                    {Translate('Customer Id')}
                  </th>
                  <th
                    style={{ fontSize: '11px' }}
                    className="text-center text-light customer-name p-2 "
                  >
                    {Translate('Customer Name')}
                  </th>
                  <th
                    style={{ fontSize: '11px' }}
                    className="text-center text-light customer-name p-2"
                  >
                    {Translate('Customer Email')}
                  </th>
                  <th
                    style={{ fontSize: '11px' }}
                    className="text-center text-light customer-name p-2"
                  >
                    {Translate('Customer Phone')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {customerData?.data?.length > 0 ? (
                  customerData?.data.map((item, index) => (
                    <tr
                      key={index}
                      className="cursor-pointer"
                      onClick={() => handleCustomerSelect(item)}
                    >
                      <th className="fs--1 py-1 text-center">
                        {item.customer_id ? item.customer_id : '-'}
                      </th>
                      <td className="fs--1 py-1 text-center">
                        {item?.name ? item?.name : '-'}
                      </td>
                      <td className="fs--1 py-1 text-center">
                        {item?.email ? item?.email : '-'}
                      </td>
                      <td className="fs--1 py-1 text-center">
                        {item?.phone ? item?.phone : '-'}
                      </td>
                    </tr>
                  ))
                ) : !isLoading && customerData?.length < 1 ? (
                  <tr>
                    <td colSpan={5}>
                      <Badge bg="danger">{Translate('No results found')}</Badge>
                    </td>
                  </tr>
                ) : null}
              </tbody>
              {isLoading && (
                <tr>
                  <td style={{ height: '400px' }} colSpan={4}>
                    <div className="d-flex flex-column align-items-center">
                      <LoadingScreen message="Looking for customers" />
                    </div>
                  </td>
                </tr>
              )}
            </Table>
            <div
              style={{ backgroundColor: '#fff' }}
              className="border-top pt-3 position-sticky bottom-0"
            >
              {!isLoading && customerData && customerData?.links && (
                <AppPagination
                  data={customerData}
                  url={`${location.pathname}?${createSearchParams({
                    ...allQueryParams,
                    customer_page: ''
                  })}`}
                />
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
CustomerAdvancedSearch.propTypes = {
  handleCustomer: PropTypes.func
};
export default CustomerAdvancedSearch;
