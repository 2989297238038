import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Collapse } from 'react-bootstrap';

const TreeviewListItem = ({
  item,
  openedItems,
  setOpenedItems,
  selectedItems,
  setSelectedItems,
  selection,
  onTogglePermission,
  searchKey
}) => {
  const [open, setOpen] = useState(false);
  const [children, setChildren] = useState([]);
  const [firstChildren, setFirstChildren] = useState([]);
  const [childrenOpen, setChildrenOpen] = useState(false);
  const checkRef = useRef();
  function setItemName(name, searchKey) {
    return searchKey?.length > 0
      ? name.replace(new RegExp(searchKey, 'gi'), '<mark>$&</mark>')
      : item?.name;
  }
  const getChildrens = item => {
    function flatInnter(item) {
      let flat = [];
      item.map(child => {
        if (child.children) {
          flat = [...flat, child.id, ...flatInnter(child.children)];
        } else {
          flat = [...flat, child.id];
        }
      });

      return flat;
    }
    if (item.children) {
      return flatInnter(item.children);
    } else {
      return [];
    }
  };

  const handleOnExiting = () => {
    setOpenedItems(openedItems.filter(openedItem => openedItem !== item.id));
  };
  const handleEntering = () => {
    setOpenedItems([...openedItems, item.id]);
  };
  const handleSingleCheckboxChange = e => {
    if (e.target.checked) {
      setSelectedItems([...selectedItems, item.id]);
    } else {
      setSelectedItems(
        selectedItems.filter(selectedItem => selectedItem !== item.id)
      );
    }
  };
  const handleParentCheckboxChange = e => {
    const filteredItems = selectedItems.filter(
      selectedItem =>
        children.indexOf(selectedItem) === -1 && selectedItem !== item.id
    );
    if (e.target.checked) {
      setSelectedItems([...filteredItems, item.id, ...children]);
    } else {
      setSelectedItems(filteredItems);
    }
  };
  const handleToggle = event => {
    event.preventDefault();
    setOpen(!open);
    if (typeof onTogglePermission === 'function') onTogglePermission(item);
  };

  const stringifiedItem = JSON.stringify(item);
  const stringifiedChildren = JSON.stringify(children);

  useEffect(() => {
    const parsedItem = JSON.parse(stringifiedItem);
    setChildren(getChildrens(parsedItem));
    if (parsedItem.children) {
      setFirstChildren(parsedItem.children.map(child => child.id));
    }
  }, [stringifiedItem]);

  useEffect(() => {
    const isItemOpen = () => {
      return openedItems.indexOf(item.id) !== -1;
    };
    const isChildrenOpen = () => {
      return openedItems.some(item => firstChildren.indexOf(item) !== -1);
    };

    setChildrenOpen(isChildrenOpen());
    setOpen(isItemOpen());
  }, [children, firstChildren, item.id, openedItems]);

  useEffect(() => {
    const parsedChildren = JSON.parse(stringifiedChildren);
    const childrenSelected = selectedItems.some(
      selectedItem => parsedChildren.indexOf(selectedItem) !== -1
    );
    const allChildrenSelected = parsedChildren.every(
      child => selectedItems.indexOf(child) !== -1
    );

    if (checkRef.current) {
      checkRef.current.checked = true;
    }
    if (childrenSelected && checkRef.current) {
      checkRef.current.indeterminate = true;
    }
    if (!childrenSelected && checkRef.current) {
      checkRef.current.indeterminate = false;
    }
    if (allChildrenSelected && checkRef.current) {
      checkRef.current.indeterminate = false;
    }
    if (!allChildrenSelected && checkRef.current) {
      checkRef.current.checked = false;
    }
  }, [selectedItems, stringifiedChildren]);
  return !item?.hide ? (
    <li className="treeview-list-item">
      {Object.prototype.hasOwnProperty.call(item, 'children') ? (
        <>
          <div
            className={classNames('toggle-container', {
              'tree-item-has-no-childrens':
                !Array.isArray(item.children) ||
                item.children.filter(
                  childrenItem => childrenItem?.hide !== true
                ).length < 1
            })}
          >
            {selection && (
              <input
                type="checkbox"
                className="form-check-input"
                onChange={handleParentCheckboxChange}
                ref={checkRef}
              />
            )}
            <a
              className={classNames('collapse-toggle', {
                collapsed: open || item.expanded
              })}
              href="#!"
              onClick={handleToggle}
            >
              <p
                className={classNames('treeview-text', {
                  'ms-2': !selection,
                  'tree-item-with-custome-icon':
                    item?.icon && item.icon !== 'folder'
                })}
              >
                {item?.icon && item.icon !== 'folder' ? (
                  <FontAwesomeIcon
                    icon={item.icon}
                    className={classNames('me-2', item.iconClass)}
                  />
                ) : null}
                <span
                  dangerouslySetInnerHTML={{
                    __html: setItemName(item.name, searchKey)
                  }}
                ></span>
              </p>
            </a>
          </div>
          <Collapse
            in={open}
            onExiting={handleOnExiting}
            onEntering={handleEntering}
          >
            <ul
              className={classNames('treeview-list', {
                'collapse-hidden': !open,
                'collapse-show treeview-border': open,
                'treeview-border-transparent': childrenOpen
              })}
            >
              {item.children.map((nestedItem, index) => (
                <TreeviewListItem
                  key={index}
                  item={nestedItem}
                  index={index}
                  openedItems={openedItems}
                  setOpenedItems={setOpenedItems}
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  selection={selection}
                  onTogglePermission={onTogglePermission}
                  searchKey={searchKey}
                />
              ))}
            </ul>
          </Collapse>
        </>
      ) : (
        <div className="treeview-item">
          {selection && (
            <input
              type="checkbox"
              className="form-check-input"
              onChange={handleSingleCheckboxChange}
              checked={selectedItems.indexOf(item.id) !== -1}
            />
          )}
          <a
            href="#!"
            className="flex-1"
            onClick={
              typeof onTogglePermission === 'function'
                ? () => onTogglePermission(item)
                : undefined
            }
          >
            <p className="treeview-text">
              <FontAwesomeIcon
                icon={item.icon}
                className={classNames('me-2', item.iconClass)}
              />
              <span
                dangerouslySetInnerHTML={{
                  __html: setItemName(item.name, searchKey)
                }}
              ></span>
            </p>
          </a>
        </div>
      )}
    </li>
  ) : null;
};

const Treeview = ({
  data,
  selection,
  openedItems = [],
  setOpenedItems,
  selectedItems = [],
  setSelectedItems,
  onTogglePermission,
  searchKey
}) => {
  return (
    <ul className="treeview treeview-select">
      {data.map((treeviewItem, index) => (
        <TreeviewListItem
          key={index}
          item={treeviewItem}
          openedItems={openedItems}
          setOpenedItems={setOpenedItems}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          selection={selection}
          onTogglePermission={onTogglePermission}
          searchKey={searchKey}
        />
      ))}
    </ul>
  );
};

TreeviewListItem.propTypes = {
  item: PropTypes.object,
  openedItems: PropTypes.array,
  setOpenedItems: PropTypes.func,
  selectedItems: PropTypes.array,
  setSelectedItems: PropTypes.func,
  selection: PropTypes.bool,
  onTogglePermission: PropTypes.func,
  searchKey: PropTypes.string
};

Treeview.propTypes = {
  data: PropTypes.array.isRequired,
  selection: PropTypes.bool, // If true selection is enabled.
  openedItems: PropTypes.array, // Default expanded children ids.
  setOpenedItems: PropTypes.func, // Setter to Default expanded.
  selectedItems: PropTypes.array, // Selected item ids..
  setSelectedItems: PropTypes.func, // Setter to select items,
  onTogglePermission: PropTypes.func,
  searchKey: PropTypes.string
};

export default Treeview;
