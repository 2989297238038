import { React, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Card, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { BiRepeat } from 'react-icons/bi';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { AuthWizardContext, StoreContext } from 'context/Context';
import { showToast } from 'module/Common/Toast/toast';
import { CancelButton, SaveButton } from 'module/Common/Buttons/CommonButton';
import AppDatePicker from 'components/app-date-picker/AppDatePicker';
import generateFormDataHelper from 'helpers/generateFormDataHelper';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';
import ErrorAlert from 'module/Common/Error/ErrorAlert';
import IssueItemPopUpTable from './IssueItemPopUpTable';
import setNewFormData from 'helpers/setNewFormData';
import SelectWarehouse from 'components/form/SelectWarehouse';
import removeRefData from 'helpers/removeRefData';

function IssueItemPopUp({
  show,
  data,
  onHide,
  setIssuedItem,
  successCallBack,
  isMaterialRequest
}) {
  const { store } = useContext(StoreContext);
  const { user } = useContext(AuthWizardContext);
  const userBranchData = user?.branch;
  const Translate = useAxisproTranslate();
  const [formData, setFormData] = useState({});
  const [formError, setFormError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [onSave, setOnSave] = useState(false);

  const handleFieldChange = (e, action) => {
    setFormData(generateFormDataHelper(e, action, formData));
  };

  const handleSubmit = e => {
    e.preventDefault();
    const dataWithoutRef = {
      ...removeRefData(formData, 'object'),
      items: removeRefData(formData.items, 'array')
    };
    const newFormData = setNewFormData(dataWithoutRef);

    setOnSave(true);
    axios({
      method: 'post',
      url: isMaterialRequest ? '/material-request-issue-items' : '/issue-items',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: newFormData
    })
      .then(response => {
        if (response.data.success === true) {
          showToast(response.data.message, 'success');
          typeof setIssuedItem === 'function' && setIssuedItem(true);
          setFormError({});
          setFormData({
            ...formData,
            warehouse_id_ref: '',
            warehouse_id: ''
          });
          typeof onHide === 'function' && onHide();
          typeof successCallBack === 'function' &&
            successCallBack({
              ...response,
              targetId: data?.id,
              issuedItems: formData?.items
            });
        } else {
          showToast(
            'Something went wrong, please refresh the page and try again.',
            'error'
          );
        }
      })
      .catch(error => {
        showToast(
          error?.response?.data?.message ??
            'Something went wrong, please refresh the page and try again.',
          'error'
        );
        const validation_error = error?.response?.data?.data?.errors ?? null;
        validation_error && setFormError({ ...validation_error });
      })
      .finally(() => setOnSave(false));
  };

  const stringifiedData = JSON.stringify(
    typeof data === 'object' ? data : null
  );

  useEffect(() => {
    const parsedData = JSON.parse(stringifiedData);
    if (parsedData) {
      setFormData(prevData => ({
        ...prevData,
        trans_date: store.currentDate,
        warehouse_id: userBranchData.inventory_settings.default_warehouse_id,
        warehouse_id_ref: {
          label: userBranchData.inventory_settings?.default_warehouse_name,
          value: userBranchData.inventory_settings.default_warehouse_id
        },
        items: parsedData?.details?.length
          ? parsedData.details?.map(item => {
              const issuableQuantity =
                parseFloat(item?.max_issuable_quantity) || 0;

              return {
                max_issuable_quantity: issuableQuantity,
                request_detail_id: item.id,
                stock_id: item.stock_id,
                requested_quantity: item.quantity,
                issued_quantity:
                  item.quantity_issued === '' ? 0 : item.quantity_issued,
                item_display_name: item.item_display_name,
                quantity:
                  item.item.qoh > issuableQuantity
                    ? issuableQuantity
                    : item.item.qoh <= 0
                    ? 0
                    : item.item.qoh,
                qoh: item.item.qoh,
                ...(parsedData?.work_order
                  ? {}
                  : {
                      account_code: item?.item?.purchase_account_code,
                      account_name_ref:
                        item?.item?.purchase_account_code +
                        ' - ' +
                        item?.item?.purchase_account_name
                    }),
                ...(parsedData?.work_order
                  ? {
                      account_name_ref:
                        parsedData.work_order_detail?.item?.wip_account_code
                          ?.length > 0
                          ? parsedData.work_order_detail.item.wip_account_code +
                            ' - ' +
                            parsedData?.work_order_detail?.item
                              ?.wip_account_name
                          : parsedData.work_order_detail?.item
                              ?.category_wip_account_code?.length > 0
                          ? parsedData.work_order_detail.item
                              .category_wip_account_code +
                            ' - ' +
                            parsedData?.work_order_detail?.item
                              ?.category_wip_account_name
                          : '-'
                    }
                  : {})
              };
            })
          : [],
        [isMaterialRequest ? 'material_request_id' : 'purchase_request_id']:
          parsedData.id
      }));
    }
  }, [
    stringifiedData,
    isMaterialRequest,
    store.currentDate,
    userBranchData.inventory_settings.default_warehouse_id,
    userBranchData.inventory_settings?.default_warehouse_name
  ]);

  useEffect(() => {
    const parsedData = JSON.parse(stringifiedData);
    const QOHDependsOnWareHouse = async () => {
      let WarehouseQoh;

      const stockIdArray = [
        ...(Array.isArray(parsedData?.details)
          ? parsedData.details.map(item => item?.stock_id)
          : [])
      ];
      setIsLoading(true);
      try {
        const { data } = await axios.get(
          `inventory/items/${formData?.warehouse_id}/qoh`,
          {
            params: {
              stock_id: stockIdArray
            }
          }
        );
        WarehouseQoh = data.data;
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
      setFormData(prev => ({
        ...prev,
        items: parsedData?.details?.map(item => {
          const foundItem = WarehouseQoh.find(
            ware => ware.item.item_id === item.item_id
          );
          const issuableQuantity = parseFloat(item?.max_issuable_quantity) || 0;
          return {
            max_issuable_quantity: issuableQuantity,
            request_detail_id: item.id,
            stock_id: item.stock_id,
            requested_quantity: item.quantity,
            issued_quantity:
              item.quantity_issued == '' ? 0 : item.quantity_issued,
            item_display_name: item.item_display_name,
            quantity:
              issuableQuantity < foundItem?.qoh
                ? issuableQuantity
                : foundItem?.qoh ?? 0,
            account_code: parsedData?.work_order
              ? ''
              : item?.item?.purchase_account_code,
            account_name_ref: parsedData?.work_order
              ? parsedData?.work_order_detail?.item?.wip_account_code?.length >
                0
                ? parsedData.work_order_detail.item.wip_account_code +
                  ' - ' +
                  parsedData?.work_order_detail?.item?.wip_account_name
                : parsedData?.work_order_detail?.item?.category_wip_account_code
                    ?.length > 0
                ? parsedData.work_order_detail.item.category_wip_account_code +
                  ' - ' +
                  parsedData?.work_order_detail?.item?.category_wip_account_name
                : '-'
              : item?.item?.purchase_account_code +
                ' - ' +
                item?.item?.purchase_account_name,
            qoh: foundItem?.qoh ?? '0'
            // (
            //   item?.item?.warehouse_wise_qoh?.length > 0 &&
            //   WarehouseQoh.find(wh => wh.location_id == formData?.warehouse_id)
            // )?.qoh || '0'
          };
        })
      }));
    };

    if (formData?.warehouse_id && parsedData) {
      QOHDependsOnWareHouse();
    }
  }, [stringifiedData, formData?.warehouse_id]);

  return (
    <Modal show={show} size="xl" className="modal-with-overlay">
      <Card>
        <Card.Header className="border-bottom text-uppercase fs-1 text-dark d-flex align-items-center gap-1">
          <BiRepeat className="text-dark" size={20} />
          {Translate('Issue Items')}
        </Card.Header>
        <Card.Body>
          {!Object.keys(formError).length == 0 ? (
            <ErrorAlert setFormError={setFormError} formError={formError} />
          ) : (
            ''
          )}
          <Container>
            <Row>
              <Form.Group
                as={Col}
                xxl={12}
                xl={12}
                md={12}
                sm={12}
                className="mb-3"
              >
                <Row className="ms-3">
                  <Col
                    xl={4}
                    md={6}
                    sm={12}
                    className="p-0 text-start pe-4 d-flex"
                  >
                    <Form.Label className="require-data text-dark m-0 me-3">
                      {Translate('Date')}
                    </Form.Label>
                    <AppDatePicker
                      name="start_date"
                      value={formData.trans_date}
                      yearPlaceholder="yyyy"
                      monthPlaceholder="mm"
                      dayPlaceholder="dd"
                      onChange={handleFieldChange}
                    />
                    {formError.start_date ? (
                      <FormErrorPopover
                        id="recurring_start_date"
                        totalErrorCount={Object.keys(formError).length}
                        errorMessage={formError.start_date}
                      />
                    ) : (
                      ''
                    )}
                  </Col>
                  <Col
                    xl={4}
                    md={6}
                    sm={12}
                    className="p-0 text-start ms-4 d-flex"
                  >
                    <Form.Label className="require-data text-dark m-0  me-3">
                      {Translate('Warehouse')}
                    </Form.Label>
                    <div className="w-100">
                      <SelectWarehouse
                        name="warehouse_id"
                        value={formData.warehouse_id_ref}
                        onChange={handleFieldChange}
                      />
                    </div>
                    {formError.start_date ? (
                      <FormErrorPopover
                        id="recurring_start_date"
                        totalErrorCount={Object.keys(formError).length}
                        errorMessage={formError.start_date}
                      />
                    ) : (
                      ''
                    )}
                  </Col>
                  <Col xl={6} md={6} sm={12} className="ps-0">
                    <Row className="d-flex flex-row">
                      <Col
                        xl={11}
                        md={11}
                        sm={11}
                        className="pe-sm-0 flex-2"
                      ></Col>
                      <Col
                        xl={1}
                        md={1}
                        sm={1}
                        className={
                          'text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1'
                        }
                      ></Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Group>
            </Row>
            <IssueItemPopUpTable
              entries={formData.items}
              setFormData={setFormData}
              formError={formError}
              setFormError={setFormError}
              formData={formData}
              isLoading={isLoading}
            />
          </Container>
        </Card.Body>
        <Card.Footer className="d-flex justify-content-end border-top gap-1 mt-5">
          <CancelButton onSave={onSave} handleCancel={onHide} />
          <SaveButton
            onSave={onSave}
            handleSubmit={handleSubmit}
            createPermission={true}
            editPermission={true}
          />
        </Card.Footer>
      </Card>
    </Modal>
  );
}

IssueItemPopUp.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  data: PropTypes.any,
  setIssuedItem: PropTypes.func,
  successCallBack: PropTypes.func,
  isMaterialRequest: PropTypes.bool
};

export default IssueItemPopUp;
