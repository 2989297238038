import { React, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const DownKeyController = ({
  url,
  data,
  currentData,
  pageNumber,
  lastPage,
  entry
}) => {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const filterQueryParam = queryParams.get('filters');
  const stringifiedData = JSON.stringify(data);

  const handleKeyDown = useCallback(
    event => {
      const data = JSON.parse(stringifiedData);
      const foundObject = data.find(item => item.id === currentData);
      const index = data.indexOf(foundObject);

      const buildUrl = (nextItemId, page, additionalParams = '') =>
        `${url}${
          entry
            ? `?entry=${nextItemId}&page=${page}&layout=column${additionalParams}`
            : `${nextItemId}?page=${page}${additionalParams}`
        }`;

      const additionalParams = filterQueryParam
        ? `&filters=${encodeURIComponent(filterQueryParam)}`
        : '';

      switch (event.key) {
        case 'ArrowDown': {
          event.preventDefault();
          const nextItem = data[index + 1];
          if (nextItem) {
            const newUrl = pageNumber
              ? buildUrl(nextItem.id, pageNumber, additionalParams)
              : buildUrl(nextItem.id, '', additionalParams);
            navigate(newUrl);
          }
          break;
        }

        case 'ArrowUp': {
          event.preventDefault();
          const nextItem = data[index - 1];
          if (nextItem) {
            const newUrl = pageNumber
              ? buildUrl(nextItem.id, pageNumber, additionalParams)
              : buildUrl(nextItem.id, '', additionalParams);
            navigate(newUrl);
          }
          break;
        }

        case 'ArrowRight': {
          event.preventDefault();
          if (
            parseInt(pageNumber) + 1 <= parseInt(lastPage) &&
            parseInt(lastPage) !== 1
          ) {
            const newPage = parseInt(pageNumber) + 1;
            const newUrl = buildUrl('', newPage, additionalParams);
            navigate(newUrl);
          }
          break;
        }

        case 'ArrowLeft': {
          event.preventDefault();
          if (parseInt(pageNumber) > 1) {
            const newPage = parseInt(pageNumber) - 1;
            const newUrl = buildUrl('', newPage, additionalParams);
            navigate(newUrl);
          } else if (parseInt(pageNumber) === 1) {
            const newUrl = buildUrl('', 1, additionalParams);
            navigate(newUrl);
          }
          break;
        }

        default:
          break;
      }
    },
    [
      currentData,
      stringifiedData,
      entry,
      filterQueryParam,
      lastPage,
      navigate,
      pageNumber,
      url
    ]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return <div></div>;
};
DownKeyController.propTypes = {
  url: PropTypes.string,
  data: PropTypes.any,
  currentData: PropTypes.string,
  pageNumber: PropTypes.any,
  lastPage: PropTypes.any,
  entry: PropTypes.bool
};
export default DownKeyController;
