import { React, useEffect, useRef, useState } from 'react';
import { Card, Col, Placeholder, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

function UnpaidSalesInvoicesLoading({ loadNextPage, links }) {
  const [nextPage, setNextPage] = useState();

  const componentRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0
    };
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        loadNextPage(nextPage);
      }
    }, options);
    observer.observe(componentRef.current);
    return () => {
      observer.disconnect();
    };
  }, [loadNextPage, nextPage]);

  useEffect(() => {
    if (links !== '' && links.links) {
      setNextPage(links.links.next);
    }
  }, [links]);

  return (
    <>
      <Col sm={4} ref={componentRef}>
        <Card
          className="attachments-card m-2"
          style={{
            height: '13rem',
            width: '15rem',
            boxShadow: '5px 8px 10px 8px rgba(0, 0, 0, 0.2)'
          }}
        >
          <Card.Header className=" ">
            <Row>
              <Placeholder as="p" animation="glow" className="m-0">
                <Placeholder
                  className="w-100"
                  size="md"
                  bg="secondary"
                  style={{ opacity: '0.01' }}
                />
              </Placeholder>
            </Row>
            <Row>
              <Placeholder as="p" animation="glow" className="m-0">
                <Placeholder
                  className="w-100"
                  size="md"
                  bg="secondary"
                  style={{ opacity: '0.01' }}
                />
              </Placeholder>
            </Row>
            <Row>
              <Placeholder as="p" animation="glow" className="m-0">
                <Placeholder
                  className="w-100"
                  size="md"
                  bg="secondary"
                  style={{ opacity: '0.01' }}
                />
              </Placeholder>
            </Row>
            <Row>
              <Placeholder as="p" animation="glow" className="m-0">
                <Placeholder
                  className="w-100"
                  size="md"
                  bg="secondary"
                  style={{ opacity: '0.01' }}
                />
              </Placeholder>
            </Row>
            <Row>
              <Placeholder as="p" animation="glow" className="m-0">
                <Placeholder
                  className="w-100"
                  size="md"
                  bg="secondary"
                  style={{ opacity: '0.01' }}
                />
              </Placeholder>
            </Row>
          </Card.Header>
        </Card>
      </Col>
    </>
  );
}

UnpaidSalesInvoicesLoading.propTypes = {
  links: PropTypes.any,
  loadNextPage: PropTypes.func
};

export default UnpaidSalesInvoicesLoading;
