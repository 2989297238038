import useAxisproTranslate from 'hooks/useAxisproTranslate';
import React, { useRef } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import {
  createSearchParams,
  Navigate,
  useSearchParams
} from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import LoadingScreenCover from 'components/common/loading-screen/LoadingScreenCover';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import Search from 'components/search/Search';
import RolePermissionRoleLineItem from './components/RolePermissionRoleLineItem';
import axios from 'axios';
import SystemAdminPermissionTreeview from 'components/system-admin-permission-treeview/SystemAdminPermissionTreeview';
import PermissionLineItemLoading from 'components/permission-line-item-loading/PermissionLineItemLoading';

export default function RolePermissions() {
  const Translate = useAxisproTranslate();

  const [queryParams] = useSearchParams();
  const roleId = queryParams.get('role_id');
  const roleName = queryParams.get('role_name');
  const pageNumber = queryParams.get('page');
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [roles, setRoles] = useState([]);
  const [searchKey, setSearchKey] = useState('');
  const apiRequestAbortControllerRef = useRef(null);

  useEffect(() => {
    const getData = async (page = 1, name) => {
      apiRequestAbortControllerRef?.current?.abort();
      apiRequestAbortControllerRef.current = new AbortController();

      try {
        const response = await axios.get('roles-autocomplete', {
          params: {
            page: page,
            ...(name ? { name: name } : null)
          },
          signal: apiRequestAbortControllerRef.current.signal
        });

        const rolesList = response?.data?.data;
        if (rolesList?.[0]?.id) {
          setRoles(rolesList);
        }
      } catch (error) {
        if (error.name !== 'CanceledError') {
          console.error('Error fetching roles:', error);
        } else {
          console.error('API request canceled');
        }
      } finally {
        setIsSearching(false);
        setIsLoading(false);
      }
    };

    setIsSearching(true);
    const apiCall = setTimeout(
      () => {
        getData(pageNumber, searchKey);
      },
      searchKey ? 1200 : 0
    );

    return () => {
      clearTimeout(apiCall);
      apiRequestAbortControllerRef?.current?.abort();
    };
  }, [pageNumber, searchKey, apiRequestAbortControllerRef]);

  return !isLoading ? (
    <>
      {!roleId && roles?.length > 0 ? (
        <Navigate
          to={`/system-admin/roles-permissions?${createSearchParams({
            role_id: roles[0]?.id,
            role_name: roles[0]?.name
          })}`}
          replace
        />
      ) : (
        <Row className="flex-fill mb-3">
          <Col sm={12} md={3} className="d-flex pe-md-2 ps-3">
            <Card className="p-0 flex-fill mb-0">
              <Card.Header
                className="p-3 d-flex"
                style={{ backgroundColor: 'whitesmoke' }}
              >
                <span className="fs--1 fw-bold text-dark text-uppercase">
                  {Translate('Roles')}
                </span>
              </Card.Header>
              <Card>
                <Search
                  placeholder="Search role by name"
                  onChange={event => setSearchKey(event.target.value)}
                />
                <SimpleBar className="simplebar-style-list-page-without-pagination">
                  {isSearching ? (
                    <PermissionLineItemLoading rows={100} />
                  ) : roles?.length > 0 ? (
                    roles.map((item, index) => (
                      <RolePermissionRoleLineItem
                        key={index}
                        item={item}
                        active={item.id === roleId ? true : false}
                      />
                    ))
                  ) : (
                    <h5 className="text-center text-secondary mt-3">
                      {Translate('Role items not found!')} &#128578;
                    </h5>
                  )}
                </SimpleBar>
              </Card>
            </Card>
          </Col>
          <Col sm={12} md={9} className="d-md-flex flex-column ps-sm-3 ps-md-0">
            <SystemAdminPermissionTreeview
              module="role"
              targetId={roleId}
              targetName={roleName}
            />
          </Col>
        </Row>
      )}
    </>
  ) : (
    <LoadingScreenCover className="mb-3">
      {isLoading ? (
        <LoadingScreen message={Translate('Looking for roles')} />
      ) : (
        <h5 className="text-center text-secondary">
          {Translate('Role items not found!')} &#128578;
        </h5>
      )}
    </LoadingScreenCover>
  );
}
