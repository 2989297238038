import React from 'react';
import { Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import FixedItemTabViewDetails from 'components/fixed-item-details/FixedItemTabViewDetails';
const AssetItemTabView = ({ rentalContractData, fromEmployeeAllocation }) => {
  return (
    <Container className="mt-3 m-0 ps-3 " style={{ height: '50vh' }}>
      <FixedItemTabViewDetails
        fixedAssetType={
          fromEmployeeAllocation
            ? rentalContractData?.fixed_asset_type
            : rentalContractData?.details?.[0]?.item?.fixed_asset_type
        }
        data={
          fromEmployeeAllocation
            ? rentalContractData
            : rentalContractData?.details?.[0]?.item
        }
        columnClassName="flex-reverse"
        flexColumn
      />
    </Container>
  );
};
AssetItemTabView.propTypes = {
  rentalContractData: PropTypes.object,
  fromEmployeeAllocation: PropTypes.bool
};
export default AssetItemTabView;
