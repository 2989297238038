import { React } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { Button, Col, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { FaCalendar } from 'react-icons/fa';
import { MdOutlineTransform } from 'react-icons/md';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import SelectAccountDescriptions from 'components/form/SelectAccountDescriptions';
import SelectCostCenter from 'components/form/SelectCostCenter';
import generateFormDataHelper from 'helpers/generateFormDataHelper';
import SelectTransactionTypes from 'components/form/SelectTransactionTypes';
import SelectSubLedger from 'components/form/SelectSubLedger';
import removeRefData from 'helpers/removeRefData';
import DateRangePicker from 'module/Common/TableFilter/Components/DateRangePicker';

const LedgerReportForm = ({
  setFormData,
  formData,
  setShowReport,
  sm,
  md,
  xl,
  fullWidth,
  hideHeader,
  accountCode,
  onSubmit
}) => {
  const Translate = useAxisproTranslate();
  let navigate = useNavigate();
  const handleFieldChange = (e, action) => {
    setFormData(generateFormDataHelper(e, action, formData));
    if (action.name === 'account_code') {
      setFormData(prev => ({
        ...prev,
        sub_ledger_code: '',
        sub_ledger_code_ref: ''
      }));
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (onSubmit) {
      if (typeof onSubmit === 'function') onSubmit();
      return;
    }
    setShowReport(true);
    if (formData.account_code) {
      const newFormData = removeRefData(formData);
      navigate({
        pathname: '/reports/ledger-report',
        search: `?${createSearchParams({
          ...newFormData,
          show_report: true,
          account_code_ref: formData.account_code_ref
            ? JSON.stringify(formData.account_code_ref)
            : '',
          cost_center_id_ref: formData.cost_center_id_ref
            ? JSON.stringify(formData.cost_center_id_ref)
            : '',
          date_range: newFormData.date_range,
          cost_center_id: newFormData.cost_center_id ?? '',
          sub_ledger_code: newFormData.sub_ledger_code ?? '',
          sub_ledger_code_ref: formData.sub_ledger_code_ref
            ? JSON.stringify(formData.sub_ledger_code_ref)
            : '',
          date_from: newFormData.date_from ?? '',
          date_to: newFormData.date_to ?? ''
        })}`
      });
    }
  };
  return (
    <Form
      style={!fullWidth ? { width: '100%', maxWidth: 300 } : undefined}
      onSubmit={handleSubmit}
    >
      {!hideHeader ? (
        <div className={'mt-5'}>
          <div className="text-center mb-2 mt-3 p-3">
            <h4 className="text-dark mb-3">
              {Translate('Direct Axis Technology LLC')}
            </h4>
            <h4 className="mb-2 fs-1 text-uppercase text-primary">
              {Translate('Ledger Report')}
            </h4>
          </div>
        </div>
      ) : null}

      <Row sm={sm ?? 1} md={md ?? 1} xl={xl ?? 1}>
        <Form.Group className="mb-3" as={Col}>
          <Form.Label>{Translate('Date Rage')}</Form.Label>
          <DateRangePicker
            formData={formData}
            setFormData={setFormData}
            selectThisMonth
            dateRange_form={{
              to: 'date_to',
              from: 'date_from',
              range: 'date_range',
              month: 'month'
            }}
          />
        </Form.Group>
        {!accountCode ? (
          <Form.Group className="mb-3" as={Col}>
            <Form.Label>{Translate('Choose ledger')}</Form.Label>
            <SelectAccountDescriptions
              name="account_code"
              value={formData.account_code_ref}
              handleFieldChange={handleFieldChange}
              keyName="code"
              required
            />
          </Form.Group>
        ) : null}

        {formData?.account_code && (
          <Form.Group className="mb-3" as={Col}>
            <Form.Label>{Translate('Choose sub ledger')}</Form.Label>
            <SelectSubLedger
              name="sub_ledger_code"
              value={formData.sub_ledger_code_ref}
              handleFieldChange={handleFieldChange}
              ledger_id={formData?.account_code}
              required
            />
          </Form.Group>
        )}
        <Form.Group className="mb-3" as={Col}>
          <Form.Label>{Translate('Cost Center')}</Form.Label>
          <div className="d-flex flex-row">
            <div className="bg-secondary py-1 px-2 d-flex justify-content-center align-items-center">
              <FaCalendar size={10} className="text-white" />
            </div>
            <div className="flex-fill">
              <SelectCostCenter
                value={formData.cost_center_id_ref}
                name="cost_center_id"
                handleFieldChange={handleFieldChange}
                placeholder=""
                style={{ borderRadius: 'none' }}
              />
            </div>
          </div>
        </Form.Group>
        <Form.Group className="mb-3" as={Col}>
          <Form.Label>{Translate('Transaction Type')}</Form.Label>
          <div className="d-flex flex-row">
            <div className="bg-secondary py-1 px-2 d-flex justify-content-center align-items-center">
              <MdOutlineTransform size={10} className="text-white" />
            </div>
            <div className="flex-fill">
              <SelectTransactionTypes
                name="transaction_type"
                keyName="slug"
                handleFieldChange={handleFieldChange}
                value={formData.transaction_type_ref}
                style={{ borderRadius: 'none' }}
              />
            </div>
          </div>
        </Form.Group>
      </Row>
      <Form.Group className="d-flex justify-content-end">
        <Button
          type="submit"
          variant="success"
          size="sm"
          // disabled={!formData.date_to || !formData.date_from}
        >
          {Translate('Run Report')}
        </Button>
      </Form.Group>
    </Form>
  );
};

LedgerReportForm.propTypes = {
  setFormData: PropTypes.func,
  formData: PropTypes.object,
  setShowReport: PropTypes.func,
  accountCode: PropTypes.string,
  fullWidth: PropTypes.bool,
  hideHeader: PropTypes.bool,
  sm: PropTypes.number,
  md: PropTypes.number,
  xl: PropTypes.number,
  onSubmit: PropTypes.func
};

export default LedgerReportForm;
