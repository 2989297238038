import React, { useCallback, useRef } from 'react';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import DownloadLineItem from './DownloadLineItem';

export default function DownloadCenterRecentDownloads({
  show,
  items,
  handleShowAll
}) {
  const [showWindow, setShowWindow] = useState(show);
  const dropdownSectionRef = useRef(null);

  function handleClose(event) {
    event.preventDefault();
    removeScreen();
  }

  function handleShowAllButton(event) {
    event.preventDefault();
    removeScreen();
    handleShowAll();
  }

  const handleClickOutside = useCallback(event => {
    if (
      dropdownSectionRef?.current &&
      !dropdownSectionRef.current.contains(event.target)
    ) {
      window.removeEventListener('click', handleClickOutside);
      setShowWindow(false);
    }
  }, []);

  const removeScreen = useCallback(() => {
    window.removeEventListener('click', handleClickOutside);
    setShowWindow(false);
  }, [handleClickOutside]);

  useEffect(() => {
    if (show) {
      setShowWindow(show);
      window.addEventListener('click', handleClickOutside, true);
    }

    return () => {
      window.removeEventListener('click', handleClickOutside);
      setShowWindow(false);
    };
  }, [show, handleClickOutside]);

  return showWindow && Array.isArray(items) && items.length > 0 ? (
    <div
      className="download-center-quick-notification-screen position-absolute shadow-sm d-flex flex-column"
      ref={dropdownSectionRef}
    >
      <div className="d-flex flex-row align-items-center header-section shadow-sm">
        <p className="mb-0 me-2">Recent Downloads</p>
        <Button
          size="sm"
          variant="transparent"
          className="ms-auto p-0"
          onClick={handleClose}
        >
          <FaTimes />
        </Button>
      </div>

      <div className="downloads-list-section">
        <ul className="list-unstyled">
          {items.slice(0, 5).map((data, index) => (
            <li className="mb-2 py-2" key={index}>
              <DownloadLineItem item={data} />
            </li>
          ))}
        </ul>
      </div>

      <div className="text-center footer-section border-top p">
        <Button
          variant="transparent"
          size="sm"
          className="p-0"
          onClick={handleShowAllButton}
        >
          Show all
        </Button>
      </div>
    </div>
  ) : null;
}

DownloadCenterRecentDownloads.propTypes = {
  show: PropTypes.bool,
  items: PropTypes.array,
  handleShowAll: PropTypes.func
};
