import { useCallback, useContext, useEffect } from 'react';
import { TabViewContentReloadHandlerContext } from 'context/Context';

const useTabViewContentReloadHandler = configs => {
  const {
    TabViewContentReloadHandlerContextData,
    tabViewContentReloadHandlerContextDispatch
  } = useContext(TabViewContentReloadHandlerContext);

  const addTab = useCallback(
    tabNameArray => {
      tabViewContentReloadHandlerContextDispatch({
        type: 'ADD-TAB-TO-RELOAD-LIST',
        payload: tabNameArray
      });
    },
    [tabViewContentReloadHandlerContextDispatch]
  );

  const removeTab = useCallback(
    tabNameArray => {
      tabViewContentReloadHandlerContextDispatch({
        type: 'REMOVE-TAB-FROM-RELOAD-LIST',
        payload: tabNameArray
      });
    },
    [tabViewContentReloadHandlerContextDispatch]
  );

  const isTabIncludes = useCallback(
    tabName =>
      TabViewContentReloadHandlerContextData.reloadPendingTabList?.includes(
        tabName
      ),
    [TabViewContentReloadHandlerContextData.reloadPendingTabList]
  );

  useEffect(() => {
    if (Array.isArray(configs?.activeTabList)) {
      addTab(configs?.activeTabList);
    }

    return () => {
      if (Array.isArray(configs?.activeTabList)) {
        removeTab(configs?.activeTabList);
      }
    };
  }, [addTab, configs?.activeTabList, removeTab]);

  return {
    reloadPendingTabList:
      TabViewContentReloadHandlerContextData?.reloadPendingTabList,
    addTab,
    removeTab,
    isTabIncludes
  };
};

export default useTabViewContentReloadHandler;
