import axios from 'axios';
import setNewFormData from 'helpers/setNewFormData';
import { useCallback, useEffect, useRef, useState } from 'react';
import handleDraft from '../functions/handleDraft';
import { showToast } from 'module/Common/Toast/toast';
import removeRefData from 'helpers/removeRefData';
import { autoFocusInput } from 'module/Sales/common/functions/autoFocusInput';
import useAcodaxRoundOff from 'hooks/useAcodaxRoundOffFunction';
import useAcodaxNumberFormat from 'hooks/useAcodaxNumberFormat';
import isObject from 'helpers/isObject';

function useDraft(draftDependencies) {
  const acodaxRoundOffAmount = useAcodaxRoundOff();
  const acodaxNumberFormat = useAcodaxNumberFormat();
  const [formData, setFormData] = useState(
    draftDependencies && draftDependencies.defaultFormData
      ? draftDependencies.defaultFormData
      : null
  );
  const [netAmounts, setNetAmounts] = useState({
    TaxableAmount: 0,
    ItemTotal: 0,
    GLTotal: 0,
    discount: 0,
    tax: 0
  });
  const [draftReadyToSave, setDraftReadyToSave] = useState(false);
  const [draftId, setDraftId] = useState(null);
  const [draftData, setDraftData] = useState({});
  const [lastSavedDraftData, setLastSavedDraftData] = useState('');
  const [draftIsSaving, setDraftIsSaving] = useState(false);
  const [showDraftWindow, setShowDraftWindow] = useState(false);
  // const [timer, setTimer] = useState(null);
  const timer = useRef(null);
  const includeRoundOffAmount = draftDependencies?.includeRoundOffAmount;

  const draftTransType = draftDependencies?.trans_type || null;
  const draftConversionDataString = JSON.stringify(
    isObject(draftDependencies?.conversionData)
      ? draftDependencies.conversionData
      : {}
  );
  const draftWithoutItemsArrayString = JSON.stringify(
    Array.isArray(draftDependencies?.withoutItemsArray)
      ? draftDependencies?.withoutItemsArray
      : []
  );

  const draftPayload = draftDependencies?.draftPayload || formData;

  const getDraftData = useCallback(async () => {
    return handleDraft({
      trans_type: draftTransType,
      setDraftId: setDraftId,
      setDraftData: setDraftData,
      setShowDraftWindow: setShowDraftWindow,
      conversionData: JSON.parse(draftConversionDataString)
    });
  }, [draftTransType, draftConversionDataString]);

  const handleResumeDraftData = () => {
    setFormData(prev => ({ ...prev, ...draftData.data.draft_data }));
  };

  useEffect(() => {
    const saveDraftData = (data, draftId) => {
      const draftableData = typeof data === 'object' ? { ...data } : undefined;

      delete draftableData.files;
      let encodedData = encodeURIComponent(JSON.stringify(draftableData));

      if (lastSavedDraftData === encodedData) return;

      if (!draftReadyToSave) {
        setLastSavedDraftData(encodedData);
        return;
      }

      if (draftIsSaving) return;
      if (timer?.current) clearTimeout(timer.current);
      timer.current = setTimeout(async () => {
        setDraftIsSaving(true);
        await handleDraft({
          trans_type: draftTransType,
          data: draftableData,
          draftId: draftId,
          setDraftId: setDraftId,
          setDraftData: setDraftData,
          setShowDraftWindow: setShowDraftWindow,
          withoutItemsArray: JSON.parse(draftWithoutItemsArrayString),
          conversionData: JSON.parse(draftConversionDataString)
        });
        setLastSavedDraftData(encodedData);
        setDraftIsSaving(false);
      }, 1300);
    };

    if (!draftIsSaving) {
      saveDraftData(draftPayload, draftId);
    }
  }, [
    draftPayload,
    draftId,
    draftIsSaving,
    draftReadyToSave,
    lastSavedDraftData,
    draftTransType,
    draftWithoutItemsArrayString,
    draftConversionDataString
  ]);

  const detailsString = JSON.stringify(
    typeof draftPayload?.details === 'object' ? draftPayload.details : null
  );
  const glItemsString = JSON.stringify(
    typeof draftPayload?.gl_items === 'object' ? draftPayload.gl_items : null
  );

  useEffect(() => {
    let netTotalAndDiscount = {
      TaxableAmount: 0,
      ItemTotal: 0,
      GLTotal: 0,
      discount: 0,
      tax: 0
    };

    const detailsData = JSON.parse(detailsString);
    if (Array.isArray(detailsData)) {
      netTotalAndDiscount = detailsData.reduce(
        (initialValue, item) => {
          let subTotal = parseFloat(item?.sub_total ?? 0);
          let netTotal = parseFloat(item?.line_total ?? 0);
          let netDiscount = parseFloat(item?.net_discount_amount ?? 0);
          let netTax = parseFloat(item?.line_total_tax ?? 0);

          initialValue.TaxableAmount += subTotal - netDiscount;
          initialValue.ItemTotal += netTotal;
          initialValue.discount += netDiscount;
          initialValue.tax += netTax;
          return initialValue;
        },
        { TaxableAmount: 0, ItemTotal: 0, discount: 0, tax: 0 }
      );
    }

    const glItemsData = JSON.parse(glItemsString);
    if (Array.isArray(glItemsData)) {
      let glTotalData = glItemsData.reduce(
        (initialValue, item) => {
          initialValue.tax += parseFloat(
            item?.total_ref?.total_tax_amount ?? 0
          );
          initialValue.GLTotal += parseFloat(item?.total_ref?.total ?? 0);
          initialValue.TaxableAmount += parseFloat(item?.amount ?? 0);
          return initialValue;
        },
        { TaxableAmount: 0, GLTotal: 0, tax: 0 }
      );

      netTotalAndDiscount.GLTotal = glTotalData.GLTotal;
      netTotalAndDiscount.TaxableAmount += glTotalData.TaxableAmount;
      netTotalAndDiscount.tax += glTotalData.tax;
    }

    //set formdata round_off_amount
    if (includeRoundOffAmount) {
      const total =
        (netTotalAndDiscount?.ItemTotal || 0) +
        (netTotalAndDiscount?.GLTotal || 0);

      const total_round = acodaxRoundOffAmount(total);

      const diff = total_round - total;
      setFormData(prev => ({
        ...prev,
        round_off_amount: acodaxNumberFormat(diff)
      }));
    }

    setNetAmounts(netTotalAndDiscount);
  }, [
    detailsString,
    glItemsString,
    includeRoundOffAmount,
    acodaxRoundOffAmount,
    acodaxNumberFormat
  ]);

  const handleSaveDraftData = useCallback(() => {
    setDraftReadyToSave(true);
  }, []);

  return {
    formData: formData,
    setFormData: setFormData,
    netAmounts: netAmounts,
    draftId: draftId,
    setDraftId: setDraftId,
    draftData: draftData,
    setDraftData: setDraftData,
    draftIsSaving: draftIsSaving,
    setDraftIsSaving: setDraftIsSaving,
    showDraftWindow: showDraftWindow,
    setShowDraftWindow: setShowDraftWindow,
    saveDraftData: handleSaveDraftData,
    disableSaveDraftData: () => setDraftReadyToSave(false),
    getDraftData: getDraftData,
    handleResumeDraftData: handleResumeDraftData
  };
}

function useSaveToCart({
  onTableItemChange,
  cartItems,
  transType,
  transNumber
}) {
  const [isSaving, setIsSaving] = useState(false);

  const saveToCart = useCallback(
    itemsArray => {
      setIsSaving(true);
      let arrayOfCartItems = setNewFormData({
        items: itemsArray,
        trans_type: transType,
        ...(transNumber ? { trans_no: transNumber } : null)
      });

      axios
        .post('purchase/add-to-cart-bulk', arrayOfCartItems)
        .then(resposne => {
          if (resposne?.data?.data?.items) {
            let arrayOfCartItems = resposne.data.data.items.reduce(
              (intiArray, item) => {
                let id_ref = parseInt(item.id_ref);
                let itemRef = cartItems.find(
                  cartItem => cartItem.id_ref === id_ref
                );

                if (!item.id) {
                  delete item.id;
                }

                item.add_to_draft_ref = itemRef.add_to_draft_ref ?? true;
                item.id_ref = id_ref;
                item.removeThisKeys = [
                  'edit',
                  'add_to_cart_ref',
                  'add_to_draft_ref'
                ];
                intiArray.push(item);
                return intiArray;
              },
              []
            );

            if (arrayOfCartItems.length > 0) {
              onTableItemChange(
                arrayOfCartItems,
                'UPDATE-MULTIPLE',
                arrayOfCartItems.filter(
                  cartItem => cartItem.add_to_draft_ref === true
                ).length > 0
                  ? true
                  : false
              );
            }

            setIsSaving(false);
          }
          autoFocusInput('stock_id');
        })
        .catch(error => {
          if (
            error?.response?.data?.data?.errors &&
            typeof error.response.data.data.errors === 'object'
          ) {
            let allErrors = error.response.data.data.errors;
            let newData = cartItems.reduce((intiArray, item) => {
              let errors = allErrors[item.id_ref] ?? null;
              if (errors) {
                item.errors = errors;
                item.removeThisKeys = ['add_to_cart_ref'];
              }
              intiArray.push(item);
              return intiArray;
            }, []);

            if (newData.length > 0) {
              onTableItemChange(newData, 'UPDATE-MULTIPLE');
            }

            setIsSaving(false);
          }
        });
    },
    [transType, transNumber, cartItems, onTableItemChange]
  );

  useEffect(() => {
    if (!isSaving && Array.isArray(cartItems)) {
      let pendingCartItems = cartItems.reduce((array, item) => {
        if (array.length < 10 && item.add_to_cart_ref === true) {
          let cartItem = removeRefData(item);
          cartItem.id_ref = item.id_ref;
          cartItem.tax_included = item.tax_included ? 1 : 0;

          if (item?.tax_group_id) {
            cartItem.tax_group_id = item.tax_group_id;
          }

          if (item.batch_number) {
            cartItem.batch_number = item.batch_number;
          }

          if (parseInt(item.is_kit) === 1) {
            if (item.kit_items) {
              let kit_items = {};
              setNewFormData({
                ['kit_items]']: item.kit_items.reduce((newArray, item) => {
                  newArray.push({
                    tax_included: cartItem.tax_included,
                    id: item.id,
                    kit_item_id: item.kit_item_id,
                    kit_id: item.kit_id,
                    stock_id: item.stock_id,
                    base_quantity: item.base_quantity_ref
                  });
                  return newArray;
                }, [])
              }).forEach((value, key) => (kit_items[key] = value));
              cartItem = { ...cartItem, ...kit_items };
            }
            cartItem.kit_id = cartItem.stock_id;
            delete cartItem.stock_id;
          }

          if (item?.discount_unit_ref === '%') {
            cartItem.discount_percent = item.discount_percent;
          } else if (item?.discount_amount > 0) {
            cartItem.discount_amount = item.discount_amount;
          }

          array.push(cartItem);
        }
        return array;
      }, []);

      if (pendingCartItems.length > 0) {
        if (!pendingCartItems[0].trans_date) {
          return showToast(
            'The transaction date is invalid. Please select a valid date.',
            'error'
          );
        }
        saveToCart(pendingCartItems);
      }
    }
  }, [isSaving, cartItems, saveToCart]);

  return {
    saveToCart: saveToCart,
    isSaving: isSaving
  };
}

export { useDraft, useSaveToCart };
