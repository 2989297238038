import { React, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Dropdown, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { FaDownload, FaFileExcel, FaFilePdf } from 'react-icons/fa';
import qs from 'qs';
import { ExportContext } from 'context/Context';
import { showToast } from 'module/Common/Toast/toast';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { showWarningToast } from 'module/Common/Toast/showWarningToast';

const ExportButton = ({
  size,
  variant,
  className,
  url,
  initialMessage,
  successMessage,
  filters,
  interBranch,
  ...rest
}) => {
  const Translate = useAxisproTranslate();
  const { exportArray, dispatch } = useContext(ExportContext);
  const [isExporting, setIsExporting] = useState(false);

  const handleExport = (e, file_type) => {
    e.preventDefault();
    setIsExporting(true);
    showWarningToast(Translate(initialMessage));
    axios
      .get(url, {
        params: {
          mode: file_type,
          ...filters,
          ...(interBranch && {
            show_inter_branch_transactions: interBranch ? 1 : 0
          })
        },
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(response => {
        if (response.status === 200 && response.data && response.data.data) {
          // Add to export context
          dispatch({
            type: 'ADD',
            payload: {
              url: url,
              file_type: file_type,
              initialMessage: Translate(initialMessage),
              successMessage: Translate(successMessage),
              ...response.data.data
            }
          });
        } else {
          showToast(
            'Something went wrong, please refresh the page and try again.',
            'error'
          );

          setIsExporting(false);
        }
      })
      .catch(error => {
        console.error(error);
        showToast(
          'Something went wrong, please refresh the page and try again.',
          'error'
        );

        setIsExporting(false);
      });
  };

  useEffect(() => {
    let exportItem = url
      ? exportArray.filter(item => item.url === url && !item.status)
      : [];

    exportItem = exportItem.length > 0 ? exportItem[0] : null;
    setIsExporting(exportItem ? true : false);
  }, [exportArray, url]);

  return (
    <Dropdown>
      <Dropdown.Toggle
        id="dropdown-basic"
        disabled={isExporting}
        size={size ?? 'sm'}
        variant={variant ?? 'primary'}
        className={`d-flex flex-row align-items-center px-2 ${className}`}
        {...rest}
      >
        {isExporting ? (
          <>
            <Spinner
              animation="border"
              className="me-1"
              style={{ width: 12, height: 12 }}
            />
            <span className="fs--1">{Translate('Exporting')}</span>
          </>
        ) : (
          <>
            <FaDownload className="me-1" size={10} />
            <span className="fs--1">{Translate('Export')}</span>
          </>
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item href="#" onClick={e => handleExport(e, 'pdf')}>
          <div className="d-flex flex-row align-items-center">
            <FaFilePdf
              className="me-1"
              size={11}
              style={{ color: '#f40f02' }}
            />
            <span className="fw-bolder mb-0 small">{Translate('PDF')}</span>
          </div>
        </Dropdown.Item>
        <Dropdown.Item href="#" onClick={e => handleExport(e, 'excel')}>
          <div className="d-flex flex-row align-items-center">
            <FaFileExcel
              className="me-1"
              size={11}
              style={{ color: '#1d6f42' }}
            />
            <span className="fw-bolder mb-0 small">{Translate('Excel')}</span>
          </div>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

ExportButton.propTypes = {
  variant: PropTypes.string,
  to: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  url: PropTypes.string.isRequired,
  filters: PropTypes.object,
  interBranch: PropTypes.bool,
  initialMessage: PropTypes.string.isRequired,
  successMessage: PropTypes.string.isRequired
};

export default ExportButton;
