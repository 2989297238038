import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Modal } from 'react-bootstrap';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { RiErrorWarningLine } from 'react-icons/ri';
import { IoMdClose } from 'react-icons/io';

export const ConfirmationBox = ({
  show,
  handleSubmit,
  onHide,
  selectButton,
  title,
  content,
  specificContent
}) => {
  const Translate = useAxisproTranslate();

  return (
    <Modal
      show={show}
      size="md"
      backdrop="static"
      className="key-down-confirm-modal p-0 m-0 border-0"
    >
      <Card>
        <Card.Header className="text-center header d-flex align-items-center justify-content-between border-rounded-0 p-2 pt-3 pb-2 border-bottom">
          <div className="d-flex align-items-center">
            <RiErrorWarningLine
              size={25}
              className="me-1 text-warning fw-bold cursor-pointer"
            />
            <div className="fs--1 text-uppercase text-dark">
              {Translate(title ?? 'Confirm Navigation')}
            </div>
          </div>
          <IoMdClose onClick={onHide} className="text-dark" size={25} />
        </Card.Header>
        <Card.Body className="d-flex flex-column align-items-center justify-content-center p-0 ">
          <p className="p-3 pb-0 fs--1 text-muted">
            {specificContent
              ? content
              : Translate(
                  content ??
                    'Confirming navigation to another page. Are you certain you want to continue?'
                )}
          </p>
        </Card.Body>
        <Card.Footer className="d-flex gap-2 align-items-center justify-content-end">
          <Button
            className={`${
              !selectButton ? 'text-light' : 'text-dark'
            } border-0  border-dark  rounded-1 ps-3 pe-3`}
            size="sm"
            style={{
              backgroundColor: !selectButton ? '#DB4343' : '#ededed'
            }}
            onClick={onHide}
          >
            {Translate('No')}
          </Button>
          <Button
            style={{
              backgroundColor: selectButton ? '#00D27A' : '#ededed'
            }}
            className={`${
              selectButton ? 'text-light' : 'text-dark'
            } rounded-1 ps-3 pe-3 border-0  `}
            size="sm"
            onClick={handleSubmit}
          >
            {Translate('Yes')}
          </Button>
        </Card.Footer>
      </Card>
    </Modal>
  );
};
ConfirmationBox.propTypes = {
  show: PropTypes.bool,
  handleSubmit: PropTypes.func,
  onHide: PropTypes.func,
  selectButton: PropTypes.bool,
  specificContent: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.string
};
