import useAxisproTranslate from 'hooks/useAxisproTranslate';
import React, { useCallback, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import PropTypes from 'prop-types';
import EntriesPopUpFormModal from './EntriesPopUpFormModal';
import EntriesPopUpLineItemAccordionView from './EntriesPopUpLineItemAccordionView/EntriesPopUpLineItemAccordionView';
import { useSaveToCart } from 'module/Purchase/common/hooks/usePurchaseHooks';
import { showToast } from 'module/Common/Toast/toast';
import handleTableItem from 'module/Purchase/common/functions/handleTableItem';

export default function EntriesPopUpForm({
  type,
  transaction_date,
  taxIncluded,
  enableTaxGroup,
  supplierDetails,
  transType,
  formSettingsTransType,
  transNumber,
  addButtonText,
  addCaseTitle,
  editCaseTitle,
  addToCart,
  keyName,
  entries,
  setFormData,
  handleSaveDraftData,
  enableBatchSection,
  batchIsEditable,
  setIncludeRoundOffAmount
}) {
  const Translate = useAxisproTranslate();
  const [show, setShow] = useState(false);
  const [itemIsAdding, setItemIsAdding] = useState(false);
  const entriesLength = entries?.length;
  const entriesExist = !!(entriesLength > 0);
  const editingItemData = Array.isArray(entries)
    ? entries.find(
        entry => entry?.edit === true && entry?.add_to_cart_ref !== true
      )
    : undefined;
  const batchEnabledFirstItem = Array.isArray(entries)
    ? entries.find(entry => Boolean(Number(entry?.has_batch_inventory)))
    : undefined;

  const handleFormModal = () => {
    const targetKeyName = keyName || 'details';
    if (editingItemData) {
      setFormData(prev => ({
        ...prev,
        [targetKeyName]: Array.isArray(prev?.[targetKeyName])
          ? prev[targetKeyName].map(entry => {
              if (entry?.edit) {
                delete entry?.edit;
              }
              return entry;
            })
          : prev?.[targetKeyName]
      }));

      return;
    }

    setShow(!show);
  };

  const onTableItemChange = useCallback(
    async (data, method, saveToDraft) => {
      if (!transaction_date) {
        return showToast(
          Translate('Transaction date is required, please select a valid date')
        );
      }

      setItemIsAdding(true);
      handleTableItem({
        data: data,
        method: method,
        setFormData: setFormData,
        handleSaveDraftData: handleSaveDraftData,
        saveToDraft: saveToDraft ?? false
      });
      setItemIsAdding(false);
    },
    [transaction_date, handleSaveDraftData, setFormData, Translate]
  );

  useSaveToCart({
    onTableItemChange: onTableItemChange,
    cartItems: entries,
    transType: transType,
    transNumber: transNumber
  });

  return (
    <>
      {!itemIsAdding && (show || editingItemData) ? (
        <EntriesPopUpFormModal
          type={type}
          transaction_date={transaction_date}
          taxIncluded={taxIncluded}
          enableTaxGroup={enableTaxGroup}
          supplierDetails={supplierDetails}
          transType={transType}
          formSettingsTransType={formSettingsTransType}
          transNumber={transNumber}
          show
          onHide={handleFormModal}
          addCaseTitle={addCaseTitle}
          editCaseTitle={editCaseTitle}
          addToCart={addToCart}
          keyName={keyName}
          setMainFormData={setFormData}
          entries={entries}
          editingItemData={editingItemData}
          enableBatchSection={enableBatchSection}
          batchIsEditable={batchIsEditable}
          setIncludeRoundOffAmount={setIncludeRoundOffAmount}
        />
      ) : null}

      {entriesExist && (
        <EntriesPopUpLineItemAccordionView
          type={type}
          entries={entries}
          setFormData={setFormData}
          handleSaveDraftData={handleSaveDraftData}
          taxIncluded={taxIncluded}
          enableTaxGroup={enableTaxGroup}
          enableBatchSection={enableBatchSection}
          batchIsEditable={batchIsEditable}
          batchEnabledItemFound={!!batchEnabledFirstItem}
          keyName={keyName}
        />
      )}

      <div className="d-flex flex-column mb-4">
        <Alert variant={entriesExist ? 'success' : 'warning'}>
          <span>
            {Translate(
              entriesExist
                ? 'To add more line items, You can use'
                : 'To submit this, we need at least one line item. You can add line items using the'
            )}
          </span>
          &nbsp;<b>{Translate('Add Item')}</b>&nbsp;
          <span>{Translate('button provided below.')}</span>
        </Alert>
        <Button
          className="ms-auto rounded d-flex flex-row align-items-center gap-2"
          size="sm"
          type="button"
          onClick={handleFormModal}
        >
          <FaPlus size={11} />
          <span>{Translate(addButtonText || 'Add Item')}</span>
        </Button>
      </div>
    </>
  );
}

EntriesPopUpForm.propTypes = {
  type: PropTypes.oneOf(['FIXED-ASSET']).isRequired,
  transaction_date: PropTypes.string,
  taxIncluded: PropTypes.bool,
  enableTaxGroup: PropTypes.bool,
  supplierDetails: PropTypes.object,
  transType: PropTypes.string,
  formSettingsTransType: PropTypes.string,
  transNumber: PropTypes.string,
  addButtonText: PropTypes.string,
  addCaseTitle: PropTypes.string,
  editCaseTitle: PropTypes.string,
  addToCart: PropTypes.bool,
  keyName: PropTypes.string,
  setFormData: PropTypes.func,
  entries: PropTypes.array,
  handleSaveDraftData: PropTypes.func,
  enableBatchSection: PropTypes.bool,
  batchIsEditable: PropTypes.bool,
  setIncludeRoundOffAmount: PropTypes.func
};
