import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { AuthWizardContext } from 'context/Context';
import AccountCurrencyDetailsItem from './AccountCurrencyDetailsItem';
import classNames from 'classnames';
import { useEffect } from 'react';
import FormLayer from 'module/Common/Layer/FormLayer';
import { useState } from 'react';
import { showToast } from 'module/Common/Toast/toast';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import getAccountCurrencyDetails from 'helpers/getAccountCurrencyDetails';

export default function AccountCurrencyDetails({
  role,
  className,
  targetId,
  transactionDate,
  isLoading,
  setIsLoading,
  callBackFun,
  showLoadingScreen
}) {
  const { user } = useContext(AuthWizardContext);
  const Translate = useAxisproTranslate();
  const [currencyDetails, setCurrencyDetails] = useState(undefined);
  const branchDetail = user?.branch;

  useEffect(() => {
    async function init() {
      const executeCallBackFun = Boolean(typeof callBackFun === 'function');
      const executeSetIsLoading = Boolean(typeof setIsLoading === 'function');

      try {
        if (!targetId || !transactionDate) {
          setCurrencyDetails(undefined);
          executeCallBackFun && callBackFun(undefined);
          return;
        }

        executeSetIsLoading && setIsLoading(true);

        const currencyDetailsApiResult = await getAccountCurrencyDetails({
          role: role?.toUpperCase(),
          targetId: targetId,
          date: transactionDate
        });

        setCurrencyDetails(currencyDetailsApiResult);
        executeCallBackFun && callBackFun(currencyDetailsApiResult);
      } catch (error) {
        executeCallBackFun && callBackFun(undefined);
        showToast(
          Translate(
            error?.response?.data?.data?.message ||
              error?.message ||
              'Something went wrong, please refresh the page and try again.'
          ),
          'error'
        );
      } finally {
        executeSetIsLoading && setIsLoading(false);
      }
    }

    init();
    return () => {
      setCurrencyDetails(undefined);
    };
  }, [targetId, transactionDate, Translate, role, setIsLoading, callBackFun]);

  return showLoadingScreen && isLoading ? (
    <FormLayer />
  ) : currencyDetails &&
    currencyDetails?.currency_code !== branchDetail?.base_currency ? (
    <div
      className={classNames(
        'px-3 py-2 mb-3 bg-light border-bottom sticky-top fw-bold d-flex flex-column flex-md-row justify-content-center justify-content-md-start align-items-md-center gap-3 shadow-sm',
        className
      )}
    >
      <AccountCurrencyDetailsItem
        title={`${role} Currency`}
        value={currencyDetails?.currency_code}
        badgeBg="success"
      />

      <div className="align-self-stretch border-end border-2 d-none d-md-block"></div>

      <AccountCurrencyDetailsItem
        title={`System Currency`}
        value={branchDetail?.base_currency}
        badgeBg="info"
      />

      <div className="align-self-stretch border-end border-2 d-none d-md-block"></div>

      <AccountCurrencyDetailsItem
        title={`1 ${branchDetail?.base_currency}`}
        titleSeparator="="
        value={`${currencyDetails?.rate} ${currencyDetails?.currency_code}`}
        badgeBg="primary"
      />
    </div>
  ) : null;
}

AccountCurrencyDetails.propTypes = {
  role: PropTypes.oneOf(['Supplier', 'Customer']).isRequired,
  className: PropTypes.string,
  targetId: PropTypes.string,
  transactionDate: PropTypes.string,
  isLoading: PropTypes.bool,
  setIsLoading: PropTypes.func,
  callBackFun: PropTypes.func,
  showLoadingScreen: PropTypes.bool
};
