import { React, useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { Offcanvas } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FaUserCog } from 'react-icons/fa';
import './CustomerDetails.css';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import CustomerTabView from 'module/Common/ViewPages/Customer/CustomerTabView';

const CustomerDetails = ({
  show,
  onHide,
  value,
  showCustomerDetailsArea,
  className,
  width
}) => {
  const Translate = useAxisproTranslate();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchData = useCallback(async () => {
    const permission = {
      show_cs: getAcodaxPermissionSlug('CS', 'show'),
      activity_log: getAcodaxPermissionSlug('LG', 'history')
    };

    if (permission?.show_cs) {
      try {
        const res = await axios.get(`sales/customers/${value.value}`);
        setData(res.data.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }

    if (permission?.activity_log) {
      try {
        await axios.get('activity-log', {
          params: { customer_id: value.value }
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [value.value]);

  useEffect(() => {
    setLoading(true);
    if (showCustomerDetailsArea) {
      fetchData();
    }
  }, [showCustomerDetailsArea, value.value, fetchData]);

  return (
    <Offcanvas
      show={show}
      placement="end"
      style={{ width: width ? width : '50%' }}
      onHide={onHide}
      className={className}
    >
      <Offcanvas.Header
        closeButton
        style={{ boxShadow: 'none' }}
        className="offcanvas-heading-style"
      >
        <div>
          <FaUserCog size={25} className="me-2" />
          {Translate('Customer Details')}
        </div>
      </Offcanvas.Header>
      {!loading && data !== '' ? (
        <Offcanvas.Body className="customer-details-offcanvas p-0">
          <CustomerTabView
            fetchData={fetchData}
            loading={loading}
            customerData={data}
            Offcanvas={true}
          />
        </Offcanvas.Body>
      ) : (
        <LoadingScreen />
      )}
    </Offcanvas>
  );
};

CustomerDetails.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  value: PropTypes.any,
  showCustomerDetailsArea: PropTypes.bool,
  className: PropTypes.string,
  width: PropTypes.string
};

export default CustomerDetails;
