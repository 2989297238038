import React, { useCallback, useReducer } from 'react';
import PropTypes from 'prop-types';
import AppContext from 'context/Context';
import { settings } from './config';
import { getColor, getItemFromStore } from 'helpers/utils';
import useToggleStyle from './hooks/useToggleStyle';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { configReducer } from 'reducers/configReducer';

window.Pusher = Pusher;
window.Echo = new Echo({
  broadcaster: 'reverb',
  key: process.env.REACT_APP_WEBSOCKET_KEY,
  wsHost: process.env.REACT_APP_WEBSOCKET_HOST,
  wsPort: process.env.REACT_APP_WEBSOCKET_PORT,
  forceTLS: process.env.REACT_APP_WEBSOCKET_FORCE_TLS,
  disableStats: true
});

const Main = props => {
  const configState = {
    isFluid: getItemFromStore('isFluid', settings.isFluid),
    isRTL: getItemFromStore('isRTL', settings.isRTL),
    isDark: getItemFromStore('isDark', settings.isDark),
    navbarPosition: getItemFromStore('navbarPosition', settings.navbarPosition),
    isNavbarVerticalCollapsed: getItemFromStore(
      'isNavbarVerticalCollapsed',
      settings.isNavbarVerticalCollapsed
    ),
    navbarStyle: getItemFromStore('navbarStyle', settings.navbarStyle),
    currency: settings.currency,
    showBurgerMenu: settings.showBurgerMenu,
    showSettingPanel: false,
    navbarCollapsed: false
  };

  const [config, configDispatch] = useReducer(configReducer, configState);

  const { isLoaded } = useToggleStyle(
    config.isRTL,
    config.isDark,
    configDispatch
  );

  const setConfig = useCallback(
    (key, value) => {
      configDispatch({
        type: 'SET_CONFIG',
        payload: {
          key,
          value,
          setInStore: [
            'isFluid',
            'isRTL',
            'isDark',
            'navbarPosition',
            'isNavbarVerticalCollapsed',
            'navbarStyle'
          ].includes(key)
        }
      });
    },
    [configDispatch]
  );

  if (!isLoaded) {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: config.isDark ? getColor('dark') : getColor('light')
        }}
      />
    );
  }

  return (
    <AppContext.Provider value={{ config, setConfig }}>
      {props.children}
    </AppContext.Provider>
  );
};

Main.propTypes = { children: PropTypes.node };

export default Main;
