import { React, useEffect, useState } from 'react';
import {
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams
} from 'react-router-dom';
import axios from 'axios';
import { Offcanvas, Form } from 'react-bootstrap';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import { apiCall } from 'helpers/apiCalls';
import { showToast } from 'module/Common/Toast/toast';
import { SaveButton } from 'module/Common/Buttons/CommonButton';
import generateFormDataHelper from 'helpers/generateFormDataHelper';
import removeRefData from 'helpers/removeRefData';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import BackButton from 'components/common/BackButton';
import { FaUserGroup } from 'react-icons/fa6';
import useFormTabNavigator from 'hooks/useFormTabNavigator ';
import { customerGroupFormKeys } from 'helpers/formKeys';

const CustomerGroupForm = () => {
  const Translate = useAxisproTranslate();
  let navigate = useNavigate();
  const [getData] = useOutletContext();
  const { id } = useParams();
  const [queryParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [onSave, setOnSave] = useState(false);
  const [formData, setFormData] = useState(customerGroupFormKeys);
  const [formError, setFormError] = useState({});
  const pageNumber = queryParams.get('page');
  const permission = {
    create: getAcodaxPermissionSlug('CSG', 'create'),
    update: getAcodaxPermissionSlug('CSG', 'update')
  };

  const handleFieldChange = (e, action) =>
    setFormData(prev => generateFormDataHelper(e, action, prev));

  const handleSubmit = e => {
    e.preventDefault();
    setFormError({});
    setOnSave(true);
    let formDataWithoutRefKeys = removeRefData(formData);
    axios({
      method: id ? 'put' : 'post',
      url: `sales/customer-groups${id ? '/' + id : ''}`,
      data: formDataWithoutRefKeys
    })
      .then(response => {
        const responseData = response?.data;
        if (responseData?.success === true) {
          showToast(responseData?.message, 'success');
          getData(pageNumber ?? 1);
          navigate(id ? -1 : '/sales/customer-groups');
        } else {
          showToast(
            Translate(
              'Something went wrong, please refresh the page and try again.'
            ),
            'error'
          );
        }
        setOnSave(false);
      })
      .catch(error => {
        const errorData = error?.response?.data;
        if (errorData?.message) {
          const validation_error = errorData?.data?.errors
            ? errorData.data.errors
            : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          showToast(
            Translate(
              'Something went wrong, please refresh the page and try again.'
            ),
            'error'
          );
        }
        setOnSave(false);
      });
  };

  useEffect(() => {
    async function initFunc() {
      if (id) {
        setLoading(true);
        let details = await apiCall({
          url: 'sales/customer-groups/' + id
        });
        setFormData({
          name: details.name
        });
        setLoading(false);
      }
    }

    initFunc();
  }, [id]);

  useFormTabNavigator('form-tab-switch-control');

  return (
    <Offcanvas show onHide={() => navigate(-1)} placement="end">
      <Offcanvas.Header
        closeButton
        className="border-bottom offcanvas-heading-style"
      >
        <div>
          <FaUserGroup size={20} className="me-1 text-dark" />
          {id
            ? Translate('Edit Customer Group')
            : Translate('Add Customer Group')}
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {!loading ? (
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="require-data">
                {Translate('Name')}
              </Form.Label>
              <Form.Control
                type="text"
                name="name"
                className="form-tab-switch-control"
                onChange={handleFieldChange}
                value={formData.name}
                isInvalid={!!formError.name}
              />
              <Form.Control.Feedback type="invalid">
                {formError.name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="d-flex gap-1">
              <SaveButton
                handleSubmit={handleSubmit}
                onSave={onSave}
                id={id}
                createPermission={permission?.create}
                editPermission={permission?.update}
              />
              {!onSave && (
                <BackButton variant={'danger'} size={'sm'}>
                  {Translate('Cancel')}
                </BackButton>
              )}
            </Form.Group>
          </Form>
        ) : (
          <div
            className=" d-flex align-item-center justify-content-center"
            style={{ height: '80vh' }}
          >
            <LoadingScreen message="Looking for data" />
          </div>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default CustomerGroupForm;
