import { React, useContext, useEffect } from 'react';
import { Card, Col, FormGroup, Row } from 'react-bootstrap';
import SimpleBarReact from 'simplebar-react';
import PropTypes from 'prop-types';

import { FiImage } from 'react-icons/fi';
import { GrClose } from 'react-icons/gr';
import { BsExclamationCircle } from 'react-icons/bs';
import { FaDownload, FaFileExcel, FaFilePdf } from 'react-icons/fa';

import { AuthWizardContext } from 'context/Context';
import useAxisproTranslate from 'hooks/useAxisproTranslate';

const ChatWindowBody = ({
  data,
  loadNextPage,
  links,
  loadingPreviousMessage,
  attachments,
  setAttachments,
  setFormData,
  formData,
  messagesEndRef,
  setPageNumber
}) => {
  const { user } = useContext(AuthWizardContext);
  const Translate = useAxisproTranslate();
  function formatFileSize(sizeInBytes) {
    const kilobyte = 1024;
    const megabyte = kilobyte * 1024;
    const gigabyte = megabyte * 1024;

    if (sizeInBytes >= gigabyte) {
      return `${(sizeInBytes / gigabyte).toFixed(2)} GB`;
    } else if (sizeInBytes >= megabyte) {
      return `${(sizeInBytes / megabyte).toFixed(2)} MB`;
    } else if (sizeInBytes >= kilobyte) {
      return `${(sizeInBytes / kilobyte).toFixed(2)} KB`;
    } else {
      return `${sizeInBytes} B`;
    }
  }

  const handleDownload = url => {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.download = true;
    link.click();
  };

  const removeFileAttachment = index => {
    const updatedAttachments = [...attachments];
    updatedAttachments.splice(index, 1);
    setAttachments(updatedAttachments);

    const updatedFiles = [...formData.files];
    updatedFiles.splice(index, 1);
    setFormData({ ...formData, files: updatedFiles });
  };

  useEffect(() => {
    const currentRef = messagesEndRef.current;
    if (currentRef) {
      const observer = new IntersectionObserver(entries => {
        const target = entries[0];
        if (target.isIntersecting) {
          setPageNumber(1);
        }
      });

      observer.observe(currentRef);

      return () => {
        observer.unobserve(currentRef);
      };
    }
  }, [messagesEndRef, setPageNumber]);

  return (
    <>
      <Card.Body className="chat-window-big-screen-offcanvas-custom-style">
        <SimpleBarReact className="big-screen-simplebar-custome-styles">
          <div className="d-flex justify-content-center align-items-center">
            {loadingPreviousMessage ? (
              <p>{Translate('Loading previous messages...')}</p>
            ) : links &&
              links.meta &&
              links.meta.current_page != links.meta.last_page ? (
              <p
                onClick={() => loadNextPage(links.links.next)}
                style={{ cursor: 'pointer' }}
              >
                {Translate('Load previous message')}
              </p>
            ) : (
              ''
            )}
          </div>
          {data &&
            data.length > 0 &&
            data.map((item, index) => (
              <>
                <div key={index}>
                  {user.id != item.sender_id ? (
                    <div className="mb-2 message-content-right-side-styles">
                      <div>
                        <p className="p-1" style={{ marginBottom: 0 }}>
                          {item.message}
                        </p>
                        {item &&
                          item.media &&
                          item.media.length > 0 &&
                          item.media.map(media => (
                            <>
                              <Card.Header
                                className="m-0 rounded"
                                style={{
                                  backgroundColor: 'white'
                                }}
                              >
                                <Row className="d-flex">
                                  <Col xs={1}>
                                    <FormGroup>
                                      {media.file_name.endsWith('jpg') ||
                                      media.file_name.endsWith('jpeg') ||
                                      media.file_name.endsWith('png') ? (
                                        <FiImage size={25} color="green" />
                                      ) : media.file_name.endsWith('pdf') ? (
                                        <FaFilePdf size={25} color="red" />
                                      ) : media.file_name.endsWith('xls') ||
                                        media.file_name.endsWith('xlsx') ||
                                        media.file_name.endsWith('xlsm') ? (
                                        <FaFileExcel size={25} color="green" />
                                      ) : (
                                        ''
                                      )}
                                    </FormGroup>
                                  </Col>
                                  <Col xs={8}>
                                    <h2 className="p-0 m-0">
                                      {media.name.length > 10
                                        ? media.name.substring(0, 10) + '...'
                                        : media.name}
                                    </h2>
                                    <p className="p-0 m-0">
                                      {formatFileSize(media.size)}
                                    </p>
                                  </Col>
                                  <Col xs={1}>
                                    <FaDownload
                                      style={{ cursor: 'pointer' }}
                                      onClick={() =>
                                        handleDownload(media.original_url)
                                      }
                                    />
                                  </Col>
                                </Row>
                              </Card.Header>
                            </>
                          ))}
                      </div>
                      <div className="message-time p-0 m-0">
                        {item?.created_at}
                      </div>
                      <div className="chat-right-sided-arrow-style"></div>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center">
                      <div className="mb-2 message-content-left-side-styles">
                        <div>
                          <p
                            style={{
                              marginBottom: 0
                            }}
                            className="p-1"
                          >
                            {item.message}
                          </p>
                          <div className="message-time p-0 m-0">
                            {item?.created_at}
                          </div>
                          {item &&
                            item.media &&
                            item.media.length > 0 &&
                            item.media.map(media => (
                              <>
                                <Card.Header
                                  className=" m-0 mb-2 rounded"
                                  style={{
                                    backgroundColor: 'white'
                                  }}
                                >
                                  <Row className="d-flex">
                                    <Col xs={1}>
                                      <FormGroup>
                                        {media.file_name.endsWith('jpg') ||
                                        media.file_name.endsWith('jpeg') ||
                                        media.file_name.endsWith('png') ? (
                                          <FiImage size={25} color="green" />
                                        ) : media.file_name.endsWith('pdf') ? (
                                          <FaFilePdf size={25} color="red" />
                                        ) : media.file_name.endsWith('xls') ||
                                          media.file_name.endsWith('xlsx') ||
                                          media.file_name.endsWith('xlsm') ? (
                                          <FaFileExcel
                                            size={25}
                                            color="green"
                                          />
                                        ) : (
                                          ''
                                        )}
                                      </FormGroup>
                                    </Col>
                                    <Col xs={8}>
                                      <h2 className="p-0 m-0">
                                        {media.name && media.name.length > 10
                                          ? media.name.substring(0, 10) + '...'
                                          : media.name}
                                      </h2>
                                      <p className="p-0 m-0">
                                        {formatFileSize(media.size)}
                                      </p>
                                    </Col>
                                    <Col xs={1} className="text-end">
                                      <FaDownload
                                        style={{ cursor: 'pointer' }}
                                        onClick={() =>
                                          handleDownload(media.original_url)
                                        }
                                      />
                                    </Col>
                                  </Row>
                                </Card.Header>
                              </>
                            ))}
                        </div>
                        <div className="chat-left-sided-arrow-style"></div>
                      </div>
                      {!item.success && (
                        <>
                          <BsExclamationCircle color="red" />
                          <span className="ms-1 text-danger">
                            {Translate('failed!')}
                          </span>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </>
            ))}
          <div ref={messagesEndRef} />
        </SimpleBarReact>
        <div className="m-0 me-2 attachment-big-screen-simplebar-custom-styles">
          <SimpleBarReact style={{ height: '70px', overflowX: 'hidden' }}>
            {attachments &&
              attachments.length > 0 &&
              attachments.map((media, index) => (
                <Card.Header
                  key={index}
                  className="m-0 ms-2"
                  style={{
                    backgroundColor: '#EEEEEE'
                  }}
                >
                  <Row className="d-flex">
                    <Col xs={1}>
                      <FormGroup>
                        {media.file_name.endsWith('jpg') ||
                        media.file_name.endsWith('jpeg') ||
                        media.file_name.endsWith('png') ? (
                          <FiImage size={25} color="green" />
                        ) : media.file_name.endsWith('pdf') ? (
                          <FaFilePdf size={25} color="red" />
                        ) : media.file_name.endsWith('xls') ||
                          media.file_name.endsWith('xlsx') ||
                          media.file_name.endsWith('xlsm') ? (
                          <FaFileExcel size={25} color="green" />
                        ) : (
                          ''
                        )}
                      </FormGroup>
                    </Col>
                    <Col xs={10}>
                      <h2 className="p-0 m-0">
                        {media.file_name.length > 90
                          ? media.file_name.substring(0, 90) + '...'
                          : media.file_name}
                      </h2>
                      <p className="p-0 m-0"> {formatFileSize(media.size)}</p>
                    </Col>
                    <Col xs={1} className={'text-center'}>
                      <GrClose
                        style={{ cursor: 'pointer' }}
                        onClick={() => removeFileAttachment(index)}
                      />
                    </Col>
                  </Row>
                </Card.Header>
              ))}
          </SimpleBarReact>
        </div>
      </Card.Body>
    </>
  );
};

ChatWindowBody.propTypes = {
  data: PropTypes.any,
  loadNextPage: PropTypes.func,
  links: PropTypes.any,
  setIsMessageSent: PropTypes.func,
  isMessageSent: PropTypes.bool,
  loadingPreviousMessage: PropTypes.bool,
  attachments: PropTypes.array,
  setAttachments: PropTypes.func,
  setFormData: PropTypes.func,
  formData: PropTypes.any,
  messagesEndRef: PropTypes.any,
  setPageNumber: PropTypes.func
};

export default ChatWindowBody;
