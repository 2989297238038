import { React, useEffect, useState } from 'react';
import axios from 'axios';
import {
  Card,
  Col,
  Container,
  Form,
  Modal,
  OverlayTrigger,
  Popover,
  Row
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import { MdCreditScore } from 'react-icons/md';
import { BsExclamationCircleFill } from 'react-icons/bs';

import { apiCall } from 'helpers/apiCalls';
import { CancelButton, SaveButton } from 'module/Common/Buttons/CommonButton';
import { showToast } from 'module/Common/Toast/toast';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import CreditStatusList from './CreditStatusList';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';
import ErrorAlert from 'module/Common/Error/ErrorAlert';

function AddCreditStatus({ show, onHide, setData, data, callBack }) {
  const Translate = useAxisproTranslate();
  const [creditStatusData, setCreditStatusData] = useState({
    description: '',
    disallow_invoices: '',
    inactive: ''
  });
  const [onSave, setOnSave] = useState(false);
  const [formError, setFormError] = useState({});
  const [editId, setEditId] = useState('');
  const [loading, setLoading] = useState(false);

  const handleFieldChange = e => {
    let value = null;
    if (e.target.type === 'checkbox') {
      value = !e.target.checked ? 0 : 1;
    } else {
      value = e.target.value;
    }

    setCreditStatusData({
      ...creditStatusData,
      [e.target.name]: value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    setFormError({});
    setOnSave(true);
    axios({
      method: editId.length > 0 ? 'put' : 'post',
      url: `sales/credit-status/${editId ? editId : ''}`,
      data: creditStatusData
    })
      .then(response => {
        if (response.data.success === true) {
          setCreditStatusData({
            description: '',
            disallow_invoices: '',
            inactive: ''
          });
          if (editId.length === 0) {
            if (response.data.data) callBack(response.data.data);
            showToast(response.data.message, 'success');
            onHide();
            setData(
              {
                label: response.data.data.description,
                value: response.data.data.id
              },
              {
                action: 'select-option',
                option: undefined,
                name: 'credit_status_id'
              }
            );
          }
          setEditId('');
        }
        setOnSave(false);
      })
      .catch(error => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        }
        setOnSave(false);
      });
  };

  useEffect(() => {
    async function initFunc() {
      if (editId.length > 0) {
        setLoading(true);
        let details = await apiCall({
          url: 'sales/credit-status/' + editId
        });

        setCreditStatusData({
          name: details.name,
          description: details.description,
          disallow_invoices: details.disallow_invoices,
          inactive: details.inactive
        });
        setLoading(false);
      }
    }

    initFunc();
  }, [editId]);

  useEffect(() => {
    setCreditStatusData(prevCreditStatusData => ({
      ...prevCreditStatusData,
      description: data
    }));
  }, [data]);

  const handleCancel = () => {
    onHide();
    setFormError({});
  };

  return (
    <Modal
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
    >
      {!loading ? (
        <Card className="p-1">
          <Card.Header className=" d-flex flex-row align-items-center border-bottom">
            <MdCreditScore size={20} className="text-dark me-1 m-0" />
            <h5 className="modal-form-heading" style={{ fontSize: '15px' }}>
              {editId.length > 0
                ? Translate('Edit') + ' ' + Translate('credit status')
                : Translate('Add') + ' ' + Translate('credit status')}
            </h5>
          </Card.Header>
          <Form onSubmit={handleSubmit}>
            <Card.Body className="pb-0">
              {!Object.keys(formError).length == 0 ? (
                <ErrorAlert setFormError={setFormError} formError={formError} />
              ) : (
                ''
              )}
              <Row>
                <Form.Group as={Col} md={12} sm={12} className="mb-3">
                  <Row>
                    <Col xl={4} md={4} sm={12}>
                      <Form.Label>{Translate('Description')}</Form.Label>
                    </Col>
                    <Col xl={8} md={8} sm={12}>
                      <Row className="d-flex flex-row">
                        <Col xl={11} md={11} sm={11} className="pe-sm-0 flex-2">
                          <Form.Control
                            type="text"
                            name="description"
                            onChange={handleFieldChange}
                            value={creditStatusData.description}
                          />
                        </Col>

                        <Col
                          xl={1}
                          md={1}
                          sm={1}
                          className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0"
                        >
                          {formError.description ? (
                            <FormErrorPopover
                              id="description"
                              totalErrorCount={Object.keys(formError).length}
                              errorMessage={formError.description}
                            />
                          ) : (
                            ''
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group as={Col} md={6} sm={12} className="mb-3">
                  <Row className="d-flex flex-row">
                    <Col xl={11} md={11} sm={11} className="pe-sm-0 flex-2">
                      <Form.Check
                        type="switch"
                        name="disallow_invoices"
                        onChange={handleFieldChange}
                        value={creditStatusData.disallow_invoices}
                        label={Translate('Disallow Invoice')}
                        style={{ boxShadow: 'none' }}
                        checked={
                          creditStatusData.disallow_invoices == 1
                            ? creditStatusData.disallow_invoices
                            : ''
                        }
                      ></Form.Check>
                    </Col>
                    <Col
                      xl={1}
                      md={1}
                      sm={1}
                      className={
                        formError.disallow_invoices
                          ? 'text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-2'
                          : 'text-danger d-flex align-items-center flex-1 p-sm-0 m-0'
                      }
                    >
                      <OverlayTrigger
                        trigger={['hover', 'focus']}
                        placement="bottom"
                        overlay={
                          <Popover
                            id="popover-basic"
                            style={{ borderRadius: '0px' }}
                          >
                            <Popover.Body className="fs--1 p-3 text-danger">
                              {formError.disallow_invoices}
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <a>
                          <BsExclamationCircleFill
                            size={13}
                            style={{
                              display: formError.disallow_invoices ? '' : 'none'
                            }}
                          />
                        </a>
                      </OverlayTrigger>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group as={Col} md={6} sm={12} className="mb-3">
                  <Row className="d-flex flex-row">
                    <Col xl={11} md={11} sm={11} className="pe-sm-0 flex-2">
                      <Form.Check
                        type="switch"
                        name="inactive"
                        onChange={handleFieldChange}
                        value={creditStatusData.inactive}
                        checked={
                          creditStatusData.inactive == 1
                            ? creditStatusData.inactive
                            : ''
                        }
                        style={{ boxShadow: 'none' }}
                        label={Translate('Inactive')}
                      ></Form.Check>
                    </Col>
                    <Col
                      xl={1}
                      md={1}
                      sm={1}
                      className={
                        formError.inactive
                          ? 'text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-2'
                          : 'text-danger d-flex align-items-center flex-1 p-sm-0 m-0'
                      }
                    >
                      <OverlayTrigger
                        trigger={['hover', 'focus']}
                        placement="bottom"
                        overlay={
                          <Popover
                            id="popover-basic"
                            style={{ borderRadius: '0px' }}
                          >
                            <Popover.Body className="fs--1 p-3 text-danger">
                              {formError.inactive}
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <a>
                          <BsExclamationCircleFill
                            size={13}
                            style={{
                              display: formError.inactive ? '' : 'none'
                            }}
                          />
                        </a>
                      </OverlayTrigger>
                    </Col>
                  </Row>
                </Form.Group>
              </Row>
            </Card.Body>
            <Card.Footer className="d-flex flex-row gap-2 justify-content-start border-top p-2">
              <Form.Group className="d-flex flex-row gap-2 justify-content-start">
                <SaveButton
                  handleSubmit={handleSubmit}
                  onSave={onSave}
                  createPermission={true}
                  editPermission={true}
                  id={editId}
                />
                <CancelButton handleCancel={handleCancel} onSave={onSave} />
              </Form.Group>
            </Card.Footer>
          </Form>
          <CreditStatusList setEditId={setEditId} editId={editId} />
        </Card>
      ) : (
        <Container
          style={{ height: '32vh' }}
          className="d-flex align-items-center justify-content-center"
        >
          <LoadingScreen />
        </Container>
      )}
    </Modal>
  );
}

AddCreditStatus.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  setData: PropTypes.func,
  data: PropTypes.any,
  callBack: PropTypes.func
};

export default AddCreditStatus;
