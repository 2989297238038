import { React, useEffect, useState } from 'react';
import axios from 'axios';
import { Alert, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { showToast } from 'module/Common/Toast/toast';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import VoucherDetail from 'components/voucher-details/VoucherDetail';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';

function AllocationsTable({
  allocationData,
  id,
  t_type,
  tabApiCallCompleted,
  setTabApiCallCompleted,
  activeTabKey
}) {
  const Translate = useAxisproTranslate();
  const [voucherId, setVoucherId] = useState();
  const [transType, setTransType] = useState();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showVoucherDetailArea, setShowVoucherDetailArea] = useState(false);

  const clickOnVoucher = (voucher_id, trans_type) => {
    setVoucherId(voucher_id);
    setTransType(trans_type);
    setShowVoucherDetailArea(true);
  };
  const handleVoucherDetailsArea = () => {
    setShowVoucherDetailArea(!showVoucherDetailArea);
  };

  useEffect(() => {
    const fetchAllocationData = () => {
      setLoading(true);
      axios
        .get(`finance/allocations`, {
          params: { trans_no: id, trans_type: t_type }
        })
        .then(res => {
          if (res?.data?.success) {
            setData({
              allocations: res?.data?.data
            });
          } else {
            showToast('Something went wrong! Please contact admin', 'error');
          }
        })
        .catch(error => {
          if (error) {
            showToast('Something went wrong! Please contact admin', 'error');
          }
        })
        .finally(() => {
          setLoading(false);
          setTabApiCallCompleted(prev => ({
            ...prev,
            allocations: true
          }));
        });
    };

    if (id) {
      if (activeTabKey !== 'allocations' || tabApiCallCompleted) return;
      fetchAllocationData();
    } else {
      if (allocationData) {
        setData(allocationData);
        setLoading(false);
      }
    }
  }, [
    activeTabKey,
    tabApiCallCompleted,
    allocationData,
    id,
    t_type,
    setTabApiCallCompleted
  ]);

  return (
    <>
      <Table
        striped
        bordered
        hover
        responsive
        className="mt-2 simple-table-animation"
      >
        {!loading ? (
          <>
            <thead className="bg-dark text-light text-uppercase">
              <tr>
                <th className="fs--2 p-2 text-center">#</th>
                <th className="fs--2 p-2 text-center">{Translate('Date')}</th>
                <th className="fs--2 p-2 text-center">
                  {Translate('Reference')}
                </th>
                <th className="fs--2 p-2 text-center">
                  {Translate('Trans Type')}
                </th>
                <th className="fs--2 p-2 text-center">
                  {Translate('Allocated Amount')}
                </th>
              </tr>
            </thead>
            {data?.allocations?.length > 0 ? (
              data?.allocations.map((item, key) => (
                <tbody key={key} className="text-dark">
                  <tr>
                    <td className="p-2 text-center">{key + 1}</td>
                    <th className="p-2 text-center">
                      {item.trans_date_formatted}
                    </th>
                    <th className="p-2 text-center">
                      {item?.type_from_object ? (
                        getAcodaxPermissionSlug(
                          item?.type_from_object?.trans_type,
                          'show'
                        ) ? (
                          <a
                            href="#"
                            onClick={() =>
                              clickOnVoucher(
                                item?.type_from_object?.trans_no,
                                item?.type_from_object?.trans_type
                              )
                            }
                          >
                            {item?.type_from_object?.reference}
                          </a>
                        ) : (
                          item?.type_from_object?.reference
                        )
                      ) : item?.type_to_object ? (
                        getAcodaxPermissionSlug(
                          item?.type_to_object?.trans_type,
                          'show'
                        ) ? (
                          <a
                            href="#"
                            onClick={() =>
                              clickOnVoucher(
                                item?.type_to_object?.trans_no,
                                item?.type_to_object?.trans_type
                              )
                            }
                          >
                            {item?.type_to_object?.reference}
                          </a>
                        ) : (
                          item?.type_to_object?.reference
                        )
                      ) : (
                        ' - '
                      )}
                    </th>
                    <th className="p-2 text-center text-uppercase">
                      {item?.type_from_object
                        ? Translate(item?.type_from_object?.trans_type_name)
                        : item?.type_to_object
                        ? Translate(item?.type_to_object?.trans_type_name)
                        : ' - '}
                    </th>
                    <th className="p-2 text-center">{item.amount_formatted}</th>
                  </tr>
                </tbody>
              ))
            ) : (
              <tbody>
                <tr>
                  <td colSpan={8} className="p-2">
                    <Alert className="text-center m-0" variant="danger">
                      {Translate('Nothing to show!')}
                    </Alert>
                  </td>
                </tr>
              </tbody>
            )}
          </>
        ) : (
          <div
            style={{ height: '50vh' }}
            className="d-flex align-items-center justiyf-content-center w-100"
          >
            <LoadingScreen
              message={Translate('Getting allocations details!')}
            />
          </div>
        )}
      </Table>
      <VoucherDetail
        show={showVoucherDetailArea}
        onHide={handleVoucherDetailsArea}
        setShowVoucherDetailArea={setShowVoucherDetailArea}
        type={transType}
        voucherId={voucherId}
        infoPage={true}
        offCanvas={true}
      />
    </>
  );
}

AllocationsTable.propTypes = {
  allocationData: PropTypes.object,
  id: PropTypes.any,
  t_type: PropTypes.any,
  activeTabKey: PropTypes.string,
  tabApiCallCompleted: PropTypes.bool,
  setTabApiCallCompleted: PropTypes.func
};

export default AllocationsTable;
