import { Fragment, React, useContext, useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

import { FaUserSecret, FaUsers } from 'react-icons/fa';
import { BsFillPatchPlusFill } from 'react-icons/bs';
import { BiReceipt } from 'react-icons/bi';
import { AiOutlinePlusSquare } from 'react-icons/ai';
import { MdOutlineInsertLink } from 'react-icons/md';

import { AuthWizardContext } from 'context/Context';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import AddCustomer from 'module/Common/Forms/AddCustomer';
import SupplierPopupForm from 'components/form/popup-forms/SupplierPopupForm';
import ReceivePayment from 'module/Common/Forms/ReceivePayment';
import QuickLinksForm from 'module/Common/Forms/QuickLinksForm';

function QuickForms() {
  const { user } = useContext(AuthWizardContext);
  const [queryParams] = useSearchParams();
  const customer = queryParams.get('add_new_customer');
  const supplier = queryParams.get('add_new_supplier');
  const Translate = useAxisproTranslate();
  const [isOpen, setIsOpen] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [form, setForm] = useState({
    customer: customer ? customer : false,
    supplier: supplier ? supplier : false,
    receivePayment: false,
    quickLinks: false
  });

  useEffect(() => {
    setForm(prev => ({
      ...prev,
      customer: customer ? customer : false,
      supplier: supplier ? supplier : false
    }));
  }, [customer, supplier]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleForm = code => {
    if (code === 'customer') {
      setForm({
        customer: true,
        supplier: false,
        receivePayment: false,
        quickLinks: false
      });
    } else if (code === 'supplier') {
      setForm({
        customer: false,
        supplier: true,
        receivePayment: false,
        quickLinks: false
      });
    } else if (code === 'receive-payment') {
      setForm({
        customer: false,
        supplier: false,
        receivePayment: true,
        quickLinks: false
      });
    } else if (code === 'quick-links') {
      setForm({
        customer: false,
        supplier: false,
        receivePayment: false,
        quickLinks: true
      });
    }
  };

  useEffect(() => {
    const permission = {
      create_cs: getAcodaxPermissionSlug('CS', 'create'),
      create_supplier: getAcodaxPermissionSlug('supplier', 'create'),
      create_payment: getAcodaxPermissionSlug('CR', 'create')
    };

    const menuItemsArray = [
      {
        id: 1,
        name: Translate('Add Customer'),
        code: 'customer',
        icon: <FaUsers size={18} />,
        type: 'danger',
        slug: permission?.create_cs
      },
      {
        id: 2,
        name: Translate('Add Suppliers'),
        code: 'supplier',
        icon: <FaUserSecret size={18} />,
        type: 'info',
        slug: permission?.create_supplier
      },
      {
        id: 3,
        name: Translate('Receive Payment'),
        code: 'receive-payment',
        icon: <BiReceipt size={18} />,
        type: 'warning',
        slug: permission?.create_payment
      },
      {
        id: 4,
        name: Translate('Add Quick Links'),
        code: 'quick-links'
      }
    ];
    setMenuItems(menuItemsArray);
    window.addEventListener('scroll', () => {
      window.innerWidth < 1200 && setIsOpen(false);
    });
  }, [Translate]);

  const handleOnHide = () => {
    setForm({
      customer: false,
      supplier: false,
      receivePayment: false,
      quickLinks: false
    });
  };

  return (
    <>
      <Dropdown
        navbar={true}
        as="li"
        show={isOpen}
        onToggle={handleToggle}
        className="quick-links-dropdown"
      >
        <Dropdown.Toggle
          bsPrefix="toggle"
          variant="transparent"
          style={{ boxShadow: 'none', border: 'none' }}
          className="px-0 d-flex align-items-center justify-content-center quick-links-dropdown quick-links-driver-element"
        >
          <BsFillPatchPlusFill
            size={19}
            className="me-1"
            title={Translate('Quick Links')}
          />
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-card  dropdown-menu-center quick-links-dropdown ">
          <div className="quick-links-result-driver-element">
            <Dropdown.Header className="fs--1 text-dark fw-medium text-center">
              {Translate('Quick Links')}
            </Dropdown.Header>
            <Dropdown.Divider className="m-0" />
            <div className="bg-white rounded-2 pt-2 dark__bg-1000">
              {menuItems.map(item =>
                item?.slug ? (
                  <Dropdown.Item
                    key={item.id}
                    onClick={() => handleForm(item.code)}
                    className="d-flex gap-1 align-items-center"
                  >
                    {item.icon}
                    {item.name}
                  </Dropdown.Item>
                ) : item?.code === 'quick-links' ? (
                  <Fragment key={item.id}>
                    {user?.quick_links && user.quick_links.length > 0 ? (
                      <>
                        <Dropdown.Divider className="m-0" />
                        <div className="bg-white rounded-2 dark__bg-1000">
                          {user?.quick_links &&
                            user.quick_links.length > 0 &&
                            user.quick_links.map(item => (
                              <Dropdown.Item
                                key={item.id}
                                as={Link}
                                to={item.url}
                                className="d-flex gap-1 align-items-center"
                              >
                                <MdOutlineInsertLink size={18} />
                                {item.label}
                              </Dropdown.Item>
                            ))}
                        </div>
                        <Dropdown.Divider className="m-0 mb-1" />
                      </>
                    ) : (
                      ''
                    )}
                    <Dropdown.Item
                      key={item.id}
                      onClick={() => handleForm(item.code)}
                      style={{
                        border: '1px solid whitesmoke',
                        backgroundColor: 'whitesmoke',
                        width: '100px'
                      }}
                      className="d-flex gap-1 align-items-center justify-content-center ms-4 quick-links-add-button-driver-element"
                    >
                      {Translate('Add')}
                      <AiOutlinePlusSquare size={20} />
                    </Dropdown.Item>
                  </Fragment>
                ) : (
                  ''
                )
              )}
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
      <AddCustomer show={form.customer} onHide={handleOnHide} />
      <SupplierPopupForm show={form.supplier} onHide={handleOnHide} />
      <ReceivePayment show={form.receivePayment} onHide={handleOnHide} />
      <QuickLinksForm
        show={form.quickLinks}
        onHide={handleOnHide}
        handleToggle={handleToggle}
      />
    </>
  );
}

export default QuickForms;
