import { React, useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { Alert, Container, Table } from 'react-bootstrap';
import { EmployeeAllocationReportHead } from '../Common/data';
import { showToast } from 'module/Common/Toast/toast';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import ReportsContainer from 'components/report-components/reports-container/ReportsContainer';
import CustomerDetails from 'components/customer-details/CustomerDetails';
import TableHeader from '../Common/TableHeader';
import MinimalReportDataFetching from 'module/Common/Loading/MinimalReportDataFetching';
import ClickPerson from 'module/Common/ClickableData/ClickPerson';
import useReportApiCalls from '../Common/useReportApiCalls';
import AssetDetails from 'components/asset-details/AssetDetails';

function EmployeeAllocationReport() {
  const [queryParams] = useSearchParams();
  const reportApiCall = useReportApiCalls();
  const filterItems = queryParams.get('filters');
  const Translate = useAxisproTranslate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showCustomerDetailsArea, setCustomerDetailsArea] = useState(false);
  const [value, setValue] = useState({ value: 0 });
  const [formData, setFormData] = useState({});
  const [nextLink, setNextLink] = useState(null);
  const pageNumber = queryParams.get('page');
  const [showAssetDetailsArea, setShowAssetDetailsArea] = useState(false);
  const permissions = {
    show_user: getAcodaxPermissionSlug('user', 'show'),
    show_asset: getAcodaxPermissionSlug('FA', 'show')
  };
  useEffect(() => {
    const fetchData = async (page = 1) => {
      setLoading(true);
      try {
        const data = await reportApiCall(
          '/fixedasset/employee-allocation-report',
          {
            page: page
          }
        );
        if (data?.links?.next !== null) {
          setNextLink(data.links.next);
        } else {
          setNextLink(null);
        }
        setData(data.data);
      } catch (error) {
        console.error('Error fetching employee allocation report data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData(pageNumber);
  }, [pageNumber, filterItems, reportApiCall]);

  const clickOnCustomer = customer_id => {
    setValue({ value: customer_id });
    setCustomerDetailsArea(true);
  };

  const handleAssetDetailsArea = () => {
    setShowAssetDetailsArea(!showAssetDetailsArea);
    setValue({ value: 0 });
  };

  const clickOnAsset = asset_id => {
    setValue({ value: asset_id });
    setShowAssetDetailsArea(true);
  };

  const loadNextPage = useCallback(async () => {
    if (nextLink && nextLink !== null) {
      try {
        const res = await axios.get(nextLink);
        if (res.data.success) {
          setData(prevData => [...prevData, ...res.data.data.data]);
          setNextLink(res?.data?.data?.links?.next || null);
        }
      } catch (error) {
        console.error(error);
        showToast('Something went wrong, please contact admin', 'error');
      }
    }
  }, [nextLink, setData, setNextLink]);

  return (
    <>
      <ReportsContainer
        title={'Employee Allocation Report'}
        url="/fixedasset/employee-allocation-report/export"
        initialMessage={'Employee Allocation export started'}
        successMessage={'Employee Allocation export completed'}
        data={data}
        loading={loading}
        setFormData={setFormData}
        formData={formData}
        moduleName="employee_allocation_report"
      >
        <Container fluid className="p-0 reports-container-wrapper">
          <Table
            bordered={data?.length > 0 ? true : false}
            striped
            hover
            size="sm"
          >
            <TableHeader headers={EmployeeAllocationReportHead} />
            {data && data.length > 0 ? (
              <>
                {data.map((item, index) => (
                  <tbody key={index}>
                    <tr className="report-column-custom-style">
                      <td>
                        <span className="report-column-content-custom-style">
                          {index + 1}
                        </span>
                      </td>
                      <td>
                        <span className="name-column fs--1">
                          {item.allocation_id}
                        </span>
                      </td>
                      <td className="name-column fs--1">{item.stock_id}</td>
                      <td className="name-column fs--1">
                        {permissions.show_asset ? (
                          <a
                            className="report-column-content-custom-style cursor-pointer"
                            onClick={() => clickOnAsset(item.asset_id)}
                          >
                            {item?.asset_name}
                          </a>
                        ) : (
                          <span> {item?.asset_name}</span>
                        )}
                      </td>
                      <td>
                        <span className="name-column fs--1">
                          {item.issued_at_formatted}
                        </span>
                      </td>
                      <td>
                        <span className="name-column fs--1">
                          {item.expected_return_at_formatted}
                        </span>
                      </td>
                      <td>
                        <span className="name-column fs--1">
                          {item.returned_at_formatted}
                        </span>
                      </td>
                      <td>
                        <span className="name-column fs--1">
                          <ClickPerson
                            name={item.receiver_name}
                            person_type="USER"
                            className="fs--1"
                            permission={permissions?.show_user}
                            clickOnFunction={() =>
                              clickOnCustomer(item.receiver_id)
                            }
                          />
                        </span>
                      </td>
                      <td>
                        <span
                          className={`report-column-content-custom-style fs--2 ${
                            item?.status === 'returned'
                              ? 'text-success'
                              : item?.payment_status === 'Partially Paid'
                              ? 'text-warning'
                              : 'text-danger'
                          }`}
                        >
                          {item.status}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                ))}
                {nextLink && nextLink !== null && nextLink !== '' && (
                  <MinimalReportDataFetching
                    row={3}
                    column={12}
                    nextLink={nextLink}
                    loadNextPage={loadNextPage}
                  />
                )}
              </>
            ) : (
              <tr className="alert-styles">
                <td colSpan={12}>
                  <Alert variant="warning">
                    {Translate('Currently there is no report generated.')}
                  </Alert>
                </td>
              </tr>
            )}
          </Table>
        </Container>
      </ReportsContainer>
      <CustomerDetails
        show={showCustomerDetailsArea}
        onHide={() => {
          setCustomerDetailsArea(!showCustomerDetailsArea);
          setValue({ value: 0 });
        }}
        value={value}
        showCustomerDetailsArea={showCustomerDetailsArea}
      />
      <AssetDetails
        show={showAssetDetailsArea}
        onHide={handleAssetDetailsArea}
        value={value}
        showAssetDetailsArea={showAssetDetailsArea}
      />
    </>
  );
}

export default EmployeeAllocationReport;
