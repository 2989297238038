import React, { useEffect } from 'react';
import { Form, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import useAxisproTranslate from 'hooks/useAxisproTranslate';

export default function WorkOrderAdditionalCostsModalTableForm({
  WorkOrderDetailsArray,
  formData,
  setFormData,
  isCommonComponent
}) {
  const Translate = useAxisproTranslate();
  const WorkOrderDetailsArrayLength = WorkOrderDetailsArray?.length;

  function handleEditLineItem(event, index) {
    const inputName = event.target.name;
    if (formData?.costs?.[index]) {
      setFormData(prev => {
        prev.costs[index][inputName] = event.target.value;
        const qty = parseFloat(prev.costs[index].quantity);
        const unitPrice = parseFloat(prev.costs[index].unit_price);
        prev.costs[index].total_ref =
          (isNaN(unitPrice) ? 0 : unitPrice) * (isNaN(qty) ? 0 : qty);
        return {
          ...prev
        };
      });
    }
  }

  useEffect(() => {
    function setData(dataArray, prev) {
      const itemsTotalLength = !isNaN(WorkOrderDetailsArrayLength)
        ? WorkOrderDetailsArrayLength
        : 0;

      const totalQuanity = !isNaN(parseFloat(prev?.quantity))
        ? parseFloat(prev.quantity)
        : 0;

      const totalAmount = !isNaN(parseFloat(prev?.unit_price))
        ? parseFloat(prev.unit_price)
        : 0;

      let quantity = 0,
        balanceQuantity = 0,
        unitPrice = 0,
        balanceUnitPrice = 0;

      if (totalQuanity >= itemsTotalLength) {
        quantity = Math.floor(totalQuanity / itemsTotalLength);

        balanceQuantity = totalQuanity - quantity * (itemsTotalLength - 1);

        unitPrice = Math.floor(totalAmount / itemsTotalLength);

        balanceUnitPrice = totalAmount - unitPrice * (itemsTotalLength - 1);
      } else {
        balanceQuantity = totalQuanity;
        balanceUnitPrice = totalAmount;
      }

      return dataArray.reduce((newResult, item) => {
        if (isCommonComponent || parseFloat(item?.producing_quantity) > 0) {
          const newResultLength = newResult?.length;
          const itemQty = newResultLength > 0 ? quantity : balanceQuantity;
          const itemPrice = newResultLength > 0 ? unitPrice : balanceUnitPrice;
          const total = itemQty * itemPrice;
          const newItem = {
            work_order_detail_id: item?.id,
            item_name_ref: item?.item_display_name ?? '-',
            quantity: itemQty,
            unit_price: itemPrice,
            total_ref: total
          };

          newResult.push(newItem);
        }

        return newResult;
      }, []);
    }

    setFormData(prev => ({
      ...prev,
      costs: !Array.isArray(WorkOrderDetailsArray)
        ? []
        : setData(WorkOrderDetailsArray, prev)
    }));
  }, [
    WorkOrderDetailsArray,
    WorkOrderDetailsArrayLength,
    formData.quantity,
    formData.unit_price,
    isCommonComponent,
    setFormData
  ]);

  return (
    <Table
      striped
      bordered
      hover
      responsive
      className="simple-table-animation fs--2"
    >
      <thead className="bg-dark text-light text-uppercase">
        <tr>
          <th>{Translate('Item Name')}</th>
          <th>{Translate('Quantity')}</th>
          <th>{Translate('Amount')}</th>
          <th>{Translate('Total')}</th>
        </tr>
      </thead>
      <tbody>
        {formData?.costs?.map((item, index) => (
          <tr key={index}>
            <td>{item?.item_name_ref ?? '-'}</td>
            <td>
              <Form.Control
                type="number"
                name="quantity"
                min={1}
                value={item?.quantity}
                onChange={event => handleEditLineItem(event, index)}
              />
            </td>
            <td>
              <Form.Control
                type="number"
                step="0.1"
                min={0}
                name="unit_price"
                value={item?.unit_price}
                onChange={event => handleEditLineItem(event, index)}
              />
            </td>
            <td>{item?.total_ref}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

WorkOrderAdditionalCostsModalTableForm.propTypes = {
  WorkOrderDetailsArray: PropTypes.array,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  isCommonComponent: PropTypes.bool
};
