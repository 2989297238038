import { React, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Offcanvas } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './SupplierDetails.scss';

import { FaUserCog } from 'react-icons/fa';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import SupplierDetailsTab from './SupplierDetailsTab';
import OtherDetailsCollapse from './OtherDetailsCollapse';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';

const SupplierDetails = ({
  show,
  onHide,
  value,
  showSupplierDetailsArea,
  className
}) => {
  const Translate = useAxisproTranslate();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [activity, setActivity] = useState({});
  const [links, setLinks] = useState({});
  const [activityLoading, setActivityLoading] = useState(false);
  const permission = {
    show_supplier: getAcodaxPermissionSlug('supplier', 'show'),
    activity_log: getAcodaxPermissionSlug('LG', 'history')
  };

  useEffect(() => {
    const fetchData = async () => {
      if (permission?.show_supplier) {
        await axios
          .get(`purchase/supplier/${value.value}`)
          .then(res => {
            setData(res.data.data);
            setLoading(false);
          })
          .catch(error => {
            console.log(error);
          });
      }
      if (permission?.activity_log) {
        await axios
          .get(`activity-log`, { params: { supplier_id: value.value } })
          .then(res => {
            setActivity(res.data.data.data);
            setLinks(res.data.data);
            setActivityLoading(false);
          })
          .catch(error => {
            console.log(error);
          });
      }
    };

    setLoading(true);
    setActivityLoading(true);
    if (showSupplierDetailsArea) {
      fetchData();
    }
  }, [
    showSupplierDetailsArea,
    value.value,
    permission?.show_supplier,
    permission?.activity_log
  ]);

  return (
    <Offcanvas
      show={show}
      placement="end"
      onHide={onHide}
      className={className}
    >
      <Offcanvas.Header
        closeButton
        style={{ boxShadow: 'none' }}
        className={'offcanvas-heading-style'}
      >
        <div>
          <FaUserCog size={25} className="me-2" />
          {Translate('Supplier Details')}
        </div>
      </Offcanvas.Header>
      {!loading && data !== '' ? (
        <Offcanvas.Body className="supplier-details-offcanvas pt-0">
          <div className="supplier-header d-flex flex-row align-items-center">
            <div className="profile-icon d-flex justify-content-center align-items-center me-2 shadow-sm">
              <img
                className="profile-icon"
                src={data.image}
                alt="customer-image"
              />
            </div>
            <div className="details">
              <div className="d-flex">
                <h5 className="mt-1 me-2 text-uppercase">{data.name}</h5>
                <Link
                  to={
                    `/purchase/supplier?entry=${value.value}&layout=column&page=` +
                    ''
                  }
                >
                  <FontAwesomeIcon icon="external-link-alt" size="xs" />
                </Link>
              </div>
              <div className="d-flex mt-2">
                <h6 className="mb-0 text-muted">
                  {data.email_address}
                  {data.email_address ? (
                    <span className="me-1 mx-1">|</span>
                  ) : (
                    ''
                  )}
                  {data.contact_number}
                </h6>
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-between pt-3 pb-2">
            <div className="me-2 py-2">
              <h6 className="text-muted text-uppercase">
                {Translate('Outstanding Payable')}
              </h6>
              <h5 className="text-warning">
                {data.total_outstanding_formatted}
              </h5>
            </div>
            <div className="py-2">
              <h6 className="text-muted text-uppercase">
                {Translate('Unused Credits')}
              </h6>
              <h5 className="text-success">{data.unused_credits_formatted}</h5>
            </div>
          </div>
          {data.address || data.secondary_contact_number ? (
            <OtherDetailsCollapse data={data} />
          ) : (
            ''
          )}

          <SupplierDetailsTab
            data={data}
            activity={activity}
            activityLoading={activityLoading}
            setActivity={setActivity}
            links={links}
            setLinks={setLinks}
          />
        </Offcanvas.Body>
      ) : (
        <LoadingScreen />
      )}
    </Offcanvas>
  );
};

SupplierDetails.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  value: PropTypes.object,
  showSupplierDetailsArea: PropTypes.bool,
  className: PropTypes.string
};

export default SupplierDetails;
