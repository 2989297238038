import { React, useContext, useEffect, useState } from 'react';
import axios from 'axios';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { AuthWizardContext } from 'context/Context';
import { showToast } from 'module/Common/Toast/toast';
import SelectBranch from 'components/form/SelectBranch';
import FormLayer from 'module/Common/Layer/FormLayer';
import { ConfirmationBox } from 'module/Common/ArrowKeyController/ConfirmationBox';

function ChangeBranch() {
  const { user } = useContext(AuthWizardContext);
  const Translate = useAxisproTranslate();
  const [showConfirmationBox, setShowConfirmationBox] = useState(false);
  const [branchData, setBranchData] = useState({});
  const [enterDetails, setEnterDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (user?.branch_id) {
      setBranchData({
        branch_id: user?.branch_id,
        branch_id_ref: {
          label: user?.branch?.branch_name,
          value: user?.branch_id
        }
      });
    }
  }, [user]);

  const branchChange = data => {
    setIsLoading(true);
    setShowConfirmationBox(false);
    axios({
      method: 'put',
      url: `user/${user?.id}`,
      data: data
    })
      .then(response => {
        setIsLoading(false);
        setEnterDetails({});
        if (response.data.success) {
          showToast(response.data.message, 'success');
          window.location.reload();
        } else {
          showToast(
            Translate(
              `Something went wrong, please refresh the page and try again.`
            ),
            'error'
          );
        }
      })
      .catch(error => {
        if (error.response.data && error.response.data.message) {
          showToast(Translate(error.response.data.message), 'error');
        } else {
          showToast(
            Translate(
              `Something went wrong, please refresh the page and try again.`
            ),
            'error'
          );
        }
      });
  };

  const handleFieldChange = (e, action) => {
    if (action?.action === 'clear') {
      setBranchData({
        branch_id: user?.branch_id,
        branch_id_ref: {
          label: user?.branch?.branch_name,
          value: user?.branch_id
        }
      });
    } else {
      let data = {
        first_name: user?.first_name,
        last_name: user?.last_name,
        email: user?.email,
        username: user?.username,
        branch_id: e?.value,
        company_id: user?.branch?.company_id,
        time_zone: user?.time_zone
      };
      setEnterDetails(data);
      setShowConfirmationBox(true);
      setBranchData({
        branch_id: e?.value,
        branch_id_ref: e
      });
    }
  };

  const handleCancel = () => {
    if (user?.branch_id) {
      setBranchData({
        branch_id: user?.branch_id,
        branch_id_ref: {
          label: user?.branch?.branch_name,
          value: user?.branch_id
        }
      });
    }
    setShowConfirmationBox(false);
  };

  return (
    <div
      className="flex-fill me-2 form-layer-container"
      style={{ width: '13rem' }}
    >
      {isLoading && <FormLayer style={{ zIndex: 1016, position: 'fixed' }} />}
      <SelectBranch
        name={'branch_id'}
        value={branchData?.branch_id_ref}
        handleFieldChange={handleFieldChange}
        navbar
      />
      <ConfirmationBox
        show={showConfirmationBox && branchData?.branch_id !== user?.branch_id}
        onHide={handleCancel}
        handleSubmit={() => branchChange(enterDetails)}
        title={'Confirm Branch Change'}
        content={
          <>
            Are you sure you want to switch to{' '}
            <strong className="fw-bold text-dark">
              {branchData?.branch_id_ref?.label ?? ''}
            </strong>
            ?
          </>
        }
        specificContent
        selectButton
      />
    </div>
  );
}

export default ChangeBranch;
