import { TranslationsContext } from 'context/Context';
import React, { useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import translationsContextReducer from 'reducers/translationsContextReducer';

export default function TranslationsContextProvider({ children }) {
  const [translations, dispatchTranslations] = useReducer(
    translationsContextReducer,
    useContext(TranslationsContext)
  );
  return (
    <TranslationsContext.Provider
      value={{ translations, dispatchTranslations }}
    >
      {children}
    </TranslationsContext.Provider>
  );
}

TranslationsContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};
