import axios from 'axios';
import { StoreContext } from 'context/Context';
import FormattedData from 'module/Common/TableFilter/FormatedData';
import { showToast } from 'module/Common/Toast/toast';
import { useCallback, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
const useReportApiCalls = () => {
  const { store } = useContext(StoreContext);
  const [queryParams] = useSearchParams();
  const filterItems = queryParams.get('filters');
  const RowsPerPage = queryParams.get('rows_per_page');
  const branch_id = store?.globalConfiguration?.branch_id;
  const reportApiCall = useCallback(
    (url, params, fullResponse, isBranchWise = true) => {
      const branchParams = isBranchWise ? { branch_id: branch_id } : {};
      return new Promise((resolve, reject) => {
        axios
          .get(url, {
            params: {
              ...branchParams,
              ...(RowsPerPage && { rows_per_page: RowsPerPage }),
              ...params,
              ...FormattedData(JSON.parse(filterItems))
            }
          })
          .then(response => {
            if (response.data.success) {
              if (fullResponse) {
                resolve(response.data);
              } else {
                resolve(response.data.data);
              }
            }
          })
          .catch(error => {
            if (error) {
              showToast(
                'Something went wrong! Please contact Admin for further details!',
                'error'
              );
            }
            reject(error);
          });
      });
    },
    [RowsPerPage, filterItems, branch_id]
  );
  return reportApiCall;
};
export default useReportApiCalls;
