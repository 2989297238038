import React, { useEffect } from 'react';
import { Image, Row } from 'react-bootstrap';
import { FaArrowRight } from 'react-icons/fa';
import PropTypes from 'prop-types';
import {
  createSearchParams,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import { removeParamsFunc } from 'module/Common/Hooks/useRemoveParams';
const MultiTenanceyList = ({ compnayData }) => {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  useEffect(() => {
    if (compnayData?.length <= 0) {
      navigate('/authentication/login');
    }
  }, [compnayData?.length, navigate]);
  return (
    <div className="w-100 p-0 m-0 mutli-tenant-list">
      {compnayData?.map((items, index) => {
        return (
          <Row
            className={`w-100 p-0 m-0 ${
              compnayData?.length - 1 !== index && 'border-bottom  mb-4'
            } `}
            key={index}
          >
            <div className="tenant-wrapper d-flex align-items-center justify">
              <div className="d-flex align-items-center justify-content-start w-100">
                <Image
                  style={{ width: '50px', height: '50px' }}
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQsAIK8vMtGcFg41fpEHnQ1ndzth2JpjTM4z9iYpvFfbZ4F-NAb6SkNludIrfR3B1a65I8&usqp=CAU"
                  roundedCircle
                />
                <div>
                  <div
                    className="ms-2 text-dark name"
                    style={{ fontSize: '13px' }}
                  >
                    {items?.name}
                  </div>
                  <div className="ms-2 " style={{ fontSize: '10px' }}>
                    {items?.name}
                  </div>
                </div>
              </div>
              <div className="login-button">
                <button
                  size="sm"
                  className="login-button d-flex align-items-center justify-content-center"
                  onClick={() =>
                    navigate(
                      `/authentication/tenant-login?${createSearchParams(
                        removeParamsFunc(
                          {
                            ...allQueryParams,
                            tenant_id: items?.id
                          },
                          ['tenancy_list']
                        )
                      )}`
                    )
                  }
                >
                  {' '}
                  LOGIN <FaArrowRight className="ms-2" />
                </button>
              </div>
            </div>
          </Row>
        );
      })}
    </div>
  );
};
MultiTenanceyList.propTypes = {
  compnayData: PropTypes.array
};
export default MultiTenanceyList;
