import axios from 'axios';

export function getBaseUrl() {
  return new Promise((resolve, reject) => {
    const defaultRejectResponseData = {
      requestName: 'host',
      message: 'Invalid host'
    };
    const urlObject = new URL(window.location.href);
    if (
      (process.env.REACT_APP_API_DEVELOPMENT_URL &&
        process.env.REACT_APP_API_CUSTOM_DEVELOPMENT == 'DEV') ||
      process.env.REACT_APP_API_CUSTOM_REQUEST_URL == urlObject.origin
    ) {
      resolve({
        api_url: process.env.REACT_APP_API_DEVELOPMENT_URL,
        multi_tenancy: false
      });
    } else {
      axios
        .get(process.env.REACT_APP_SET_API_URL, {
          headers: {
            withCredentials: true,
            settingBaseUrl: true
          }
        })
        .then(response => {
          const urlData = response?.data;
          if (urlData?.api_url) {
            resolve(urlData);
          }

          reject(defaultRejectResponseData);
        })
        .catch(error => {
          reject({ ...defaultRejectResponseData, ...error });
        });
    }
  });
}

export function getLoggedUserInfo() {
  return new Promise((resolve, reject) => {
    const defaultRejectResponseData = {
      requestName: 'user',
      invalidUser: true
    };
    const loggedUserLocalStorageData = localStorage.getItem('AXIS_PRO_USER');
    if (loggedUserLocalStorageData) {
      const loggedUserData = JSON.parse(loggedUserLocalStorageData);
      if (loggedUserData?.token) {
        axios
          .get('user/info')
          .then(response => {
            const loggedUserUpdatedInfo = response?.data?.data;
            if (loggedUserUpdatedInfo?.id) {
              resolve({
                ...loggedUserUpdatedInfo,
                token: loggedUserData.token
              });
            } else {
              reject(defaultRejectResponseData);
            }
          })
          .catch(error => {
            reject({ ...defaultRejectResponseData, ...error });
          });
      } else {
        reject(defaultRejectResponseData);
      }
    } else {
      reject(defaultRejectResponseData);
    }
  });
}

export const MENU_LINKS_LOCAL_STORAGE_KEY = 'ACODAX_MENU_LINKS';
export function setMenuLinks(update) {
  return new Promise((resolve, reject) => {
    const defaultRejectResponseData = {
      requestName: 'menuLinks',
      message: 'No results found.'
    };
    const menuLinks = localStorage.getItem(MENU_LINKS_LOCAL_STORAGE_KEY);

    if (!update && menuLinks) {
      resolve(JSON.parse(menuLinks));
    } else {
      axios
        .get('user/erp/menu-links')
        .then(response => {
          const menuLinksResult = response?.data?.data;
          if (!menuLinksResult) {
            reject(defaultRejectResponseData);
          }

          localStorage.setItem(
            MENU_LINKS_LOCAL_STORAGE_KEY,
            JSON.stringify(menuLinksResult)
          );

          resolve(menuLinksResult);
        })
        .catch(error => {
          reject({ ...defaultRejectResponseData, ...error });
        });
    }
  });
}

export function setAssignedPermissions(update) {
  return new Promise((resolve, reject) => {
    const defaultRejectResponseData = {
      requestName: 'permission',
      message: 'No results found.'
    };
    const assignedPermissionsLocalStorageData =
      localStorage.getItem('ACODAX_PERMISSION');

    if (!update && assignedPermissionsLocalStorageData) {
      resolve(assignedPermissionsLocalStorageData);
    } else {
      axios
        .get('auth/permissions')
        .then(response => {
          const permissionsResult = response?.data?.data;
          if (!permissionsResult) {
            reject(defaultRejectResponseData);
          }

          localStorage.setItem(
            'ACODAX_PERMISSION',
            JSON.stringify(permissionsResult)
          );

          resolve(permissionsResult);
        })
        .catch(error => {
          reject({ ...defaultRejectResponseData, ...error });
        });
    }
  });
}

export function setTranslations(language) {
  return new Promise((resolve, reject) => {
    const defaultRejectResponseData = {
      requestName: 'translation',
      message: 'Provide a valid language to set the translations.'
    };
    const translationsLocalStorageData =
      localStorage.getItem('ACODAX_TRANSLATION');

    if (translationsLocalStorageData) {
      resolve(JSON.parse(translationsLocalStorageData));
    } else {
      if (!language) {
        reject(defaultRejectResponseData);
        return;
      }

      axios
        .get(`/translations?locale=${language}`)
        .then(response => {
          const translationsResult = response?.data?.data;
          if (!translationsResult) {
            reject({
              ...defaultRejectResponseData,
              message: 'No results found for the selected language.'
            });

            return;
          }

          localStorage.setItem(
            'ACODAX_TRANSLATION',
            JSON.stringify(translationsResult)
          );

          resolve(translationsResult);
        })
        .catch(error => {
          reject({ ...defaultRejectResponseData, ...error });
        });
    }
  });
}

export function handleUserWebSocketConnection(userData, action, callback) {
  if (action === 'START') {
    window.Echo.channel(`app-broadcast-${userData?.branch_id}`).listen(
      'AcodaxAppBranchBroadCast',
      settingsData => {
        if (
          settingsData?.type === 'settings.updated' &&
          typeof callback?.AcodaxAppBranchBroadCast === 'function'
        ) {
          callback.AcodaxAppBranchBroadCast(settingsData);
        }
      }
    );

    window.Echo.channel(
      `app-broadcast-${userData?.branch_id}-${userData?.id}`
    ).listen('AcodaxAppUserBroadCast', settingsData => {
      if (
        settingsData?.type === 'permissions.updated' &&
        typeof callback?.AcodaxAppUserBroadCast === 'function'
      ) {
        callback.AcodaxAppUserBroadCast(settingsData);
      }
    });
  }

  if (action === 'STOP') {
    window.Echo.channel(`app-broadcast-${userData?.branch_id}`).stopListening(
      'AcodaxAppBroadCast'
    );
    window.Echo.channel(
      `app-broadcast-${userData?.branch_id}-${userData?.id}`
    ).stopListening('AcodaxAppBroadCast');
  }
}
