import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { _validatePassword } from 'module/System Admin/UserRegistration/Common/InputValidators';
import { StoreContext } from 'context/Context';
import { FaExclamationCircle } from 'react-icons/fa';
import { FaCircleCheck } from 'react-icons/fa6';
const PasswordPolicyValidationInput = ({
  name,
  handleFieldChange,
  formError,
  formData,
  handleError,
  HideValidation
}) => {
  const { store } = useContext(StoreContext);
  const [showPasswordPolicy, setShowPasswordPolicy] = useState(false);
  const [valid, setValid] = useState({
    password: []
  });
  const updateValid = error => {
    setValid(() => ({
      ...error
    }));
  };
  const hasErrors = valid?.password?.some(error => error.error);
  const handleChange = e => {
    updateValid({
      password: _validatePassword(
        e?.target?.value,
        formData?.username,
        store?.globalConfiguration?.system_settings ?? ''
      )
    });

    handleFieldChange(e);
  };

  const stringifiedFormData = JSON.stringify(formData);

  useEffect(() => {
    const parsedFormData = JSON.parse(stringifiedFormData);
    handleError(hasErrors && parsedFormData[name]);
  }, [valid, stringifiedFormData, handleError, hasErrors, name]);

  const popover = (
    <Popover className="w-100">
      <Popover.Body>
        <ul className="password-validation-list">
          {valid?.password.map((items, index) => (
            <li
              key={index}
              className={`d-flex  ${
                items?.error ? 'text-danger' : 'text-success'
              } mt-1`}
            >
              {items?.error ? (
                <FaExclamationCircle className=" mt-1" />
              ) : (
                <FaCircleCheck className="mt-1" />
              )}
              <div className="ms-2">{items?.message}</div>
            </li>
          ))}
        </ul>
      </Popover.Body>
    </Popover>
  );
  return (
    <>
      <OverlayTrigger
        trigger="click"
        placement="bottom"
        overlay={valid?.password.length > 0 ? popover : <></>}
        show={showPasswordPolicy && hasErrors}
      >
        <Form.Control
          type="password"
          name={name}
          onFocus={() => setShowPasswordPolicy(true)}
          onBlur={() => setShowPasswordPolicy(false)}
          onChange={handleChange}
          value={formData[name]}
          isInvalid={!!(formError[name] || (hasErrors && formData[name]))}
        />
      </OverlayTrigger>
      {!HideValidation && (
        <Form.Control.Feedback type="invalid">
          {formError[name]}
        </Form.Control.Feedback>
      )}
    </>
  );
};
PasswordPolicyValidationInput.propTypes = {
  handleFieldChange: PropTypes.func,
  formError: PropTypes.object,
  formData: PropTypes.object,
  name: PropTypes.string,
  handleError: PropTypes.func,
  HideValidation: PropTypes.bool
};
export default PasswordPolicyValidationInput;
