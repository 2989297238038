import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Offcanvas, ButtonGroup } from 'react-bootstrap';
import Layout1 from 'assets/img/generic/Layout-1.png';
import Layout2 from 'assets/img/generic/Layout-2.png';
import RadioItem from './RadioItem';
import PropTypes from 'prop-types';
import {
  Layout_1,
  Layout_2,
  dashboardCardName
} from '../../module/Dashboard/DashboardLayout';
import Flex from 'components/common/Flex';
import { RiAppsLine } from 'react-icons/ri';
import { FcPlus } from 'react-icons/fc';
import useAxisproTranslate from 'hooks/useAxisproTranslate';

const SettingsPanel = ({
  setLayout,
  handleModal,
  customModal,
  selectLayout,
  deletedLayout,
  setDeletedLayout,
  size
}) => {
  const Translate = useAxisproTranslate();
  const areArraysEqual = (array1, array2) =>
    JSON.stringify(array1) === JSON.stringify(array2);
  // const screenSize = () => {
  //   const { innerWidth } = window;
  //   if (innerWidth >= 1540) {
  //     return 'xl';
  //   } else if (innerWidth < 1540 && innerWidth >= 770) {
  //     return 'md';
  //   } else if (innerWidth < 770) {
  //     return 'sm';
  //   } else {
  //     return 'xl';
  //   }
  // };
  const [defaultLayout, setDefaultLayout] = useState([]);
  useEffect(() => {
    setDefaultLayout([
      {
        name: Translate('Default Layout'),
        active: areArraysEqual(Layout_1[size], selectLayout[size]),
        layoutItem: Layout_1[size],
        image: Layout1,
        full_layout: Layout_1
      },
      {
        name: Translate('Layout-1'),
        active: areArraysEqual(Layout_2[size], selectLayout[size]),
        layoutItem: Layout_2[size],
        image: Layout2,
        full_layout: Layout_2
      }
    ]);
  }, [selectLayout, size, Translate]);
  const handleLayout = (layout, key) => {
    setDeletedLayout({});
    setLayout(layout);
    setDefaultLayout(prevLayouts =>
      prevLayouts.map((item, index) => {
        if (index === key) {
          return {
            ...item,
            active: areArraysEqual(layout, item.layoutItem),
            layoutItem: layout
          };
        } else {
          return {
            ...item,
            active: false
          };
        }
      })
    );
  };

  const handleAddItem = itemToAdd => {
    const newLayout = deletedLayout[size].map(item => {
      if (item?.i === itemToAdd) {
        setLayout(prev => [...prev, item]);
        return undefined;
      } else {
        return item;
      }
    });

    setDeletedLayout(prev => ({
      ...prev,
      [size]: newLayout.filter(Boolean || null)
    }));
  };
  return (
    <Offcanvas
      show={customModal}
      onHide={handleModal}
      placement="end"
      style={{ maxWidth: '22rem' }}
      className="border-0"
    >
      <Offcanvas.Header
        closeButton
        closeVariant="white"
        className="bg-shape "
        style={{ backgroundColor: '#253E53' }}
      >
        <Offcanvas.Title as="div" className="py-1 flex-grow-1 light">
          <h5 className="text-white">
            <FontAwesomeIcon icon="palette" className="me-2 fs-0" />
            {Translate('Customize')}
          </h5>
          <p className="mb-0 fs--1 text-white opacity-75">
            {Translate('Set your own customized dashboard')}
          </p>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="scrollbar">
        <h5 className="fs-0">{Translate('Dashboard Layout')}</h5>
        <p className="fs--1">
          {Translate('Choose the perfect layout for your app')}.
        </p>

        <ButtonGroup className="btn-group-navbar-style">
          {defaultLayout.map((item, index) => {
            return (
              <RadioItem
                key={index}
                name="theme-mode"
                label={item.name}
                active={item.active}
                onChange={() => handleLayout(item.layoutItem, index)}
                image={item.image}
              />
            );
          })}
        </ButtonGroup>
        {Object.keys(deletedLayout).length > 0 && (
          <>
            <h5 className="fs-0">{Translate('Heading')}</h5>
            <hr />
            {deletedLayout[size]?.map((item, index) => {
              if (dashboardCardName[item.i]) {
                return (
                  <>
                    <Flex justifyContent="between" key={index} className="mt-2">
                      <RiAppsLine size={17} className="me-2" />
                      <div className="flex-1">
                        <p className="fs--1 mb-0">
                          {Translate(dashboardCardName[item.i])}{' '}
                        </p>
                      </div>

                      <FcPlus
                        size={20}
                        className="me-2 cursor-pointer"
                        onClick={() => {
                          handleAddItem(item.i);
                        }}
                      />
                    </Flex>
                    <hr />
                  </>
                );
              }
            })}
          </>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

SettingsPanel.propTypes = {
  setLayout: PropTypes.any,
  layout: PropTypes.array,
  handleModal: PropTypes.any,
  customModal: PropTypes.bool,
  setSelectedLayout: PropTypes.any,
  selectLayout: PropTypes.any,
  deletedLayout: PropTypes.any,
  setDeletedLayout: PropTypes.any,
  size: PropTypes.string
};
export default SettingsPanel;
