import { React, useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { MdOutlinePayments } from 'react-icons/md';

import { apiCall } from 'helpers/apiCalls';
import { showToast } from 'module/Common/Toast/toast';
import { FollowUpFormKeys } from 'helpers/formKeys';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import AppDatePicker from 'components/app-date-picker/AppDatePicker';
import ErrorAlert from 'module/Common/Error/ErrorAlert';
import setNewFormData from 'helpers/setNewFormData';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
function FollowUpAddPopup({ show, person, invoiceData, onHide, fetchData }) {
  const Translate = useAxisproTranslate();
  const [isLoading, setIsLoading] = useState(false);
  const [queryParams] = useSearchParams();
  const edit_id = queryParams.get('edit_id');
  const [formData, setFormData] = useState(FollowUpFormKeys);
  const [formError, setFormError] = useState({});
  const [saving, setSaving] = useState(false);

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  useEffect(() => {
    // formData.person_id = person.personId;
    // formData.person_type = person.personType.toLowerCase();
    // formData.subject_id = invoiceData.id;
    // formData.subject_type = 'SI';
    setFormData(prev => ({
      ...prev,
      person_id: person.personId,
      person_type: person.personType.toLowerCase(),
      subject_id: invoiceData.id,
      subject_type: 'SI'
    }));
  }, [invoiceData, person]);

  const handleSubmit = e => {
    const newFormData = setNewFormData(formData);
    e.preventDefault();
    setSaving(true);
    axios({
      method: edit_id ? 'put' : 'post',
      url: `sales/followups${
        edit_id ? '/' + edit_id + '?' + new URLSearchParams(newFormData) : ''
      }`,
      ...(!edit_id && { data: newFormData })
    })
      .then(response => {
        if (response.data.success === true) {
          showToast(response.data.message, 'success');
          onHide();
          fetchData();
          setFormError({});
          setSaving(false);
          setFormData(FollowUpFormKeys);
        } else {
          showToast(
            'Something went wrong, please refresh the page and try again.',
            'error'
          );
        }
        setSaving(false);
      })
      .catch(error => {
        setSaving(false);
        if (error.response.data && error.response.data.message) {
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data
              ? error.response.data.data
              : '';
          validation_error && setFormError({ ...validation_error.errors });
        } else {
          showToast(
            'Something went wrong, please refresh the page and try again.',
            'error'
          );
        }
        setSaving(false);
      });
  };

  useEffect(() => {
    if (edit_id) {
      const getData = async () => {
        setIsLoading(true);
        const data = await apiCall({
          url: `sales/followups/${edit_id}`
        });
        setFormData(prev => ({
          ...prev,
          followup_date: data.followup_date,
          next_followup_date: data.next_followup_date,
          memo: data.memo
        }));
        setIsLoading(false);
      };

      getData();
    }
  }, [edit_id]);

  const handleCancel = () => {
    setFormData(FollowUpFormKeys);
    onHide();
  };

  return (
    <>
      <Modal
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        style={{ zIndex: 9998 }}
        className="modal-with-overlay"
      >
        <Card>
          <Card.Header className="fs-1 text-dark d-flex align-items-center border-bottom">
            <MdOutlinePayments size={20} className={'me-2'} />
            {Translate(edit_id ? 'Edit Follow Up' : 'Add Follow Up')}&nbsp;
            <span style={{ cursor: 'pointer' }}>- {invoiceData.reference}</span>
          </Card.Header>
          <Form onSubmit={handleSubmit}>
            {isLoading ? (
              <div className="mt-5 ">
                <LoadingScreen />
              </div>
            ) : (
              <Card.Body className="pt-1">
                <Col md={12} sm={12} className={'m-0'}>
                  <div className="p-2 ps-0 d-flex align-items-center">
                    {Translate('Customer Name')}
                    &nbsp;: &nbsp;
                    <span className="fw-bold text-warning">
                      {invoiceData.customer_name}
                    </span>
                  </div>
                </Col>
                <Col md={12} sm={12} className={'m-0'}>
                  <div className="p-2 ps-0 d-flex align-items-center">
                    {Translate('Person Type')}
                    &nbsp;: &nbsp;
                    <span className="fw-bold ">{person.personType}</span>
                  </div>
                </Col>
                <div>
                  {!Object.keys(formError).length == 0 ? (
                    <ErrorAlert
                      setFormError={setFormError}
                      formError={formError}
                    />
                  ) : (
                    ''
                  )}
                </div>
                <Form.Group as={Row} className="mt-2">
                  <Col md={12} sm={12}>
                    <Form.Label className="m-0 ">
                      {Translate('Follow Up Date')}
                    </Form.Label>
                    <AppDatePicker
                      name="followup_date"
                      value={formData.followup_date}
                      yearPlaceholder="yyyy"
                      monthPlaceholder="mm"
                      dayPlaceholder="dd"
                      onChange={handleFieldChange}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mt-3">
                  <Col md={12} sm={12}>
                    <Form.Label className="m-0 ">
                      {Translate('Notes')}
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={6}
                      type="text"
                      name="memo"
                      value={formData.memo}
                      onChange={handleFieldChange}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mt-3">
                  <Col md={12} sm={12}>
                    <Form.Label className="m-0 ">
                      {Translate('Next Follow Up Date')}
                    </Form.Label>
                    <AppDatePicker
                      name="next_followup_date"
                      value={formData.next_followup_date}
                      yearPlaceholder="yyyy"
                      monthPlaceholder="mm"
                      dayPlaceholder="dd"
                      onChange={handleFieldChange}
                    />
                  </Col>
                </Form.Group>
              </Card.Body>
            )}
            <Card.Footer className="d-flex gap-2">
              <Button
                variant={edit_id ? 'info' : 'success'}
                size="sm"
                type={'submit'}
                disabled={saving}
              >
                {!saving
                  ? edit_id
                    ? Translate('Edit Follow Up')
                    : Translate('Add Follow Up')
                  : Translate('Saving') + '...'}
              </Button>
              <Button
                variant="danger"
                size="sm"
                disabled={saving}
                onClick={handleCancel}
              >
                {Translate('Cancel')}
              </Button>
            </Card.Footer>
          </Form>
        </Card>
      </Modal>
    </>
  );
}

FollowUpAddPopup.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  invoiceData: PropTypes.any,
  fetchData: PropTypes.func,
  person: PropTypes.object,
  dueAmount: PropTypes.number
};

export default FollowUpAddPopup;
