import React, { useCallback, useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import axios from 'axios';
import TreeView from './TreeView';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import useAxisproTranslate from 'hooks/useAxisproTranslate';

const SubCategoryTree = ({ show, setShow, setFromData, categoryId, name }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const Translate = useAxisproTranslate();
  const handleClose = () => setShow(false);

  const fetchCategory = useCallback(() => {
    if (categoryId) {
      setFromData(prev => ({
        ...prev,
        [name]: '',
        [name + '_name']: ''
      }));
      setIsLoading(true);
      axios
        .get(`settings/categories/${categoryId}`)
        .then(res => {
          console.log(res.data.data);

          setCategories(res.data.data);
          setIsLoading(false);
        })
        .catch(error => {
          console.error('Error fetching categories:', error);
          setIsLoading(false);
        });
    }
  }, [categoryId, setFromData, name]);

  const handleSelect = category => {
    setFromData(prev => ({
      ...prev,
      [name]: category?.id,
      [name + '_name']: category?.name
    }));
    handleClose();
  };
  useEffect(() => {
    fetchCategory();
  }, [fetchCategory]);

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title> {Translate('SUB CATEGORY')} </Modal.Title>
      </Modal.Header>
      <Modal.Body className="mb-4">
        {isLoading ? (
          <LoadingScreen />
        ) : categories?.subcategories?.length > 0 ? (
          <TreeView data={[categories]} handleSelect={handleSelect} />
        ) : (
          <p>{Translate('No categories found')}</p>
        )}
      </Modal.Body>
    </Modal>
  );
};

SubCategoryTree.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  setFromData: PropTypes.func,
  categoryId: PropTypes.string,
  name: PropTypes.string
};

export default SubCategoryTree;
