import { React } from 'react';
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';
import useAutoCompleteApiCallHandler from 'hooks/useAutoCompleteApiCallHandler';

function SelectFilterItem({
  handleFieldChange,
  name,
  value,
  onFocus,
  stockItemList,
  type,
  filter_keys = {},
  placeholder,
  error,
  style
}) {
  const { fetchData, setDefaultResult, loading, defaultOptions } =
    useAutoCompleteApiCallHandler({
      url: 'inventory/items-auto',
      setParams: searchKey => ({
        item_name: searchKey,
        exclude_kits: stockItemList ? 1 : type ? 1 : 0,
        product_type: type ? 'govt_service' : '',
        ...filter_keys
      }),
      setOptions: option => setOptionData(option),
      onFocus: onFocus,
      dependencyKeyToResetDefaultOptions: JSON.stringify(filter_keys)
    });
  const setOptionData = option => ({
    label: option.item_name,
    value: option.stock_id
  });

  return (
    <AsyncSelect
      cacheOptions
      isClearable
      isLoading={loading}
      loadOptions={fetchData}
      defaultOptions={defaultOptions}
      onFocus={setDefaultResult}
      name={name ? name : 'item'}
      value={value ?? ''}
      isSelected={true}
      placeholder={placeholder ?? ''}
      onChange={handleFieldChange}
      className={error && 'form-control ps-0 py-0 is-invalid'}
      styles={{
        menuPortal: provided => ({ ...provided, zIndex: 9999 }),
        menu: provided => ({
          ...provided,
          zIndex: 9999,
          fontSize: '13px',
          borderRadius: '0px'
        }),
        control: provided => ({
          ...provided,
          height: 32,
          minHeight: 32,
          ...style
        })
      }}
      menuPortalTarget={document.body}
    />
  );
}

SelectFilterItem.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.any,
  value: PropTypes.any,
  companyId: PropTypes.any,
  stockItemList: PropTypes.bool,
  type: PropTypes.bool,
  children: PropTypes.any,
  filter_keys: PropTypes.object,
  onFocus: PropTypes.any,
  placeholder: PropTypes.string,
  error: PropTypes.any,
  style: PropTypes.object
};

export default SelectFilterItem;
