import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import generateFilterFormData from 'helpers/generateFilterFormData';
import { FaTimes } from 'react-icons/fa';
import AppDatePicker from 'components/app-date-picker/AppDatePicker';

const FilterItem = ({
  item,
  index,
  options,
  filters,
  setActiveFilters,
  setChangeEvent,
  changeEvent
}) => {
  const [customeElement, setcustomeElement] = useState('DEFAULT');
  const name = item.column ?? null;
  const handleFieldChange = (e, action) => {
    setChangeEvent(!changeEvent);
    let newFormData = generateFilterFormData(e, action, item);
    if (!action && e) {
      if (e.target.name === 'column') {
        let filterItemData = filters[newFormData.column];
        newFormData.type = filterItemData
          ? filterItemData.type ?? 'string'
          : null;
        newFormData.operator = filterItemData?.operators?.[0]?.value ?? '';
        newFormData.value = '';
        newFormData.value_ref = null;
      } else if (e.target.name === 'operator' && e.target.value === 'BETWEEN') {
        delete newFormData.value;
        delete newFormData.value_ref;
        newFormData.value_from = '';
        newFormData.value_to = '';
      } else if (e.target.name === 'operator') {
        delete newFormData?.value_from;
        delete newFormData?.value_to;
        newFormData.value = '';
        newFormData.value_ref = null;
      }
    }

    setActiveFilters(prev =>
      prev.map((filterItem, key) => {
        return key === index ? newFormData : filterItem;
      })
    );
  };

  const handleRemoveItem = () => {
    setActiveFilters(prev => prev.filter((filterItem, key) => key !== index));
  };

  useEffect(() => {
    if (name && filters[name].Element) {
      let Element = filters[name].Element;
      setcustomeElement(
        typeof Element === 'function'
          ? Element({
              ...item,
              name: 'value',
              handleFieldChange: handleFieldChange
            })
          : Element
      );
    }

    return () => {
      setcustomeElement('DEFAULT');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  return (
    <tr>
      <td
        className="p-2 border-end"
        style={{ width: '240px', maxWidth: '300px' }}
      >
        {options && Array.isArray(options) ? (
          <Form.Select
            className="py-1"
            value={item.column}
            name="column"
            onChange={handleFieldChange}
          >
            <option value="">Select</option>
            {options.map((option, index) => (
              <option
                value={option}
                key={index}
                hidden={!filters[option].active}
              >
                {filters[option].label}
              </option>
            ))}
          </Form.Select>
        ) : null}
      </td>
      <td
        className="p-2 border-end"
        style={{ width: '200px', maxWidth: '200px' }}
      >
        <Form.Select
          className="py-1"
          name="operator"
          value={item.operator}
          onChange={handleFieldChange}
        >
          <option value="">Select</option>
          {name && Array.isArray(filters[name].operators)
            ? filters[name].operators.map((option, key) => (
                <option value={option.value} key={key}>
                  {option.name}
                </option>
              ))
            : null}
        </Form.Select>
      </td>
      <td className="p-2 d-flex flex-row">
        {['DEFAULT', 'STRING', 'NUMBER'].includes(customeElement)
          ? [...Array(item.operator === 'BETWEEN' ? 2 : 1).keys()].map(key => (
              <Form.Control
                key={key}
                type={customeElement === 'NUMBER' ? 'number' : 'text'}
                name={'value'.concat(
                  item.operator === 'BETWEEN' ? (key < 1 ? '_from' : '_to') : ''
                )}
                value={
                  item[
                    'value'.concat(
                      item.operator === 'BETWEEN'
                        ? key < 1
                          ? '_from'
                          : '_to'
                        : ''
                    )
                  ]
                }
                onChange={handleFieldChange}
                className={key > 0 ? 'ms-1' : ''}
              />
            ))
          : customeElement === 'SELECT'
          ? [...Array(item.operator === 'BETWEEN' ? 2 : 1).keys()].map(key => (
              <Form.Select
                key={key}
                name={'value'.concat(
                  item.operator === 'BETWEEN' ? (key < 1 ? '_from' : '_to') : ''
                )}
                value={
                  item[
                    'value'.concat(
                      item.operator === 'BETWEEN'
                        ? key < 1
                          ? '_from'
                          : '_to'
                        : ''
                    )
                  ]
                }
                onChange={handleFieldChange}
                className={key > 0 ? 'ms-1' : ''}
              >
                <option>Select</option>
                {name &&
                filters[name] &&
                filters[name].options &&
                Array.isArray(filters[name].options)
                  ? filters[name].options.map((option, key) => (
                      <option key={key} value={option.value}>
                        {option.label}
                      </option>
                    ))
                  : null}
              </Form.Select>
            ))
          : customeElement === 'DATE'
          ? [...Array(item.operator === 'BETWEEN' ? 2 : 1).keys()].map(key => (
              <AppDatePicker
                key={key}
                name={'value'.concat(
                  item.operator === 'BETWEEN' ? (key < 1 ? '_from' : '_to') : ''
                )}
                value={
                  item[
                    'value'.concat(
                      item.operator === 'BETWEEN'
                        ? key < 1
                          ? '_from'
                          : '_to'
                        : ''
                    )
                  ]
                }
                yearPlaceholder="yyyy"
                monthPlaceholder="mm"
                dayPlaceholder="dd"
                onChange={handleFieldChange}
                className={key > 0 ? 'ms-1' : ''}
              />
            ))
          : customeElement}

        <div className="my-auto ms-auto ">
          <Button
            type="button"
            variant="transparant"
            size="sm"
            className="text-danger ms-1"
            onClick={handleRemoveItem}
            style={{ boxShadow: 'none' }}
          >
            <FaTimes size={11} />
          </Button>
        </div>
      </td>
    </tr>
  );
};

FilterItem.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  options: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  setActiveFilters: PropTypes.func.isRequired,
  setChangeEvent: PropTypes.func,
  changeEvent: PropTypes.bool
};

export default FilterItem;
