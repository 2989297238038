import TableMethods from 'plugin/react-quill/table-methods';
import Quill from 'quill';
import React, { useEffect, useRef } from 'react';
import QuillTableBetter from 'quill-table-better';
import BlotFormatter from 'quill-blot-formatter-2';
import 'quill/dist/quill.snow.css';
import 'quill-table-better/dist/quill-table-better.css';

Quill.register(
  {
    'modules/table-better': QuillTableBetter,
    'modules/tableMethods': TableMethods,
    'modules/blotFormatter': BlotFormatter
  },
  true
);

const DrectionClassAttributor = Quill.import('attributors/class/direction');
DrectionClassAttributor.whitelist = [
  'rtl',
  'dmContentTable',
  'dmContentDate',
  'dmContentUserName',
  'dmContentUserMail',
  'dmContentUserPhone'
];

const TableContainer = Quill.import('formats/table-container');
TableContainer.defaultClassName = 'ql-table-better new-test-calass';

export default function AcodaxQuillEditor({ ...rest }) {
  const { onChange, value, quillClassRef } = rest;
  const quillContainer = useRef(null);
  const quillRef = useRef(null);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!quillContainer.current || quillRef.current) return;

    const toolbarOptions = [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],

      [
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        { color: [] },
        { background: [] },
        { align: [] }
      ],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' }
      ],
      ['link', 'image', 'table'],
      [{ direction: 'rtl' }, 'clean']
    ];

    const options = {
      //debug: 'info', // Uncomment for debugging
      modules: {
        toolbar: toolbarOptions,
        blotFormatter: {},
        table: false,
        'table-better': {},
        keyboard: {
          bindings: QuillTableBetter.keyboardBindings
        },
        tableMethods: true
      },
      placeholder: 'Compose an epic...',
      theme: 'snow'
    };

    let quill = new Quill(quillContainer.current, options);
    quill.clipboard.dangerouslyPasteHTML(0, value);
    if (typeof onChange === 'function') {
      quill.on('text-change', () => {
        onChange(quill.root.innerHTML);
      });
    }

    quillRef.current = quill;
    if (quillClassRef) quillClassRef.current = quill;

    return () => {
      if (quillRef.current) {
        const qlToolbar = document.querySelector('.ql-toolbar');
        if (qlToolbar) qlToolbar?.remove();
        quillRef.current.off('text-change');
        quillRef.current = null;
      }
    };
  }, [quillContainer]);
  /* eslint-enable react-hooks/exhaustive-deps */

  return <div ref={quillContainer}></div>;
}
