import { React, useLayoutEffect, useState } from 'react';
import {
  createSearchParams,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import {
  ButtonGroup,
  Card,
  Container,
  Dropdown,
  Tab,
  Tabs
} from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import PropTypes from 'prop-types';

import { AiOutlineFileText } from 'react-icons/ai';
import { TiTick } from 'react-icons/ti';
import {
  MdClose,
  MdOutlineDeliveryDining,
  MdOutlinePendingActions
} from 'react-icons/md';

import { useBreakpoints } from 'hooks/useBreakpoints';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import {
  CloseButton,
  EditButton,
  MoreButton
} from 'module/Common/Buttons/ViewPageButtons';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import { commonEndPoints } from '../../Api/endpoints';
import TransactionsData from 'module/Common/Timeline/TransactionsData';
import SalesOrderView from './SalesOrderView';
import VoidTransactionTab from '../Common/VoidTransactionTab';
import CustomerDetails from 'components/customer-details/CustomerDetails';
import ViewPagePrintButton from '../Common/ViewPagePrintButton';
import AttachementsView from '../Common/AttachementsView';
import FileUpload from '../Common/FileUpload';
import SendEmail from '../Common/SendEmail';
import PDFView from 'components/PDFViewerButton/PDFView';
import VoucherDetail from 'components/voucher-details/VoucherDetail';
import Badges from '../Common/ViewPageDetails/Badges';
import PersonName from '../Common/ViewPageDetails/PersonName';
import MainDetails from '../Common/ViewPageDetails/MainDetails';
import Reference from '../Common/ViewPageDetails/Reference';
import FooterDetails from '../Common/ViewPageDetails/FooterDetails';
import ApproveButton from 'components/approval-component/button/ApproveButton';
import RejectButton from 'components/approval-component/button/RejectButton';
import ActivityLog from 'components/activity-log/ActivityLog';
import BranchToBranch from 'components/inter-branch-transaction/components/BranchToBranch';
// import FormSettingsOffcanvas from 'components/form-settings-offcanvas/FormSettingsOffcanvas';

function SalesOrderTabView({
  salesOrderData,
  loading,
  infoPage,
  onHide,
  voidScreen,
  fetchData,
  offCanvas,
  setShowVoucherDetailArea,
  voidTransactionReport,
  getData,
  interBranch
}) {
  let navigateTo = useNavigate();
  const Translate = useAxisproTranslate();
  const { breakpoints } = useBreakpoints();
  const [queryParams] = useSearchParams();
  const [key, setKey] = useState(voidScreen ? 'voidForm' : 'overview');
  const [transactions, setTransactions] = useState([]);
  const [showCustomerDetailsArea, setCustomerDetailsArea] = useState(false);
  const [upload, setUpload] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const allQueryParams = Object.fromEntries([...queryParams]);
  const status = queryParams.get('status');
  const [showVoucherDetailsPreview, setShowVoucherDetailsPreview] =
    useState(undefined);
  const value = {
    value: salesOrderData.customer_id
  };
  const permission = {
    show_cs: getAcodaxPermissionSlug('CS', 'show'),
    print_so: getAcodaxPermissionSlug('SO', 'print'),
    update_so: getAcodaxPermissionSlug('SO', 'update'),
    edit_so: getAcodaxPermissionSlug('CSO', 'update_created'),
    create_si: getAcodaxPermissionSlug('SI', 'create'),
    create_sd: getAcodaxPermissionSlug('SD', 'create'),
    create_wo: getAcodaxPermissionSlug('WO', 'create'),
    send_mail: getAcodaxPermissionSlug('SO', 'send-mail'),
    void_so: getAcodaxPermissionSlug('VOID', 'TRANSACTION')
  };

  const connectedWorkOrdersLength = Array.isArray(
    salesOrderData.connected_work_orders
  )
    ? salesOrderData.connected_work_orders.length
    : 0;

  const handleCustomerDetailsArea = () => {
    setCustomerDetailsArea(!showCustomerDetailsArea);
  };

  const sendEmail = () => {
    setShowEmailModal(true);
  };

  const handleCancel = () => {
    setShowEmailModal(false);
  };

  function handleVoucherPreview(data) {
    setShowVoucherDetailsPreview({
      ...data,
      show: true
    });
  }

  useLayoutEffect(() => {
    if (
      salesOrderData &&
      salesOrderData.connected_invoices &&
      salesOrderData.connected_deliveries
    ) {
      let connected = [
        ...salesOrderData.connected_invoices,
        ...salesOrderData.connected_deliveries
      ];
      let sorted = connected.sort((a, b) =>
        a.timestamp.localeCompare(b.timestamp)
      );
      setTransactions(sorted);
    }
  }, [salesOrderData]);

  // const conversionPermissions = {
  //   normalSOtoSD:
  //     permission?.create_sd &&
  //     !interBranch &&
  //     !salesOrderData?.is_inter_branch_transaction,
  //   interSOtoSD:
  //     permission?.create_sd &&
  //     interBranch &&
  //     salesOrderData?.is_inter_branch_transaction === 1 &&
  //     !salesOrderData?.is_auto_inter_branch_transaction &&
  //     salesOrderData?.inter_branch_approval_trans_status !== 'rejected',
  //   normalSOtoSI:
  //     permission?.create_si &&
  //     !interBranch &&
  //     !salesOrderData?.is_inter_branch_transaction,
  //   interSOtoSI:
  //     permission?.create_si &&
  //     interBranch &&
  //     salesOrderData?.is_inter_branch_transaction === 1 &&
  //     salesOrderData?.inter_branch_approval_trans_status !== 'rejected' &&
  //     salesOrderData?.inter_branch_approval_trans_status !== 'pending' &&
  //     salesOrderData?.is_fully_sent,
  //   interAutoSOtoSI:
  //     permission?.create_si &&
  //     interBranch &&
  //     salesOrderData?.is_inter_branch_transaction === 1 &&
  //     salesOrderData?.inter_branch_approval_trans_status !== 'rejected' &&
  //     salesOrderData?.inter_branch_approval_trans_status !== 'pending' &&
  //     salesOrderData?.is_inter_branch_purchase_delivery_approved ===
  //       'approved' &&
  //     salesOrderData?.is_fully_sent
  // };

  return (
    <>
      <Card className="flex-fill view-page-content-wrapper">
        <Card.Header
          className={
            breakpoints.up('sm')
              ? 'd-flex m-0 align-items-center border-bottom'
              : 'border-bottom'
          }
        >
          <>
            <div className="d-flex m-0 align-items-center">
              <AiOutlineFileText size={28} className="me-2 text-dark" />
              <h5
                className="mb-0 d-flex text-uppercase view-page-header"
                style={{ fontSize: '25px' }}
              >
                {Translate(interBranch ? 'Inter Company Sales' : 'Sales Order')}
              </h5>
            </div>
            {voidTransactionReport ? (
              <Badges label={'Voided'} className="ms-2 fs--1" bg={'danger'} />
            ) : null}
            {!interBranch && salesOrderData?.is_inter_branch_transaction ? (
              <BranchToBranch className={'ms-2'} badgeColor="info" size={15} />
            ) : null}
            {salesOrderData?.total_qty_sent === 0 && !interBranch ? (
              <Badges
                label={'Not Delivered'}
                className="ms-2 fs--1"
                bg={'danger'}
              />
            ) : salesOrderData?.total_qty_sent > 0 &&
              salesOrderData?.total_qty_sent !==
                salesOrderData?.total_qty_ordered &&
              !interBranch ? (
              <Badges
                label={`Partially Delivered`}
                className="ms-2 fs--1"
                bg={'dark'}
              />
            ) : salesOrderData?.total_qty_sent ===
                salesOrderData?.total_qty_ordered && !interBranch ? (
              <Badges
                label={'Fully Delivered'}
                className="ms-2 fs--1"
                bg={'warning'}
                icon={<TiTick className="ms-1" />}
              />
            ) : (
              ''
            )}
            {salesOrderData?.is_inter_branch_transaction &&
            interBranch &&
            salesOrderData?.is_fully_invoiced ? (
              <Badges
                label={'Transaction Completed'}
                className="ms-2 fs--1"
                bg={'success'}
                icon={<TiTick className="ms-1" />}
              />
            ) : null}
            {/* {salesOrderData?.is_inter_branch_transaction &&
            interBranch &&
            salesOrderData?.inter_branch_approval_trans_status === 'approved' &&
            salesOrderData?.total_qty_sent === 0 ? (
              <Badges
                label={'Not Delivered'}
                className="ms-2 fs--1"
                bg={'danger'}
                icon={<MdOutlineDeliveryDining className="ms-1" />}
              />
            ) : null} */}
            {salesOrderData?.is_inter_branch_transaction &&
            salesOrderData?.is_inter_branch_purchase_delivery_approved !==
              null &&
            salesOrderData?.is_fully_sent ? (
              <Badges
                label={
                  salesOrderData?.is_inter_branch_purchase_delivery_approved ===
                  'pending'
                    ? 'PD Needs Approval'
                    : salesOrderData?.is_inter_branch_purchase_delivery_approved ===
                      'approved'
                    ? 'PD Approved'
                    : 'PD Rejected'
                }
                className="ms-2 fs--1"
                bg={
                  salesOrderData?.is_inter_branch_purchase_delivery_approved ===
                  'pending'
                    ? 'warning'
                    : salesOrderData?.is_inter_branch_purchase_delivery_approved ===
                      'approved'
                    ? 'success'
                    : 'danger'
                }
                icon={
                  salesOrderData?.is_inter_branch_purchase_delivery_approved ===
                  'pending' ? (
                    <MdOutlinePendingActions className="ms-1" />
                  ) : salesOrderData?.is_inter_branch_purchase_delivery_approved ===
                    'approved' ? (
                    <TiTick className="ms-1" />
                  ) : (
                    <MdClose className="ms-1" />
                  )
                }
              />
            ) : null}
            {salesOrderData?.is_auto_inter_branch_transaction ||
            (salesOrderData?.is_inter_branch_transaction &&
              interBranch &&
              salesOrderData?.inter_branch_approval_trans_status ===
                'approved' &&
              salesOrderData?.is_inter_branch_purchase_delivery_approved ===
                'approved') ? (
              salesOrderData?.total_qty_sent === 0 ? (
                <Badges
                  label={'Not Delivered'}
                  className="ms-2 fs--1"
                  bg={'danger'}
                  icon={<MdOutlineDeliveryDining className="ms-1" />}
                />
              ) : salesOrderData?.total_qty_sent > 0 &&
                salesOrderData?.total_qty_sent !==
                  salesOrderData?.total_qty_ordered ? (
                <Badges
                  label={'Partially Delivered'}
                  className="ms-2 fs--1"
                  bg={'warning'}
                  icon={<MdOutlineDeliveryDining className="ms-1" />}
                />
              ) : salesOrderData?.total_qty_sent ===
                  salesOrderData?.total_qty_ordered &&
                !salesOrderData?.is_fully_invoiced ? (
                <div>
                  <Badges
                    label={'Fully Delivered'}
                    className="ms-2 fs--1"
                    bg={'success'}
                    icon={<MdOutlineDeliveryDining className="ms-1" />}
                  />
                  <Badges
                    label={'Not Invoiced'}
                    className="ms-2 fs--1"
                    bg={'danger'}
                    icon={<MdOutlinePendingActions className="ms-1" />}
                  />
                </div>
              ) : null
            ) : null}
            {salesOrderData?.is_inter_branch_transaction &&
            salesOrderData?.inter_branch_approval_trans_status !== null &&
            !salesOrderData?.is_fully_sent ? (
              <Badges
                label={
                  salesOrderData?.inter_branch_approval_trans_status ===
                  'pending'
                    ? 'PO Needs Approval'
                    : salesOrderData?.inter_branch_approval_trans_status ===
                      'approved'
                    ? 'PO Approved'
                    : 'PO Rejected'
                }
                className="ms-2 fs--1"
                bg={
                  salesOrderData?.inter_branch_approval_trans_status ===
                  'pending'
                    ? 'warning'
                    : salesOrderData?.inter_branch_approval_trans_status ===
                      'approved'
                    ? 'success'
                    : 'danger'
                }
                icon={
                  salesOrderData?.inter_branch_approval_trans_status ===
                  'pending' ? (
                    <MdOutlinePendingActions className="ms-1" />
                  ) : salesOrderData?.inter_branch_approval_trans_status ===
                    'approved' ? (
                    <TiTick className="ms-1" />
                  ) : (
                    <MdClose className="ms-1" />
                  )
                }
              />
            ) : null}
            <ButtonGroup className="ms-auto d-flex gap-2 flex-row align-items-center dropdown-menu-style">
              {(status &&
                status !== 'approved-request' &&
                status !== 'active') ||
              voidScreen ||
              offCanvas ? (
                ''
              ) : (
                <MoreButton
                  sendEmail={sendEmail}
                  setUpload={setUpload}
                  emailPermission={permission?.send_mail}
                  {...(salesOrderData?.estimation_id
                    ? {
                        convertToWorkOrder: permission?.create_wo
                          ? salesOrderData?.estimation_id
                            ? '/production/work-orders/add/' + salesOrderData.id
                            : null
                          : false,
                        disableFirstConversion:
                          salesOrderData?.is_fully_work_ordered
                      }
                    : {
                        firstConversionValidation:
                          (permission?.create_sd &&
                            !interBranch &&
                            !salesOrderData?.is_inter_branch_transaction) ||
                          (permission?.create_sd &&
                            interBranch &&
                            salesOrderData?.is_inter_branch_transaction === 1 &&
                            !salesOrderData?.is_auto_inter_branch_transaction &&
                            salesOrderData?.inter_branch_approval_trans_status !==
                              'rejected'),
                        disableFirstConversion:
                          salesOrderData.is_fully_sent ||
                          (interBranch &&
                            salesOrderData?.inter_branch_approval_trans_status ===
                              'pending'),
                        firstConversionTo: interBranch
                          ? '/sales/inter-branch-sales/sales-deliveries/add/so/' +
                            salesOrderData.id
                          : '/sales/sales-deliveries/add/so/' +
                            salesOrderData.id,
                        afterFirstConversion:
                          interBranch &&
                          salesOrderData?.inter_branch_approval_trans_status ===
                            'pending'
                            ? 'Waiting for PO approval'
                            : 'Fully Delivered!',
                        beforeFirstConversion: 'Convert to Sales Delivery',
                        secondConversionValidation:
                          (permission?.create_si &&
                            !interBranch &&
                            !salesOrderData?.is_inter_branch_transaction) ||
                          (permission?.create_si &&
                            interBranch &&
                            salesOrderData?.is_inter_branch_transaction === 1 &&
                            salesOrderData?.inter_branch_approval_trans_status !==
                              'rejected' &&
                            salesOrderData?.inter_branch_approval_trans_status !==
                              'pending' &&
                            salesOrderData?.is_inter_branch_purchase_delivery_approved ===
                              'approved' &&
                            salesOrderData?.is_fully_sent),
                        disableSecondConversion:
                          salesOrderData.is_fully_invoiced,
                        secondConversionTo: interBranch
                          ? '/sales/inter-branch-sales/sales-invoice/add/so/' +
                            salesOrderData.id
                          : '/sales/sales-invoice/add/so/' + salesOrderData.id,
                        afterSecondConversion: 'Fully Invoiced!',
                        beforeSecondConversion: 'Convert to Sales Invoice'
                      })}
                  voidTransactionData={
                    permission?.void_so
                      ? {
                          id: salesOrderData?.id,
                          trans_type: 'SO',
                          callBack: () => {
                            const url = interBranch
                              ? '/sales/inter-branch-sales/sales-order/info'
                              : '/sales/sales-order/info';
                            navigateTo(
                              `${url}?${createSearchParams({
                                ...allQueryParams
                              })}`
                            );
                            typeof getData === 'function' && getData(1);
                          }
                        }
                      : undefined
                  }
                />
              )}
              {(status &&
                status !== 'approved-request' &&
                status !== 'active') ||
              voidScreen ||
              infoPage ||
              offCanvas ||
              salesOrderData?.is_auto_inter_branch_transaction ||
              salesOrderData?.inter_branch_approval_trans_status ===
                'approved' ? (
                ''
              ) : permission?.update_so || permission?.edit_so ? (
                <EditButton
                  to={
                    interBranch
                      ? `/sales/inter-branch-sales/sales-order/edit/${
                          salesOrderData.id
                        }?${createSearchParams({
                          ...allQueryParams
                        })}`
                      : `/sales/sales-order/edit/${
                          salesOrderData.id
                        }?${createSearchParams({
                          ...allQueryParams
                        })}`
                  }
                />
              ) : (
                ''
              )}
              {(status &&
                status !== 'approved-request' &&
                status !== 'active') ||
                (!voidTransactionReport && permission?.print_so && (
                  <ViewPagePrintButton
                    variant="danger"
                    className="text-white"
                    style={{ boxShadow: 'none' }}
                    url={`${commonEndPoints.print.so}/${salesOrderData.id}`}
                    pageTitle="Sales Order print view"
                  />
                ))}
              {/* <FormSettingsOffcanvas type="SO" /> */}
              {infoPage || offCanvas ? (
                <CloseButton
                  setShowVoucherDetailArea={setShowVoucherDetailArea}
                  onHide={onHide}
                />
              ) : (
                ''
              )}
              {salesOrderData?.user_actions?.Approve && (
                <ApproveButton
                  name={salesOrderData?.user_actions?.Approve}
                  Id={salesOrderData?.approval_event_id}
                  data={salesOrderData}
                  getData={getData}
                  info={true}
                  approveRequestTab={interBranch ? false : true}
                />
              )}
              {salesOrderData?.user_actions?.Reject && (
                <RejectButton
                  name={salesOrderData?.user_actions?.Reject}
                  Id={salesOrderData?.approval_event_id}
                  data={salesOrderData}
                  getData={getData}
                  info={true}
                />
              )}
            </ButtonGroup>
          </>
        </Card.Header>
        <Card.Body className={loading && 'd-flex'}>
          <div className="d-flex align-items-start justify-content-between">
            <div>
              {salesOrderData?.customer?.name ||
              salesOrderData?.customer_name ? (
                <PersonName
                  label={interBranch ? 'Company Name' : 'Customer Name'}
                  value={
                    salesOrderData?.customer?.name ||
                    salesOrderData?.customer_name
                  }
                  view
                  permission={permission?.show_cs}
                  setCustomerDetailsArea={setCustomerDetailsArea}
                />
              ) : null}
              {salesOrderData?.trans_date_formatted ? (
                <MainDetails
                  label={'Transaction Date'}
                  value={salesOrderData?.trans_date_formatted}
                />
              ) : null}
            </div>
            <div className="view-page-contents d-flex flex-column align-items-start">
              {!salesOrderData?.is_auto && salesOrderData?.reference ? (
                <Reference
                  offCanvas={offCanvas}
                  voidTransactionReport={voidTransactionReport}
                  to={
                    interBranch
                      ? `/sales/inter-branch-sales/sales-order/info/${salesOrderData?.id}`
                      : `/sales/sales-order/info/${salesOrderData?.id}`
                  }
                  value={salesOrderData?.reference}
                />
              ) : null}
              {salesOrderData?.estimation_id ? (
                <>
                  {salesOrderData?.enquiry_id ||
                  salesOrderData?.enquiry_reference ? (
                    <div className="d-flex flex-wrap align-items-center gap-2 w-100">
                      <h6 className="mb-2 fw-bold me-auto">
                        {Translate('Enquiry')}:
                      </h6>
                      <div className="d-flex flex-row">
                        {salesOrderData?.enquiry_id ? (
                          <a
                            href="#"
                            className={`text-dark ${
                              offCanvas ? 'mb-1 reference-style' : 'mb-2'
                            }`}
                            onClick={() =>
                              handleVoucherPreview({
                                type: 'ENQUIRY',
                                targetId: salesOrderData.enquiry_id
                              })
                            }
                          >
                            {salesOrderData?.enquiry?.reference ?? '-'}
                          </a>
                        ) : (
                          <p
                            className={`text-dark ${
                              offCanvas ? 'mb-1 reference-style' : 'mb-2'
                            }`}
                          >
                            {salesOrderData.enquiry_reference}
                          </p>
                        )}
                      </div>
                    </div>
                  ) : null}

                  <div className="d-flex flex-wrap align-items-center gap-2 w-100">
                    <h6 className="mb-2 fw-bold me-auto">
                      {Translate('Estimation')}:
                    </h6>
                    <div className="d-flex flex-row">
                      <a
                        href="#"
                        className={`text-dark ${
                          offCanvas ? 'mb-1 reference-style' : 'mb-2'
                        }`}
                        onClick={() =>
                          handleVoucherPreview({
                            type: 'ESTIMATION',
                            targetId: salesOrderData?.estimation_id,
                            modalView: true
                          })
                        }
                      >
                        {salesOrderData?.estimation?.reference ?? '-'}
                      </a>
                    </div>
                  </div>

                  <div className="d-flex flex-wrap align-items-center gap-2 w-100">
                    <h6 className="mb-2 fw-bold me-auto">
                      {Translate('Sales Quotation')}:
                    </h6>
                    <div className="d-flex flex-row">
                      <a
                        href="#"
                        className={`text-dark ${
                          offCanvas ? 'mb-1 reference-style' : 'mb-2'
                        }`}
                        onClick={() =>
                          handleVoucherPreview({
                            type: 'SQ',
                            targetId: salesOrderData?.sales_quote_id
                          })
                        }
                      >
                        {salesOrderData?.sales_quotation_reference ?? '-'}
                      </a>
                    </div>
                  </div>

                  {connectedWorkOrdersLength > 0 ? (
                    <div className="d-flex flex-wrap align-items-center gap-2 w-100">
                      <h6 className="mb-2 fw-bold me-auto">
                        {Translate('Work order')}:
                      </h6>
                      <div className="d-flex flex-row align-items-center gap-1">
                        <a
                          href="#"
                          className={`text-dark ${
                            offCanvas ? 'mb-1 reference-style' : 'mb-2'
                          }`}
                          onClick={() =>
                            handleVoucherPreview({
                              type: 'WO',
                              targetId:
                                salesOrderData.connected_work_orders[
                                  connectedWorkOrdersLength - 1
                                ]?.id
                            })
                          }
                        >
                          {
                            salesOrderData.connected_work_orders[
                              connectedWorkOrdersLength - 1
                            ]?.reference
                          }
                        </a>
                        {connectedWorkOrdersLength > 1 ? (
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="transparent"
                              size="sm"
                              className="text-success p-0 mb-1"
                              style={{
                                boxShadow: 'none'
                              }}
                            ></Dropdown.Toggle>

                            <Dropdown.Menu>
                              {[...salesOrderData.connected_work_orders]
                                .reverse()
                                .map((item, index) => (
                                  <Dropdown.Item
                                    href="#"
                                    key={index}
                                    onClick={() =>
                                      handleVoucherPreview({
                                        type: 'WO',
                                        targetId: item?.id
                                      })
                                    }
                                  >
                                    {item?.reference}
                                  </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                </>
              ) : null}
            </div>
          </div>
          <Container className="p-0 view-page-tab-style-wrapper">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={k => setKey(k)}
              color="red"
              className="custom-offcanvas-header-tabs"
            >
              {voidScreen && (
                <Tab eventKey="voidForm" title={Translate('Voiding Form')}>
                  <SimpleBar className="simplebar-view-page-style">
                    <VoidTransactionTab
                      transType={salesOrderData.trans_type}
                      transId={salesOrderData.id}
                    />
                  </SimpleBar>
                </Tab>
              )}
              <Tab eventKey="overview" title={Translate('Overview')}>
                <SimpleBar className="simplebar-view-page-style">
                  <SalesOrderView salesOrderData={salesOrderData} />
                </SimpleBar>
              </Tab>
              {transactions?.length > 0 && (
                <Tab eventKey="transaction" title={Translate('Transactions')}>
                  <SimpleBar className="simplebar-view-page-style">
                    <TransactionsData
                      transaction={transactions}
                      interBranch={interBranch}
                    />
                  </SimpleBar>
                </Tab>
              )}
              {salesOrderData?.media?.length > 0 && (
                <Tab eventKey="attachments" title={Translate('Attachments')}>
                  <SimpleBar className="simplebar-view-page-style">
                    <AttachementsView
                      data={salesOrderData}
                      fetchData={fetchData}
                      setKey={setKey}
                    />
                  </SimpleBar>
                </Tab>
              )}{' '}
              {salesOrderData?.id && status !== 'pending' ? (
                <Tab eventKey="pdfView" title={Translate('PDF View')}>
                  <SimpleBar className="simplebar-view-page-style pdf-tab-view-simplebar-style">
                    {key === 'pdfView' && (
                      <PDFView
                        viewType="tab"
                        url={`${commonEndPoints.print.so}/${salesOrderData.id}`}
                      />
                    )}
                  </SimpleBar>
                </Tab>
              ) : null}
              {salesOrderData?.id ? (
                <Tab eventKey="activityLog" title={Translate('Activity Log')}>
                  <SimpleBar className="simplebar-view-page-style pdf-tab-view-simplebar-style">
                    {key === 'activityLog' && (
                      <ActivityLog subjectID={salesOrderData?.id} />
                    )}
                  </SimpleBar>
                </Tab>
              ) : null}
            </Tabs>
          </Container>
        </Card.Body>
        <FooterDetails
          created_at={salesOrderData?.created_at}
          created_by={salesOrderData?.created_user_full_name}
        />
      </Card>
      <CustomerDetails
        show={showCustomerDetailsArea}
        onHide={handleCustomerDetailsArea}
        value={value}
        showCustomerDetailsArea={showCustomerDetailsArea}
      />
      {salesOrderData.id && (
        <FileUpload
          show={upload}
          onHide={() => setUpload(false)}
          fetchData={fetchData}
          type={'SO'}
          id={salesOrderData.id}
          setKey={setKey}
        />
      )}
      <SendEmail
        url={commonEndPoints.email.so + salesOrderData.id}
        showEmailModal={showEmailModal}
        handleCancel={handleCancel}
        data={salesOrderData.customer_email}
      />

      <VoucherDetail
        show={!!showVoucherDetailsPreview?.show}
        onHide={() => setShowVoucherDetailsPreview(undefined)}
        setShowVoucherDetailArea={setShowVoucherDetailArea}
        type={showVoucherDetailsPreview?.type}
        voucherId={showVoucherDetailsPreview?.targetId}
        infoPage={true}
        offCanvas={true}
        modalView={showVoucherDetailsPreview?.modalView}
      />
    </>
  );
}

SalesOrderTabView.propTypes = {
  salesOrderData: PropTypes.any,
  loading: PropTypes.bool,
  infoPage: PropTypes.bool,
  onHide: PropTypes.func,
  fetchData: PropTypes.func,
  voidScreen: PropTypes.bool,
  interBranch: PropTypes.bool,
  offCanvas: PropTypes.bool,
  setShowVoucherDetailArea: PropTypes.func,
  voidTransactionReport: PropTypes.bool,
  getData: PropTypes.func
};

export default SalesOrderTabView;
