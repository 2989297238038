import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ActiveTaskContext } from 'context/Context';

export default function TaskTimer({
  start,
  itemTargetId,
  setWorkOrderVoucherData
}) {
  const { activeTask, setActiveTask } = useContext(ActiveTaskContext);
  const timeData = activeTask?.timeData;

  useEffect(() => {
    // start timer
    const timerTimeout = setTimeout(() => {
      if (!start && activeTask) return;
      const currentTimeData = {
        ...(activeTask?.timeData ?? {
          hour: 0,
          minute: 0,
          second: 0
        })
      };
      if (currentTimeData?.second > 58) {
        currentTimeData.second = 0;
        if (currentTimeData?.minute > 58) {
          currentTimeData.minute = 0;
          currentTimeData.hour += 1;
        } else {
          currentTimeData.minute += 1;
        }
      } else {
        currentTimeData.second += 1;
      }

      setActiveTask(prev => ({
        ...prev,
        timeData: currentTimeData,
        ...(itemTargetId && setWorkOrderVoucherData
          ? {
              itemTargetId: itemTargetId,
              setWorkOrderVoucherData: setWorkOrderVoucherData
            }
          : null)
      }));
    }, 1000);

    return () => {
      clearTimeout(timerTimeout);
    };
  }, [start, activeTask, itemTargetId, setActiveTask, setWorkOrderVoucherData]);

  return (
    <div className="bg-white border d-flex flex-row gap-1 align-items-center p-1 fw-bold rounded-start">
      <span>{timeData?.hour?.toString()?.padStart(2, '0') || '00'}</span>
      <span>:</span>
      <span>{timeData?.minute?.toString()?.padStart(2, '0') || '00'}</span>
      <span>:</span>
      <span>{timeData?.second?.toString()?.padStart(2, '0') || '00'}</span>
    </div>
  );
}

TaskTimer.propTypes = {
  start: PropTypes.bool,
  itemTargetId: PropTypes.string,
  setWorkOrderVoucherData: PropTypes.func
};
