import { React, useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import LoadingScreenCover from 'components/common/loading-screen/LoadingScreenCover';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import ContractTabView from 'module/Common/ViewPages/Contract/ContractTabView';

function ContractInfo({
  contractId,
  rendering,
  firstId,
  offCanvas,
  setShowVoucherDetailArea,
  voidTransactionReport,
  getData
}) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async isLoading => {
    if (!rendering) {
      if (firstId !== undefined) {
        let url = voidTransactionReport
          ? 'settings/void-transaction-details'
          : `sales/contracts/${firstId}`;
        const fetchData = async () => {
          try {
            const response = await axios.get(url, {
              params: {
                trans_type: voidTransactionReport ? 'CT' : '',
                trans_no: voidTransactionReport ? firstId : ''
              }
            });

            if (response.data.success) {
              setData(response.data.data);
            }
          } catch (error) {
            console.log(error);
          } finally {
            setLoading(false);
          }
        };
        fetchData();
      }
    } else {
      if (contractId !== undefined) {
        if (isLoading) {
          setLoading(true);
        }
        let url = voidTransactionReport
          ? 'settings/void-transaction-details'
          : `sales/contracts/${contractId}`;
        await axios
          .get(url, {
            params: {
              trans_type: voidTransactionReport ? 'CT' : '',
              trans_no: voidTransactionReport ? contractId : ''
            }
          })
          .then(res => {
            if (res.data.success) {
              setData(res.data.data);
              setLoading(false);
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    const timeoutId = setTimeout(() => {
      fetchData(loading);
    }, 200);
    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractId, firstId]);

  return (
    <>
      {loading && data ? (
        <LoadingScreenCover>
          <LoadingScreen message="Looking for details" />
        </LoadingScreenCover>
      ) : (
        <ContractTabView
          contractData={data}
          loading={loading}
          fetchData={fetchData}
          setShowVoucherDetailArea={setShowVoucherDetailArea}
          offCanvas={offCanvas}
          voidTransactionReport={voidTransactionReport}
          getData={getData}
        />
      )}
    </>
  );
}

ContractInfo.propTypes = {
  contractId: PropTypes.string,
  firstId: PropTypes.string,
  rendering: PropTypes.bool,
  offCanvas: PropTypes.bool,
  setShowVoucherDetailArea: PropTypes.func,
  voidTransactionReport: PropTypes.bool,
  getData: PropTypes.func
};

export default ContractInfo;
