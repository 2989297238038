import useAxisproTranslate from 'hooks/useAxisproTranslate';
import React, { useEffect, useState } from 'react';
import { Alert, Modal, Spinner, Table } from 'react-bootstrap';
import { AiFillFile } from 'react-icons/ai';
import PropTypes from 'prop-types';
import axios from 'axios';
import WorkOrderStatusBadge from '../../components/WorkOrderStatusBadge';

export default function WorkOrderMaterialRequestLineItemDetailsModal({
  targetId,
  filters,
  onHide
}) {
  const Translate = useAxisproTranslate();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const stringifiedFilters = JSON.stringify(filters);

  useEffect(() => {
    const parsedFilters = JSON.parse(stringifiedFilters);
    setErrorMessage(undefined);
    setIsLoading(true);
    setData(undefined);
    axios
      .get('purchase/material-request/' + targetId, {
        params: parsedFilters || null
      })
      .then(response => {
        setData(response?.data?.data);
      })
      .catch(error => {
        setErrorMessage(
          error?.response?.data?.message ??
            'Something went wrong, please refresh the page and try again.'
        );
      })
      .finally(() => setIsLoading(false));
  }, [targetId, stringifiedFilters]);

  return (
    <Modal show size="lg" onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title className="text-uppercase d-flex flex-row align-items-center gap-1 fw-bold">
          <AiFillFile size={18} className="text-success" />
          {Translate('Material Request Details')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorMessage ? (
          <Alert variant="danger">{Translate(errorMessage)}</Alert>
        ) : isLoading ? (
          <Alert className="d-flex flex-row align-items-center gap-2">
            <span>{Translate('Looking for material request details')}</span>
            <Spinner
              style={{
                width: 15,
                height: 15
              }}
            />
          </Alert>
        ) : data ? (
          <Table
            responsive
            striped
            bordered
            hover
            className="simple-table-animation"
          >
            <thead>
              <tr className="bg-dark text-light fs--2 text-uppercase">
                <th>#</th>
                <td>{Translate('Item code')}</td>
                <td>{Translate('Item name')}</td>
                <td className="text-center">{Translate('Requested qty')}</td>
                <td className="text-center">{Translate('Issued qty')}</td>
                <td className="text-center">{Translate('Status')}</td>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(data?.details) && data.details.length > 0
                ? data.details.map((item, index) => (
                    <tr key={index} className="fs--1">
                      <th className="fw-bold">{index + 1}</th>
                      <td>{item?.stock_id}</td>
                      <td>{item?.item_display_name}</td>
                      <td className="text-center">{item?.quantity}</td>
                      <td className="text-center">{item?.quantity_issued}</td>
                      <td className="text-center">
                        <WorkOrderStatusBadge
                          quantity={item?.quantity}
                          quantityIssued={item?.quantity_issued}
                        />
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </Table>
        ) : null}
      </Modal.Body>
    </Modal>
  );
}

WorkOrderMaterialRequestLineItemDetailsModal.propTypes = {
  targetId: PropTypes.string,
  onHide: PropTypes.func,
  filters: PropTypes.object
};
