import { React, useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import LoadingScreenCover from 'components/common/loading-screen/LoadingScreenCover';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import RentalContractTabView from 'module/Common/ViewPages/RentalContract/RentalContractTabView';
import commonFunction from '../commonFunction';
import AfterSavePaymentModal from 'components/after-save/AfterSavePaymentModal';

function RentalContractInfo({
  rentalId,
  offCanvas,
  setShowVoucherDetailArea,
  voidTransactionReport,
  getData
}) {
  const [data, setData] = useState();
  const { fetchData } = commonFunction;
  const [loading, setLoading] = useState(true);
  const apiRequestAbortControllerRef = useRef(null);
  const [afterSavePaymentModal, setAfterSavePaymentModal] = useState({
    show: false,
    data: ''
  });
  const getRentalContract = useCallback(
    async (enableLoading = true) => {
      if (!rentalId) return;
      try {
        apiRequestAbortControllerRef?.current?.abort();
        apiRequestAbortControllerRef.current = new AbortController();
        if (enableLoading) setLoading(true);
        await fetchData(
          voidTransactionReport,
          rentalId,
          setLoading,
          setData,
          apiRequestAbortControllerRef.current?.signal
        );
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error('Error fetching rental contract:', error);
        }
      }
    },
    [fetchData, voidTransactionReport, rentalId]
  );

  useEffect(() => {
    setLoading(true);
    getRentalContract();
    return () => {
      apiRequestAbortControllerRef?.current?.abort();
      setData(undefined);
    };
  }, [getRentalContract]);

  const handlePaymentSuccess = paymentData => {
    if (paymentData) {
      setAfterSavePaymentModal({
        show: true,
        data: paymentData
      });
    }
  };

  const afterSave = {
    mainData: 'Paymen Received Successfully!',
    printButton: {
      url: 'finance/customer_receipt/print',
      pageTitle: 'Customer Receipt Print View'
    },
    viewButton: {
      title: 'View Customer Receipt',
      url: '/finance/voucher-inquiry'
    }
  };

  return (
    <>
      {loading ? (
        <LoadingScreenCover>
          <LoadingScreen message="Looking for details" />
        </LoadingScreenCover>
      ) : (
        <RentalContractTabView
          rentalContractData={data}
          loading={loading}
          fetchData={getRentalContract}
          setShowVoucherDetailArea={setShowVoucherDetailArea}
          offCanvas={offCanvas}
          voidTransactionReport={voidTransactionReport}
          getData={getData}
          handlePaymentSuccess={handlePaymentSuccess}
        />
      )}
      <AfterSavePaymentModal
        show={afterSavePaymentModal?.show}
        onHide={() => {
          setAfterSavePaymentModal({
            show: false,
            data: ''
          });
        }}
        data={afterSavePaymentModal?.data}
        dataProcessing={loading}
        afterSave={afterSave}
      />
    </>
  );
}

RentalContractInfo.propTypes = {
  rentalId: PropTypes.string,
  rendering: PropTypes.bool,
  offCanvas: PropTypes.bool,
  setShowVoucherDetailArea: PropTypes.func,
  voidTransactionReport: PropTypes.bool,
  getData: PropTypes.func,
  contentLoading: PropTypes.bool
};

export default RentalContractInfo;
