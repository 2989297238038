import axios from 'axios';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import PropTypes from 'prop-types';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import useTabViewContentReloadHandler from 'hooks/useTabViewContentReloadHandler';
import React, { Fragment } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import './pdf_viewer_style.scss';

import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export default function PDFView({
  url,
  show,
  onHide,
  viewType,
  tabViewReloadHandlerID,
  activeTabKey,
  tabApiCallCompleted,
  setTabApiCallCompleted
}) {
  const Translate = useAxisproTranslate();
  const { isTabIncludes, removeTab } = useTabViewContentReloadHandler();
  const loadTabData = tabViewReloadHandlerID
    ? isTabIncludes(tabViewReloadHandlerID)
    : true;

  const [isLoading, setIsLoading] = useState(false);
  const [fileUrl, setFileUrl] = useState(undefined);

  const [numPages, setNumPages] = useState();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function handleDownload() {
    let fileData = typeof fileUrl === 'string' ? fileUrl.split('/') : [];
    if (fileData.length < 1) return;
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileData[fileData.length - 1];
    document.body.appendChild(link);
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );
    document.body.removeChild(link);
  }

  function handlePrint() {
    const printScreen = window.open(
      fileUrl,
      '_blank',
      'toolbar=yes,scrollbars=yes,resizable=yes'
    );
    printScreen.onload = printScreen.print;
  }

  useEffect(() => {
    if (
      (activeTabKey && activeTabKey !== 'pdfView') ||
      (tabApiCallCompleted && !loadTabData) ||
      !loadTabData
    )
      return;
    if (loadTabData) {
      if (tabViewReloadHandlerID) removeTab([tabViewReloadHandlerID]);
      setIsLoading(true);
      axios({
        method: 'post',
        url: url,
        responseType: 'arraybuffer',
        headers: {
          Accept: 'application/pdf',
          'Content-Type': 'application/octet-stream'
        }
      })
        .then(response => {
          const pdfFile = new Blob([response.data], {
            type: 'application/pdf'
          });
          if (pdfFile instanceof Blob) {
            setFileUrl(window.URL.createObjectURL(pdfFile));
          }
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          setIsLoading(false);
          typeof setTabApiCallCompleted === 'function' &&
            setTabApiCallCompleted(prev => ({
              ...prev,
              pdfView: true
            }));
        });
    }

    return () => {
      setIsLoading(false);
    };
  }, [
    url,
    tabViewReloadHandlerID,
    loadTabData,
    activeTabKey,
    tabApiCallCompleted,
    setTabApiCallCompleted,
    removeTab
  ]);

  return viewType === 'tab' ? (
    <div className="pdf-viewer-tab  w-100 d-flex flex-column h-100">
      {isLoading ? (
        <div
          style={{ height: '40rem' }}
          className="d-flex align-items-center justiyf-content-center"
        >
          <LoadingScreen message={Translate('Creating print view')} />
        </div>
      ) : fileUrl ? (
        <div className="mx-auto m-3">
          {Array.from(Array(numPages).keys()).map(pageNumber => (
            <Fragment key={pageNumber + 1}>
              <Document
                className="shadow"
                file={fileUrl}
                onLoadSuccess={onDocumentLoadSuccess}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              >
                <Page pageNumber={pageNumber + 1} />
              </Document>
              <p className="mt-2 small">
                Page {pageNumber + 1} of {numPages}
              </p>
            </Fragment>
          ))}
        </div>
      ) : null}
    </div>
  ) : show ? (
    <Modal
      show={true}
      onHide={onHide}
      dialogClassName="pdf-viewer-modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Print, email, or save as PDF</Modal.Title>
        <br />
      </Modal.Header>
      <Modal.Body className="d-flex flex-column">
        <p>
          To print, right-click the preview and select <b>Print</b>. Or, click
          the <b>Print</b> icon if you see one below.
        </p>
        <div className="d-flex flex-wrap flex-grow-1">
          <div className="pe-5">
            <p>Print settings</p>
            <div className="d-flex flex-row align-items-center">
              <label htmlFor="OrientationItem">Orientation</label>
              <select name="Orientation" id="OrientationItem" className="ms-4">
                <option value="portrait">Portrait</option>
                <option value="landscape">Landscape</option>
              </select>
            </div>
          </div>
          <div className="flex-fill d-flex border">
            {isLoading ? (
              <LoadingScreen message={Translate('Creating print view')} />
            ) : fileUrl ? (
              <iframe src={fileUrl} className="w-100 flex-grow-1"></iframe>
            ) : null}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex flex-wrap align-items-center">
        <Button
          variant="secondary"
          onClick={onHide}
          className="me-auto"
          size="sm"
        >
          Close
        </Button>
        {fileUrl ? (
          <>
            <Button variant="warning" size="sm">
              Email
            </Button>
            <Button variant="primary" onClick={handleDownload} size="sm">
              Save as PDF
            </Button>
            <Button variant="success" onClick={handlePrint} size="sm">
              Print
            </Button>
          </>
        ) : null}
      </Modal.Footer>
    </Modal>
  ) : null;
}

PDFView.propTypes = {
  url: PropTypes.string,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  viewType: PropTypes.string,
  tabViewReloadHandlerID: PropTypes.string,
  activeTabKey: PropTypes.string,
  tabApiCallCompleted: PropTypes.bool,
  setTabApiCallCompleted: PropTypes.func
};
