import { React, useEffect, useState } from 'react';
import { Badge, Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { RiArrowRightSLine } from 'react-icons/ri';
import { useSearchParams } from 'react-router-dom';

const ApprovalWorkFlow = ({ data }) => {
  const [queryParams] = useSearchParams();
  const status = queryParams.get('status');
  const [isApproved, setIsApproved] = useState(false);
  const [approvedArray, setApprovedArray] = useState([]);
  const [lastStageId, setIsLastStageId] = useState();

  useEffect(() => {
    if (data.length > 0) {
      const lastObject = data[data.length - 1];
      setIsLastStageId(lastObject?.id ?? '');
      setIsApproved(
        lastObject.status === 'approved' || lastObject.status === 'rejected'
      );
    }
    if (status === 'pending') {
      const tempArray = [];
      for (const obj of data) {
        if (obj.status === 'pending') {
          tempArray.push(obj);
          break;
        }
        tempArray.push(obj);
      }
      setApprovedArray(tempArray);
    }
  }, [data, status]);

  return (
    <Container className="mt-2 approval-history">
      <Row>
        <Col
          className="mt-3 d-flex align-items-center justify-content-end p-0"
          md={1}
        >
          <div className="start-and-end-flow">
            <h1>Start</h1>
          </div>
        </Col>
        <Col md={1} className="m-0 p-0 d-flex align-items-center mt-3">
          <div className="line-approval m-0 p-0  w-100"></div>
          <RiArrowRightSLine className="m-0 p-0 " size={25} />
        </Col>
        {status === 'pending'
          ? approvedArray?.map((items, index) => {
              return (
                <>
                  <Col
                    md={3}
                    className={`single-start-card m-0 p-0 mt-3 d-flex align-items-center justify-content-center position-relative`}
                    key={index}
                  >
                    <div className="m-0 p-0 text-center">
                      <h1 className="text-capitalize pt-3 m-0">
                        {items?.stage?.stage_name}
                      </h1>
                      <Badge
                        className="mb-3 mt-2  text-capitalize"
                        bg={
                          items?.status === 'approved'
                            ? 'success'
                            : items?.status === 'rejected'
                            ? 'danger'
                            : 'primary'
                        }
                      >
                        {items?.status}
                      </Badge>
                      {items?.status !== 'pending' && (
                        <>
                          <div className="create-user">
                            {items?.stage?.stage_name === 'Created'
                              ? items.created_user_full_name
                              : items?.updated_user_full_name}
                          </div>
                          <div className="created-time">
                            {items?.stage?.stage_name === 'Created'
                              ? items?.created_at
                              : items.updated_at}
                          </div>
                        </>
                      )}
                    </div>
                  </Col>
                  {items?.id === lastStageId &&
                  isApproved ? null : items?.status === 'pending' ? null : (
                    <Col
                      md={1}
                      className="m-0 p-0 d-flex align-items-center mt-3"
                    >
                      <div className="line-approval m-0 p-0 w-100"></div>
                      <RiArrowRightSLine className="m-0 p-0 " size={25} />
                    </Col>
                  )}
                </>
              );
            })
          : data?.map((items, index) => {
              return (
                <>
                  <Col
                    md={3}
                    className={`single-start-card m-0 p-0 mt-3 d-flex align-items-center justify-content-center position-relative`}
                    key={index}
                  >
                    <div className="m-0 p-0 text-center">
                      <h1 className="text-capitalize pt-3 m-0">
                        {items?.stage?.stage_name}
                      </h1>
                      <Badge
                        className="mb-3 text-capitalize"
                        bg={
                          items?.status === 'approved'
                            ? 'success'
                            : items?.status === 'rejected'
                            ? 'danger'
                            : 'primary'
                        }
                      >
                        {items?.status}
                      </Badge>
                      <div className="create-user">
                        {items?.stage?.stage_name === 'Created'
                          ? items.created_user_full_name
                          : items?.updated_user_full_name}
                      </div>
                      <div className="created-time">
                        {items?.stage?.stage_name === 'Created'
                          ? items?.created_at
                          : items.updated_at}
                      </div>
                    </div>
                  </Col>
                  {items?.id === lastStageId &&
                  isApproved ? null : items?.status === 'pending' ? null : (
                    <Col
                      md={1}
                      className="m-0 p-0 d-flex align-items-center mt-3"
                    >
                      <div className="line-approval m-0 p-0 w-100"></div>
                      <RiArrowRightSLine className="m-0 p-0 " size={25} />
                    </Col>
                  )}
                </>
              );
            })}
        {!isApproved ? null : (
          <>
            <Col md={1} className="m-0 p-0 d-flex align-items-center mt-3">
              <div className="line-approval m-0 p-0  w-100"></div>
              <RiArrowRightSLine className="m-0 p-0 " size={25} />
            </Col>
            <Col
              className="mt-3 d-flex align-items-center justify-content-end p-0"
              md={1}
            >
              <div className="start-and-end-flow">
                <h1>End</h1>
              </div>
            </Col>
          </>
        )}
      </Row>
    </Container>
  );
};
ApprovalWorkFlow.propTypes = {
  data: PropTypes.object
};
export default ApprovalWorkFlow;
