import { React, useCallback, useEffect, useState } from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';
import { Badge, Card, Spinner } from 'react-bootstrap';
import qs from 'qs';
import SimpleBar from 'simplebar-react';
import { AiOutlineFileText } from 'react-icons/ai';
import { apiCall } from 'helpers/apiCalls';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import TableHeader from 'helpers/tableHeader';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import AppPagination from 'components/common/app-pagination/AppPagination';
import FormattedData from '../../Common/TableFilter/FormatedData';
import Filter from 'module/Common/TableFilter/Filter';
import CommonSearch from 'components/common-search/CommonSearch';
import ExportButton from 'components/export-button/ExportButton';
import FilterButton from 'components/add-button/FilterButton';
import VoucherDetail from 'components/voucher-details/VoucherDetail';
import { showToast } from 'module/Common/Toast/toast';
import axios from 'axios';
import ConfirmWarning from 'module/Common/Warnings/ConfirmWarning';
import { HiMiniDocumentPlus } from 'react-icons/hi2';
import PrintButton from 'components/print-button/PrintButton';

const DeferredTransaction = () => {
  const Translate = useAxisproTranslate();
  const [queryParams] = useSearchParams();
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showVoucherDetailArea, setShowVoucherDetailArea] = useState(false);
  const [voucherId, setVoucherId] = useState('');
  const [transType, setTransType] = useState('');
  const [deferredData, setDeferredData] = useState({});
  const [showConfirmationBox, setShowConfirmationBox] = useState(false);
  const [deferredIds, setDeferredIds] = useState([]);
  const pageNumber = queryParams.get('page');
  const filterItems = queryParams.get('filters');
  const allQueryParams = Object.fromEntries([...queryParams]);
  const hideFilterObject =
    JSON.parse(localStorage.getItem('HIDE_ACODAX_ERP_FILTER_MODULE')) || [];

  const handleVoucherDetail = (id, trans_type) => {
    setTransType(trans_type);
    setVoucherId(id);
    setShowVoucherDetailArea(true);
  };
  const handleChangeStatus = ids => {
    setDeferredIds(ids);
    setShowConfirmationBox(true);
  };
  const handleCancel = () => {
    setShowConfirmationBox(false);
    setDeferredIds([]);
  };
  const handleSubmit = () => {
    setShowConfirmationBox(false);
    setIsSaving(true);
    axios({
      method: 'post',
      url: `finance/post-deferred-transaction`,
      data: { deferred_transaction_ids: deferredIds }
    })
      .then(response => {
        setIsLoading(false);
        setIsSaving(false);
        if (response.data.success) {
          const updatedData = [...(deferredData?.data ?? [])];
          updatedData
            .filter(item => deferredIds.includes(item.id[0]))
            .map(item => {
              item.status = 'completed';
              item.voucher_id = response?.data?.data?.id;
              item.voucher_reference = response?.data?.data?.reference;
              return item;
            });
          setDeferredData({ ...deferredData, data: updatedData });
          setDeferredIds([]);
          showToast(response.data.message, 'success');
        } else {
          showToast(
            Translate(
              `Something went wrong, please refresh the page and try again.`
            ),
            'error'
          );
        }
      })
      .catch(error => {
        setIsLoading(false);
        if (error.response.data && error.response.data.message) {
          showToast(Translate(error.response.data.message), 'error');
        } else {
          showToast(
            Translate(
              `Something went wrong, please refresh the page and try again.`
            ),
            'error'
          );
        }
      });
  };
  const columns = [
    {
      accessor: 'trans_date',
      Header: Translate(' DATE'),
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-0'
      },
      Cell: rowData => {
        const { trans_date_formatted } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{trans_date_formatted}</h5>;
      }
    },
    {
      accessor: 'refference',
      Header: Translate('TRANSACTION REFERENCE'),
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-0'
      },
      Cell: rowData => {
        const { reference, sales_invoice_id } = rowData.row.original;
        return (
          <h5
            className="mb-0 fs--1 text-primary"
            onClick={() => handleVoucherDetail(sales_invoice_id, 'SI')}
          >
            {reference}
          </h5>
        );
      }
    },
    {
      accessor: 'voucher_reference',
      Header: Translate('VOUCHER REFERENCE'),
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-0'
      },
      Cell: rowData => {
        const { voucher_id, voucher_reference } = rowData.row.original;
        return (
          <h5
            className="mb-0 fs--1 text-primary"
            onClick={() => handleVoucherDetail(voucher_id, 'JV')}
          >
            {voucher_reference ?? '--'}
          </h5>
        );
      }
    },
    {
      accessor: 'amount',
      Header: Translate('AMOUNT'),
      headerProps: { className: 'pe-1 text-end me-2' },
      cellProps: {
        className: 'py-0 text-end'
      },
      Cell: rowData => {
        const { total_amount } = rowData.row.original;
        return <h5 className="mb-0 fs--1 ">{total_amount}</h5>;
      }
    },

    {
      accessor: 'status',
      Header: Translate('STATUS'),
      headerProps: { className: 'pe-1 ps-5' },
      cellProps: {
        className: ' text-capitalize ps-5 '
      },
      Cell: rowData => {
        const { status } = rowData.row.original;
        return status === 'pending' ? (
          <Badge className="text-uppercase" bg="warning">
            {status}
          </Badge>
        ) : status === 'completed' ? (
          <Badge className="text-uppercase" bg="success">
            {status}
          </Badge>
        ) : (
          <Badge className="text-uppercase" bg="danger">
            {status}
          </Badge>
        );
      }
    },
    {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: 'py-0 pe-5 text-end button-styles-in-table'
      },
      Cell: rowData => {
        const { status, id, voucher_id } = rowData.row.original;
        return (
          <div>
            {deferredIds[0] && deferredIds[0] === id[0] && isSaving ? (
              <Spinner size="sm" className="" />
            ) : status === 'completed' ? (
              <>
                <PrintButton
                  variant="transparent"
                  className=" m-0 p-0 ps-1 pe-1 text-black"
                  style={{ boxShadow: 'none' }}
                  url={`finance/journal-entry/print/${voucher_id}`}
                  pageTitle={'Journal Voucher Print View'}
                />
              </>
            ) : (
              <HiMiniDocumentPlus
                title="Post Journal Entry"
                color="#2c7be5"
                size={20}
                className=""
                onClick={() => handleChangeStatus(id)}
              />
            )}
          </div>
        );
      }
    }
  ];

  const getData = useCallback(
    async (page = 1) => {
      setIsLoading(true);
      const data = await apiCall({
        url: '/finance/deferred-transaction',
        params: {
          page: page,
          ...FormattedData(JSON.parse(filterItems))
        },
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      });
      setDeferredData(data);
      setIsLoading(false);
    },
    [filterItems]
  );

  useEffect(() => {
    getData(pageNumber);
  }, [pageNumber, filterItems, getData]);

  return (
    <>
      <Outlet context={[getData]} />
      <Card className="flex-fill mb-3">
        <Card.Header className="d-flex flex-row justify-content-between border-bottom">
          <AiOutlineFileText size={25} className="me-1 text-dark" />
          <TableHeader title={Translate('Deferred Transactions')} table />
          <CommonSearch />
          <ExportButton
            className="me-1"
            url="/finance/deferred-transaction-report/export"
            initialMessage={'Deferred Transaction  export started'}
            successMessage={'Deferred Transaction  export completed'}
            file_type={'pdf'}
            variant={'danger'}
            filters={FormattedData(JSON.parse(filterItems))}
          />
          <FilterButton moduleName="deferred_transactions" />
        </Card.Header>
        <Filter moduleName="deferred_transactions" />
        <SimpleBar
          className={`${
            hideFilterObject.includes('deferred_transactions')
              ? 'simplebar-style-with-pagination'
              : 'simplebar-style-for-list-page-with-single-filter-line-button-below'
          }`}
        >
          <Card.Body className="p-0">
            {!isLoading && deferredData && deferredData?.data ? (
              <AdvanceTableWrapper
                columns={columns}
                data={deferredData.data ?? []}
                sortable
              >
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    size: 'sm',
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                  }}
                />
              </AdvanceTableWrapper>
            ) : (
              <div
                className=" d-flex align-item-center justify-content-center"
                style={{ height: '60vh' }}
              >
                <LoadingScreen message="Looking for data" />
              </div>
            )}
          </Card.Body>
        </SimpleBar>
        <Card.Footer className="border-top p-2">
          {!isLoading && deferredData && deferredData.links && (
            <AppPagination
              data={deferredData}
              url="/finance/deferred-transactions"
              search={allQueryParams}
            />
          )}
        </Card.Footer>
      </Card>
      <VoucherDetail
        show={showVoucherDetailArea}
        onHide={() => setShowVoucherDetailArea(false)}
        setShowVoucherDetailArea={setShowVoucherDetailArea}
        type={transType}
        voucherId={voucherId}
        offCanvas={true}
      />
      <ConfirmWarning
        show={showConfirmationBox && deferredIds?.length > 0}
        onHide={handleCancel}
        handleSubmit={handleSubmit}
        modalTitle={'Are you sure?'}
        content={'Are you sure you want to post this deferred transaction '}
        buttonTitle="Yes"
      />
    </>
  );
};

export default DeferredTransaction;
