import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Dropdown,
  Form,
  Modal,
  Row,
  Spinner
} from 'react-bootstrap';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import AppDatePicker from 'components/app-date-picker/AppDatePicker';
import { showToast } from 'module/Common/Toast/toast';
import axios from 'axios';
import ErrorAlert from 'module/Common/Error/ErrorAlert';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';
import generateFormDataHelper from 'helpers/generateFormDataHelper';
import { TbReportMoney } from 'react-icons/tb';
import { AiFillFileAdd } from 'react-icons/ai';
import Total from 'module/Common/Total';
import { additionalCostFormKeys } from 'helpers/formKeys';
import SelectSupplier from 'components/form/SelectSupplier';
import AdditionalCostPopUpDropdownLineItemSection from './AdditionalCostPopUpDropdownLineItemSection';
import SelectTaxGroup from 'components/form/SelectTaxGroup';
import removeRefData from 'helpers/removeRefData';
import SelectBankAccount from 'components/form/SelectBankAccount';

export default function AdditionalCostPopUpDropdownItem({ details }) {
  const Translate = useAxisproTranslate();
  const [show, setShow] = useState(false);
  const [formError, setFormError] = useState({});
  const [formData, setFormData] = useState(additionalCostFormKeys);
  const [isSaving, setIsSaving] = useState(false);

  const labelStyle = {
    minWidth: 120
  };
  const handleModal = () => {
    setFormData(additionalCostFormKeys);
    setFormError({});
    setIsSaving(false);
    setShow(!show);
  };

  const handleFieldChange = (e, action) => {
    let newFormData = generateFormDataHelper(e, action, formData);
    if (action?.name === 'supplier_id' && newFormData.account_code) {
      newFormData = {
        ...newFormData,
        bank_account_id: '',
        bank_account_id_ref: ''
      };
    }
    setFormData(newFormData);
  };

  const total = useMemo(() => {
    const totalTaxPercentage = formData?.tax_group_id_ref?.tax_details.tax_items
      .reduce((total, item) => {
        if (
          item?.effective_from_date &&
          formData?.trans_date >= item.effective_from_date
        ) {
          total = total + parseFloat(item.tax_percentage);
        }

        return total;
      }, 0)
      .toFixed(2);
    const totalAmount = formData?.unit_price || 0;
    let total_data = {
      taxAmount: 0,
      netTotal: 0
    };
    if (totalTaxPercentage > 0) {
      total_data.taxAmount =
        (parseFloat(totalAmount) * totalTaxPercentage) / 100;
    }

    total_data.netTotal = parseFloat(totalAmount) + total_data.taxAmount;

    return total_data;
  }, [
    formData?.unit_price,
    formData?.trans_date,
    formData?.tax_group_id_ref?.tax_details.tax_items
  ]);

  const handleSubmit = async event => {
    event.preventDefault();
    setFormError({});
    setIsSaving(true);
    const urlList = {
      PI: 'purchase/purchase-invoice-additional-cost'
    };

    try {
      let formDataWithoutRefkeys = {
        ...removeRefData(formData, 'object', {
          removeValue: [null, 'null']
        })
      };

      formDataWithoutRefkeys.gl_items = [
        ...removeRefData(formData?.gl_items, 'array', {
          removeValue: [null, 'null']
        })
      ];

      const apiResponse = await axios.post(urlList?.[details?.trans_type], {
        parent_invoice_id: details?.id,
        ...formData,
        discount_amount: '' //api need this key other wise showing 500 error
      });

      if (apiResponse?.status === 201 && apiResponse?.data?.success) {
        setShow(false);
        showToast(
          apiResponse?.data?.message ??
            'Transaction has been voided successfully',
          'success'
        );

        typeof details?.callBack === 'function' &&
          details.callBack(apiResponse);
      }
    } catch (error) {
      setFormError({ ...(error?.response?.data?.data?.errors ?? null) });
      if (error?.response?.data?.data.message) {
        return showToast(
          error?.response?.data?.data.message ??
            'Something went wrong, please refresh the page and try again.',
          'error'
        );
      }
      showToast(
        error?.response?.data?.message ??
          error?.message ??
          'Something went wrong, please refresh the page and try again.',
        'error'
      );
    } finally {
      setIsSaving(false);
    }
  };

  return details?.id && details?.trans_type ? (
    <>
      <Dropdown.Item
        className="d-flex align-items-center"
        onClick={handleModal}
      >
        <TbReportMoney className="me-1 text-dark" size={14} />
        {Translate('Add Additional Cost')}
      </Dropdown.Item>

      <Modal size="xl" show={show} onHide={handleModal}>
        <Modal.Header closeButton className="pb-2">
          <Modal.Title className="text-uppercase d-flex flex-column  gap-1 pb-0 mb-0">
            <div className="fw-bold">
              <AiFillFileAdd size={18} className="text-success" />
              {Translate('Add Additional Cost')}
            </div>
            <small className="ms-3 mb-0 pb-0">
              {Translate('Eg : Record import / Export expenses')}
            </small>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {typeof formError === 'object' &&
            Object.keys(formError).length > 0 && (
              <ErrorAlert formError={formError} setFormError={setFormError} />
            )}
          <Form onSubmit={handleSubmit} className="wo-ad-cost-form">
            <Row>
              <Col md={6}>
                {/* <Form.Group
                  className="mb-4 d-flex flex-row gap-4"
                  controlId="form.item_display_name"
                  as={Col}
                  md={12}
                >
                  <Form.Label className="require-data pe-2" style={labelStyle}>
                    {Translate('Expense Ledger')}
                  </Form.Label>
                  <div className="flex-fill d-flex flex-row align-items-center">
                    <div className="flex-fill ">
                      <SelectAccountDescriptions
                        keyName="code"
                        name="account_code"
                        placeholder={Translate('Search...')}
                        value={formData.account_code_ref}
                        activeAccountCode={formData?.account_code_option_ref}
                        handleFieldChange={handleFieldChange}
                        error={formError?.account_code}
                      />
                    </div>
                    <FormErrorPopover
                      id="account_code"
                      totalErrorCount={Object.keys(formError).length}
                      errorMessage={formError?.account_code}
                      addSpace
                    />
                  </div>
                </Form.Group> */}
                <Form.Group
                  className="mb-4 d-flex flex-row gap-4"
                  controlId="form.date"
                  as={Col}
                  md={12}
                >
                  <Form.Label className="require-data" style={labelStyle}>
                    {Translate('Date')}
                  </Form.Label>
                  <div className="flex-fill d-flex flex-row align-items-center">
                    <div className="flex-fill">
                      <AppDatePicker
                        name="trans_date"
                        value={formData.trans_date}
                        yearPlaceholder="yyyy"
                        monthPlaceholder="mm"
                        dayPlaceholder="dd"
                        onChange={handleFieldChange}
                        showDefaultDate
                        isInvalid={!!formError?.trans_date}
                      />
                    </div>
                    <FormErrorPopover
                      id="trans_date"
                      totalErrorCount={Object.keys(formError).length}
                      errorMessage={formError?.trans_date}
                      addSpace
                    />
                  </div>
                </Form.Group>
                {!formData?.supplier_id && (
                  <Form.Group
                    className="mb-4 d-flex flex-row gap-4"
                    controlId="form.bank_account_id"
                    as={Col}
                    md={12}
                  >
                    <Form.Label className="require-data " style={labelStyle}>
                      {Translate('Bank Account')}
                    </Form.Label>
                    <div className="flex-fill d-flex flex-row align-items-center">
                      <div className="flex-fill">
                        <SelectBankAccount
                          name="bank_account_id"
                          placeholder={Translate('Search...')}
                          value={formData.bank_account_id_ref}
                          handleFieldChange={handleFieldChange}
                          error={formError?.bank_account_id_ref}
                        />
                      </div>
                      <FormErrorPopover
                        id="bank_account_id"
                        totalErrorCount={Object.keys(formError).length}
                        errorMessage={formError?.bank_account_id}
                        addSpace
                      />
                    </div>
                  </Form.Group>
                )}
                <Form.Group
                  className="mb-4 d-flex flex-row gap-4 "
                  controlId="form.item_display_name"
                  as={Col}
                  md={12}
                >
                  <Form.Label className="require-data " style={labelStyle}>
                    {Translate('Tax Group')}
                  </Form.Label>
                  <div className="flex-fill d-flex flex-row align-items-center">
                    <div className="flex-fill">
                      <SelectTaxGroup
                        withTaxDetails
                        name="tax_group_id"
                        placeholder={Translate('Search...')}
                        value={formData.tax_group_id_ref}
                        onChange={handleFieldChange}
                        isClearable={false}
                      />
                    </div>
                    <FormErrorPopover
                      id="tax_group_id"
                      totalErrorCount={Object.keys(formError).length}
                      errorMessage={formError?.tax_group_id}
                      addSpace
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group
                  className="mb-4 d-flex flex-row gap-4"
                  controlId="form.stock_id"
                  md={12}
                >
                  <Form.Label style={labelStyle}>
                    {Translate('Supplier Name')}
                  </Form.Label>
                  <div className="flex-fill d-flex flex-row align-items-center">
                    <div className="flex-fill">
                      <SelectSupplier
                        value={formData.supplier_id_ref}
                        name="supplier_id"
                        handleFieldChange={handleFieldChange}
                        placeholder={Translate('Search...')}
                        style={{ borderRadius: 'none' }}
                        includeDetailsKey
                        error={formError?.supplier_id}
                        withAddButton
                      />
                    </div>
                    <FormErrorPopover
                      id="supplier_id"
                      totalErrorCount={Object.keys(formError).length}
                      errorMessage={formError?.supplier_id}
                      addSpace
                    />
                  </div>
                </Form.Group>
                <Form.Group
                  className="mb-4 d-flex flex-row gap-4"
                  controlId="form.stock_id"
                  as={Col}
                  md={12}
                >
                  <Form.Label style={labelStyle}>
                    {Translate('Supplier Reference')}
                  </Form.Label>
                  <div className="flex-fill d-flex flex-row align-items-center">
                    <div className="flex-fill">
                      <Form.Control
                        name="supplier_reference"
                        onChange={handleFieldChange}
                        value={formData.supplier_reference}
                        isInvalid={!!formError?.supplier_reference}
                      />
                    </div>

                    <FormErrorPopover
                      id="supplier_reference"
                      totalErrorCount={Object.keys(formError).length}
                      errorMessage={formError?.supplier_reference}
                      addSpace
                    />
                  </div>
                </Form.Group>
                <Form.Group
                  className="mb-4 d-flex flex-row gap-4"
                  controlId="form.unit_price"
                  as={Col}
                  md={12}
                >
                  <Form.Label className="require-data pe-0" style={labelStyle}>
                    {Translate('Expense Amount')}
                  </Form.Label>
                  <div className="flex-fill d-flex flex-row align-items-center">
                    <div className="flex-fill">
                      <Form.Control
                        type="number"
                        step="0.1"
                        min={0}
                        name="unit_price"
                        onChange={handleFieldChange}
                        value={formData.unit_price}
                      />
                    </div>
                    <FormErrorPopover
                      id="unit_price"
                      totalErrorCount={Object.keys(formError).length}
                      errorMessage={formError?.unit_price}
                      addSpace
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>

            <AdditionalCostPopUpDropdownLineItemSection
              orderDetailsArray={details?.orderDetailsArray}
              formData={formData}
              setFormData={setFormData}
              total={total}
            />

            <Total tax={total?.taxAmount} subTotal={total?.netTotal} />

            <Form.Group className="mb-3" controlId="note">
              <Form.Label>{Translate('Notes')}</Form.Label>
              <div className="d-flex flex-row align-items-center">
                <div className="flex-fill">
                  <Form.Control
                    type="text"
                    as="textarea"
                    name="note"
                    rows={5}
                    onChange={handleFieldChange}
                    value={formData.note}
                  />
                </div>
                <FormErrorPopover
                  id="note"
                  totalErrorCount={Object.keys(formError).length}
                  errorMessage={formError.note}
                />
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {!isSaving ? (
            <Button variant="danger" size="sm" onClick={handleModal}>
              {Translate('Cancel')}
            </Button>
          ) : null}

          <Button
            variant="success"
            size="sm"
            onClick={handleSubmit}
            disabled={isSaving}
            className="d-flex flex-row gap-1 align-items-center justify-content-center"
          >
            {isSaving ? (
              <>
                <Spinner
                  style={{
                    width: 15,
                    height: 15
                  }}
                />
                &nbsp;{Translate('Saving')}
              </>
            ) : (
              Translate('Save')
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  ) : null;
}

AdditionalCostPopUpDropdownItem.propTypes = {
  details: PropTypes.shape({
    id: PropTypes.string,
    trans_type: PropTypes.string,
    orderDetailsArray: PropTypes.array,
    callBack: PropTypes.func
  })
};
