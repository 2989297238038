export const removeKitDetails = data => {
  const filteredArray = data.filter(item => {
    if (item?.is_kit == 0) {
      return true;
    } else if (
      item?.is_kit == 1 &&
      item?.kit_items &&
      item?.kit_items.length > 0
    ) {
      return false;
    }
    return true;
  });
  return filteredArray;
};
// export const removeKitDetails = data => {
//   console.log(data);
//   const filteredArray = data.filter(item => {
//     return !(!('stock_id' in item) && 'kit_items' in item) || item.remove;
//   });
//   return filteredArray;
// };
// export const removeKitDetails = data => {
//   const filteredArray = data.filter(
//     element => !(element.remove === 'false' ? false : element.remove)
//   );
//   return filteredArray;
// }
// return console.log(
//   !((!('stock_id' in item) && 'kit_items' in item) || item.remove)
// );
// };

export const addValueInsidePropsField = (object, key, value) => {
  if (typeof object !== 'object' || object === null) {
    return;
  }
  let propsObject = {};
  propsObject[key] = value;
  object.props = JSON.stringify(propsObject);
  return object;
};
