import { React, useEffect, useState } from 'react';
import axios from 'axios';
import { Card, Col, Form, Modal, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { FaCentercode } from 'react-icons/fa';

import { showToast } from '../Toast/toast';
import { CancelButton, SaveButton } from '../Buttons/CommonButton';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import ErrorAlert from '../Error/ErrorAlert';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';
import { costCenterFormKeys } from 'helpers/formKeys';

function AddCostCenter({ show, onHide, setFormData, data }) {
  const Translate = useAxisproTranslate();
  const [costCenterData, setCostCenterData] = useState(costCenterFormKeys);
  const [onSave, setOnSave] = useState(false);
  const [formError, setFormError] = useState({});

  useEffect(() => {
    setCostCenterData(prevState => ({
      ...prevState,
      name: data
    }));
  }, [data]);

  const handleFieldChange = e => {
    setCostCenterData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    setFormError({});
    setOnSave(true);

    axios({
      method: 'post',
      url: 'finance/cost-centers',
      data: costCenterData
    })
      .then(response => {
        const responseData = response?.data;
        const data = responseData?.data;
        if (responseData?.success === true) {
          setCostCenterData({
            name: '',
            description: ''
          });
          onHide();
          showToast(responseData?.message, 'success');
          setFormData(prev => ({
            ...prev,
            cost_center_id: data.id,
            cost_center_id_ref: {
              label: data.name,
              value: data.id
            }
          }));
        }
        setOnSave(false);
      })
      .catch(error => {
        const errorData = error?.response?.data;
        if (errorData?.message) {
          const validation_error = errorData?.data?.errors
            ? errorData.data.errors
            : null;
          validation_error && setFormError({ ...validation_error });
        }
        setOnSave(false);
      });
  };

  const handleCancel = () => {
    onHide();
    setFormError({});
  };
  const handleClearForm = () => {
    setCostCenterData(costCenterFormKeys);
  };
  return (
    <Modal
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      className="modal-with-overlay"
      onHide={handleCancel}
    >
      <Card className="p-0">
        <Modal.Header
          closeButton
          className="d-flex flex-row align-items-center border-bottom"
        >
          <div className="d-flex align-items-center">
            <FaCentercode size={25} className="text-dark me-2 m-0" />
            <h5 className="modal-form-heading">
              {Translate('Add Cost Center')}
            </h5>
          </div>
        </Modal.Header>
        <Form>
          <Card.Body className="pb-0">
            {!Object.keys(formError).length == 0 ? (
              <ErrorAlert setFormError={setFormError} formError={formError} />
            ) : (
              ''
            )}
            <Row>
              <Form.Group as={Col} md={12} sm={12} className="mb-3">
                <Row>
                  <Col xl={4} md={4} sm={12}>
                    <Form.Label>{Translate('Name')}</Form.Label>
                  </Col>
                  <Col xl={8} md={8} sm={12}>
                    <Row className="d-flex flex-row">
                      <Col xl={11} md={11} sm={11} className="pe-sm-0 flex-2">
                        <Form.Control
                          type="text"
                          name="name"
                          onChange={handleFieldChange}
                          value={costCenterData.name}
                          autoFocus
                        />
                      </Col>
                      <Col
                        xl={1}
                        md={1}
                        sm={1}
                        className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0"
                      >
                        {formError.name ? (
                          <FormErrorPopover
                            id="name"
                            totalErrorCount={Object.keys(formError).length}
                            errorMessage={formError.name}
                          />
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group as={Col} md={12} sm={12} className="mb-3">
                <Row>
                  <Col xl={4} md={4} sm={12}>
                    <Form.Label>{Translate('Description')}</Form.Label>
                  </Col>
                  <Col xl={8} md={8} sm={12}>
                    <Row className="d-flex flex-row">
                      <Col xl={11} md={11} sm={11} className="pe-sm-0 flex-2">
                        <Form.Control
                          as="textarea"
                          rows={5}
                          name="description"
                          onChange={handleFieldChange}
                          value={costCenterData.description}
                        />
                      </Col>
                      <Col
                        xl={1}
                        md={1}
                        sm={1}
                        className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0"
                      >
                        {formError.description ? (
                          <FormErrorPopover
                            id="description"
                            totalErrorCount={Object.keys(formError).length}
                            errorMessage={formError.description}
                          />
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Group>
            </Row>
          </Card.Body>
          <Card.Footer className="d-flex flex-row gap-2 justify-content-start border-top">
            <SaveButton
              handleSubmit={handleSubmit}
              onSave={onSave}
              createPermission={true}
              editPermission={true}
            />
            <CancelButton
              handleCancel={handleClearForm}
              onSave={onSave}
              clear={true}
            />
          </Card.Footer>
        </Form>
      </Card>
    </Modal>
  );
}

AddCostCenter.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  setFormData: PropTypes.func,
  data: PropTypes.any
};

export default AddCostCenter;
