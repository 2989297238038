import { React, useCallback, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Badge, Toast } from 'react-bootstrap';

import { BsFillChatQuoteFill } from 'react-icons/bs';

import { AuthWizardContext } from 'context/Context';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import MessengerOffCanvas from 'components/chat/MessengerOffCanvas';

const ChatNotification = () => {
  const Translate = useAxisproTranslate();
  const [showChatWindow, setShowChatWindow] = useState(false);
  const { user } = useContext(AuthWizardContext);
  const [messageCount, setMessageCount] = useState();
  const [showToast, setShowToast] = useState(false);

  const getUnreadMessageCount = useCallback(() => {
    axios
      .get(`user/chats/${user.id}/get-unread-message-count`)
      .then(response => {
        if (response && response.data && response.data.data > 0) {
          setMessageCount(response.data.data);
        }
      });
  }, [user.id]);

  const getChatUsers = () => {
    setMessageCount(0);
    setShowChatWindow(true);
  };

  useEffect(() => {
    getUnreadMessageCount();
  }, [getUnreadMessageCount]);

  useEffect(() => {
    setTimeout(() => {
      setShowToast(false);
    }, 5000);
  }, [showToast]);

  useEffect(() => {
    const currentURL = window.location.href;
    const urlObject = new URL(currentURL);
    const domain = urlObject.hostname;
    window.Echo.channel(`user_chat_${domain}_${user.id}`).listen(
      'ChatBroadcast',
      () => {
        getUnreadMessageCount();
        setShowToast(true);
      }
    );
    return () => {
      setShowToast(false);
      window.Echo.channel(`user_chat_${domain}_${user.id}`).stopListening(
        'ChatBroadcast'
      );
    };
  }, [getUnreadMessageCount, user.id]);

  return (
    <>
      <div
        style={{
          position: 'relative',
          display: 'inline-block',
          cursor: 'pointer'
        }}
      >
        <BsFillChatQuoteFill
          onClick={getChatUsers}
          size={20}
          className="ms-1 me-1"
          title={Translate('Messenger')}
        />
        {messageCount > 0 && (
          <Badge
            bg="success"
            pill
            style={{
              position: 'absolute',
              top: '-10px',
              right: '-10px'
            }}
          >
            {messageCount}
          </Badge>
        )}
      </div>

      <MessengerOffCanvas
        show={showChatWindow}
        setShowChatWindow={setShowChatWindow}
      />
      <Toast
        show={showToast}
        onClose={() => setShowToast(false)}
        style={{
          position: 'absolute',
          top: '4.5rem',
          right: '10px',
          backgroundColor: '#00D27A',
          color: 'white'
        }}
      >
        <Toast.Body>
          {messageCount > 1
            ? Translate('You have') + messageCount + Translate('new messages')
            : Translate('You have a new message')}
        </Toast.Body>
      </Toast>
    </>
  );
};

export default ChatNotification;
