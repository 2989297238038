import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import SubCategoryTree from './SubCategorySelect/SubCategoryTree';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
const SubCategoryFilter = ({ formData, item, setFormData, filterForm }) => {
  const [showSubCategoryModal, setShowSubCategoryModal] = useState(false);
  const Translate = useAxisproTranslate();
  return (
    <div className="d-flex align-items-center justify-content-between">
      <Form.Control
        name="sub_category_id"
        className="bg-light"
        disabled
        style={{ backgroundColor: 'transparent' }}
        value={formData[item.name + '_name'] ?? ''}
      />
      <Button
        variant="light"
        className="d-flex align-items-center justify-content-center sub-category-fileter-choose-button"
        onClick={() => setShowSubCategoryModal(true)}
        style={{
          borderColor: 'darkgrey',
          height: '32px',
          fontSize: '12px'
        }}
        disabled={!formData?.category_id}
      >
        {Translate('Choose')}
      </Button>
      <SubCategoryTree
        show={showSubCategoryModal}
        setShow={setShowSubCategoryModal}
        setFromData={setFormData}
        categoryId={formData?.category_id}
        name={filterForm?.subCategory[0]?.name}
      />
    </div>
  );
};
SubCategoryFilter.propTypes = {
  formData: PropTypes.object,
  item: PropTypes.object,
  setFormData: PropTypes.func,
  filterForm: PropTypes.object
};
export default SubCategoryFilter;
