import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';

const NavbarVerticalMenuItem = ({ route, open, handleNavItemClick }) => {
  return (
    <>
      <div
        onClick={handleNavItemClick}
        className={route.children && open ? 'active-style-with-children' : ''}
      >
        <Flex alignItems="center">
          {route.icon && (
            <span className="nav-link-icon ms-2 p-1">
              <FontAwesomeIcon icon={route.icon} />
            </span>
          )}
          <span
            className={
              route.icon
                ? 'nav-link-text ps-1 hover-style'
                : route?.children && route?.children?.length > 0
                ? 'hover-style nav-link-text ps-1 ms-1'
                : 'hover-style nav-link-text ps-0'
            }
          >
            {route.name}
          </span>
          {route.badge && (
            <SoftBadge pill bg={route.badge.type} className="ms-2">
              {route.badge.text}
            </SoftBadge>
          )}
        </Flex>
      </div>
    </>
  );
};

const routeShape = {
  active: PropTypes.bool,
  name: PropTypes.string,
  to: PropTypes.string,
  icon: PropTypes.any
};
routeShape.children = PropTypes.arrayOf(PropTypes.shape(routeShape));
NavbarVerticalMenuItem.propTypes = {
  route: PropTypes.any,
  open: PropTypes.bool,
  isNavbarVerticalCollapsed: PropTypes.bool,
  handleNavItemClick: PropTypes.func
};

export default React.memo(NavbarVerticalMenuItem);
