import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Main from './Main';
import 'helpers/initFA';
import { BrowserRouter as Router } from 'react-router-dom';
import AuthProvider from 'providers/AuthProvider';
import TabViewContentReloadHandlerContextProvider from 'providers/TabViewContentReloadHandlerContextProvider';
import ShortCutKeyController from 'module/Common/ArrowKeyController/ShortCutKeyController';
import ErrorBoundary from 'ErrorBoundary ';
import TranslationsContextProvider from 'providers/TranslationsContextProvider';
import BroadcastChannelContextProvider from 'providers/BroadcastChannelContextProvider';

const root = ReactDOM.createRoot(document.getElementById('main'));
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <BroadcastChannelContextProvider>
        <Main>
          <Router basename={process.env.PUBLIC_URL}>
            <TranslationsContextProvider>
              <AuthProvider>
                <ShortCutKeyController>
                  <TabViewContentReloadHandlerContextProvider>
                    <App />
                  </TabViewContentReloadHandlerContextProvider>
                </ShortCutKeyController>
              </AuthProvider>
            </TranslationsContextProvider>
          </Router>
        </Main>
      </BroadcastChannelContextProvider>
    </ErrorBoundary>
  </React.StrictMode>
);

document.addEventListener('wheel', function () {
  if (document.activeElement.type === 'number') {
    document.activeElement.blur();
  }
});
