import {
  React,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState
} from 'react';
import axios from 'axios';
import { Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';
import AppContext, {
  AuthWizardContext,
  TranslationsContext
} from 'context/Context';
import authReducer from 'reducers/authReducer';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import {
  getBaseUrl,
  getLoggedUserInfo,
  handleUserWebSocketConnection,
  setAssignedPermissions,
  setMenuLinks,
  setTranslations
} from 'helpers/authProviderFunc';

export const authProviderIsReadyKey = 'authProviderIsReady';
const AuthProvider = ({ children }) => {
  const { config: appConfig, setConfig: setAppConfig } = useContext(AppContext);
  const authProviderIsReady = appConfig[authProviderIsReadyKey];

  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [user, dispatch] = useReducer(
    authReducer,
    useContext(AuthWizardContext)
  );

  const { dispatchTranslations } = useContext(TranslationsContext);

  const lockScreen = useCallback(() => {
    dispatch({ type: 'UPDATE', payload: { ...user, lockScreen: true } });
  }, [user]);

  useEffect(() => {
    if (authProviderIsReady) return;
    let loggedUserData;
    const init = async () => {
      try {
        setLoading(true);
        setErrorMessage(null);

        // set application base url
        const baseURLData = await getBaseUrl();

        if (!baseURLData?.api_url) {
          setErrorMessage('Invalid host.!');
          return;
        }

        axios.defaults.baseURL = baseURLData.api_url;
        localStorage.setItem(
          'ACODAX_MULTI_TENANCEY',
          Boolean(baseURLData?.multi_tenancy)
        );
        // set application base url end

        // set logged user updated details
        loggedUserData = await getLoggedUserInfo();
        if (
          !loggedUserData?.id ||
          !loggedUserData?.branch_id ||
          !loggedUserData?.token
        )
          return;

        //  set menu links
        const menuLinks = await setMenuLinks();
        loggedUserData.menu_links = Array.isArray(menuLinks) ? menuLinks : [];
        //  set menu links end

        dispatch({
          type: 'UPDATE',
          payload: loggedUserData
        });
        // set logged user updated details end

        // set permissions data
        await setAssignedPermissions();
        // set permissions data end

        // set translations data
        const translations = await setTranslations(loggedUserData?.language);
        dispatchTranslations({
          type: 'SET',
          payload: translations
        });
        // set translations data end

        // start webSocket connections
        handleUserWebSocketConnection(loggedUserData, 'START', {
          AcodaxAppBranchBroadCast: async () => {
            loggedUserData = await getLoggedUserInfo();
            if (
              loggedUserData?.id &&
              loggedUserData?.branch_id &&
              loggedUserData?.token
            ) {
              dispatch({
                type: 'UPDATE',
                payload: loggedUserData
              });
            } else {
              dispatch({
                type: 'DELETE'
              });
            }
          },
          AcodaxAppUserBroadCast: async () => {
            // set permissions data
            await setAssignedPermissions(true);
            // set permissions data end

            //  set menu links
            const menuLinks = await setMenuLinks(true);
            if (Array.isArray(menuLinks)) {
              dispatch({
                type: 'ADD',
                payload: {
                  menu_links: menuLinks
                }
              });
            }
            //  set menu links end
          }
        });

        setAppConfig(authProviderIsReadyKey, true);
        setLoading(false);
      } catch (error) {
        console.error('error', error);
        if (error?.invalidUser || error?.requestName === 'user') {
          dispatch({
            type: 'DELETE'
          });
          setLoading(false);
        } else {
          setErrorMessage(
            error?.requestName === 'host'
              ? 'Invalid host.!'
              : error?.requestName === 'menuLinks'
              ? 'Failed to set menu links. Please try again, or contact the system administrator.'
              : error?.requestName === 'permission'
              ? 'Failed to set permissions. Please try again, or contact the system administrator.'
              : error?.requestName === 'translation'
              ? 'Failed to set translations. Please try again, or contact the system administrator.'
              : error?.message ||
                'Something went wrong. Please try again later, or contact the system administrator.'
          );
        }
      }
    };

    init();
    return () => {
      if (loggedUserData?.id && loggedUserData?.branch_id) {
        handleUserWebSocketConnection(loggedUserData, 'STOP');
      }
    };
  }, [dispatchTranslations, authProviderIsReady, setAppConfig]);

  return (
    <AuthWizardContext.Provider
      value={{
        user,
        dispatch,
        lockScreen
      }}
    >
      {loading ? (
        <div
          style={{ width: '100%', minHeight: '100vh' }}
          className="d-flex justify-content-center align-items-center"
        >
          {errorMessage ? (
            <Alert variant="danger">{errorMessage}</Alert>
          ) : (
            <LoadingScreen />
          )}
        </div>
      ) : (
        children
      )}
    </AuthWizardContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.any.isRequired
};
export default AuthProvider;
