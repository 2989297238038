import { React, useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Col, Form, Modal, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { MdCategory } from 'react-icons/md';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { showToast } from '../Toast/toast';
import { CancelButton, SaveButton } from '../Buttons/CommonButton';
import ErrorAlert from '../Error/ErrorAlert';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';

function AddSalesType({ show, onHide, setFormData, setSalesType, data }) {
  const Translate = useAxisproTranslate();
  const [salesTypeData, setSalesTypeData] = useState({
    name: '',
    tax_included: ''
  });
  const [onSave, setOnSave] = useState(false);
  const [formError, setFormError] = useState({});

  const handleFieldChange = e => {
    setSalesTypeData({
      ...salesTypeData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    setFormError({});
    setOnSave(true);

    axios({
      method: 'post',
      url: 'settings/sales-types',
      data: salesTypeData
    })
      .then(response => {
        if (response.data.success === true) {
          {
            setSalesType ? setSalesType([response.data.data.id]) : '';
          }
          setSalesTypeData({
            name: '',
            tax_included: ''
          });
          onHide();
          showToast(response.data.message, 'success');
          setFormData(prev => ({
            ...prev,
            sales_type_id: response.data.data.id,
            sales_type_id_ref: {
              label: response.data.data.name,
              value: response.data.data.id
            }
          }));
        }
        setOnSave(false);
      })
      .catch(error => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          console.log(error);
        }
        setOnSave(false);
      });
  };

  const handleCancel = () => {
    onHide();
    setFormError({});
  };

  useEffect(() => {
    if (data) {
      setSalesTypeData(prevData => ({
        ...prevData,
        name: data
      }));
    }
  }, [data, salesTypeData]);

  const handleClearForm = () => {
    setSalesTypeData({
      name: '',
      tax_included: ''
    });
  };
  return (
    <Modal
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      className="modal-with-overlay"
      onHide={handleCancel}
    >
      <Card className="p-0">
        <Modal.Header
          className="d-flex flex-row align-items-center border-bottom"
          closeButton
        >
          <MdCategory size={25} className="text-dark me-1 m-0" />
          <h5 className="modal-form-heading">{Translate('Add Sales Type')}</h5>
        </Modal.Header>
        <Form>
          <Card.Body className="pb-0">
            {!Object.keys(formError).length == 0 ? (
              <ErrorAlert setFormError={setFormError} formError={formError} />
            ) : (
              ''
            )}
            <Row>
              <Form.Group as={Col} md={12} sm={12} className="mb-3">
                <Row>
                  <Col xl={5} md={5} sm={12}>
                    <Form.Label>{Translate('Name')}</Form.Label>
                  </Col>
                  <Col xl={7} md={7} sm={12}>
                    <Row className="d-flex flex-row">
                      <Col xl={9} md={9} sm={11} className="pe-sm-0 flex-2">
                        <Form.Control
                          type="text"
                          name="name"
                          onChange={handleFieldChange}
                          value={salesTypeData.name}
                          autoFocus
                        />
                      </Col>
                      <Col
                        xl={1}
                        md={1}
                        sm={1}
                        className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0"
                      >
                        {formError.name ? (
                          <FormErrorPopover
                            id="name"
                            totalErrorCount={Object.keys(formError).length}
                            errorMessage={formError.name}
                          />
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group as={Col} md={12} sm={12} className="mb-3">
                <Row>
                  <Col xl={5} md={5} sm={12}>
                    <Form.Label>{Translate('Tax Included')}</Form.Label>
                  </Col>
                  <Col xl={7} md={7} sm={12}>
                    <Row className="d-flex flex-row">
                      <Col xl={9} md={9} sm={11} className="pe-sm-0 flex-2">
                        <Form.Select
                          name="tax_included"
                          onChange={handleFieldChange}
                          value={salesTypeData.tax_included}
                        >
                          <option>{Translate('Select')}</option>
                          <option value={0}>{Translate('No')}</option>
                          <option value={1}>{Translate('Yes')}</option>
                        </Form.Select>
                      </Col>
                      <Col
                        xl={1}
                        md={1}
                        sm={1}
                        className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0"
                      >
                        {formError.tax_included ? (
                          <FormErrorPopover
                            id="tax_included"
                            totalErrorCount={Object.keys(formError).length}
                            errorMessage={formError.tax_included}
                          />
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Group>
            </Row>
          </Card.Body>
          <Card.Footer className="d-flex flex-row gap-2 justify-content-start border-top">
            <SaveButton
              handleSubmit={handleSubmit}
              onSave={onSave}
              createPermission={true}
              editPermission={true}
            />
            <CancelButton
              handleCancel={handleClearForm}
              onSave={onSave}
              clear={true}
            />
          </Card.Footer>
        </Form>
      </Card>
    </Modal>
  );
}

AddSalesType.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  setFormData: PropTypes.func,
  setSalesType: PropTypes.func,
  data: PropTypes.string
};

export default AddSalesType;
